import React, { useMemo, useState, useCallback } from "react";
import * as R from "ramda";
import { Button, ButtonGroup, Tooltip } from "react-bootstrap";
import { MdOpenInNew, MdWarning } from "react-icons/md";

import {
  EditsMap,
  NielsenEstimates,
  calculateAudienceInfo,
  RotationsAndPricing,
  RotationPricing,
  time24hr,
  FormattedRow,
  weekFormatter,
  isMultiDatePicker,
  getFormattedRow,
  isNew,
  createNewRowFromSpots,
  findOldMetricNumber,
  findOldMetricString,
  isMultiWeekEdited,
  isNewRowInAggregate,
  deepMergeLinearImpressionOverrides,
} from "./linearBuyingUtils";
import {
  PlanRow,
  RowEdit,
  TYPES,
  TYPES_TO_NAMES,
  TYPES_MAP,
  LENGTHS,
  AVAILS,
} from "@blisspointmedia/bpm-types/dist/LinearBuying";
import {
  ABBREVIATED_MEAUREMENT_NAMES,
  CREATIVE_LENGTH_OPTIONS,
  CURRENT_WEEK_START,
  DAYS_OF_WEEK,
  MEDIA_CLASSIFICATION_OPTIONS,
  ORDER_TYPE_OPTIONS,
  PRETTY_DEMO_NAMES,
} from "./linearBuyingConstants";
import {
  BPMTable,
  BPMButton,
  CheckBox,
  SelfFocusingInput,
  SelfFocusingSelect,
  OverlayTrigger,
  CellRenderer,
} from "../Components";

import { formatMoney, formatNumber, formatNumberAsInt, formatCAD } from "../utils/format-utils";
import { convert24hrTo12hr } from "../utils/time-utils";
import { StateSetter } from "../utils/types";
import { CampaignMetadata } from "./LinearBuying";
import "./EditTableView.scss";
import { useExperimentFlag } from "../utils/experiments/experiment-utils";
import * as Dfns from "date-fns/fp";
import AddOrderedImpressionsModal from "./Modals/AddOrderedImpressionsModal";

// This maps the Type to the equivalent value in Rotations and Pricing (the "status" field).
// We use this when changing the Type or Rotation and need to set the row values.
// TODO: Eventually we will add type to linear rates and not use the status field anymore.
const TYPE_TO_STATUS_IN_NETWORK_PRICING = {
  [TYPES_MAP.Regular]: "buyable",
  [TYPES_MAP.Secured]: "secured",
  [TYPES_MAP.General]: "general",
  [TYPES_MAP.Remnant]: "remnant",
  [TYPES_MAP.Upfront]: "upfront",
} as const;

interface FormattedRowTableType {
  count: number | string;
  cpm: number | string;
  impressions: number | string;
  totalSpend: number | string;
  trp: number | string;
}

interface totalsRowAggSpots {
  [weekOf: string]: number;
}

const renderTotals: CellRenderer<Element | number | string | undefined> = ({
  data,
  style = {},
  classes = [],
}) => {
  return (
    <div style={style} className={[...classes, "grandTotalCell"].join(" ")}>
      {data}
    </div>
  );
};

const isUpfront = (type: number) => {
  return TYPES_TO_NAMES[type] === "Upfront";
};

/**
 * Use this when changing Rotation or Type to get new values from Rotations and Pricing.
 * If type is general, use either general or buyable. Otherwise, we want them to match.
 */
const shouldUseRotation = (
  formattedType: "buyable" | "secured" | "general" | "remnant" | "upfront",
  status: string
) => {
  if (formattedType === "general") {
    return formattedType === status || status === "buyable";
  } else {
    return formattedType === status;
  }
};

/**
 * Checks which type options to show for a given network.
 * TODO: change this once we add type to linear rates.
 */
const getTypeOptions = (
  networkPricing: Record<string, RotationPricing[]>
): Record<string, string | number>[] => {
  const typesToUse = {
    Regular: false,
    Secured: false,
    Remnant: false,
    Upfront: true,
    // Always show General type as an option.
    General: true,
  };

  for (let avail of R.keys(networkPricing)) {
    for (let rotation of networkPricing[avail]) {
      if (rotation.status === "buyable") {
        typesToUse.Regular = true;
      }
      if (rotation.status === "secured") {
        typesToUse.Secured = true;
      }
      if (rotation.status === "upfront") {
        typesToUse.Upfront = true;
      }
    }
  }

  return ORDER_TYPE_OPTIONS.filter(type => typesToUse[type.label]);
};

/**
 * Checks which days exist in dow array.
 */
const makeDaysOfWeekMap = (dow: string[]) => {
  let daysMap = {
    M: dow.includes("M"),
    Tu: dow.includes("Tu"),
    W: dow.includes("W"),
    Th: dow.includes("Th"),
    F: dow.includes("F"),
    Sa: dow.includes("Sa"),
    Su: dow.includes("Su"),
  };
  return daysMap;
};

/**
 * returns CAD or USD
 */
const currencyFormatter = (cost: number, numDecimals: number, CAD: boolean | string): string => {
  return CAD ? formatCAD(cost, numDecimals) : formatMoney(cost, numDecimals);
};

/**
 * Check if the plan row has a pending edit of the given type.
 */
const isEdited = (row: PlanRow, editType: string) => {
  if (row.edits && R.has(editType, row.edits)) {
    return true;
  } else {
    return false;
  }
};

/**
 * If there's an edited value, use that. Otherwise, use the original value.
 */
const valueToUse = (row: PlanRow, editType: string) => {
  if (row.edits && R.has(editType, row.edits)) {
    return row.edits[editType];
  } else {
    return row[editType];
  }
};

/**
 * For aggregate row  -- If there's an edited value, use that. Otherwise, use the original value.
 */
const valueToUseAggregate = (
  row: PlanRow,
  formattedRow: FormattedRow,
  editType: string,
  aggregateProp: string
) => {
  if (row) {
    if (row.edits && R.has(editType, row.edits)) {
      return row.edits[editType];
    } else {
      return row[editType];
    }
  }
  return formattedRow[aggregateProp];
};

/**
 * Returns the rotation options for the given row.
 */
const getRotationOptions = (
  row: FormattedRow,
  rotations: RotationPricing[]
): {
  label: string;
  value: string;
}[] => {
  if (!rotations) {
    return [];
  }
  const currentType: typeof TYPES[number] = valueToUse(row, "type");
  const currentStatus = TYPE_TO_STATUS_IN_NETWORK_PRICING[currentType];
  return rotations
    .filter(rotation => {
      if (currentStatus === "general") {
        return rotation.status === currentStatus || rotation.status === "buyable";
      } else {
        return rotation.status === currentStatus;
      }
    })
    .map(rotation => {
      return {
        label: rotation.rotationLabel,
        value: rotation.rotationLabel,
      };
    });
};

/**
 * Returns the market options for the given row.
 */
const getMarketOptions = (
  row: FormattedRow,
  markets: RotationPricing[]
): {
  label: string | null;
  value: string | null;
}[] => {
  if (!markets) {
    return [];
  }
  const currentType: typeof TYPES[number] = valueToUse(row, "type");
  const currentStatus = TYPE_TO_STATUS_IN_NETWORK_PRICING[currentType];

  return markets
    .filter(rotation => {
      if (currentStatus === "general") {
        return (
          (rotation.status === currentStatus || rotation.status === "buyable") &&
          rotation.market !== null
        );
      } else {
        return rotation.status === currentStatus && rotation.market !== null;
      }
    })
    .map(rotation => {
      return {
        label: rotation.market,
        value: rotation.market,
      };
    });
};

const getAvailOptions = (networkPricing: Record<string, RotationPricing[]>) => {
  return R.keys(networkPricing).map(avail => ({
    label: avail,
    value: avail,
  }));
};

interface EditTableViewProps {
  rows: PlanRow[];
  editsMap: EditsMap;
  rotationsAndPricing: RotationsAndPricing;
  nielsenEstimates: NielsenEstimates;
  selectedDemos: Record<string, boolean>;
  selectedMeasurement: string;
  universeEstimate: number | undefined;
  campaignMetadata: CampaignMetadata;
  weeks: string[];
  selectedRows: Record<string, PlanRow>;
  setNewRows: StateSetter<Record<string, PlanRow>>;
  setEditsMap: StateSetter<EditsMap>;
  setSelectedRows: StateSetter<Record<string, PlanRow>>;
  setShowCreativeAllocationModal: StateSetter<boolean>;
  setCreativeAllocationModalData: StateSetter<any>;
  setCreativeAllocationModalDataV2: StateSetter<any>;
}

const EditTableView: React.FC<EditTableViewProps> = ({
  rows,
  editsMap,
  rotationsAndPricing,
  nielsenEstimates,
  selectedDemos,
  selectedMeasurement,
  universeEstimate,
  campaignMetadata,
  weeks,
  selectedRows,
  setNewRows,
  setEditsMap,
  setSelectedRows,
  setShowCreativeAllocationModal,
  setCreativeAllocationModalData,
  setCreativeAllocationModalDataV2,
}) => {
  const enableCampaigns = useExperimentFlag("enableLinearCampaigns");
  const enableRadioBuying = useExperimentFlag("enableRadioBuying");
  const enableLinearBuyingUpdates = useExperimentFlag("enableAllLinearBuyingChanges");
  const enableCAD = useExperimentFlag("enableCADLinearCompanies");

  const [focusedCell, setFocusedCell] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const isMultiWeek = useMemo(() => isMultiDatePicker(weeks), [weeks]);

  const editRow = useCallback(
    (row: PlanRow, edits: RowEdit, key: string, isNewRow: boolean) => {
      if (isNewRow) {
        setNewRows(current => ({ ...current, [key]: { ...current[key], ...edits } }));
      } else {
        setEditsMap(current => ({
          ...current,
          [key]: { ...row, edits: { ...R.path([key, "edits"], current), ...edits } },
        }));
      }
    },
    [setEditsMap, setNewRows]
  );

  const selectRow = useCallback(
    (row: FormattedRow) => {
      const { key } = row;
      if (R.has(key, selectedRows)) {
        setSelectedRows(current => R.omit([key], current));
      } else {
        setSelectedRows(current => ({ ...current, [key]: row }));
      }
    },
    [selectedRows, setSelectedRows]
  );

  const formattedRows: FormattedRow[] = useMemo(
    () =>
      (rows || []).map((row: PlanRow) => {
        let rowToUse = row;
        if (editsMap[row.key]) {
          rowToUse = editsMap[row.key];
        }
        //impressions for buys if not upfront type but in linear buying
        const formattedType: string = TYPES_TO_NAMES[`${rowToUse.type}`];
        const totalSpend = rowToUse.count * rowToUse.cost;
        let overriddenImpressions = row.impressions;

        // Calculate CPM for ordered impressons if it's an upfront buy
        let orderedCpm = 0;
        if (isUpfront(rowToUse.type) && rowToUse.orderedImpressions && enableLinearBuyingUpdates) {
          orderedCpm = (totalSpend / rowToUse.orderedImpressions) * 1000;
          if (orderedCpm === Infinity) {
            orderedCpm = 0;
          }
        }

        // For all buying types rowToUse.edits.impressions is always updated if the impressions or secondary impressions column is changed
        // If impressionOverrides (secondary impressions on site) is changed for upfront type, rowToUse.edits.impressions and rowToUse.edits.impressionOverrides both are updated
        // Impressions is always the number displayed in impressions or secondary impressions on site so impressions should match the impressions in impressionsOverride object for upfront types
        let impressions, cpm, trp;
        //if type is upfront && impressions have been saved to the database
        if (
          isUpfront(rowToUse.type) &&
          overriddenImpressions !== 0 &&
          !R.isNil(overriddenImpressions)
        ) {
          impressions = overriddenImpressions || 0;
          cpm = (totalSpend / overriddenImpressions) * 1000 || 0;
          trp = 0; // TODO: Wait for confirmation from Michelle about using Impression Overrides to calculate TRP
        } else {
          //upfront buys without impressions or all other buy types will display nielsens
          //unless there is an overriden impressions for non-upfront buy types saved in database
          ({ impressions, cpm, trp } = calculateAudienceInfo({
            row: { ...rowToUse, ...rowToUse.edits },
            nielsenEstimates,
            universeEstimate,
          }));
        }
        const data: FormattedRow = {
          ...rowToUse,
          formattedType,
          totalSpend,
          impressions: overriddenImpressions || impressions,
          cpm,
          trp,
          orderedCpm, // TODO: Wait for confirmation from Michelle about including Ordered TRP as well
          rows: [],
        };
        return data;
      }),
    [rows, editsMap, enableLinearBuyingUpdates, nielsenEstimates, universeEstimate]
  );

  const totalsRow = useMemo(() => {
    if (R.isEmpty(formattedRows)) {
      return;
    }
    let aggSpotsTotals: totalsRowAggSpots = {};
    for (let week of weeks) {
      aggSpotsTotals[weekFormatter(week)] = 0;
    }
    let totalCount = 0;
    let totalSpend = 0;
    let totalImpressions = 0;
    let totalTRP = 0;
    for (let row of formattedRows) {
      if (editsMap[row.key]) {
        row = { ...row, ...editsMap[row.key].edits };
      }
      const spend = row.count * row.cost;
      aggSpotsTotals[weekFormatter(row.week)] += row.count || 0;
      totalCount += row.count;
      totalSpend += spend;
      totalImpressions += row.impressions || 0;
      totalTRP += row.trp || 0;
    }

    let totalCPM = (totalSpend / totalImpressions) * 1000;
    if (totalCPM === Infinity) {
      totalCPM = 0;
    }

    const total = {
      count: formatNumberAsInt(totalCount),
      totalSpend: currencyFormatter(totalSpend, 0, enableCAD),
      impressions: formatNumberAsInt(totalImpressions),
      cpm: currencyFormatter(totalCPM, 2, enableCAD),
      trp: formatNumber(totalTRP, 1),
      ...aggSpotsTotals,
    };
    return total;
  }, [formattedRows, editsMap, weeks, enableCAD]);

  const aggregatedRows: FormattedRow[] = useMemo(() => {
    return formattedRows.reduce((acc, row) => {
      //check dates and return
      if (!isMultiWeek || R.isEmpty(formattedRows)) {
        return acc;
      }
      const {
        company,
        channel,
        network,
        avail,
        rotation,
        length,
        dow,
        daypart_start,
        daypart_end,
        cost,
        type,
        campaign_id,
        media_classification,
        market,
        orderedImpressionsDemo,
        orderedImpressionsMeasurement,
      } = row;
      let key: string;
      if (row?.edits) {
        key = `${network}_${row.edits?.avail || row.avail}_${row.edits?.length || length}_${
          row.edits?.dow || row.dow
        }_${row.edits?.daypart_start || row.daypart_start}_${
          row.edits?.daypart_end || row.daypart_end
        }_${row.edits?.cost || row.cost}_${row.edits?.rotation || row.rotation}_${
          row.edits?.type || row.type
        }_${row.edits?.campaign_id || row.campaign_id}_${
          row.edits?.media_classification || row.media_classification
        }_${market}`;
      } else {
        key = `${network}_${avail}_${length}_${dow}_${daypart_start}_${daypart_end}_${cost}_${rotation}_${type}_${campaign_id}_${media_classification}_${market}`;
      }
      if (!acc[key]) {
        acc[key] = {
          company,
          channel,
          network,
          avail,
          length,
          dow,
          daypart_start,
          daypart_end,
          cost,
          rotation,
          type,
          campaign_id,
          media_classification,
          market,
          key,
          notes: "",
          cpm: 0,
          count: 0,
          impressions: 0,
          totalSpend: 0,
          trp: 0,
          creatives: null,
          rows: [],
          edits: false,
          isNewRow: false,
          orderedImpressions: 0,
          orderedImpressionsDemo,
          orderedImpressionsMeasurement,
          orderedCpm: 0,
        };
      }
      acc[key].avail = valueToUse(row, "avail");
      acc[key].market = row.market;
      acc[key].length = valueToUse(row, "length");
      acc[key].dow = valueToUse(row, "dow");
      acc[key].daypart_start = valueToUse(row, "daypart_start");
      acc[key].daypart_end = valueToUse(row, "daypart_end");
      acc[key].rotation = valueToUse(row, "rotation");
      acc[key].cost = valueToUse(row, "cost");
      acc[key].type = valueToUse(row, "type");
      acc[key].campaign_id = valueToUse(row, "campaign_id");
      acc[key].media_classification = valueToUse(row, "media_classification");
      acc[key].creatives = valueToUse(row, "creatives");
      // row.impressions already takes edits into account
      acc[key].impressions += row.impressions || 0;
      acc[key].count += valueToUse(row, "count") || 0;
      acc[key].totalSpend = acc[key].count * acc[key].cost;
      acc[key].cpm = (acc[key].totalSpend / acc[key].impressions) * 1000;
      if (acc[key].cpm === Infinity) {
        acc[key].cpm = 0;
      }
      acc[key].trp += row.trp || 0;
      acc[key].orderedImpressions += valueToUse(row, "orderedImpressions") || 0;
      acc[key].orderedCpm = (acc[key].totalSpend / acc[key].orderedImpressions) * 1000;
      if (acc[key].orderedCpm === Infinity) {
        acc[key].orderedCpm = 0;
      }
      acc[key].rowId = row.key;
      acc[key].rows = R.concat(acc[key].rows, [row]);
      acc[key][weekFormatter(row.week)] = row.count || 0;
      if (row.edits?.notes) {
        acc[key].notes += `${Dfns.format("M/d/yyyy", Dfns.parseISO(row.week))}: ${
          row.edits?.notes
        } `;
      } else if (row.notes) {
        acc[key].notes += `${Dfns.format("M/d/yyyy", Dfns.parseISO(row.week))}: ${row.notes} `;
      }
      return acc;
    }, []);
  }, [formattedRows, isMultiWeek]);
  const editedAggregatedRows: FormattedRow[] = useMemo(() => {
    if (aggregatedRows) {
      let editedAggRows = { ...aggregatedRows };
      for (let key of Object.keys(aggregatedRows)) {
        let row = aggregatedRows[key];
        const spots = weeks.reduce((acc, week) => {
          const spotsLabel = weekFormatter(week);
          if (!R.has(spotsLabel, row)) {
            return { ...acc, [spotsLabel]: 0 };
          }
          return { ...acc, [spotsLabel]: row[spotsLabel] };
        }, {});
        editedAggRows[key] = { ...row, ...spots };
      }
      return editedAggRows;
    }
    return [];
  }, [aggregatedRows, weeks]);

  let tableHeaders = [
    {
      label: "",
      name: "id",
      width: 40,
      nonInteractive: true,
      renderer: (row: FormattedRow) => (
        <CheckBox
          className="checkbox"
          checked={R.has(row.key, selectedRows)}
          onCheck={() => selectRow(row)}
        />
      ),
    },
    {
      label: "Network",
      name: "network",
      renderer: (row: FormattedRow) => (
        <div
          className={`${isNewRowInAggregate(row.rows) ? " newRow" : row.isNewRow ? " newRow" : ""}`}
        >
          {row.network}
        </div>
      ),
    },
    {
      label: "Avail",
      name: "avail",
      width: 85,
      renderer: (row: FormattedRow, i: number) => {
        const { network, avail, key, isNewRow } = row;
        const networkPricing = R.pathOr({}, [network], rotationsAndPricing);
        const availOptions = getAvailOptions(networkPricing);
        return (
          <>
            {(focusedCell === `avail_${i}` && isNewRow) ||
            (focusedCell === `avail_${i}` && isNewRowInAggregate(row.rows)) ? (
              <SelfFocusingSelect
                menuPortalTarget={document.body}
                className={`selfFocusingSelect${
                  isNewRowInAggregate(row.rows) ? " newRow" : isNewRow ? " newRow" : ""
                }`}
                isDisabled={isNewRowInAggregate(row.rows) ? false : weeks[0] < CURRENT_WEEK_START}
                onBlur={() => setFocusedCell("")}
                isClearable={false}
                value={{
                  label: valueToUse(row, "avail"),
                  value: valueToUse(row, "avail"),
                }}
                options={availOptions}
                onChange={selection => {
                  const newAvail: typeof AVAILS[number] = selection.value;

                  /*
                  When changing avail, need to also change:
                    - the rotation because some rotations only exist for a specific avail
                    - the market, cost, times, and dow based on the new rotation
                  */
                  let newCost = 0;
                  let newRotation = "No rotation selected";
                  let newStartTime = "06:00";
                  let newEndTime = "06:00";
                  let newDow = ["M", "Tu", "W", "Th", "F", "Sa", "Su"];
                  let newMarket: string | null = null;
                  for (let rotation of networkPricing[newAvail]) {
                    newCost = rotation.cost30s;
                    newRotation = rotation.rotationLabel;
                    newStartTime = time24hr(rotation.daypartBegin);
                    newEndTime = time24hr(rotation.daypartEnd);
                    newDow = rotation.daysOfWeek;
                    newMarket = rotation.market;
                    break;
                  }

                  const availEdits = {
                    avail: newAvail,
                    cost: newCost,
                    rotation: newRotation,
                    daypart_start: newStartTime,
                    daypart_end: newEndTime,
                    dow: newDow,
                    market: newMarket,
                  };
                  isMultiWeek
                    ? R.map(individualRow => {
                        return editRow(
                          individualRow,
                          availEdits,
                          individualRow.key,
                          Boolean(individualRow.isNewRow)
                        );
                      }, row.rows)
                    : editRow(row, availEdits, key, Boolean(isNewRow));
                }}
              />
            ) : (
              <div
                onClick={() => setFocusedCell(`avail_${i}`)}
                className={`${
                  isNewRowInAggregate(row.rows) ? " newRow" : isNewRow ? " newRow" : ""
                }`}
              >
                {isEdited(row, "avail") && <span className="oldValue">{avail}</span>}
                <span>{valueToUse(row, "avail")}</span>
              </div>
            )}
          </>
        );
      },
    },
    {
      label: "Rotation",
      name: "rotation",
      flex: 1,
      minFlexWidth: 300,
      renderer: (row: FormattedRow, i: number) => {
        const { network, avail, rotation, type, length, key, isNewRow } = row;
        const networkPricing = R.pathOr({}, [network], rotationsAndPricing);
        const rotationOptions = getRotationOptions(row, networkPricing[avail]);

        return (
          <>
            {(focusedCell === `rotation_${i}` && isUpfront(type)) ||
            (focusedCell === `rotation_${i}` &&
              isUpfront(type) &&
              isNewRowInAggregate(row.rows)) ? (
              <SelfFocusingInput
                disabled={isNewRowInAggregate(row.rows) ? false : weeks[0] < CURRENT_WEEK_START}
                onBlur={() => setFocusedCell("")}
                as="textarea"
                rows={1}
                maxLength={45}
                value={valueToUse(row, "rotation") || ""}
                onChange={e => {
                  const newRotation = e.target.value;
                  const rotationEdits = {
                    rotation: newRotation,
                  };
                  isMultiWeek
                    ? R.map(individualRow => {
                        return editRow(
                          individualRow,
                          rotationEdits,
                          individualRow.key,
                          Boolean(individualRow.isNewRow)
                        );
                      }, row.rows)
                    : editRow(row, rotationEdits, key, Boolean(isNewRow));
                }}
              />
            ) : focusedCell === `rotation_${i}` ? (
              <SelfFocusingSelect
                menuPortalTarget={document.body}
                className="selfFocusingSelect"
                isDisabled={weeks[0] < CURRENT_WEEK_START}
                onBlur={() => setFocusedCell("")}
                isClearable={false}
                value={{
                  label: valueToUse(row, "rotation"),
                  value: valueToUse(row, "rotation"),
                }}
                options={rotationOptions}
                onChange={selection => {
                  const newRotation: string = selection.value;
                  /*
                  When changing rotation, need to also change:
                    - the cost based on the new rotation (need to account for length too)
                    - the times based on the new rotation
                    - days of the week based on the new rotation
                    - market based on the new rotation
                  */
                  let newCost = 0;
                  let newStartTime = "06:00";
                  let newEndTime = "06:00";
                  let newDow = ["M", "Tu", "W", "Th", "F", "Sa", "Su"];
                  let newMarket: string | null = null;

                  let formattedTypeForNetworkPricing = TYPE_TO_STATUS_IN_NETWORK_PRICING[type];

                  for (let rotation of networkPricing[avail]) {
                    if (
                      newRotation === rotation.rotationLabel &&
                      shouldUseRotation(formattedTypeForNetworkPricing, rotation.status)
                    ) {
                      newCost = rotation.cost30s;
                      newStartTime = time24hr(rotation.daypartBegin);
                      newEndTime = time24hr(rotation.daypartEnd);
                      newDow = rotation.daysOfWeek;
                      newMarket = rotation.market;
                    }
                  }

                  if (length === 15) {
                    newCost = newCost / 2;
                  }
                  if (length === 60) {
                    newCost = newCost * 2;
                  }

                  const rotationEdits = {
                    rotation: newRotation,
                    cost: newCost,
                    daypart_start: newStartTime,
                    daypart_end: newEndTime,
                    dow: newDow,
                    market: newMarket,
                  };
                  isMultiWeek
                    ? R.map(individualRow => {
                        return editRow(
                          individualRow,
                          rotationEdits,
                          individualRow.key,
                          Boolean(individualRow.isNewRow)
                        );
                      }, row.rows)
                    : editRow(row, rotationEdits, key, Boolean(isNewRow));
                }}
              />
            ) : (
              <div
                onClick={() => {
                  setFocusedCell(`rotation_${i}`);
                }}
                className={`${
                  isNewRowInAggregate(row.rows) ? " newRow" : isNewRow ? " newRow" : ""
                }`}
              >
                {isMultiWeek
                  ? isMultiWeekEdited(row.rows, "rotation") && (
                      <span className="oldValue">{findOldMetricString(row.rows, "rotation")}</span>
                    )
                  : isEdited(row, "rotation") && <span className="oldValue">{rotation}</span>}
                <span>{valueToUse(row, "rotation")}</span>
              </div>
            )}
          </>
        );
      },
    },
    {
      label: "Market",
      name: "market",
      flex: 1,
      minFlexWidth: 150,
      renderer: (row: FormattedRow, i: number) => {
        const { network, avail, market, type, length, key, isNewRow } = row;
        const networkPricing = R.pathOr({}, [network], rotationsAndPricing);
        const marketOptions = getMarketOptions(row, networkPricing[avail]);
        return (
          <>
            {focusedCell === `market_${i}` ? (
              <SelfFocusingSelect
                menuPortalTarget={document.body}
                className="selfFocusingSelect"
                isDisabled={weeks[0] < CURRENT_WEEK_START}
                onBlur={() => setFocusedCell("")}
                isClearable={false}
                value={{
                  label: valueToUse(row, "market"),
                  value: valueToUse(row, "market"),
                }}
                options={marketOptions}
                onChange={selection => {
                  const newMarket: string | null = selection.value;
                  /*
                  When changing market, need to also change:
                    - the rotation because some rotations only exist for a specific market
                    - the cost, times, and dow based on the new rotation
                  */
                  let newCost = 0;
                  let newStartTime = "06:00";
                  let newEndTime = "06:00";
                  let newDow = ["M", "Tu", "W", "Th", "F", "Sa", "Su"];
                  let newRotation = "No rotation selected";

                  let formattedTypeForNetworkPricing = TYPE_TO_STATUS_IN_NETWORK_PRICING[type];

                  for (let rotation of networkPricing[avail]) {
                    if (
                      newMarket === rotation.market &&
                      shouldUseRotation(formattedTypeForNetworkPricing, rotation.status)
                    ) {
                      newCost = rotation.cost30s;
                      newStartTime = time24hr(rotation.daypartBegin);
                      newEndTime = time24hr(rotation.daypartEnd);
                      newDow = rotation.daysOfWeek;
                      newRotation = rotation.rotationLabel;
                    }
                  }

                  if (length === 15) {
                    newCost = newCost / 2;
                  }
                  if (length === 60) {
                    newCost = newCost * 2;
                  }

                  const rotationEdits = {
                    rotation: newRotation,
                    cost: newCost,
                    daypart_start: newStartTime,
                    daypart_end: newEndTime,
                    dow: newDow,
                    market: newMarket,
                  };

                  isMultiWeek
                    ? R.map(individualRow => {
                        return editRow(
                          individualRow,
                          rotationEdits,
                          individualRow.key,
                          Boolean(individualRow.isNewRow)
                        );
                      }, row.rows)
                    : editRow(row, rotationEdits, key, Boolean(isNewRow));
                }}
              />
            ) : (
              <div
                onClick={() => setFocusedCell(`market_${i}`)}
                className={`${
                  isNewRowInAggregate(row.rows) ? " newRow" : isNewRow ? " newRow" : ""
                }`}
              >
                {isMultiWeek
                  ? isMultiWeekEdited(row.rows, "market") && (
                      <span className="oldValue">{findOldMetricString(row.rows, "market")}</span>
                    )
                  : isEdited(row, "market") && <span className="oldValue">{market}</span>}
                <span>{valueToUse(row, "market")}</span>
              </div>
            )}
          </>
        );
      },
    },
    {
      label: "Start Time",
      name: "daypart_start",
      width: 150,
      renderer: (row: FormattedRow, i: number) => {
        const { key, isNewRow, daypart_start } = row;
        return (
          <>
            {focusedCell === `startTime_${i}` ? (
              <SelfFocusingInput
                disabled={weeks[0] < CURRENT_WEEK_START}
                onBlur={() => setFocusedCell("")}
                type="time"
                value={time24hr(valueToUse(row, "daypart_start")) || ""}
                onChange={e => {
                  const newStartTime = e.target.value || "00:00";

                  const daypartStartEdit = {
                    daypart_start: newStartTime,
                  };

                  isMultiWeek
                    ? R.map(individualRow => {
                        return editRow(
                          individualRow,
                          daypartStartEdit,
                          individualRow.key,
                          Boolean(individualRow.isNewRow)
                        );
                      }, row.rows)
                    : editRow(row, daypartStartEdit, key, Boolean(isNewRow));
                }}
              />
            ) : (
              <div
                onClick={() => setFocusedCell(`startTime_${i}`)}
                className={`${
                  isNewRowInAggregate(row.rows) ? " newRow" : isNewRow ? " newRow" : ""
                }`}
              >
                {isMultiWeek
                  ? isMultiWeekEdited(row.rows, "daypart_start") && (
                      <span className="oldValue">
                        {convert24hrTo12hr(findOldMetricString(row.rows, "daypart_start"))}
                      </span>
                    )
                  : isEdited(row, "daypart_start") && (
                      <span className="oldValue">{convert24hrTo12hr(daypart_start)}</span>
                    )}
                <span>{convert24hrTo12hr(valueToUse(row, "daypart_start"))}</span>
              </div>
            )}
          </>
        );
      },
    },
    {
      label: "End Time",
      name: "daypart_end",
      width: 150,
      renderer: (row: FormattedRow, i: number) => {
        const { key, isNewRow, daypart_end } = row;
        return (
          <>
            {focusedCell === `endTime_${i}` ? (
              <SelfFocusingInput
                disabled={weeks[0] < CURRENT_WEEK_START}
                onBlur={() => setFocusedCell("")}
                type="time"
                value={time24hr(valueToUse(row, "daypart_end")) || ""}
                onChange={e => {
                  const newEndTime = e.target.value || "00:00";

                  const daypartEndEdit = {
                    daypart_end: newEndTime,
                  };

                  isMultiWeek
                    ? R.map(individualRow => {
                        return editRow(
                          individualRow,
                          daypartEndEdit,
                          individualRow.key,
                          Boolean(individualRow.isNewRow)
                        );
                      }, row.rows)
                    : editRow(row, daypartEndEdit, key, Boolean(isNewRow));
                }}
              />
            ) : (
              <div
                onClick={() => setFocusedCell(`endTime_${i}`)}
                className={`${
                  isNewRowInAggregate(row.rows) ? " newRow" : isNewRow ? " newRow" : ""
                }`}
              >
                {isMultiWeek
                  ? isMultiWeekEdited(row.rows, "daypart_end") && (
                      <span className="oldValue">
                        {convert24hrTo12hr(findOldMetricString(row.rows, "daypart_end"))}
                      </span>
                    )
                  : isEdited(row, "daypart_end") && (
                      <span className="oldValue">{convert24hrTo12hr(daypart_end)}</span>
                    )}
                <span>{convert24hrTo12hr(valueToUse(row, "daypart_end"))}</span>
              </div>
            )}
          </>
        );
      },
    },
    {
      label: "Days",
      name: "dow",
      flex: 1,
      minFlexWidth: 250,
      renderer: (row: FormattedRow) => {
        const { key, isNewRow } = row;
        const daysOfWeek = makeDaysOfWeekMap(valueToUse(row, "dow"));
        return (
          <ButtonGroup>
            {DAYS_OF_WEEK.map(day => (
              <Button
                disabled={weeks[0] < CURRENT_WEEK_START}
                key={day}
                size="sm"
                variant={daysOfWeek[day] ? "primary" : "outline-primary"}
                onClick={() => {
                  daysOfWeek[day] = !daysOfWeek[day];
                  let newDow: string[] = [];
                  for (let day of DAYS_OF_WEEK) {
                    if (daysOfWeek[day]) {
                      newDow.push(day);
                    }
                  }

                  if (!newDow.length) {
                    newDow = ["M", "Tu", "W", "Th", "F", "Sa", "Su"];
                  }

                  const dayOfWeekEdits = {
                    dow: newDow,
                  };
                  isMultiWeek
                    ? R.map(individualRow => {
                        return editRow(
                          individualRow,
                          dayOfWeekEdits,
                          individualRow.key,
                          Boolean(individualRow.isNewRow)
                        );
                      }, row.rows)
                    : editRow(row, dayOfWeekEdits, key, Boolean(isNewRow));
                }}
              >
                {day}
              </Button>
            ))}
          </ButtonGroup>
        );
      },
    },
    {
      label: "Type",
      name: "type",
      width: 100,
      renderer: (row: FormattedRow, i: number) => {
        const { key, isNewRow, type, avail, network } = row;
        const networkPricing = R.pathOr({}, [network], rotationsAndPricing);
        const typeOptions = getTypeOptions(networkPricing);
        return (
          <>
            {(focusedCell === `type_${i}` && isNewRow) ||
            (focusedCell === `type_${i}` && isNewRowInAggregate(row.rows)) ? (
              <SelfFocusingSelect
                menuPortalTarget={document.body}
                className="selfFocusingSelect"
                isDisabled={isNewRowInAggregate(row.rows) ? false : weeks[0] < CURRENT_WEEK_START}
                options={typeOptions}
                onBlur={() => setFocusedCell("")}
                isClearable={false}
                value={{
                  label: TYPES_TO_NAMES[`${valueToUse(row, "type")}`],
                  value: valueToUse(row, "type"),
                }}
                onChange={selection => {
                  const newType: typeof TYPES[number] = selection.value;

                  /*
                  When changing type, need to also change:
                    - the rotation because some rotations only exist for a specific type
                    - the market, cost, times, and dow based on the new rotation
                  */
                  let newCost = 0;
                  let newRotation = "No rotation selected";
                  let newStartTime = "06:00";
                  let newEndTime = "06:00";
                  let newDow = ["M", "Tu", "W", "Th", "F", "Sa", "Su"];
                  let newMarket: string | null = null;

                  let formattedTypeForNetworkPricing = TYPE_TO_STATUS_IN_NETWORK_PRICING[newType];

                  for (let rotation of networkPricing[avail]) {
                    if (shouldUseRotation(formattedTypeForNetworkPricing, rotation.status)) {
                      newCost = rotation.cost30s;
                      newRotation = rotation.rotationLabel;
                      newStartTime = time24hr(rotation.daypartBegin);
                      newEndTime = time24hr(rotation.daypartEnd);
                      newDow = rotation.daysOfWeek;
                      newMarket = rotation.market;
                      break;
                    }
                  }

                  const typeEdits = {
                    type: newType,
                    cost: newCost,
                    rotation: newRotation,
                    daypart_start: newStartTime,
                    daypart_end: newEndTime,
                    dow: newDow,
                    market: newMarket,
                  };

                  isMultiWeek
                    ? R.map(individualRow => {
                        return editRow(
                          individualRow,
                          typeEdits,
                          individualRow.key,
                          Boolean(individualRow.isNewRow)
                        );
                      }, row.rows)
                    : editRow(row, typeEdits, key, Boolean(isNewRow));
                }}
              />
            ) : (
              <div
                onClick={() => setFocusedCell(`type_${i}`)}
                className={`${
                  isNewRowInAggregate(row.rows) ? " newRow" : isNewRow ? " newRow" : ""
                }`}
              >
                {isMultiWeek
                  ? isMultiWeekEdited(row.rows, "type") && (
                      <span className="oldValue">{findOldMetricString(row.rows, "type")}</span>
                    )
                  : isEdited(row, "type") && (
                      <span className="oldValue">{TYPES_TO_NAMES[`${type}`]}</span>
                    )}
                <span>{TYPES_TO_NAMES[`${valueToUse(row, "type")}`]}</span>
              </div>
            )}
          </>
        );
      },
    },
    {
      label: "Length",
      name: "length",
      width: 85,
      renderer: (row: FormattedRow, i: number) => {
        const { key, isNewRow, length, network, avail } = row;
        const networkPricing = R.pathOr({}, [network], rotationsAndPricing);
        return (
          <>
            {focusedCell === `length_${i}` ? (
              <SelfFocusingSelect
                menuPortalTarget={document.body}
                className="selfFocusingSelect"
                isDisabled={weeks[0] < CURRENT_WEEK_START}
                onBlur={() => setFocusedCell("")}
                isClearable={false}
                options={CREATIVE_LENGTH_OPTIONS}
                value={{
                  label: valueToUse(row, "length"),
                  value: valueToUse(row, "length"),
                }}
                onChange={selection => {
                  const newLength: typeof LENGTHS[number] = selection.value;

                  let newCost = 0;

                  for (let rotation of networkPricing[avail]) {
                    if (row.rotation === rotation.rotationLabel) {
                      newCost = rotation.cost30s;
                    }
                  }

                  if (newLength === 15) {
                    newCost = newCost / 2;
                  }
                  if (newLength === 60) {
                    newCost = newCost * 2;
                  }

                  const lengthEdits = {
                    length: newLength,
                    cost: Math.round(newCost),
                  };
                  isMultiWeek
                    ? R.map(individualRow => {
                        return editRow(
                          individualRow,
                          lengthEdits,
                          individualRow.key,
                          Boolean(individualRow.isNewRow)
                        );
                      }, row.rows)
                    : editRow(row, lengthEdits, key, Boolean(isNewRow));
                }}
              />
            ) : (
              <div
                onClick={() => setFocusedCell(`length_${i}`)}
                className={`${
                  isNewRowInAggregate(row.rows) ? " newRow" : isNewRow ? " newRow" : ""
                }`}
              >
                {isMultiWeek
                  ? isMultiWeekEdited(row.rows, "length") && (
                      <span className="oldValue">{findOldMetricString(row.rows, "length")}</span>
                    )
                  : isEdited(row, "length") && <span className="oldValue">{length}</span>}
                <span>{valueToUse(row, "length")}</span>
              </div>
            )}
          </>
        );
      },
    },
    {
      label: "Rate",
      name: "cost",
      renderer: (row: FormattedRow, i: number) => {
        const { key, isNewRow, cost } = row;
        return (
          <>
            {focusedCell === `rate_${i}` ? (
              <SelfFocusingInput
                min="0"
                disabled={weeks[0] < CURRENT_WEEK_START}
                onBlur={() => setFocusedCell("")}
                type="number"
                value={valueToUse(row, "cost")}
                onChange={e => {
                  let newCost = parseInt(e.target.value) || 0;

                  if (newCost < 0) {
                    newCost = Math.abs(newCost);
                  }

                  const costEdits = {
                    cost: newCost,
                  };
                  isMultiWeek
                    ? R.map(individualRow => {
                        return editRow(
                          individualRow,
                          costEdits,
                          individualRow.key,
                          Boolean(individualRow.isNewRow)
                        );
                      }, row.rows)
                    : editRow(row, costEdits, key, Boolean(isNewRow));
                }}
              />
            ) : (
              <div
                onClick={() => setFocusedCell(`rate_${i}`)}
                className={`${
                  isNewRowInAggregate(row.rows) ? " newRow" : isNewRow ? " newRow" : ""
                }`}
              >
                {isMultiWeek
                  ? isMultiWeekEdited(row.rows, "cost") && (
                      <span className="oldValue">
                        {currencyFormatter(findOldMetricNumber(row.rows, "cost"), 0, enableCAD)}
                      </span>
                    )
                  : isEdited(row, "cost") && (
                      <span className="oldValue">{currencyFormatter(cost, 0, enableCAD)}</span>
                    )}
                <span>{currencyFormatter(valueToUse(row, "cost"), 0, enableCAD)}</span>
              </div>
            )}
          </>
        );
      },
    },
    {
      label: isMultiWeek ? "Total Spots" : "Spots",
      name: "count",
      renderer: (row: FormattedRow, i: number) => {
        const { key, isNewRow, count } = row;
        return (
          <>
            {focusedCell === `spots_${i}` ? (
              <SelfFocusingInput
                min="0"
                disabled={weeks[0] < CURRENT_WEEK_START || isMultiWeek}
                onBlur={() => setFocusedCell("")}
                type="number"
                value={valueToUse(row, "count")}
                onChange={e => {
                  let newCount = parseInt(e.target.value) || 0;

                  if (newCount < 0) {
                    newCount = Math.abs(newCount);
                  }

                  const countEdits = {
                    count: newCount,
                  };

                  editRow(row, countEdits, key, Boolean(isNewRow));
                }}
              />
            ) : (
              <div
                onClick={() => setFocusedCell(`spots_${i}`)}
                className={`${
                  isNewRowInAggregate(row.rows) ? " newRow" : isNewRow ? " newRow" : ""
                }`}
              >
                {isEdited(row, "count") && <span className="oldValue">{count}</span>}
                <span>{valueToUse(row, "count")}</span>
              </div>
            )}
          </>
        );
      },
    },
    {
      label: isMultiWeek ? "Total Spend" : "Spend",
      name: "totalSpend",
      renderer: (row: FormattedRow) => {
        const { isNewRow } = row;
        return (
          <div
            className={`${isNewRowInAggregate(row.rows) ? " newRow" : isNewRow ? " newRow" : ""}`}
          >
            {isMultiWeek
              ? currencyFormatter(valueToUse(row, "totalSpend"), 0, enableCAD)
              : currencyFormatter(valueToUse(row, "cost") * valueToUse(row, "count"), 0, enableCAD)}
          </div>
        );
      },
    },

    {
      label: "Media Classification",
      name: "media_classification",
      width: 150,
      renderer: (row: FormattedRow, i: number) => {
        const { key, isNewRow, media_classification } = row;
        return (
          <>
            {focusedCell === `mediaClassification_${i}` ? (
              <SelfFocusingSelect
                menuPortalTarget={document.body}
                className="selfFocusingSelect"
                isDisabled={weeks[0] < CURRENT_WEEK_START}
                options={
                  isUpfront(row.type)
                    ? MEDIA_CLASSIFICATION_OPTIONS
                    : [{ label: "High Profile", value: "High Profile" }]
                }
                onBlur={() => setFocusedCell("")}
                isClearable
                value={{
                  label: valueToUse(row, "media_classification"),
                  value: valueToUse(row, "media_classification"),
                }}
                onChange={selection => {
                  const newClassification = selection?.value || null;

                  const medEdits = {
                    media_classification: newClassification,
                  };
                  isMultiWeek
                    ? R.map(individualRow => {
                        return editRow(
                          individualRow,
                          medEdits,
                          individualRow.key,
                          Boolean(individualRow.isNewRow)
                        );
                      }, row.rows)
                    : editRow(row, medEdits, key, Boolean(isNewRow));
                }}
              />
            ) : (
              <div
                onClick={() => setFocusedCell(`mediaClassification_${i}`)}
                className={`${
                  isNewRowInAggregate(row.rows) ? " newRow" : isNewRow ? " newRow" : ""
                }`}
              >
                {isMultiWeek
                  ? isMultiWeekEdited(row.rows, "media_classification") && (
                      <span className="oldValue">
                        {findOldMetricString(row.rows, "media_classification")}
                      </span>
                    )
                  : isEdited(row, "media_classification") && (
                      <span className="oldValue">{media_classification}</span>
                    )}
                <span>{valueToUse(row, "media_classification") || "-"}</span>
              </div>
            )}
          </>
        );
      },
    },
    {
      label: "Campaign",
      name: "campaign",
      flex: 1,
      minFlexWidth: 150,
      renderer: (row: FormattedRow, i: number) => {
        const { key, isNewRow } = row;
        const { options, idToName } = campaignMetadata;
        return (
          <>
            {focusedCell === `campaign_${i}` && isNewRow ? (
              <SelfFocusingSelect
                menuPortalTarget={document.body}
                className="selfFocusingSelect"
                isDisabled={weeks[0] < CURRENT_WEEK_START}
                options={options}
                onBlur={() => setFocusedCell("")}
                isClearable={false}
                value={{
                  label: idToName[valueToUse(row, "campaign_id")],
                  value: valueToUse(row, "campaign_id"),
                }}
                onChange={selection => {
                  const newCampaign: number = selection.value;

                  const campaignEdits = {
                    campaign_id: newCampaign,
                  };
                  isMultiWeek
                    ? R.map(individualRow => {
                        return editRow(
                          individualRow,
                          campaignEdits,
                          individualRow.key,
                          Boolean(individualRow.isNewRow)
                        );
                      }, row.rows)
                    : editRow(row, campaignEdits, key, Boolean(isNewRow));
                }}
              />
            ) : (
              <div
                onClick={() => setFocusedCell(`campaign_${i}`)}
                className={`${
                  isNewRowInAggregate(row.rows) ? " newRow" : isNewRow ? " newRow" : ""
                }`}
              >
                <span>{idToName[valueToUse(row, "campaign_id")] || "-"}</span>
              </div>
            )}
          </>
        );
      },
    },
    {
      label: "Ordered Impressions",
      name: "orderedImpressions",
      flex: 1,
      minFlexWidth: 200,
      renderer: (row: FormattedRow, i: number) => {
        const { type, isNewRow } = row;
        return (
          <>
            {focusedCell === `ordered_impressions_${i}` ? (
              <AddOrderedImpressionsModal
                row={row}
                editRow={editRow}
                onClose={() => setFocusedCell("")}
              />
            ) : (
              <div
                onClick={() => {
                  if (isUpfront(type) && !isMultiWeek) {
                    setFocusedCell(`ordered_impressions_${i}`);
                  }
                }}
                className={`${
                  isNewRowInAggregate(row.rows) ? " newRow" : isNewRow ? " newRow" : ""
                }`}
              >
                {isUpfront(type) ? (
                  <div className="stackedCell">
                    <div className="topContent">{valueToUse(row, "orderedImpressions") || "-"}</div>
                    <div className="middleContent">
                      {PRETTY_DEMO_NAMES[valueToUse(row, "orderedImpressionsDemo")]}
                    </div>
                    <div className="bottomContent">
                      {
                        ABBREVIATED_MEAUREMENT_NAMES[
                          valueToUse(row, "orderedImpressionsMeasurement")
                        ]
                      }
                    </div>
                  </div>
                ) : (
                  <div>{"-"}</div>
                )}
              </div>
            )}
          </>
        );
      },
    },
    {
      label: "Ordered CPM",
      name: "orderedCpm",
      width: 150,
      renderer: (row: FormattedRow) =>
        row.orderedCpm ? currencyFormatter(row.orderedCpm, 2, enableCAD) : "-",
    },
    {
      label: enableLinearBuyingUpdates ? "Secondary Impressions" : "Impressions",
      name: "impressions",
      flex: 1,
      minFlexWidth: enableLinearBuyingUpdates ? 200 : 150,
      renderer: (row: FormattedRow, i: number) => {
        const { key, isNewRow, impressions, impressionOverrides } = row;
        return (
          <>
            {focusedCell === `impressions_${i}` ? (
              <SelfFocusingInput
                min="0"
                disabled={weeks[0] < CURRENT_WEEK_START || isMultiWeek}
                onBlur={() => setFocusedCell("")}
                type="number"
                value={valueToUse(row, "impressions")}
                onChange={e => {
                  let newImps = parseInt(e.target.value) || 0;

                  if (newImps < 0) {
                    newImps = Math.abs(newImps);
                  }

                  const impsEdits = {
                    impressions: newImps,
                  };
                  // For all buying types rowToUse.edits.impressions is always updated if the impressions or secondary impressions column is changed
                  // If impressionOverrides (secondary impressions on site) is changed for upfront type, rowToUse.edits.impressions and rowToUse.edits.impressionOverrides both are updated
                  // Impressions is always the number displayed in impressions or secondary impressions on site so impressions should match the impressions in impressionsOverride object for upfront types
                  editRow(row, impsEdits, key, Boolean(isNewRow));
                  if (isUpfront(row.type)) {
                    // If the row is new then the edits get saved to impressionOverrides directly
                    const currentImpEdits = isNewRow
                      ? impressionOverrides || {}
                      : editsMap[key]?.edits?.impressionOverrides || {};
                    const selectedDemo = Object.keys(selectedDemos)[0];
                    const newImpEdits = {};
                    //if change is > 0 then create the object to deepmerge
                    if (newImps !== 0) {
                      newImpEdits[selectedDemo] = {};
                      newImpEdits[selectedDemo][selectedMeasurement] = newImps;
                    }
                    // Merge the new impression override edits with the current impression overrides
                    let inputImpEdits = deepMergeLinearImpressionOverrides(
                      currentImpEdits,
                      newImpEdits
                    );
                    //if change is is 0 then create an empty object to save into edits to indicate to backend that
                    //the impression overrides should be deleted
                    if (newImps === 0) {
                      inputImpEdits = {};
                    }

                    editRow(row, { impressionOverrides: inputImpEdits }, key, Boolean(isNewRow));
                  }
                }}
              />
            ) : (
              <div
                onClick={() => {
                  if (!isMultiWeek) {
                    setFocusedCell(`impressions_${i}`);
                  }
                }}
                className={`${
                  isNewRowInAggregate(row.rows) ? " newRow" : isNewRow ? " newRow" : ""
                }`}
              >
                {isEdited(row, "impressions") && <span className="oldValue">{impressions}</span>}
                <span>{formatNumberAsInt(valueToUse(row, "impressions")) || "-"}</span>
              </div>
            )}
          </>
        );
      },
    },
    {
      label: enableLinearBuyingUpdates ? "Secondary CPM" : "CPM",
      name: "cpm",
      width: enableLinearBuyingUpdates ? 150 : 85,
      renderer: (row: FormattedRow) => (row.cpm ? currencyFormatter(row.cpm, 2, enableCAD) : "-"),
    },
    {
      label: enableLinearBuyingUpdates ? "Secondary TRP" : "TRP",
      name: "trp",
      width: enableLinearBuyingUpdates ? 150 : 85,
      renderer: (row: FormattedRow) => (row.trp ? formatNumber(row.trp, 1) : "-"),
    },
    {
      label: "Notes",
      name: "notes",
      flex: 1,
      minFlexWidth: 300,
      renderer: (row: FormattedRow, i: number) => {
        const { key, isNewRow, notes } = row;
        return (
          <>
            {focusedCell === `notes_${i}` ? (
              <SelfFocusingInput
                disabled={isMultiWeek ? true : weeks[0] < CURRENT_WEEK_START}
                onBlur={() => setFocusedCell("")}
                as="textarea"
                rows={1}
                maxLength={45}
                value={valueToUse(row, "notes") || ""}
                onChange={e => {
                  const newNote = e.target.value;

                  const noteEdits = {
                    notes: newNote,
                  };
                  editRow(row, noteEdits, key, Boolean(isNewRow));
                }}
              />
            ) : (
              <div
                onClick={() => setFocusedCell(`notes_${i}`)}
                className={`${
                  isNewRowInAggregate(row.rows) ? " newRow" : isNewRow ? " newRow" : ""
                }`}
              >
                {isMultiWeek
                  ? isMultiWeekEdited(row.rows, "notes") && (
                      <span className="oldValue">{findOldMetricString(row.rows, "notes")}</span>
                    )
                  : isEdited(row, "notes") && <span className="oldValue">{notes}</span>}
                <span>{valueToUse(row, "notes")}</span>
              </div>
            )}
          </>
        );
      },
    },
    {
      label: "Creatives",
      name: "creatives",
      width: 85,
      renderer: (row: FormattedRow) => {
        return (
          <>
            <BPMButton
              onClick={() => {
                isMultiWeek
                  ? setCreativeAllocationModalDataV2(row.rows)
                  : setCreativeAllocationModalData(row);
                setShowCreativeAllocationModal(true);
              }}
            >
              <MdOpenInNew />
            </BPMButton>
            {!row.creatives && (
              <OverlayTrigger
                placement={OverlayTrigger.PLACEMENTS.LEFT.TOP}
                overlay={<Tooltip id={row.week}>Row missing rotation</Tooltip>}
              >
                <BPMButton variant="warning" size="xs">
                  <MdWarning color="white" />
                </BPMButton>
              </OverlayTrigger>
            )}
          </>
        );
      },
    },
  ];

  let newCols: typeof tableHeaders = [];
  if (isMultiWeek) {
    for (let week of weeks) {
      newCols.push({
        label: weekFormatter(week),
        name: weekFormatter(week),
        renderer: (row: FormattedRow, i: number) => {
          const individualRow = getFormattedRow(row.rows, week) as FormattedRow;
          const count = row[weekFormatter(week)];
          return (
            <>
              {focusedCell === `${weekFormatter(week)}_${i}` ? (
                <SelfFocusingInput
                  min="0"
                  disabled={week < CURRENT_WEEK_START}
                  onBlur={() => setFocusedCell("")}
                  type="number"
                  value={valueToUseAggregate(individualRow, row, "count", weekFormatter(week)) || 0}
                  onChange={e => {
                    let newCount = parseInt(e.target.value) || 0;
                    if (newCount < 0) {
                      newCount = Math.abs(newCount);
                    }
                    const countEdits = {
                      count: newCount,
                    };
                    if (individualRow) {
                      editRow(
                        individualRow,
                        countEdits,
                        individualRow.key,
                        Boolean(individualRow.isNewRow)
                      );
                    } else {
                      let newRowFromSpot = createNewRowFromSpots(newCount, row, week);
                      //add new row to newRows
                      setNewRows(current => ({ ...current, [newRowFromSpot.key]: newRowFromSpot }));
                    }
                  }}
                />
              ) : (
                <div
                  onClick={() => setFocusedCell(`${weekFormatter(week)}_${i}`)}
                  className={`${isNew(row.rows, week) ? "newRow" : ""}`}
                >
                  {(individualRow ? isEdited(individualRow, "count") : false) && (
                    <span className="oldValue">{count}</span>
                  )}
                  <span>
                    {valueToUseAggregate(individualRow, row, "count", weekFormatter(week)) || 0}
                  </span>
                </div>
              )}
            </>
          );
        },
      });
    }
  }
  tableHeaders = [...tableHeaders.slice(0, 11), ...newCols, ...tableHeaders.slice(11)];

  if (!enableCampaigns) {
    tableHeaders = tableHeaders.filter(header => {
      return header.name !== "campaign";
    });
  }

  if (!enableRadioBuying) {
    tableHeaders = tableHeaders.filter(header => {
      return header.name !== "market";
    });
  }
  if ((enableLinearBuyingUpdates && isMultiWeek) || !enableLinearBuyingUpdates) {
    tableHeaders = tableHeaders.filter(header => {
      return header.name !== "orderedImpressions" && header.name !== "orderedCpm";
    });
  }

  const headersRenderer = useCallback(
    ({ data, columnIndex }) => {
      switch (columnIndex) {
        case 0:
          return (
            <CheckBox
              checked={selectAll}
              onCheck={() => {
                if (selectAll) {
                  setSelectedRows({});
                } else {
                  let selectAllMap = {};
                  if (!R.isEmpty(editedAggregatedRows)) {
                    for (let row of editedAggregatedRows) {
                      selectAllMap[row.key] = row;
                    }
                  } else {
                    for (let row of formattedRows) {
                      selectAllMap[row.key] = row;
                    }
                  }
                  setSelectedRows(current => ({ ...current, ...selectAllMap }));
                }
                setSelectAll(!selectAll);
              }}
            />
          );
        default:
          return data;
      }
    },
    [formattedRows, editedAggregatedRows, setSelectedRows, selectAll]
  );

  return (
    <div className="newTableView">
      <BPMTable<FormattedRowTableType>
        noRowsRenderer={() => (
          <div className="noRows">
            There are no orders for the selected week(s). Start by adding a row or importing a buy.
          </div>
        )}
        data={isMultiWeek ? Object.values(editedAggregatedRows) : formattedRows}
        totals={totalsRow}
        totalsRenderer={renderTotals}
        headers={tableHeaders}
        headersRenderer={headersRenderer}
        filterBar={false}
      />
    </div>
  );
};

export default EditTableView;

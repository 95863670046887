import "./DropdownMenu.scss";
import { Button, ButtonType } from "../Button";
import { ButtonFrameworkVariant } from "../ButtonFramework/ButtonFramework";
import { Dropdown as BSDropdown } from "react-bootstrap";
import { FilterBar } from "../FilterBar";
import { MdCheckBox, MdCheckBoxOutlineBlank, MdCircle, MdOutlineCircle } from "react-icons/md";
import { MenuItem } from "../Dropdown/Dropdown";
import { typedReactMemo } from "../../utils/types";
import * as R from "ramda";
import React, { useMemo, useState } from "react";
import { OverlayTrigger } from "../OverlayTrigger";
import { SimpleTooltip, SimpleTooltipDirection } from "../SimpleTooltip";
import { Spinner } from "../Spinner";

const convertToMenus = (menuItems: any[] | any[][]): any[][] => {
  let convertedMenus = [
    ...(menuItems && Array.isArray(menuItems) && menuItems.length > 0 && Array.isArray(menuItems[0])
      ? (menuItems as any[][])
      : Array.isArray(menuItems)
      ? [menuItems as any[]]
      : []),
  ];
  if (convertedMenus && convertedMenus.length) {
    for (let menuIndex = 0; menuIndex < convertedMenus.length; ++menuIndex) {
      for (
        let itemIndex = 0;
        itemIndex < (convertedMenus[menuIndex] as any[]).length;
        ++itemIndex
      ) {
        const menuItem = convertedMenus[menuIndex][itemIndex];
        if (R.isNil(menuItem.menuIndex) || R.isNil(menuItem.itemIndex)) {
          convertedMenus[menuIndex][itemIndex] = {
            ...menuItem,
            menuIndex,
            itemIndex,
            selected: R.isNil(menuItem.selected) ? false : menuItem.selected,
            label: menuItem.label || menuItem.value,
          };
        }
      }
    }
  }
  return convertedMenus;
};

export interface DropdownMenuProps<T> {
  [passthroughProp: string]: any;
  applyContent?: string | JSX.Element;
  applyIcon?: JSX.Element;
  applyOnClick?: ((args: any) => void) | null;
  cancelContent?: string | JSX.Element;
  cancelIcon?: JSX.Element;
  cancelOnClick?: ((args: any) => void) | null;
  className?: string;
  filterBarPlaceholder?: string | string[];
  footer?: string | JSX.Element;
  footerOnClick?: () => void;
  hasFilterBar?: boolean | boolean[];
  hasTicks?: boolean;
  id?: string;
  itemOnClick?: (item: MenuItem<T>) => void;
  menuItems: MenuItem<T>[] | MenuItem<T>[][];
  multiSelect?: boolean;
  size?: "lg" | "sm";
  standalone?: boolean;
  storybook?: boolean;
  subTitles?: string | string[];
  title?: string;
  section?: string[];
  menuRenderOverride?: Record<string, "stringList">; // The key is the menu index. The value is the type of render override. Currently only "stringList" is supported.
  loading?: boolean;
}

export const DropdownMenu = typedReactMemo(
  <T extends string>({
    applyContent = "Apply",
    applyIcon,
    applyOnClick: applyOnClickInput,
    cancelContent = "Cancel",
    cancelIcon,
    cancelOnClick: cancelOnClickInput,
    className,
    filterBarPlaceholder: filterBarPlaceholderInput,
    footer: footerInput,
    footerOnClick,
    hasFilterBar,
    hasTicks: hasTicksInput,
    id,
    itemOnClick,
    section,
    menuItems: menuItemsInput,
    multiSelect: multiSelectInput,
    size = "lg",
    standalone: standaloneInput = false,
    storybook = false,
    subTitles: subTitlesInput,
    title,
    menuRenderOverride,
    loading,
    ...passthrough
  }: DropdownMenuProps<T>): JSX.Element => {
    // Resolve Different Types of Inputs
    const standalone = storybook ? true : standaloneInput;
    const filterBarPlaceholder =
      !filterBarPlaceholderInput || Array.isArray(filterBarPlaceholderInput)
        ? filterBarPlaceholderInput
        : [filterBarPlaceholderInput];
    const [storybookMenuItems, setStorybookMenuItems] = useState<MenuItem<T>[] | MenuItem<T>[][]>(
      menuItemsInput
    );
    const menuItems = useMemo(
      () => convertToMenus(storybook ? storybookMenuItems : menuItemsInput),
      [menuItemsInput, storybook, storybookMenuItems]
    );
    const subTitles =
      !subTitlesInput || Array.isArray(subTitlesInput) ? subTitlesInput : [subTitlesInput];

    // Multi-Menu
    const multiMenu = menuItems.length > 1;

    // Multi-Select
    const multiSelect = multiSelectInput || multiMenu;

    // Check if we have any item options
    const hasItemOptions = R.any(menuItem => !R.isNil(menuItem.itemOptions), R.flatten(menuItems));

    // If we have multiselect or more than one set of menu items, we need to have ticks.
    const hasTicks = R.defaultTo(
      !R.isNil(applyOnClickInput) || hasItemOptions || multiMenu || multiSelect,
      hasTicksInput
    );

    // If we have a multiple choices or menus, we need to have apply and cancel buttons.
    const applyOnClick =
      (hasTicks || multiSelect || multiMenu) && applyOnClickInput === undefined
        ? () => {}
        : applyOnClickInput;
    const cancelOnClick =
      (hasTicks || multiSelect || multiMenu) && cancelOnClickInput === undefined
        ? () => {}
        : cancelOnClickInput;

    // State Variables
    const [filters, setFilters] = useState<((line: Record<string, any>) => boolean)[]>(
      R.map(() => (line: Record<string, any>) => true, menuItems)
    );
    const [filterTokens, setFilterTokens] = useState<any[]>([]);
    const resolvedClassName = useMemo(() => {
      const classes: string[] = ["dropdownMenuContainer", size === "sm" ? size : "lg"];
      if (className) {
        classes.push(className);
      }
      if (title && title.length) {
        classes.push("title");
      }
      return classes.join(" ");
    }, [className, size, title]);

    const filteredMenuItems = useMemo(() => {
      let menuIndex = 0;
      return R.map((menuItem: MenuItem<T>[]) => {
        const filteredMenuItem =
          filters && filters[menuIndex] ? R.filter(filters[menuIndex], menuItem) : menuItem;
        ++menuIndex;
        return filteredMenuItem;
      }, menuItems);
    }, [menuItems, filters]);

    const selectedMenuItems = useMemo(
      () => R.map(menu => R.filter(item => item.selected === true, menu), [...filteredMenuItems]),
      [filteredMenuItems]
    );

    const createMenuItem = (
      menuItem: MenuItem<T>,
      tick?: JSX.Element,
      multiSelect?: boolean
    ): JSX.Element => {
      const tickContainer = hasTicks && <div className="dropdownMenuItemTick">{tick}</div>;
      const label = R.defaultTo(menuItem.value, menuItem.label);
      const labelContainerContent = menuItem.link ? (
        <a className="dropdownMenuItemLabel" href={menuItem.link} key={menuItem.value}>
          {label}
        </a>
      ) : (
        <div className="dropdownMenuItemLabel">{label}</div>
      );
      const labelContainer = menuItem.simpleTooltip ? (
        <OverlayTrigger
          placement={OverlayTrigger.PLACEMENTS.BOTTOM.CENTER}
          overlay={
            <SimpleTooltip
              text={`${menuItem.simpleTooltip}`}
              verticalShift="-40px"
              horizontalShift="-50px"
              showTooltip={true}
              direction={SimpleTooltipDirection.BOTTOM}
              maxWidth="200px"
            ></SimpleTooltip>
          }
        >
          {labelContainerContent}
        </OverlayTrigger>
      ) : (
        labelContainerContent
      );
      const itemOptionsContainer = menuItem.itemOptions ? (
        <div
          className="dropdownMenuItemOptions"
          onClick={e => {
            e.stopPropagation();
          }}
        >
          {menuItem.itemOptions}
        </div>
      ) : undefined;
      const { customContent } = menuItem;
      const onClick = () => {
        if (
          (menuItem.menuIndex || menuItem.menuIndex === 0) &&
          (menuItem.itemIndex || menuItem.itemIndex === 0)
        ) {
          const { menuIndex, itemIndex } = menuItem;
          let newMenuItems = [...(storybook ? storybookMenuItems : menuItemsInput)] as
            | MenuItem<T>[]
            | MenuItem<T>[][];
          if (!multiSelect) {
            if (multiMenu) {
              for (
                let itemIndex = 0;
                itemIndex < (newMenuItems[menuIndex] as MenuItem<T>[]).length;
                ++itemIndex
              ) {
                if (itemIndex !== menuItem.itemIndex) {
                  newMenuItems[menuIndex][itemIndex].selected = false;
                }
              }
            } else {
              for (let itemIndex = 0; itemIndex < newMenuItems.length; ++itemIndex) {
                if (itemIndex !== menuItem.itemIndex) {
                  (newMenuItems[itemIndex] as MenuItem<T>).selected = false;
                }
              }
            }
          }
          if (multiMenu) {
            newMenuItems[menuIndex][itemIndex].selected = !(storybook
              ? storybookMenuItems
              : menuItemsInput)[menuIndex][itemIndex].selected;
          } else {
            (newMenuItems[itemIndex] as MenuItem<T>).selected = !((storybook
              ? storybookMenuItems
              : menuItemsInput)[itemIndex] as MenuItem<T>).selected;
          }
          if (storybook) {
            setStorybookMenuItems(newMenuItems);
          }
        }
        if (itemOnClick) {
          itemOnClick(menuItem);
        }
      };
      return applyOnClickInput || cancelOnClickInput || itemOptionsContainer ? (
        <div
          className={`dropdownMenuItem ${R.defaultTo("", menuItem.className)} ${
            menuItem.selected ? "menuItemSelected" : ""
          }`}
          key={menuItem.value}
          onClick={onClick}
        >
          {tickContainer}
          {labelContainer}
          {itemOptionsContainer}
        </div>
      ) : (
        <BSDropdown.Item
          className={`dropdownMenuItem ${R.defaultTo("", menuItem.className)} ${
            menuItem.selected ? "menuItemSelected" : ""
          }`}
          key={menuItem.value}
          onClick={onClick}
        >
          {tickContainer}
          {labelContainer}
          {customContent}
          {itemOptionsContainer}
        </BSDropdown.Item>
      );
    };

    const menuItemsContainers: JSX.Element[] = [];
    for (let menuIndex = 0; menuIndex < menuItems.length; ++menuIndex) {
      const filterBar = ((R.isNil(hasFilterBar) && menuItems[menuIndex].length >= 10) ||
        hasFilterBar === true ||
        (hasFilterBar && hasFilterBar[menuIndex])) && (
        <div className="dropdownMenuFilterBarContainer">
          <FilterBar
            onFilter={(filter: (line: Record<string, any>) => boolean, tokens: any): void => {
              setFilters(currentFilters => {
                let newFilters = [...currentFilters];
                newFilters[menuIndex] = filter;
                return newFilters;
              });
              setFilterTokens(currentTokens => {
                let newTokens = [...currentTokens];
                newTokens[menuIndex] = tokens;
                return newTokens;
              });
            }}
            options={["label"]}
            placeholder={filterBarPlaceholder ? filterBarPlaceholder[menuIndex] : undefined}
            variant="Dropdown"
          />
        </div>
      );
      const title = subTitles && subTitles[menuIndex] && (
        <div className="dropdownMenuItemsTitleContainer">
          <div className="dropdownMenuItemsTitle">{subTitles[menuIndex]}</div>
        </div>
      );

      const menuItemsContainer = (
        <div className="dropdownMenuItems">
          {/* If using menu override, we render a string concatenation of the menu items instead of actual checkboxes. */}
          {/* The menuIndex tells us which menu "column" to do this for */}
          {menuRenderOverride && menuRenderOverride[menuIndex] ? (
            <div className="dropdownMenuItemOverride">
              {filteredMenuItems[menuIndex].map(item => item.label).join(" + ")}
            </div>
          ) : menuItems.length > 0 && filteredMenuItems.length === 0 ? (
            <div className="dropdownMenuItem" style={{ cursor: "default", height: "64px" }}>
              No search results found for "{filterTokens.join(" ")}"
            </div>
          ) : section && section.length > 1 ? (
            section.map((title, i) => {
              const heading = <div className="dropdownMenuItemsTitleContainer">{title}</div>;
              const completeSecton = (
                <div className={i > 0 ? "dropdownMenuSection" : ""} key={`dropdownMenuSection${i}`}>
                  {heading}
                  {filteredMenuItems[menuIndex]
                    .filter(el => el.section === title)
                    .map(el => createMenuItem(el, undefined, multiSelect))}
                </div>
              );
              return completeSecton;
            })
          ) : (
            R.map(menuItem => {
              const multiSelectForElement = Array.isArray(multiSelect)
                ? multiSelect[menuIndex]
                : multiSelect;
              const tick = hasTicks ? (
                multiSelectForElement ? (
                  menuItem.selected ? (
                    <MdCheckBox />
                  ) : (
                    <MdCheckBoxOutlineBlank />
                  )
                ) : menuItem.selected ? (
                  <MdCircle />
                ) : (
                  <MdOutlineCircle />
                )
              ) : undefined;
              const menuItemComponent = createMenuItem(menuItem, tick, multiSelect);
              return menuItemComponent;
            }, filteredMenuItems[menuIndex])
          )}
        </div>
      );
      menuItemsContainers.push(
        <div className="dropdownMenuItemsContainer" key={`dropdownMenuItemsContainer${menuIndex}`}>
          {filterBar}
          {title}
          {menuItemsContainer}
        </div>
      );
    }

    const header = title && (
      <div className="dropdownMenuHeaderContainer">
        <div className="dropdownMenuHeaderTitle">{title}</div>
      </div>
    );

    const body = loading ? (
      <div className="dropdownMenuSpinner">
        <Spinner />
      </div>
    ) : (
      <div className={`dropdownMenuBodyContainer ${multiMenu ? "multiMenu" : ""}`}>
        {R.map(container => container, menuItemsContainers)}
      </div>
    );

    const applyCancel = R.flatten(menuItems).length > 0 && (applyOnClick || cancelOnClick) && (
      <div className="dropdownMenuApplyCancelContainer">
        {cancelOnClick && (
          <BSDropdown.Item>
            <Button
              className="cancelButton"
              onClick={() => cancelOnClick(selectedMenuItems)}
              size={size}
              type={ButtonType.OUTLINED}
              design="secondary"
              variant={
                R.isNil(cancelIcon)
                  ? ButtonFrameworkVariant.NO_ICON
                  : ButtonFrameworkVariant.LEADING_ICON
              }
              {...(cancelIcon ? { icon: cancelIcon } : {})}
            >
              {cancelContent}
            </Button>
          </BSDropdown.Item>
        )}
        {applyOnClick && (
          <BSDropdown.Item>
            <Button
              className="applyButton"
              onClick={() => applyOnClick(selectedMenuItems)}
              size={size}
              type={ButtonType.FILLED}
              variant={
                R.isNil(applyIcon)
                  ? ButtonFrameworkVariant.NO_ICON
                  : ButtonFrameworkVariant.LEADING_ICON
              }
              {...(applyIcon ? { icon: applyIcon } : {})}
            >
              {applyContent}
            </Button>
          </BSDropdown.Item>
        )}
      </div>
    );

    const footer = footerInput && footerOnClick && (
      <div className="dropdownMenuFooterContainer" onClick={footerOnClick}>
        {footerInput}
      </div>
    );

    return standalone ? (
      <div className={resolvedClassName} id={id} {...passthrough}>
        {header}
        {body}
        {applyCancel}
        {footer}
      </div>
    ) : (
      <BSDropdown.Menu className={resolvedClassName} id={id} {...passthrough}>
        {header}
        {body}
        {applyCancel}
        {footer}
      </BSDropdown.Menu>
    );
  }
);

export default DropdownMenu;

import React from "react";
import * as Dfns from "date-fns/fp";
import { IoImageOutline } from "react-icons/io5";
import { Img } from "../../../Components";

interface MetaCreativeThumbnailProps {
  selected: boolean;
  name: string;
  url: string;
  createdAt: string;
  length: string | number;
  onClick: () => void;
  isVideo: boolean;
}

export const MetaCreativeThumbnail: React.FC<MetaCreativeThumbnailProps> = ({
  selected = false,
  name,
  url,
  createdAt,
  length,
  onClick,
  isVideo,
}) => {
  return (
    <div
      className={`metaCreativeThumbnail ${selected ? "selected" : ""} ${isVideo ? "isVideo" : ""}`}
      onClick={onClick}
    >
      <div className="metaAssetContainer">
        <div className={"createiveAssetContainer"}>
          <Img
            className="creativeImageItem"
            src={url}
            unloader={<IoImageOutline className="assetLoadingPlaceholder" />}
          />
        </div>
        <div className="assetInfo">
          {/** TODO possibly need tool tip for text greater than 3 lines */}
          <div className="assetName">{`${name}`}</div>
          <div className="createdAt">{`Created at: ${Dfns.format(
            "MM-dd-yyyy",
            Dfns.parseISO(createdAt)
          )}`}</div>
          {length && <div className="createdAt">{`Length: ${length}`}</div>}
        </div>
      </div>
    </div>
  );
};

import React, { useContext, useState, useEffect, useCallback } from "react";
import {
  FormContext,
  metaBuyingFormDefault,
  metaCampaignFormDefault,
  metaAdSetFormDefault,
} from "../MetaBuyingContext";
import { MetaBuyingNamePreview } from "./MetaBuyingNamePreview";
import { MetaBuyingOptionsNoteCard } from "./MetaBuyingOptionsNoteCard";
import { Form, DropdownButton, Dropdown } from "react-bootstrap";
import { MetaBuyingFooterControls } from "./MetaBuyingFooterControls";
import { Button, ButtonType } from "../../Components";
import { RouteComponentProps } from "@reach/router";
import {
  filterSegmentationIds,
  generateNameFields,
  filterInheritedFields,
  mapRowsByProp,
  validateName,
  mapLabelsByValues,
  getAttributionOptions,
  removeIsoTZ,
} from "../MetaBuyingUtils";
import { MetaBuyingTabs } from "../MetaBuyingTabs";
import { ButtonFrameworkVariant } from "../../Components/ButtonFramework";
import { awaitJSON, MetaLambdaFetch } from "../../utils/fetch-utils";
import {
  AdSetRow,
  CampaignRow,
  Dimension,
  CreationTab,
  Creative,
  FormField,
  Level,
} from "@blisspointmedia/bpm-types/dist/MetaBuying";
import { useSetError } from "../../redux/modals";
import * as R from "ramda";
import * as Dfns from "date-fns/fp";
import MetaBuyingNameFields from "./MetaBuyingNameFields";
import { SingleSelectMenu, DROPDOWN_BTN } from "./MetaBuyingSelectMenu";
import { MetaCreativeAssetModal } from "../DraftsAndPublished/Components/MetaCreativeAssetModal";
import { HiPlus } from "react-icons/hi";
import { MdDelete } from "react-icons/md";
import {
  TAB_AD_SET,
  CAMPAIGN,
  AD_SET,
  AD,
  VIDEO,
  IMAGE,
  STATUS_NONE,
  OUTCOME_SALES,
  ALL,
  CAMPAIGN_DAILY_BUDGET,
  CAMPAIGN_LIFETIME_BUDGET,
  AD_SET_DAILY_BUDGET,
  AD_SET_LIFETIME_BUDGET,
  CTA_OPTIONS,
  CREATIVE_TYPES,
  OPTIONS_NOTE_CARD_HEADERS,
  OPTIONS_NOTE_CARD_OPTIONS,
  CONVERSION_GOALS,
  COST_CAP,
  LOWEST_COST_WITH_BID_CAP,
  LOWEST_COST_WITH_MIN_ROAS,
} from "../MetaBuyingConstants";
import { PageTab } from "../MetaBuying";

interface MetaBuyingAdProps {
  client: string;
  namingConventions: {
    [CAMPAIGN]: number[];
    [AD_SET]: number[];
    [AD]: number[];
  };
  tabIndex: number;
  setTabIndex: React.Dispatch<React.SetStateAction<number>>;
  showReviewModal: () => void;
  selectedAdAccount: {
    account_id: string;
    account_name: string;
    business_manager: string;
  };
  validateForms: (
    metaBuyingForm: typeof metaBuyingFormDefault,
    navigate: () => void,
    nameFields: Record<string, any>,
    tabToValidate?: CreationTab
  ) => void;
  creationTabs: CreationTab[];
  granularity: Level;
  dimensions: Record<string, any>;
  allNameFields: Record<string, any>;
  setAllNameFields: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  adSets: AdSetRow[];
  campaigns: CampaignRow[];
  facebookPages: { page_id: string; name: string }[];
  // instagramAccounts: { id: string; username: string }[];
  saveNameFields: (nameFields: Record<string, any>) => void;
  goToTables: (tab: PageTab) => void;
}

export const MetaBuyingAd = ({
  client,
  selectedAdAccount,
  namingConventions,
  showReviewModal,
  validateForms,
  creationTabs,
  granularity,
  dimensions,
  tabIndex,
  setTabIndex,
  allNameFields,
  setAllNameFields,
  adSets,
  campaigns,
  facebookPages,
  // instagramAccounts,
  saveNameFields,
  goToTables,
}: MetaBuyingAdProps & RouteComponentProps): JSX.Element => {
  const { metaBuyingForm, setMetaBuyingForm } = useContext(FormContext);

  const metaAdForm = metaBuyingForm.ad;

  const namingConvention = namingConventions[AD];

  const { account_id, business_manager } = selectedAdAccount;

  const setError = useSetError();

  const ADSET_MENU_ID = "adSetMenu";

  const segmentationDimensionId = R.keys(
    R.filter(dimension => dimension.type === Dimension.SEGMENTATION_ID, dimensions)
  )[0];

  const isSegmentedLevel = R.includes(Number(segmentationDimensionId), namingConvention);

  const [nameFields, setNameFields] = useState<Record<string, any>>(
    generateNameFields(namingConvention, allNameFields)
  );

  const [enteredAdSetId, setEnteredAdSetId] = useState("");
  const [showInputAdSetId, setShowInputAdSetId] = useState(false);
  const [menusState, setMenusState] = useState<Record<string, any>>({
    [ADSET_MENU_ID]: false,
  });
  const [adSetLoading, setAdSetLoading] = useState(false);
  const [creativeModalType, setCreativeModalType] = useState<Creative | null>(null);
  const inheritedFields = filterInheritedFields(allNameFields);

  const setNameField = useCallback(
    (id, value) => {
      let newNameFields = R.clone(nameFields);
      newNameFields[id] = value;
      setNameFields(newNameFields);
    },
    [nameFields]
  );

  const setMetaCampaignForm = useCallback(
    value =>
      setMetaBuyingForm((current: any): typeof metaBuyingFormDefault => {
        return { ...current, campaign: value };
      }),
    [setMetaBuyingForm]
  );

  const setMetaAdSetForm = useCallback(
    value =>
      setMetaBuyingForm((current: any): typeof metaBuyingFormDefault => {
        return { ...current, ad_set: value };
      }),
    [setMetaBuyingForm]
  );

  const updateFormField = useCallback(
    (key: string, value: FormField) =>
      setMetaBuyingForm((current: any): typeof metaBuyingFormDefault => {
        return { ...current, ad: { ...current.ad, [key]: value } };
      }),
    [setMetaBuyingForm]
  );

  useEffect(() => {
    if (isSegmentedLevel) {
      const filteredSegIds = filterSegmentationIds(nameFields, dimensions, segmentationDimensionId);
      const selectedSegId = nameFields[segmentationDimensionId];
      if (!R.includes(selectedSegId, filteredSegIds) && !R.isEmpty(selectedSegId)) {
        setNameField(segmentationDimensionId, "");
      }
    }
  }, [nameFields, segmentationDimensionId, dimensions, setNameField, isSegmentedLevel]);

  useEffect(() => {
    const newAdName = namingConvention.map(dimensionId => nameFields[dimensionId]).join("_");
    if (!R.equals(newAdName, metaAdForm.ad_name) && metaAdForm.ad_id === null) {
      updateFormField("ad_name", newAdName);
    }
  }, [nameFields, updateFormField, namingConvention, metaAdForm]);

  useEffect(() => {
    if (metaAdForm.ad_id !== null && R.all(value => R.isEmpty(value), R.values(nameFields))) {
      let valid = validateName(
        metaAdForm.ad_name,
        AD,
        namingConventions,
        dimensions,
        client,
        business_manager
      );
      if (valid) {
        let newNameFields = R.clone(nameFields);

        const thisNameElements = metaAdForm.ad_name.split("_");

        namingConvention.forEach((dimensionId, index) => {
          newNameFields[dimensionId] = thisNameElements[index];
        });

        setNameFields(newNameFields);
      } else {
        setError({
          title: "Ad Name Error",
          message: `Unable to edit ad '${metaAdForm.ad_name}' as it does not follow the ${client} ad naming convention`,
          okayText: "Return to Drafts",
          onOkay: () => goToTables(PageTab.DRAFTS),
        });
      }
    }
  }, [
    dimensions,
    nameFields,
    namingConvention,
    inheritedFields,
    business_manager,
    metaAdForm,
    namingConventions,
    goToTables,
    client,
    setError,
  ]);

  useEffect(() => {
    const handleOutsideClick = event => {
      const closestMenuToggle = event.target.closest(".menuToggle");
      const closestAppliedSelection = event.target.closest(".appliedSelection");

      const idTokens = closestMenuToggle ? closestMenuToggle.id.split("_") : null;
      const menuId: string | null = idTokens ? idTokens[0] : null;
      const nodeType: string | null = idTokens ? idTokens[1] : null;

      if (menuId && nodeType === DROPDOWN_BTN && R.isNil(closestAppliedSelection)) {
        //@ts-ignore
        setMenusState(
          R.fromPairs(
            R.map(([key, value]) => [key, key === menuId ? !value : false], R.toPairs(menusState))
          )
        );
        //@ts-ignore
      } else if (!menuId && R.any(menuState => menuState === true, R.values(menusState))) {
        //@ts-ignore
        setMenusState(R.map(() => false, menusState));
        //@ts-ignore
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [menusState]);

  const submitAdSetId = async id => {
    setAdSetLoading(true);

    try {
      let res = await MetaLambdaFetch("/getAdSetInfo", {
        params: { id, account_id },
      });
      let { data } = await awaitJSON(res);

      if (R.isNil(data) || R.isEmpty(data)) {
        setError({
          title: "Failed to Fetch Ad Set",
          message: `No ad sets found with ID ${id} in account ${account_id}`,
        });
      } else {
        applyAdSet(data);
      }
    } catch (e) {
      setError({ message: e.message, reportError: e });
    } finally {
      setAdSetLoading(false);
    }
  };

  const toggleMenuState = (menuId, newStatus?) =>
    setMenusState({
      ...menusState,
      [menuId]: !R.isNil(newStatus) ? newStatus : !menusState[menuId],
    });

  const closeMenuState = menuId => toggleMenuState(menuId, false);

  const InputNote = ({ noteText, buttonText, switchInput }): JSX.Element => (
    <p className="metaBuyingInputNote">
      {noteText}&nbsp;
      <button onClick={() => switchInput()} className="metaBuyingInputSwitch">
        {buttonText}
      </button>
    </p>
  );

  const applyAdSet = adSet => {
    try {
      const campaign = mapRowsByProp("id", campaigns)[adSet.campaign_id];

      if (R.isNil(campaign)) {
        throw new Error(`Invalid campaign ID: '${adSet.campaign_id}'`);
      }

      if (
        !validateName(
          adSet.adset_name,
          AD_SET,
          namingConventions,
          dimensions,
          client,
          business_manager,
          granularity === CAMPAIGN ? CAMPAIGN : undefined,
          campaign.campaign_name
        )
      ) {
        throw new Error(
          `Ad Set name '${adSet.adset_name}' does not follow this client's naming convention`
        );
      } else if (
        !validateName(
          campaign.campaign_name,
          CAMPAIGN,
          namingConventions,
          dimensions,
          client,
          business_manager
        )
      ) {
        throw new Error(
          `Campaign name '${campaign.campaign_name}' does not follow ${client}'s naming convention`
        );
      }

      let newAllNameFields = R.clone(allNameFields);
      let newNameFields = R.clone(nameFields);
      let campaignNameElements = campaign.campaign_name.split("_");
      let adSetNameElements = adSet.adset_name.split("_");

      namingConventions[CAMPAIGN].forEach((dimensionId, index) => {
        newAllNameFields[dimensionId] = { value: campaignNameElements[index] };
        if (allNameFields[dimensionId].inheritedFrom) {
          newAllNameFields[dimensionId].inheritedFrom = allNameFields[dimensionId].inheritedFrom;
        }
        if (R.includes(dimensionId, namingConventions[AD])) {
          newNameFields[dimensionId] = campaignNameElements[index];
        }
      });

      namingConventions[AD_SET].forEach((dimensionId, index) => {
        newAllNameFields[dimensionId] = { value: adSetNameElements[index] };
        if (allNameFields[dimensionId].inheritedFrom) {
          newAllNameFields[dimensionId].inheritedFrom = allNameFields[dimensionId].inheritedFrom;
        }
        if (R.includes(dimensionId, namingConventions[AD])) {
          newNameFields[dimensionId] = adSetNameElements[index];
        }
      });

      let newMetaCampaignForm = R.clone(metaCampaignFormDefault);
      let newMetaAdSetForm = R.clone(metaAdSetFormDefault);

      newMetaCampaignForm.campaign_name = campaign.campaign_name || "";
      newMetaCampaignForm.status = campaign.adsmanager_status || STATUS_NONE;
      newMetaCampaignForm.meta_id = campaign.meta_id || null;
      newMetaCampaignForm.bid_strategy = campaign.bid_strategy || null;
      newMetaCampaignForm.objective = campaign.objective || OUTCOME_SALES;
      newMetaCampaignForm.special_ad_categories = campaign.special_ad_categories || [];

      if (campaign.daily_budget) {
        newMetaCampaignForm.budget = Number(campaign.daily_budget);
        newMetaCampaignForm.budget_type = CAMPAIGN_DAILY_BUDGET;
      } else if (campaign.lifetime_budget) {
        newMetaCampaignForm.budget = Number(campaign.lifetime_budget);
        newMetaCampaignForm.budget_type = CAMPAIGN_LIFETIME_BUDGET;
      }

      newMetaAdSetForm.adset_name = adSet.adset_name || "";
      newMetaAdSetForm.campaign_id = campaign.id || null;
      newMetaAdSetForm.status = adSet.adsmanager_status || STATUS_NONE;
      newMetaAdSetForm.meta_id = adSet.meta_id || null;
      newMetaAdSetForm.pixel = adSet.pixel_id || null;
      newMetaAdSetForm.conversion_event = adSet.conversion_event || adSet.custom_event_type || null;
      newMetaAdSetForm.optimization_goal = adSet.optimization_goal || null;
      newMetaAdSetForm.bid_strategy = adSet.bid_strategy || null;
      newMetaAdSetForm.bid_amount = adSet.bid_amount || null;
      newMetaAdSetForm.bid_constraints = adSet.bid_constraints || {};
      newMetaAdSetForm.genders = adSet.genders || ALL;
      newMetaAdSetForm.age_min = adSet.age_min || 18;
      newMetaAdSetForm.age_max = adSet.age_max || 65;
      newMetaAdSetForm.click_attr_window = adSet.click_attr_window || 1;
      newMetaAdSetForm.view_attr_window = adSet.view_attr_window || 0;
      newMetaAdSetForm.publisher_platforms = adSet.publisher_platforms || [];
      newMetaAdSetForm.facebook_positions = adSet.facebook_positions || [];
      newMetaAdSetForm.instagram_positions = adSet.instagram_positions || [];
      newMetaAdSetForm.messenger_positions = adSet.messenger_positions || [];
      newMetaAdSetForm.audience_network_positions = adSet.audience_network_positions || [];
      newMetaAdSetForm.start_time = adSet.start_time || null;
      newMetaAdSetForm.end_time = adSet.end_time || null;

      if (adSet.daily_budget) {
        newMetaAdSetForm.budget = Number(adSet.daily_budget);
        newMetaAdSetForm.budget_type = AD_SET_DAILY_BUDGET;
      } else if (adSet.lifetime_budget) {
        newMetaAdSetForm.budget = Number(adSet.lifetime_budget);
        newMetaAdSetForm.budget_type = AD_SET_LIFETIME_BUDGET;
      }

      if (
        !newMetaAdSetForm.optimization_goal ||
        !newMetaAdSetForm.conversion_event ||
        !newMetaAdSetForm.bid_strategy
      ) {
        throw new Error("Ad set has required fields missing");
      } else {
        const { validOptions } = CONVERSION_GOALS[newMetaAdSetForm.optimization_goal];
        const { objectives, bidStrategies } = validOptions;
        if (
          !R.includes(newMetaCampaignForm.objective, objectives) ||
          !R.includes(newMetaAdSetForm.bid_strategy, bidStrategies)
        ) {
          throw new Error("Invalid conversion options");
        }
        if (
          newMetaCampaignForm.bid_strategy &&
          newMetaCampaignForm.bid_strategy !== newMetaAdSetForm.bid_strategy
        ) {
          throw new Error("Campaign and ad set bid strategies do not match");
        }

        if (
          (newMetaAdSetForm.bid_strategy === COST_CAP ||
            newMetaAdSetForm.bid_strategy === LOWEST_COST_WITH_BID_CAP) &&
          !newMetaAdSetForm.bid_amount
        ) {
          throw new Error("No bid amount set for bid strategy");
        }

        if (
          newMetaAdSetForm.bid_strategy === LOWEST_COST_WITH_MIN_ROAS &&
          !newMetaAdSetForm.bid_constraints.roas_average_floor
        ) {
          throw new Error("No min ROAS set for bid strategy");
        }

        const { click_through, view_through } = getAttributionOptions(
          newMetaCampaignForm.objective,
          newMetaAdSetForm.optimization_goal
        );

        if (!R.includes(newMetaAdSetForm.click_attr_window, click_through)) {
          throw new Error("Invalid click through attribution window");
        }
        if (!R.includes(newMetaAdSetForm.view_attr_window, view_through)) {
          throw new Error("Invalid view through attribution window");
        }
      }

      if (
        (campaign.daily_budget || campaign.lifetime_budget) &&
        (adSet.daily_budget || adSet.lifetime_budget)
      ) {
        throw new Error("Both campaign and ad set have budgets set");
      }

      if (newMetaCampaignForm.status === STATUS_NONE && newMetaAdSetForm.status !== STATUS_NONE) {
        throw new Error("Invalid campaign status");
      }

      const allPositions = [
        ...newMetaAdSetForm.facebook_positions,
        ...newMetaAdSetForm.instagram_positions,
        ...newMetaAdSetForm.audience_network_positions,
        ...newMetaAdSetForm.messenger_positions,
      ];
      if (!allPositions.length || !newMetaAdSetForm.publisher_platforms.length) {
        throw new Error("Ad set placements missing");
      }

      if (
        newMetaAdSetForm.start_time &&
        newMetaAdSetForm.end_time &&
        Dfns.isAfter(
          Dfns.parseISO(removeIsoTZ(newMetaAdSetForm.end_time)),
          Dfns.parseISO(removeIsoTZ(newMetaAdSetForm.start_time))
        )
      ) {
        throw new Error("End date is before start date");
      }

      setNameFields(newNameFields);
      setAllNameFields(newAllNameFields);
      setMetaCampaignForm(newMetaCampaignForm);
      setMetaAdSetForm(newMetaAdSetForm);
      updateFormField("adset_id", adSet.id);
    } catch (e) {
      setError({
        title: "Invalid Ad Set",
        message: e.message,
      });
    }
  };

  const removeAdSet = () => {
    setMetaAdSetForm(metaAdSetFormDefault);
    setMetaCampaignForm(metaCampaignFormDefault);
    setNameFields(
      R.fromPairs(
        R.map(
          ([key, value]) => [key, R.includes(Number(key), inheritedFields) ? "" : value],
          R.toPairs(nameFields)
        )
      )
    );
    setEnteredAdSetId("");
    updateFormField("adset_id", null);
  };

  const switchInput = () => {
    removeAdSet();
    setShowInputAdSetId(!showInputAdSetId);
  };

  // const instagramAccountMap = mapRowsByProp("id", instagramAccounts)[
  //   metaAdForm.instagram_account_id
  // ];

  return (
    <>
      <MetaBuyingTabs
        tabIndex={tabIndex}
        creationTabs={creationTabs}
        validate={index => {
          if (index <= tabIndex) {
            saveNameFields(nameFields);
            setTabIndex(index);
          } else {
            validateForms(metaBuyingForm, showReviewModal, nameFields);
          }
        }}
      />
      <div className="metaBuyingCreate">
        <div className="metaBuyingContainer">
          <div className="metaBuyingCard">
            <div className="metaBuyingHeader">
              <h2>Ad</h2>
              <div className="metaBuyingNameWrapper">
                Name:
                <MetaBuyingNamePreview
                  dimensions={dimensions}
                  nameFields={nameFields}
                  namingConvention={namingConvention}
                  inheritedFields={inheritedFields}
                />
              </div>
            </div>

            <div className="metaBuyingFormBody">
              <div className="metaBuyingFormSection">
                <h3>Ad Name Construction</h3>
                <MetaBuyingNameFields
                  namingConvention={namingConvention}
                  dimensions={dimensions}
                  nameFields={nameFields}
                  segmentationDimensionId={segmentationDimensionId}
                  client={client}
                  agency={business_manager}
                  setNameField={setNameField}
                  inheritedFields={inheritedFields}
                  granularity={granularity}
                  disabled={!R.isNil(metaAdForm.ad_id)}
                />
              </div>

              {!R.includes(TAB_AD_SET, creationTabs) && (
                <div className="metaBuyingFormSection">
                  <h3>Ad Set</h3>
                  <Form.Group>
                    <Form.Label>Ad Set</Form.Label>
                    {showInputAdSetId ? (
                      <>
                        <InputNote
                          noteText="Can’t input Ad Set ID?"
                          buttonText="Select from dropdown instead"
                          switchInput={() => switchInput()}
                        />
                        <div className="metaBuyingSubmitInputWrapper">
                          <div className="metaBuyingSubmission">
                            {metaAdForm.adset_id && (
                              <div className="appliedSelection" onClick={removeAdSet}>
                                {metaAdForm.adset_id}
                              </div>
                            )}
                          </div>
                          <Form.Control
                            style={{ paddingTop: 10 }}
                            value={enteredAdSetId}
                            onChange={e => setEnteredAdSetId(e.target.value)}
                            disabled={metaAdForm.adset_id || adSetLoading}
                          />
                          {R.isNil(metaAdForm.adset_id) && (
                            <Button
                              className="metaBuyingSubmitButton"
                              type={ButtonType.FILLED}
                              variant={ButtonFrameworkVariant.LEADING_ICON}
                              icon={<HiPlus />}
                              onClick={() => submitAdSetId(enteredAdSetId)}
                              size="sm"
                              disabled={R.isEmpty(enteredAdSetId) || adSetLoading}
                            >
                              Submit
                            </Button>
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <InputNote
                          noteText="Don’t see your ad set?"
                          buttonText="Enter Ad Set ID instead"
                          switchInput={() => switchInput()}
                        />
                        <SingleSelectMenu
                          placeholder="Ad Set"
                          menuId={ADSET_MENU_ID}
                          data={adSets}
                          nameProp="adset_name"
                          closeMenu={() => closeMenuState(ADSET_MENU_ID)}
                          showMenu={menusState[ADSET_MENU_ID]}
                          applySelection={id => applyAdSet(mapRowsByProp("id", adSets)[id])}
                          removeSelection={removeAdSet}
                          appliedSelection={metaAdForm.adset_id}
                        />
                      </>
                    )}
                  </Form.Group>
                </div>
              )}

              <div className="metaBuyingFormSection">
                <h3>Page and Account</h3>
                <Form.Group>
                  <Form.Label>Facebook Page</Form.Label>
                  <DropdownButton
                    title={metaAdForm.page_name}
                    onSelect={value => {
                      updateFormField("facebook_page_id", value || "");
                      updateFormField(
                        "page_name",
                        facebookPages[facebookPages.findIndex(page => page.page_id === value)]
                          .name || ""
                      );
                    }}
                  >
                    {facebookPages.map(({ name, page_id }) => (
                      <Dropdown.Item key={page_id} eventKey={page_id}>
                        {name}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </Form.Group>
                {/* <Form.Group>
                  <Form.Label>Instagram Account</Form.Label>
                  <DropdownButton
                    title={instagramAccountMap ? instagramAccountMap.username : ""}
                    onSelect={value => updateFormField("instagram_account_id", value || "")}
                  >
                    {instagramAccounts.map(({ username, id }) => (
                      <Dropdown.Item key={id} eventKey={id}>
                        {username}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </Form.Group> */}
              </div>

              <div className="metaBuyingFormSection">
                <h3>Creative</h3>
                <Form.Group>
                  <Form.Label>Asset Type</Form.Label>
                  {CREATIVE_TYPES.map(({ label, value }) => (
                    <Form.Check
                      key={value}
                      type="radio"
                      label={label}
                      checked={metaAdForm.creative_format === value}
                      id={value}
                      onChange={() => updateFormField("creative_format", value)}
                      disabled={metaAdForm.creative_asset}
                    />
                  ))}
                </Form.Group>
                {metaAdForm.creative_format && (
                  <Form.Group>
                    <Form.Label>Creative Asset</Form.Label>
                    {metaAdForm.creative_asset ? (
                      <div className="selectedAsset">
                        <a
                          href={
                            metaAdForm.creative_asset.permalink_url ||
                            metaAdForm.creative_asset.picture
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {metaAdForm.creative_asset.name || metaAdForm.creative_asset.title}
                        </a>
                        <Button
                          variant={ButtonFrameworkVariant.ICON_ONLY}
                          type={ButtonType.EMPTY}
                          icon={<MdDelete />}
                          onClick={() => updateFormField("creative_asset", null)}
                        />
                      </div>
                    ) : (
                      <Button
                        className="metaBuyingSubmitButton"
                        type={ButtonType.OUTLINED}
                        onClick={() => setCreativeModalType(metaAdForm.creative_format)}
                      >
                        {`Choose ${metaAdForm.creative_format
                          .charAt(0)
                          .toUpperCase()}${metaAdForm.creative_format.slice(1).toLowerCase()}`}
                      </Button>
                    )}
                  </Form.Group>
                )}
                <Form.Group>
                  <Form.Label>Primary Text</Form.Label>
                  <Form.Control
                    value={metaAdForm.message}
                    onChange={e => updateFormField("message", e.target.value)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Headline</Form.Label>
                  <Form.Control
                    value={metaAdForm.headline}
                    onChange={e => updateFormField("headline", e.target.value)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    value={metaAdForm.description}
                    onChange={e => updateFormField("description", e.target.value)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Call to Action</Form.Label>
                  <DropdownButton
                    title={
                      //@ts-ignore
                      mapLabelsByValues(CTA_OPTIONS)[metaAdForm.call_to_action_type] || ""
                      //@ts-ignore
                    }
                    onSelect={value => updateFormField("call_to_action_type", value || "")}
                  >
                    {CTA_OPTIONS.map(({ label, value }) => (
                      <Dropdown.Item key={value} eventKey={value}>
                        {label}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Website URL</Form.Label>
                  <Form.Control
                    placeholder="https://www.tinuiti.com"
                    value={metaAdForm.url}
                    onChange={e => updateFormField("url", e.target.value)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>URL Parameters</Form.Label>
                  <Form.Control
                    placeholder="?utm_test=example"
                    value={metaAdForm.url_params}
                    onChange={e => {
                      let { value } = e.target;
                      if (value.length && value.substring(0, 1) !== "?") {
                        value = `?${value}`;
                      }
                      updateFormField("url_params", value);
                    }}
                  />
                </Form.Group>
              </div>
              <MetaBuyingFooterControls
                handleReviewClick={() => validateForms(metaBuyingForm, showReviewModal, nameFields)}
              />
            </div>
          </div>

          <MetaBuyingOptionsNoteCard
            header={OPTIONS_NOTE_CARD_HEADERS[AD]}
            options={OPTIONS_NOTE_CARD_OPTIONS[AD]}
          />
        </div>
      </div>

      {creativeModalType === IMAGE && (
        <MetaCreativeAssetModal
          show={true}
          closeModal={() => setCreativeModalType(null)}
          accountID={account_id}
          isVideo={false}
          selectCreative={asset => {
            if (asset) {
              updateFormField("creative_asset", asset);
            }
          }}
        />
      )}
      {creativeModalType === VIDEO && (
        <MetaCreativeAssetModal
          show={true}
          closeModal={() => setCreativeModalType(null)}
          accountID={account_id}
          isVideo={true}
          selectCreative={asset => {
            if (asset) {
              updateFormField("creative_asset", asset);
            }
          }}
        />
      )}
    </>
  );
};

export default MetaBuyingAd;

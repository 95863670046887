import React, { useEffect, useState, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import { RouteComponentProps } from "@reach/router";
import { TableauViz } from "@tableau/embedding-api";
import { awaitJSON, MiscLambdaFetch } from "../utils/fetch-utils";
import useLocation from "../utils/hooks/useLocation";
import * as CompanyRedux from "../redux/company";
import { useSetError } from "../redux/modals";
import { Page } from "../Components";
import "./CustomTableau.scss";

const REFRESH_TIME = 1000 * 60 * 8; // 30 days in ms

interface CustomTableauProps extends RouteComponentProps {
  dashboardName: string;
}

const CustomTableau: React.FC<CustomTableauProps> = ({ dashboardName }) => {
  const [token, setToken] = useState<string | null>(null);
  const [dashboardObj, setDashboardObj] = useState<CompanyRedux.CustomDashboardInfo | null>();
  const { company } = useLocation();
  const vizRef = useRef(null);
  const companyInfo = useSelector(CompanyRedux.companyInfoSelector(company));
  const setError = useSetError();
  const dashboardNameKey = dashboardName.replace(/_/g, " ");

  const getJwt = useCallback(async () => {
    try {
      const res = await MiscLambdaFetch("/tableauToken", {
        params: { secret: "prod/tableauJWTSecrets" },
      });
      const token = await awaitJSON(res);
      setToken(token);
    } catch (e) {
      const reportError = e as Error;
      setError({
        title: "Error",
        message: `Error fetching authentication token. ${reportError.message}`,
        reportError,
      });
    }
  }, [setError]);

  useEffect(() => {
    if (!token) {
      getJwt();
    } else {
      setTimeout(() => {
        getJwt();
      }, REFRESH_TIME);
    }
  }, [getJwt, token]);

  useEffect(() => {
    const currentDashboard = companyInfo?.custom_dashboards.find(
      obj => obj.name.toLowerCase() === dashboardNameKey
    );
    if (currentDashboard) {
      setDashboardObj(currentDashboard);
      new TableauViz();
    }
    return () => {
      setToken(null);
    };
  }, [companyInfo, dashboardNameKey, setError]);

  return (
    <Page pageType={dashboardObj?.name || "Custom Dashboard"} title={dashboardObj?.name}>
      <div className="tableauContainer">
        {token && dashboardObj && (
          <tableau-viz
            src={dashboardObj.url}
            id="tableauViz"
            hide-edit-button="true"
            hideTabs
            iframe-auth
            token={token}
            ref={vizRef}
            height="100%"
            toolbar="bottom"
          />
        )}
      </div>
    </Page>
  );
};

export default CustomTableau;

import * as R from "ramda";
import { capitalizeWords, currencyFormatter } from "../../MMM/MMMUtils";

interface MedianResult {
  median: number;
  dataPoint: number;
}

export const saturationDataFormatter = (data: any[], groupByLevel: string): { newD; medians } => {
  const groupingFunction =
    groupByLevel === "channel"
      ? (item: any) => {
          return item.channel || "N/A";
        }
      : (item: any) => {
          const channel = R.prop("channel", item) || "";
          const tactic = R.prop("tactic", item) || "";
          const brand = R.prop("brand_nonbrand", item) || "";
          const key = keyGeneratorForChannelTacticBrandGrouping(channel, tactic, brand);
          return key;
        };

  const updatedData = data.map(item =>
    groupByLevel === "channel"
      ? { name: item.channel, ...item }
      : {
          name: keyGeneratorForChannelTacticBrandGrouping(
            item.channel,
            item.tactic,
            item.brand_nonbrand
          ),
          ...item,
        }
  );

  const groupedData = R.groupBy(groupingFunction, updatedData);

  const medianOfSpend = (data: any[]) => {
    const responses: number[] = R.map(item => {
      const response = R.prop("spend", item);
      return response;
    }, data);

    const sortedResponses: number[] = R.sort((a, b) => a - b, responses);
    const median =
      sortedResponses.length % 2 === 0
        ? (sortedResponses[sortedResponses.length / 2 - 1] +
            sortedResponses[sortedResponses.length / 2]) /
          2
        : sortedResponses[Math.floor(sortedResponses.length / 2)];

    const dataPoint =
      sortedResponses.length % 2 === 0 ? sortedResponses[sortedResponses.length / 2 - 1] : median;

    return { median, dataPoint };
  };

  const medians: MedianResult[] = R.map(medianOfSpend, groupedData);
  const transformedData: { name: string; data: any[] }[] = R.pipe(
    R.toPairs,
    R.map(([name, data]) => {
      return {
        name: capitalizeWords(name)
          .replace("Paid Search Nb", "Paid Search - Non-brand")
          .replace("Paid Shopping Nb", "Paid Shopping - Non-brand")
          .replace("Tik Tok", "TikTok")
          .replace("Linear Television", "Linear TV"),
        data: data as any[],
      };
    })
  )(groupedData);

  return { newD: transformedData, medians };
};

export const saturationDataFormatterByName = (data: any[]): { newD; medians; groupedLines } => {
  const groupedNames = R.groupBy(item => R.prop("name", item), data);
  const medians = {};
  for (const [key, value] of Object.entries(groupedNames)) {
    const sortedResponses = value.map(row => row.spend).sort((a, b) => a - b);

    const median =
      sortedResponses.length % 2 === 0
        ? (sortedResponses[sortedResponses.length / 2 - 1] +
            sortedResponses[sortedResponses.length / 2]) /
          2
        : sortedResponses[Math.floor(sortedResponses.length / 2)];

    const dataPoint =
      sortedResponses.length % 2 === 0 ? sortedResponses[sortedResponses.length / 2 - 1] : median;

    medians[key] = { median, dataPoint };
  }

  const transformedData: {
    name: string;
    data: any[];
    grouping: { channel: string; platform: string; tactic: string; brand_nonbrand: string };
  }[] = R.pipe(
    R.toPairs,
    R.map(([name, data]: [string, any[]]) => {
      const channel = capitalizeWords(data[0].channel);
      let platform = capitalizeWords(data[0].platform);
      const tactic = capitalizeWords(data[0].tactic);
      const brand_nonbrand = capitalizeWords(data[0].brand_nonbrand);
      if (!platform && !brand_nonbrand && !tactic) {
        platform = `All ${channel}`;
      }

      return {
        name: capitalizeWords(name),
        data: data as any[],
        grouping: { channel, platform, tactic, brand_nonbrand },
      };
    })
  )(groupedNames);

  let groupedLines = [] as any[];
  if (Array.isArray(transformedData) && transformedData.length > 0 && transformedData[0].grouping) {
    groupedLines = transformedData.reduce((acc: { channel: string; areas: any[] }[], obj) => {
      const { channel } = obj.grouping || { channel: "unknown" };
      const existingGroup = acc.find(item => item.channel === channel);

      if (existingGroup) {
        existingGroup.areas.push(obj);
      } else {
        acc.push({ channel, areas: [obj] });
      }

      return acc;
    }, []);
  }

  return { newD: transformedData, medians, groupedLines };
};

export const decayDataFormatter = (
  data: any[],
  yAxisDataKey: string
): { newD; lines; groupedLines } => {
  const d = R.groupBy(item => R.prop("period", item), data);
  let newD = [] as any[];
  let grouping = [] as any[];

  Object.keys(d).forEach((key, index) => {
    newD[index] = {};
    newD[index].date = key;

    d[key].forEach((item: any) => {
      newD[index][item.name] = item[yAxisDataKey] * 100;
      grouping.push({});

      if (item.platform === null && item.tactic === null && item.brand_nonbrand === null) {
        grouping[index][item.name] = {
          channel: capitalizeWords(item.channel),
          platform: `All ${capitalizeWords(item.channel)}`,
          tactic: null,
          brand_nonbrand: null,
        };
      } else {
        grouping[index][item.name] = {
          channel: capitalizeWords(item.channel),
          platform: capitalizeWords(item.platform),
          tactic: capitalizeWords(item.tactic),
          brand_nonbrand: capitalizeWords(item.brand_nonbrand),
        };
      }
    });
  });

  const keys = Object.keys(newD[0]).filter(key => key !== "date");
  let lines: {
    dataKey: string;
    grouping?: { channel: string; platform: string; tactic: string; brand: string };
    name: string;
  }[] = [];
  keys.forEach((key, index) => {
    lines[index] = {
      name: capitalizeWords(key),
      dataKey: key,
      grouping: grouping ? grouping[0][key] : null,
    };
  });

  let groupedLines = [] as any[];
  if (Array.isArray(lines) && lines.length > 0 && lines[0].grouping) {
    groupedLines = lines.reduce((acc: { channel: string; areas: any[] }[], obj) => {
      const { channel } = obj.grouping || { channel: "unknown" }; // Default to 'unknown' if channel is not present
      const existingGroup = acc.find(item => item.channel === channel);

      if (existingGroup) {
        existingGroup.areas.push(obj);
      } else {
        acc.push({ channel, areas: [obj] });
      }

      return acc;
    }, []);
  }

  return { newD, lines, groupedLines };
};

export const decayDataFormatterUpdated = (
  data: any[],
  yAxisDataKey: string,
  groupByLevel: string
): { newD: any[]; lines: { name: string; dataKey: string }[] } => {
  const groupedByPeriod = R.groupBy(item => R.prop("period", item), data);
  let newD: any[] = [];

  Object.keys(groupedByPeriod).forEach((periodKey, periodIndex) => {
    newD[periodIndex] = {};
    newD[periodIndex].Index = periodKey;

    let groupedData: Record<string, any[]>;
    switch (groupByLevel) {
      case "channel":
        groupedData = R.groupBy(item => item.channel || "N/A", groupedByPeriod[periodKey]);
        break;

      default:
        groupedData = R.groupBy(
          item =>
            keyGeneratorForChannelTacticBrandGrouping(
              item.channel,
              item.tactic,
              item.brand_nonbrand
            ),
          groupedByPeriod[periodKey]
        );
        break;
    }

    Object.keys(groupedData).forEach(groupKey => {
      const items = groupedData[groupKey];

      const combinedKey = groupKey.split("_").join("_");

      if (newD[periodIndex][combinedKey] === undefined) {
        newD[periodIndex][combinedKey] = 0;
      }

      items.forEach(item => {
        newD[periodIndex][combinedKey] += item[yAxisDataKey] * 100;
      });
    });
  });

  const keys = Object.keys(newD[0]).filter(key => key !== "Index");
  let lines: { name: string; dataKey: string }[] = keys.map((key, index) => ({
    name: capitalizeWords(key),
    dataKey: key,
  }));
  lines = R.sortBy(R.prop("name"), lines);

  return { newD, lines };
};

export const standardizeMetricName = (metric: string, fullName?: boolean): string => {
  const formattedMetric = metric
    .split("_")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  if (fullName && metric === "GQV") {
    return `Google Query Volume (${formattedMetric})`;
  }

  return formattedMetric === "Awareness" ? "Aided Awareness" : formattedMetric;
};

export const standardizeMetricNameOriginal = (metric: string): string => {
  const formattedMetric = metric
    .split("_")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
  return formattedMetric;
};

export const currFormat = (s: string | number, decimals: number): string => {
  let final = String(s).replace(/,/g, "");
  let finalNum = parseInt(final);
  return currencyFormatter.format(finalNum, decimals);
};

export const percFormat = (s: string | number, decimals = 2): string => {
  let final = String(s).replace(/,/g, "");
  let finalNum = parseFloat(final);
  const percentVal = `${finalNum.toFixed(decimals)}%`;
  return percentVal;
};

export const weekFormat = (s: string | number): string => {
  let final = String(s).replace(/,/g, "");
  let finalNum = parseFloat(final);
  const percentVal = `${finalNum.toFixed(0)}`;
  return percentVal;
};

export const snapShotDataSort = (data: any[], snapChartSortValue: string): any[] => {
  let snapData;

  switch (snapChartSortValue) {
    case "Alphabetical":
      snapData = R.sortBy(R.prop("name"), data);
      break;
    case "Highest to lowest":
    case "Spend: High to low":
      snapData = R.sortBy(R.compose(R.negate, R.prop("value")), data);
      break;
    case "Lowest to highest":
    case "Spend: Low to high":
      snapData = R.sortBy(R.prop("value"), data);
      break;
    case "Effect: High to low":
      snapData = R.sortBy(R.compose(R.negate, R.prop("value2")), data);
      break;
    case "Effect: Low to high":
      snapData = R.sortBy(R.prop("value2"), data);
      break;
    case "Spend to effect ratio":
      snapData = R.sortBy(R.compose(R.negate, R.prop("spendOverOutcome")), data);
      break;
  }

  return snapData;
};

export const keyGeneratorForChannelTacticBrandGrouping = (
  channel: string,
  platform?: string,
  tactic?: string,
  brand?: string
): string => {
  const keyParts = [channel];
  if (tactic) {
    keyParts.push(capitalizeWords(tactic));
  }
  if (platform) {
    keyParts.push(capitalizeWords(platform));
  }
  if (brand) {
    keyParts.push(capitalizeWords(brand));
  }
  const key = keyParts.join(" - ");
  return key;
};

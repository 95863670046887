import React from "react";
import { RouteComponentProps } from "@reach/router";
import { Button, ButtonType } from "../Components";
import { CreationTab } from "@blisspointmedia/bpm-types/dist/MetaBuying";

interface MetaBuyingTabsProps {
  tabIndex: number;
  creationTabs: CreationTab[];
  validate: (index: number) => void;
}

export const MetaBuyingTabs = ({
  tabIndex,
  creationTabs,
  validate,
}: MetaBuyingTabsProps & RouteComponentProps): JSX.Element => {
  return (
    <div className="metaBuyingTabs">
      {creationTabs.map((tab, index) => (
        <Button
          key={tab}
          type={ButtonType.EMPTY}
          className={tabIndex === index ? "selected" : ""}
          onClick={() => validate(index)}
        >
          {index + 1}. {tab}
        </Button>
      ))}
    </div>
  );
};

import "./GoogleBrandHealth.scss";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import {
  Img,
  DownloadDropdown,
  Dropdown,
  DropdownToggleType,
  TextToggleButton,
  DmaMap,
  BPMTable,
  FullPageSpinner,
  CellRenderer,
  IconToggleButton,
} from "../Components";
import LineChart from "../Components/Charts/LineChart";
import ChartContainer from "../Components/ChartContainer";
import { downloadPNG, exportToExcel } from "../utils/download-utils";
import {
  filterTableDataByLabel,
  filterGqvQueryDataByRegionAndDateRange,
  formatGqvQueryDataForTimeSeries,
  groupFormattedGqvLineChartDataByDate,
  groupGqvQueryDataByDmaCode,
  returnTitleForGqvChart,
  formatAndRoundNumber,
  calculateMovingAverage,
  getThresholdBinsWithStdDev,
  groupGqvQueryDataByDmaCodeAndQueryLabel,
  getThresholdBinsWithStdDevNonAdjusted,
  getColorForIndexedVolume,
} from "./BrandHealthMetricsUtils";
import * as Dfns from "date-fns/fp";
import {
  Brand20,
  Brand30,
  Brand40,
  Brand50,
  Brand60,
  Brand70,
  Channel1,
  Channel2,
  Channel3,
  Channel4,
  Neutral300,
} from "../utils/colors";
import FiltersPanelGoogleBrandHealth from "./FiltersPanelGoogleBrandHealth";
import AutoSizer from "react-virtualized-auto-sizer";
import { useMap } from "../utils/hooks/useData";
import { DateRange } from "../utils/types";
import {
  BrandHealthGqvQueryInfo,
  BrandHealthTableInfo,
} from "@blisspointmedia/bpm-types/dist/BrandHealthMetrics";
import { DATE_GROUPING_OPTIONS, DateGroupingKey } from "../TVADCrossChannel/homePageConstants";
import { formatDateLabel } from "../TVADCrossChannel/homePageUtils";
import { formatNumber } from "../utils/format-utils";
import { MdOutlineMap, MdOutlineTableRows, MdShowChart } from "react-icons/md";
import GradientLegend from "./GradientLegend";

export const gqvDataHeaders = [
  {
    name: "dma_full_name",
    label: "DMA Full Name",
    flex: 1,
  },
  {
    name: "dma_code",
    label: "DMA Code",
    flex: 1,
  },
  {
    name: "Absolute Index",
    label: "Absolute Index",
    flex: 1,
    renderer: (data: BrandHealthTableInfo): string => formatNumber(data["Absolute Index"], 0),
  },
  {
    name: "Population",
    label: "Population",
    flex: 1,
    renderer: (data: BrandHealthTableInfo): string => formatNumber(data.Population, 0),
  },
];

export interface DateGroupingOption {
  value: DateGroupingKey;
  label: string;
}

interface ExtendedBrandHealthTableInfo extends BrandHealthTableInfo {
  adjustedIndexedVolume: number;
}

export const CHART_TYPE_OPTIONS = [
  { value: "graph", label: "Graph" },
  { value: "table", label: "Table" },
  { value: "map", label: "Map" },
];

interface GoogleBrandHealthProps {
  gqvQueryDataWithGeoNamesCodesAndPopulation: BrandHealthGqvQueryInfo[];
  dateRange: DateRange | undefined;
  uniqueLabels: string[];
  uniqueLabelsToSearchTerms: Map<string, string[]>;
  movingAvgDaysGqv: string;
}

const colors = [Channel1, Channel3, Channel2, Channel4];
const mapColorsActual = [Brand20, Brand30, Brand40, Brand50, Brand60, Brand70];

const mapColorsAdjusted = [
  "#FF3D3F",
  "#FF8586",
  "#FFA8A8",
  "#FFD7D7",
  "#AEF5FD",
  "#12E0FE",
  "#12E0FE",
  "#00C9E6",
  "#00B2CB",
];

const GoogleBrandHealth = ({
  gqvQueryDataWithGeoNamesCodesAndPopulation,
  dateRange,
  uniqueLabels,
  uniqueLabelsToSearchTerms,
  movingAvgDaysGqv,
}: GoogleBrandHealthProps & RouteComponentProps): JSX.Element => {
  const [chartDateGrouping, setChartDateGrouping] = useState("Day");
  const [selectedToggleOptionMap, setSelectedToggleOptionMap] = useState<string>(
    "Population Adjusted"
  );
  const [chartTypeView, setChartTypeView] = useState<string>("showGraph");
  const [currentTableLabel, setCurrentTableLabel] = useState<string>("");
  const [currentMapLabel, setCurrentMapLabel] = useState<string>("");

  useEffect(() => {
    if (uniqueLabels.length > 0) {
      setCurrentTableLabel(uniqueLabels[0]);
      setCurrentMapLabel(uniqueLabels[0]);
    }
  }, [uniqueLabels]);

  const [regionMapGoogle, setRegionMapGoogle] = useMap<string, boolean>({
    "Region-All": true,
    Northeast: false,
    South: false,
    Midwest: false,
    West: false,
  });

  const filteredGqvDataByRegionAndDateRange = useMemo(() => {
    if (gqvQueryDataWithGeoNamesCodesAndPopulation.length > 0 && regionMapGoogle && dateRange) {
      return filterGqvQueryDataByRegionAndDateRange(
        gqvQueryDataWithGeoNamesCodesAndPopulation,
        regionMapGoogle,
        dateRange
      );
    }
    return [];
  }, [gqvQueryDataWithGeoNamesCodesAndPopulation, regionMapGoogle, dateRange]);

  const tableExcelAndMapData = useMemo(
    () => groupGqvQueryDataByDmaCodeAndQueryLabel(filteredGqvDataByRegionAndDateRange),
    [filteredGqvDataByRegionAndDateRange]
  );

  const { res: timeSeriesDataGqvByRegionAndDate } = useMemo(() => {
    if (filteredGqvDataByRegionAndDateRange.length > 0) {
      const { res: formattedGqvLineChartData, uniqueLabels } = formatGqvQueryDataForTimeSeries(
        filteredGqvDataByRegionAndDateRange
      );
      const groupByDateGqvData = groupFormattedGqvLineChartDataByDate(
        formattedGqvLineChartData,
        chartDateGrouping
      );
      const groupByDateGqvDataMovingAverage = calculateMovingAverage(
        groupByDateGqvData,
        uniqueLabels,
        movingAvgDaysGqv
      );
      return { res: groupByDateGqvDataMovingAverage };
    }
    return { res: [], uniqueLabels: [] };
  }, [chartDateGrouping, filteredGqvDataByRegionAndDateRange, movingAvgDaysGqv]);

  const { filteredTableData, filteredMapData } = useMemo(() => {
    if (filteredGqvDataByRegionAndDateRange.length > 0 && (currentTableLabel || currentMapLabel)) {
      const filteredTableData = filterTableDataByLabel(
        filteredGqvDataByRegionAndDateRange,
        currentTableLabel
      );
      const filteredMapData = filterTableDataByLabel(
        filteredGqvDataByRegionAndDateRange,
        currentMapLabel
      );
      return { filteredTableData, filteredMapData };
    }
    return { filteredTableData: [], filteredMapData: [] };
  }, [filteredGqvDataByRegionAndDateRange, currentTableLabel, currentMapLabel]);

  const { formattedTableData, formattedMapData, absoluteIndexSum, populationSum } = useMemo(() => {
    if (filteredTableData.length > 0 || filteredMapData.length > 0) {
      const groupedGqvQueryDataByDmaCodeTable = groupGqvQueryDataByDmaCode(filteredTableData);
      const groupedGqvQueryDataByDmaCodeMap = groupGqvQueryDataByDmaCode(filteredMapData);
      const { absoluteIndexSum, populationSum } = groupedGqvQueryDataByDmaCodeTable.reduce(
        (acc, item) => {
          acc.absoluteIndexSum += item["Absolute Index"];
          acc.populationSum += item.Population;
          return acc;
        },
        { absoluteIndexSum: 0, populationSum: 0 }
      );
      return {
        formattedTableData: groupedGqvQueryDataByDmaCodeTable,
        formattedMapData: groupedGqvQueryDataByDmaCodeMap,
        absoluteIndexSum: absoluteIndexSum,
        populationSum: populationSum,
      };
    }
    return { formattedTableData: [], formattedMapData: [], absoluteIndexSum: 0, populationSum: 0 };
  }, [filteredMapData, filteredTableData]);

  const {
    renderColor,
    renderAdjustedColor,
    renderToolTip,
    renderAdjustedTooltip,
    thresholds,
    adjustedThresholds,
  } = useMemo(() => {
    if (formattedMapData && formattedMapData.length > 0) {
      const thresholds = getThresholdBinsWithStdDevNonAdjusted(
        formattedMapData.map(item => item["Absolute Index"])
      );

      const dmaIndexMap: { [key: string]: BrandHealthTableInfo } = {};
      formattedMapData.forEach(item => {
        dmaIndexMap[item.dma_code] = {
          dma_code: item.dma_code,
          dma_full_name: item.dma_full_name,
          "Absolute Index": item["Absolute Index"],
          Population: item.Population,
        };
      });

      const renderColor = (dma_code: string) => {
        if (dmaIndexMap[dma_code] && dmaIndexMap[dma_code]["Absolute Index"] !== undefined) {
          const { "Absolute Index": absoluteIndex } = dmaIndexMap[dma_code];
          return getColorForIndexedVolume(absoluteIndex, thresholds, mapColorsActual);
        } else {
          return Neutral300;
        }
      };

      const renderToolTip = (dma_code: string) => {
        if (!dmaIndexMap[dma_code]) {
          return (
            <div className="chartTooltipMap">
              <div className="headerLabelMap">{`N/A (DMA Code: ${dma_code})`}</div>
              <div className="itemListMap">
                <div className="itemRowMap">
                  <div className="nameMap">{"Actual Index "}</div>
                  <div className="valueMap">N/A</div>
                </div>
                <div className="itemRowMap">
                  <div className="nameMap">{"Population "}</div>
                  <div className="valueMap">N/A</div>
                </div>
              </div>
            </div>
          );
        }

        const { "Absolute Index": absoluteIndex, dma_full_name, Population } = dmaIndexMap[
          dma_code
        ];
        return (
          <div className="chartTooltipMap">
            <div className="headerLabelMap">{`${dma_full_name} (DMA Code: ${dma_code})`}</div>
            <div className="itemListMap">
              <div className="itemRowMap">
                <div className="nameMap">{"Actual Index "}</div>
                <div className="valueMap">{`${formatAndRoundNumber(absoluteIndex)}`}</div>
              </div>
              <div className="itemRowMap">
                <div className="nameMap">{"Population "}</div>
                <div className="valueMap">{`${formatNumber(Population, 0)}`}</div>
              </div>
            </div>
          </div>
        );
      };

      let adjustedThresholds = getThresholdBinsWithStdDev(
        formattedMapData.map(item => item["Absolute Index Adjusted"])
      );

      const dmaAdjustedIndexMap: { [key: string]: ExtendedBrandHealthTableInfo } = {};
      formattedMapData.forEach(item => {
        dmaAdjustedIndexMap[item.dma_code] = {
          dma_code: item.dma_code,
          dma_full_name: item.dma_full_name,
          "Absolute Index": item["Absolute Index"],
          Population: item.Population,
          adjustedIndexedVolume: item["Absolute Index Adjusted"],
        };
      });

      const renderAdjustedColor = (dma_code: string) => {
        if (
          dmaAdjustedIndexMap[dma_code] &&
          dmaAdjustedIndexMap[dma_code].adjustedIndexedVolume !== undefined
        ) {
          const { adjustedIndexedVolume } = dmaAdjustedIndexMap[dma_code];
          return getColorForIndexedVolume(
            adjustedIndexedVolume,
            adjustedThresholds,
            mapColorsAdjusted
          );
        } else {
          return Neutral300;
        }
      };

      const renderAdjustedTooltip = (dma_code: string) => {
        if (!dmaAdjustedIndexMap[dma_code]) {
          return (
            <div className="chartTooltipMap">
              <div className="headerLabelMap">{`N/A (DMA Code: ${dma_code})`}</div>
              <div className="itemListMap">
                <div className="itemRowMap">
                  <div className="nameMap">{"Actual Index "}</div>
                  <div className="valueMap">N/A</div>
                </div>
                <div className="itemRowMap">
                  <div className="nameMap">{"Population "}</div>
                  <div className="valueMap">N/A</div>
                </div>
                <div className="footerMap">
                  <div className="footerLabelMap">{"Pop. Adjusted Index "}</div>
                  <div className="footerValueMap">N/A</div>
                </div>
              </div>
            </div>
          );
        }

        const {
          "Absolute Index": absoluteIndex,
          adjustedIndexedVolume,
          dma_full_name,
          Population,
        } = dmaAdjustedIndexMap[dma_code];
        return (
          <div className="chartTooltipMap">
            <div className="headerLabelMap">{`${dma_full_name} (DMA Code: ${dma_code})`}</div>
            <div className="itemListMap">
              <div className="itemRowMap">
                <div className="nameMap">{"Actual Index "}</div>
                <div className="valueMap">{`${formatAndRoundNumber(absoluteIndex)}`}</div>
              </div>
              <div className="itemRowMap">
                <div className="nameMap">{"Population "}</div>
                <div className="valueMap">{`${formatNumber(Population, 0)}`}</div>
              </div>
              <div className="footerMap">
                <div className="footerLabelMap">{"Pop. Adjusted Index "}</div>
                <div className="footerValueMap">{`${formatNumber(adjustedIndexedVolume, 2)}`}</div>
              </div>
            </div>
          </div>
        );
      };

      return {
        renderColor,
        renderAdjustedColor,
        renderToolTip,
        renderAdjustedTooltip,
        thresholds,
        adjustedThresholds,
      };
    } else {
      return {
        renderColor: () => Neutral300,
        renderAdjustedColor: () => Neutral300,
        renderToolTip: () => "No info for this DMA",
        renderAdjustedTooltip: () => "No info for this DMA",
      };
    }
  }, [formattedMapData]);

  const mapSvgRef = useRef<SVGSVGElement | null>(null);

  const pngDownloadTable = useCallback(async () => {
    await downloadPNG(".chartContainer", `${chartDateGrouping}_Indexed_Google_Query_Volume`);
  }, [chartDateGrouping]);

  const excelDownloadTable = useCallback(() => {
    exportToExcel(
      tableExcelAndMapData,
      `IndexedGoogleQueryVolume_byDMA_${dateRange?.start}_${dateRange?.end}`
    );
  }, [dateRange?.end, dateRange?.start, tableExcelAndMapData]);

  const pngDownloadTimeSeries = useCallback(async () => {
    await downloadPNG(".chartContainer", `${chartDateGrouping}_Indexed_Google_Query_Volume`);
  }, [chartDateGrouping]);

  const excelDownloadTimeSeries = useCallback(() => {
    const transformedTimeSeriesDataGqvByRegionAndDate = timeSeriesDataGqvByRegionAndDate.map(
      ({ ReportDate, ...rest }) => ({
        Date: ReportDate,
        ...rest,
      })
    );
    exportToExcel(
      transformedTimeSeriesDataGqvByRegionAndDate,
      `IndexedGoogleQueryVolume_OverTime_${dateRange?.start}_${dateRange?.end}`
    );
  }, [dateRange?.end, dateRange?.start, timeSeriesDataGqvByRegionAndDate]);

  const superHeaderGqvTable: any[] = [{ span: 4, data: currentTableLabel }];

  const totals = {
    "Absolute Index": Number(absoluteIndexSum.toFixed(0)),
    Population: Number(populationSum.toFixed(0)),
  };

  const totalsRenderer: CellRenderer<String | Number | Element | undefined> = ({
    data,
    style,
    classes,
  }) => {
    if (data === undefined) {
      return <div style={style} className={classes.join(" ")}></div>;
    }

    return (
      <div style={style} className={classes.join(" ")}>
        <div className="cellContents">
          <div className="cellContentsHeader">TOTAL</div>
          <div className="cellContentsValue">{formatNumber(Number(data), 0)}</div>
        </div>
      </div>
    );
  };

  return dateRange !== undefined ? (
    <div className="googleBrandHealth">
      <div className="googleBrandHealthleft">
        <ChartContainer
          enableHoverDesign
          rightActions={
            <div className="googleBrandHealthRightActions">
              {chartTypeView === "showMap" && (
                <TextToggleButton
                  design="secondary-light"
                  size="sm"
                  options={["Actual", "Population Adjusted"]}
                  selectedOption={selectedToggleOptionMap}
                  onChange={setSelectedToggleOptionMap}
                ></TextToggleButton>
              )}
              <IconToggleButton
                options={[
                  { key: "showGraph", icon: <MdShowChart />, label: "graph view" },
                  { key: "showMap", icon: <MdOutlineMap />, label: "map view" },
                  { key: "showTable", icon: <MdOutlineTableRows />, label: "table view" },
                ]}
                size="sm"
                selectedOption={chartTypeView}
                onChange={selected => setChartTypeView(selected)}
              />
              <DownloadDropdown
                size="sm"
                onClickOptions={[
                  chartTypeView === "showGraph" ? excelDownloadTimeSeries : excelDownloadTable,
                  chartTypeView === "showGraph" ? pngDownloadTimeSeries : pngDownloadTable,
                ]}
              />
            </div>
          }
          title={
            <>
              {chartTypeView === "showGraph" && (
                <Dropdown
                  type={DropdownToggleType.WIDGET_TITLE}
                  value={chartDateGrouping}
                  options={DATE_GROUPING_OPTIONS}
                  onChange={option => setChartDateGrouping(option)}
                />
              )}
              <div className="titleForGqvChart">{returnTitleForGqvChart(chartTypeView)}</div>
            </>
          }
          titleAfterDashText={`${Dfns.format(
            "M/dd/yy",
            Dfns.parseISO(dateRange.start)
          )} – ${Dfns.format("M/dd/yy", Dfns.parseISO(dateRange.end))}`}
          viewRightActions={true}
        >
          {chartTypeView === "showGraph" && (
            <LineChart
              customTooltipHeader={date => `${formatDateLabel(date, chartDateGrouping, true)}`}
              data={timeSeriesDataGqvByRegionAndDate}
              lineDataKeys={uniqueLabels.map(label => ({
                name: label,
                dataKey: label,
                subHeader: uniqueLabelsToSearchTerms.get(label),
              }))}
              colorMappings={uniqueLabels.map((row, index) => ({
                name: row,
                color: colors[index],
              }))}
              tooltipFormatter={val => `${formatNumber(val, 2)}`}
              tooltipShape="line"
              sortTooltipValues={false}
              usePercentageYAxis={false}
              useLeftYAxis
              xAxisDataKey="ReportDate"
              xAxisTickFormatter={date =>
                `${
                  chartDateGrouping === "Month"
                    ? Dfns.format("M/yy", Dfns.parseISO(date))
                    : formatDateLabel(date, chartDateGrouping, false)
                }`
              }
            />
          )}
          {chartTypeView === "showMap" && (
            <div className="geoMapContainer">
              <div className="geoMapLegendAndMap">
                <GradientLegend
                  bins={
                    selectedToggleOptionMap === "Actual"
                      ? thresholds || []
                      : adjustedThresholds || []
                  }
                  actual={selectedToggleOptionMap === "Actual"}
                ></GradientLegend>
                <AutoSizer>
                  {({ width, height }) => (
                    <DmaMap
                      width={width}
                      height={height}
                      renderColor={
                        selectedToggleOptionMap === "Actual" ? renderColor : renderAdjustedColor
                      }
                      renderTooltip={
                        selectedToggleOptionMap === "Actual" ? renderToolTip : renderAdjustedTooltip
                      }
                      ref={mapSvgRef}
                      borderColor="ffffff"
                    />
                  )}
                </AutoSizer>
              </div>
              <div className="labelFilter">
                {uniqueLabels.map(label => (
                  <div key={label} className="labelItem">
                    <div className="item">
                      <label className="itemButton">
                        <input
                          type="radio"
                          name="react-tips"
                          value={label}
                          checked={currentMapLabel === label}
                          onChange={() => setCurrentMapLabel(label)}
                          className="form-check-input"
                        />
                      </label>
                      <div className="itemButtonLabel"> {label}</div>
                    </div>
                    <div className="itemSubHeader">
                      {uniqueLabelsToSearchTerms
                        .get(label)
                        ?.map(item => item.toLowerCase())
                        .join(", ")}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {chartTypeView === "showTable" && (
            <div className="gqvTableContainer">
              <div className="gqvTable">
                <BPMTable
                  data={formattedTableData}
                  headers={gqvDataHeaders}
                  filterBar={false}
                  superHeaders={superHeaderGqvTable}
                  totals={totals}
                  totalsRenderer={totalsRenderer}
                ></BPMTable>
              </div>
              <div className="labelFilter">
                {uniqueLabels.map(label => (
                  <div key={label} className="labelItem">
                    <div className="item">
                      <label className="itemButton">
                        <input
                          type="radio"
                          name="react-tips"
                          value={label}
                          checked={currentTableLabel === label}
                          onChange={() => setCurrentTableLabel(label)}
                          className="form-check-input"
                        />
                      </label>
                      <div className="itemButtonLabel"> {label}</div>
                    </div>
                    <div className="itemSubHeader">
                      {uniqueLabelsToSearchTerms
                        .get(label)
                        ?.map(item => item.toLowerCase())
                        .join(", ")}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </ChartContainer>
      </div>
      <div className="googleBrandHealthRight">
        <ChartContainer title="Filters" enableHoverDesign>
          <FiltersPanelGoogleBrandHealth
            regionMap={regionMapGoogle}
            setRegionMap={setRegionMapGoogle}
          ></FiltersPanelGoogleBrandHealth>
        </ChartContainer>
        <div className="signature">
          <div className="signatureLabelAndImageContainer">
            <div className="signatureLabel">Powered By:</div>
            <div className="signatureImage">
              <Img src="https://cdn.blisspointmedia.com/assets/img/Google.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <FullPageSpinner />
  );
};

export default GoogleBrandHealth;

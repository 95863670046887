import "./PendingSegmentationChanges.scss";
import * as R from "ramda";
import { MdClose } from "react-icons/md";
import PendingSegmentationEdits from "./PendingSegmentationEdits";
import { EditsMapAction, SegmentEditsMap } from "./SegmentationLabeling";
import { CustomSegmentsData } from "./SegmentationMapping";

interface PendingSegmentationChangesProps {
  incompleteUnlabeledEditsMap: SegmentEditsMap;
  completeUnlabeledEditsMap: SegmentEditsMap;
  labeledEditsMap: SegmentEditsMap;
  dispatchUnlabeledEditsMap: React.Dispatch<EditsMapAction>;
  dispatchLabeledEditsMap: React.Dispatch<EditsMapAction>;
  setShowPendingChanges: React.Dispatch<React.SetStateAction<boolean>>;
  customSegments: CustomSegmentsData[] | undefined;
  dataGranularity: "ad" | "ad_group" | "campaign";
}

const PendingSegmentationChanges: React.FC<PendingSegmentationChangesProps> = ({
  incompleteUnlabeledEditsMap,
  completeUnlabeledEditsMap,
  labeledEditsMap,
  dispatchUnlabeledEditsMap,
  dispatchLabeledEditsMap,
  setShowPendingChanges,
  customSegments = [],
  dataGranularity,
}) => {
  const segmentNames = customSegments?.map(segment => segment.segmentName) ?? [];

  return (
    <div className="pendingSegmentationChangesContainer">
      <div className="pendingSegmentationChangesHeader">
        Pending Changes
        <div className="closeButton" onClick={() => setShowPendingChanges(false)}>
          <MdClose />
        </div>
      </div>
      <div className="pendingSegmentationChangesBody">
        {!R.isEmpty(incompleteUnlabeledEditsMap) && (
          <div className="unlabeledSection">
            <span className="editsTitle">
              Incomplete segments for {dataGranularity.replace("_", " ")}s
              <div className="warningText">
                *These will not be saved until all segments are completed
              </div>
            </span>
            {R.keys(incompleteUnlabeledEditsMap).map(key => {
              return (
                <PendingSegmentationEdits
                  key={key}
                  campaign_key={String(key)}
                  editsMap={incompleteUnlabeledEditsMap}
                  dispatchEditsMap={dispatchUnlabeledEditsMap}
                  segmentNames={segmentNames}
                />
              );
            })}
          </div>
        )}
        {!R.isEmpty(completeUnlabeledEditsMap) && (
          <div className="unlabeledSection">
            <span className="editsTitle">
              Newly assigned segments for {dataGranularity.replace("_", " ")}s
            </span>
            {R.keys(completeUnlabeledEditsMap).map(key => {
              return (
                <PendingSegmentationEdits
                  key={key}
                  campaign_key={String(key)}
                  editsMap={completeUnlabeledEditsMap}
                  dispatchEditsMap={dispatchUnlabeledEditsMap}
                  segmentNames={segmentNames}
                />
              );
            })}
          </div>
        )}
        {!R.isEmpty(labeledEditsMap) && (
          <div className="labeledSection">
            <span className="editsTitle">
              Segment updates for {dataGranularity.replace("_", " ")}s
            </span>
            {R.keys(labeledEditsMap).map(key => {
              return (
                <PendingSegmentationEdits
                  key={key}
                  campaign_key={String(key)}
                  editsMap={labeledEditsMap}
                  dispatchEditsMap={dispatchLabeledEditsMap}
                  segmentNames={segmentNames}
                  hideUnchanged
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default PendingSegmentationChanges;

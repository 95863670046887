import { useState, createContext, ReactNode, useEffect } from "react";
import {
  PlatformPublisher,
  Position,
  Objective,
  Budget,
  BidStrategy,
  SpecialAdCategory,
  ConversionEvent,
  ConversionGoal,
  AdCreativeFormat,
  CallToAction,
  AdsManagerStatus,
  Gender,
  BidConstraint,
} from "@blisspointmedia/bpm-types/dist/MetaBuying";
import { PageTab } from "./MetaBuying";
import * as R from "ramda";
import {
  ALL,
  MARKETPLACE,
  STORY,
  SEARCH,
  FEED,
  REELS,
  PROFILE_FEED,
  PROFILE_REELS,
  RIGHT_HAND_COLUMN,
  FACEBOOK_REELS,
  FACEBOOK_REELS_OVERLAY,
  FACEBOOK_BUSINESS_EXPLORE,
  INSTREAM_VIDEO,
  VIDEO_FEEDS,
  STREAM,
  IG_SEARCH,
  CLASSIC,
  REWARDED_VIDEO,
  MESSENGER_HOME,
  SPONSORED_MESSAGES,
  EXPLORE,
  EXPLORE_HOME,
  OUTCOME_SALES,
  STATUS_NONE,
  PUBLISHER_PLATFORMS,
} from "./MetaBuyingConstants";

export const metaCampaignFormDefault: {
  meta_id: string | null;
  campaign_name: string;
  objective: Objective;
  special_ad_categories: SpecialAdCategory[];
  budget_type: Budget | null;
  budget: number | null;
  bid_strategy: BidStrategy | null;

  status: AdsManagerStatus;
} = {
  meta_id: null,
  campaign_name: "",
  objective: OUTCOME_SALES,
  special_ad_categories: [],
  budget_type: null,
  budget: null,
  bid_strategy: null,
  status: STATUS_NONE,
};

export const metaAdSetFormDefault: {
  meta_id: string | null;
  adset_name: string;
  campaign_id: string | null;
  pixel: string | null;
  conversion_event: ConversionEvent | null;
  optimization_goal: ConversionGoal | null;
  bid_strategy: BidStrategy | null;
  bid_amount: number | null;
  bid_constraints: BidConstraint;
  genders: Gender;
  age_min: number;
  age_max: number;
  budget_type: Budget | null;
  budget: number | null;
  click_attr_window: number;
  view_attr_window: number;
  included_locations: string[];
  excluded_locations: string[];
  publisher_platforms: PlatformPublisher[];
  facebook_positions: Position[];
  instagram_positions: Position[];
  messenger_positions: Position[];
  audience_network_positions: Position[];
  included_audiences_selected: string[];
  included_audiences_entered: string[];
  excluded_audiences_selected: string[];
  excluded_audiences_entered: string[];
  start_time: string | null;
  end_time: string | null;
  status: AdsManagerStatus;
} = {
  meta_id: null,
  adset_name: "",
  campaign_id: null,
  pixel: null,
  conversion_event: null,
  optimization_goal: null,
  bid_strategy: null,
  bid_amount: null,
  bid_constraints: {},
  genders: ALL,
  age_min: 18,
  age_max: 65,
  budget_type: null,
  budget: null,
  click_attr_window: 1,
  view_attr_window: 0,
  included_locations: [],
  excluded_locations: [],
  publisher_platforms: PUBLISHER_PLATFORMS,
  facebook_positions: [
    FEED,
    PROFILE_FEED,
    MARKETPLACE,
    VIDEO_FEEDS,
    RIGHT_HAND_COLUMN,
    FACEBOOK_BUSINESS_EXPLORE,
    STORY,
    FACEBOOK_REELS,
    INSTREAM_VIDEO,
    FACEBOOK_REELS_OVERLAY,
    SEARCH,
  ],
  instagram_positions: [
    STREAM,
    PROFILE_FEED,
    EXPLORE,
    EXPLORE_HOME,
    STORY,
    REELS,
    PROFILE_REELS,
    IG_SEARCH,
  ],
  messenger_positions: [MESSENGER_HOME, STORY, SPONSORED_MESSAGES],
  audience_network_positions: [CLASSIC, REWARDED_VIDEO],
  included_audiences_selected: [],
  included_audiences_entered: [],
  excluded_audiences_selected: [],
  excluded_audiences_entered: [],
  start_time: null,
  end_time: null,

  status: STATUS_NONE,
};

export const metaAdFormDefault: {
  meta_id: string | null;
  ad_id: string | null;
  ad_name: string;
  adset_name?: string | null;
  adset_id: string | null;
  adset_meta_id?: string | null;
  campaign_name?: string | null;
  campaign_id?: string | null;
  campaign_meta_id?: string | null;
  facebook_page_id: string | null;
  // instagram_account_id: string | null;
  creative_format: AdCreativeFormat | null;
  creative_asset: null;
  message: string;
  headline: string;
  description: string;
  call_to_action_type: CallToAction | null;
  url: string;
  url_params: string;
  status: AdsManagerStatus;
} = {
  meta_id: null,
  ad_id: null,
  ad_name: "",
  adset_id: null,
  facebook_page_id: null,
  // instagram_account_id: null,
  creative_format: null,
  creative_asset: null,
  message: "",
  headline: "",
  description: "",
  call_to_action_type: null,
  url: "",
  url_params: "",
  status: STATUS_NONE,
};

export const metaBuyingFormDefault: {
  campaign: typeof metaCampaignFormDefault;
  ad_set: typeof metaAdSetFormDefault;
  ad: typeof metaAdFormDefault;
} = {
  campaign: { ...metaCampaignFormDefault },
  ad_set: {
    ...metaAdSetFormDefault,
  },
  ad: {
    ...metaAdFormDefault,
  },
};

export const FormContext = createContext<any>([[], () => {}]);

export interface MetaFormContextProps {
  children: ReactNode;
  tab: string;
  refreshData: () => void;
}

export const MetaBuyingProvider = ({
  children,
  tab,
  refreshData,
}: MetaFormContextProps): JSX.Element => {
  const [metaBuyingForm, setMetaBuyingForm] = useState(metaBuyingFormDefault);
  const [previousTab, setPreviousTab] = useState<string | null>(null);

  useEffect(() => {
    if (!R.equals(previousTab, tab)) {
      setPreviousTab(tab);
      if (previousTab === PageTab.CREATE) {
        setMetaBuyingForm(R.clone(metaBuyingFormDefault));
      }
      if (tab === PageTab.CREATE || tab === PageTab.DRAFTS) {
        refreshData();
      }
    }
  }, [tab, previousTab, refreshData]);

  return (
    <FormContext.Provider
      value={{
        metaBuyingForm,
        setMetaBuyingForm,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export default MetaBuyingProvider;

import * as R from "ramda";
import { CrossChannelContext } from "../CrossChannel";
import { DEFAULT_SPARKCHARTS_MAP } from "../../SingleChannel/MetricsTableConfig/EditPagePresetModal";
import { METRIC_OPTIONS, OVERVIEW_TAB_METRIC_OPTIONS } from "../crossChannelConstants";
import {
  Metric,
  MetricTotalsByDate,
  KpiInfo,
  KpiData,
} from "@blisspointmedia/bpm-types/dist/CrossChannel";
import CompareMetrics from "../Components/CompareMetrics";
import FunnelDynamics from "../Components/FunnelDynamics";
import KpiVolume from "../Components/KpiVolume";
import MarketingInputs from "../Components/MarketingInputs";
import React, { useState, useContext, useMemo } from "react";
import SparkChart from "../../Components/SparkChart/SparkChart";
import { isSparcCompany } from "../crossChannelUtils";

interface OverviewProps {
  kpis: KpiInfo;
  data: MetricTotalsByDate[];
  otherData: MetricTotalsByDate[];
  marketingInputs: any[];
  channelClassification: Record<string, string>;
  defaultCompareMetrics: Metric[] | undefined;
  defaultCompareMetricsIncludeOtherDates;
  defaultFunnelDynamicsPreset?: string;
  defaultSparkCharts?: Metric[];
  defaultTotal?: (string | boolean)[];
}

/**
 * Adds Clicks and Impressions to KPI data because they are allowed as Funnel options
 * @param {metricsData[]} metricsData
 * @param {kpiData[]} kpiData
 * @returns {kpiData[]} kpiData with Clicks and Impressions
 */
const addDeliveryMetricsToKpiData = (metricsData: MetricTotalsByDate[], kpiData: KpiData[]) => {
  let clicks: KpiData[] = [];
  let impressions: KpiData[] = [];

  metricsData.forEach(row => {
    clicks.push({
      date: row.date,
      kpi: "Clicks",
      count: R.pathOr(0, ["metrics", Metric.CLICKS], row),
      revenue: 0,
      isOrganic: false,
    });

    impressions.push({
      date: row.date,
      kpi: "Impressions",
      count: R.pathOr(0, ["metrics", Metric.IMPRESSIONS], row),
      revenue: 0,
      isOrganic: false,
    });
  });

  return [...kpiData, ...clicks, ...impressions];
};

export const DEFAULT_METRICS = DEFAULT_SPARKCHARTS_MAP["cross-channel"];

const Overview: React.FC<OverviewProps> = ({
  kpis,
  data,
  otherData,
  marketingInputs,
  channelClassification,
  defaultCompareMetrics,
  defaultCompareMetricsIncludeOtherDates = false,
  defaultFunnelDynamicsPreset,
  defaultSparkCharts = DEFAULT_METRICS,
  defaultTotal = [false, false, true],
}) => {
  const [showMarketingInputs, setShowMarketingInputs] = useState(false);
  const { kpiData, funnelPresets } = kpis;
  const { kpi, company, dates } = useContext(CrossChannelContext);

  // Filter out comparison date range from KPI Data for use in KPI Volume and Funnel charts.
  // Comparison dates exist in KPI Data for SPARC brands because we add it to their Overview.
  const filteredKpiData = useMemo(
    () => kpiData.filter(row => row.date >= dates.start && row.date <= dates.end),
    [dates.end, dates.start, kpiData]
  );

  // For SPARC companies, display all metrics in the Spark Charts and Compare Metrics
  const sparcCompany = useMemo(() => isSparcCompany(company), [company]);

  const funnelData = useMemo(() => addDeliveryMetricsToKpiData(data, filteredKpiData), [
    data,
    filteredKpiData,
  ]);

  return (
    <div className="ccOverview">
      <div className="ccTopSection">
        <div className="ccTopSectionLeftSide">
          <div className="ccDailyKpiVolume">
            <KpiVolume
              data={filteredKpiData}
              showMarketingInputs={showMarketingInputs}
              setShowMarketingInputs={setShowMarketingInputs}
              footer={["Filters do not apply", "Source of truth data"]}
            />
          </div>
          {showMarketingInputs && (
            <div className="ccMarketingInputs">
              <MarketingInputs
                data={marketingInputs}
                channelClassification={channelClassification}
              />
            </div>
          )}
          {!showMarketingInputs && (
            <div className="ccSparkCharts">
              {defaultSparkCharts.slice(0, DEFAULT_METRICS.length).map((metric, index) => (
                <SparkChart
                  pngLabel={`KPI: ${kpi}`}
                  pngFileIdentifiers={["CrossChannel"]}
                  data={data}
                  otherData={otherData}
                  defaultMetric={metric}
                  key={metric}
                  defaultTotal={defaultTotal[index] ? defaultTotal[index] === "Total" : false}
                  metricOptions={sparcCompany ? METRIC_OPTIONS : OVERVIEW_TAB_METRIC_OPTIONS}
                />
              ))}
            </div>
          )}
        </div>
        <div className="ccTopSectionRightSide">
          <div className="ccKpiFunnel">
            <FunnelDynamics
              data={funnelData}
              funnelPresets={funnelPresets}
              defaultPreset={defaultFunnelDynamicsPreset}
            />
          </div>
        </div>
      </div>
      <div className="ccRow">
        <div className="ccAgencyNews">
          <a
            href="https://tinuiti.com/research-insights/research/forrester-wave-2024/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="ccAgencyNewsImage"
              src={"https://cdn.blisspointmedia.com/agencies/tinuiti/Tinuiti_Agency_News.png"}
              alt="Agency News"
              width={500}
              height={500}
            />
          </a>
        </div>
        <div className="ccMetricComparisonChart">
          <CompareMetrics
            pngDownloadLabel={`KPI: ${kpi}`}
            pngFileIdentifiers={["CrossChannel"]}
            data={data}
            otherData={otherData}
            defaultMetrics={defaultCompareMetrics}
            defaultIncludeOtherDates={defaultCompareMetricsIncludeOtherDates}
            includeFooter={true}
            metricOptions={sparcCompany ? METRIC_OPTIONS : OVERVIEW_TAB_METRIC_OPTIONS}
          />
        </div>
      </div>
    </div>
  );
};

export default Overview;

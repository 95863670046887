import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./BudgetIntakeTool.scss";
import { Page } from "../Components";
import { useSelector } from "react-redux";
import { currentCompanySelector } from "../redux/company";
import { useTabbedNav } from "../utils/hooks/useNav";
import { RouteComponentProps, Router } from "@reach/router";
import BudgetIntakeToolGoals from "./BudgetIntakeToolGoals";
import BudgetIntakeToolSettings from "./BudgetIntakeToolSettings";
import { awaitJSON, ToolsLambdaFetch } from "../utils/fetch-utils";
import { useSetError } from "../redux/modals";
import { emailSelector } from "../redux/user";
import * as R from "ramda";
import { processBulkImport } from "./excelUtils";
import { useExperimentFlag } from "../utils/experiments/experiment-utils";
import { BudgetIntakeRowInternal } from "@blisspointmedia/bpm-types/dist/BudgetIntakeTool";

interface GetBudgetIntakeRowsResponse {
  mappedBudgetEntries: any[];
}

interface GetBudgetCustomSegmentsList {
  segmentNames: string[];
}

const enum TabKey {
  NEW_GOALS = "New Goals",
  SETTINGS = "settings",
}

const NAVS = [
  { label: "New Goals", key: TabKey.NEW_GOALS },
  { label: "Settings", key: TabKey.SETTINGS },
];

const BudgetIntakeTool: React.FC = ({ navigate }: RouteComponentProps) => {
  const company = useSelector(currentCompanySelector);
  const email: string = useSelector(emailSelector);

  const [tableData, setTableData] = useState<BudgetIntakeRowInternal[]>();
  const [originalTableData, setOriginalTableData] = useState<BudgetIntakeRowInternal[]>();
  const [originalTableDataMap, setOriginalTableDataMap] = useState<Record<string, any>>();
  const [customSegmentsList, setCustomSegmentsList] = useState<string[]>();
  const [budgetIntakeSelectorOptionsPull, setBudgetIntakeSelectorOptions] = useState({});
  const [budgetIntakeHeadersCustom, setBudgetIntakeHeadersCustom] = useState<any[]>([]);
  const [showTableData, setShowTableData] = useState(false);
  const [deletedRows, setDeletedRows] = useState<BudgetIntakeRowInternal[]>([]);

  const [saving, setSaving] = useState(false);
  const [updatedRows, setUpdatedRows] = useState<BudgetIntakeRowInternal[]>([]);
  const [deletedBudget, setDeletedBudget] = useState<BudgetIntakeRowInternal[]>([]);
  const [newRows, setNewRows] = useState<BudgetIntakeRowInternal[]>([]);
  const [showPendingChanges, setShowPendingChanges] = useState(false);

  const [campaignTypes, setCampaignTypes] = useState<any[]>();
  const setError = useSetError();
  const shouldEnableBudgetIntakeTool = useExperimentFlag("enableBudgetIntakeTool");

  const enabledNavs = useMemo(() => {
    if (shouldEnableBudgetIntakeTool) {
      return NAVS;
    } else {
      return [];
    }
  }, [shouldEnableBudgetIntakeTool]);

  const { tab, goToTab } = useTabbedNav({
    navigate,
    baseURL: "budget-intake-tool",
    defaultKey: TabKey.NEW_GOALS,
  });

  const getFreshBudgetIntakeData = useCallback(async () => {
    try {
      setShowTableData(false);

      let resCustom = await ToolsLambdaFetch("/getBudgetCustomMapping", {
        params: {
          company,
        },
      });
      let jsonResponseCustom = await awaitJSON<GetBudgetCustomSegmentsList>(resCustom);
      setCustomSegmentsList(jsonResponseCustom.segmentNames);

      let res = await ToolsLambdaFetch("/getBudgetIntakeData", {
        params: {
          company,
        },
      });
      let jsonResponse = await awaitJSON<GetBudgetIntakeRowsResponse>(res);
      setTableData(jsonResponse.mappedBudgetEntries);
      setOriginalTableData(jsonResponse.mappedBudgetEntries);

      let originalTableDataMap: Record<string, any> = {};
      for (let row of jsonResponse.mappedBudgetEntries) {
        originalTableDataMap[row.id] = row;
      }
      setOriginalTableDataMap(originalTableDataMap);
      setShowTableData(true);
      setDeletedRows([]);

      let resOptions = await ToolsLambdaFetch("/getBudgetIntakeLabelOptions", {
        params: {
          company,
        },
      });
      let resultsOptions = await awaitJSON(resOptions);
      let goalNameOptions = [
        { label: "Budget", value: "Budget" },
        { label: "Revenue", value: "Revenue" },
        { label: "CPX", value: "CPX" },
        { label: "ROAS", value: "ROAS" },
        { label: "CPC", value: "CPC" },
        { label: "CPM", value: "CPM" },
        { label: "CTR", value: "CTR" },
        { label: "KPI Volume", value: "KPI Volume" },
      ];
      let updatedOptions = {
        ...resultsOptions,
        goalNameOptions,
      };
      setBudgetIntakeSelectorOptions(updatedOptions);
    } catch (e) {
      const reportError = e as Error;
      setError({
        message: `Failed to get Budget Intake data. ERROR: ${reportError.message}`,
        reportError,
      });
    }
  }, [company, setError]);

  useEffect(() => {
    const dateHeaders = [
      {
        label: "Start Date",
        field: "start_date",
        type: "day",
        flex: 1,
        modalRow: 0,
        modalFlex: 1,
      },
      {
        label: "End Date",
        field: "end_date",
        type: "day",
        flex: 1,
        modalRow: 0,
        modalFlex: 1,
      },
    ];
    const goalHeaders = [
      {
        label: "Goal Name",
        field: "goal_name",
        type: "select",
        flex: 1,
        modalRow: customSegmentsList ? customSegmentsList.length + 1 : 1,
        modalFlex: 1,
        options: "goalNameOptions",
      },
      {
        label: "Goal Value",
        field: "goal_value",
        type: "currency",
        flex: 1,
        modalRow: customSegmentsList ? customSegmentsList.length + 2 : 1,
        modalFlex: 1,
      },
    ];
    const hiddenHeaders = [
      {
        label: "Campaign",
        field: "campaign",
        type: "select",
        flex: 1,
        modalRow: customSegmentsList ? customSegmentsList.length + 3 : 1,
        modalFlex: 1,
        options: "Campaign",
      },
      {
        label: "KPI(s)",
        field: "kpi",
        type: "select",
        flex: 1,
        modalRow: customSegmentsList ? customSegmentsList.length + 4 : 1,
        modalFlex: 1,
        options: "Kpi",
      },
      {
        label: "[Metric] KPI Source",
        field: "source",
        type: "select",
        flex: 1,
        modalRow: customSegmentsList ? customSegmentsList.length + 5 : 1,
        modalFlex: 1,
        options: "Source",
      },
    ];

    const segments = customSegmentsList || [];
    const segmentHeaders = segments.map((segment, index) => ({
      label: segment,
      field: segment,
      type: "select",
      flex: 1,
      modalRow: index + 1,
      modalFlex: 1,
      options: segment,
    }));
    let combinedHeaders = [...dateHeaders, ...segmentHeaders, ...goalHeaders];
    if (shouldEnableBudgetIntakeTool) {
      combinedHeaders = [...combinedHeaders, ...hiddenHeaders];
    }
    setBudgetIntakeHeadersCustom(combinedHeaders);
  }, [
    setError,
    getFreshBudgetIntakeData,
    tableData,
    customSegmentsList,
    budgetIntakeSelectorOptionsPull,
    shouldEnableBudgetIntakeTool,
  ]);

  useEffect(() => {
    if (!tableData) {
      (async () => {
        await getFreshBudgetIntakeData();
      })();
    }
  }, [setError, getFreshBudgetIntakeData, tableData]);

  const saveChanges = useCallback(async () => {
    const getInternalData = (displayData: any[]): any[] => {
      return displayData.map(row => {
        let segments = {};
        customSegmentsList?.forEach(segment => {
          segments[segment] = row[segment];
        });

        return {
          id: row.id,
          company: company,
          start_date: row.start_date,
          end_date: row.end_date,
          ...segments,
          goal_name: row.goal_name,
          goal_value: row.goal_value,
          lastuser: email,
          lastmodified: row.lastmodified,
          source: row.source,
          kpi: row.kpi,
          campaign: row.campaign,
        };
      });
    };

    let convertedNewRows = getInternalData(newRows || []);
    let convertedUpdatedRows = getInternalData(updatedRows || []);
    let convertedDeletedRows = getInternalData(deletedBudget || []);

    const internalBody: any = {
      insert: convertedNewRows,
      update: convertedUpdatedRows,
      delete: convertedDeletedRows,
      company: company,
      customSegments: customSegmentsList,
    };

    try {
      setSaving(true);
      await ToolsLambdaFetch<any>("/setBudgetIntakeData", {
        method: "POST",
        body: JSON.stringify(internalBody),
      });

      await getFreshBudgetIntakeData();
      setSaving(false);
    } catch (e) {
      const reportError = e as Error;
      setError({
        message: `Failed to set Budget Intake data. \n ERROR: ${reportError.message}`,
        reportError,
      });
    }
  }, [
    newRows,
    updatedRows,
    deletedBudget,
    company,
    customSegmentsList,
    email,
    getFreshBudgetIntakeData,
    setError,
  ]);

  useEffect(() => {
    if (tableData && originalTableDataMap && customSegmentsList) {
      let isSegmentUpdated = false;
      let isUpdated = (row: any) => {
        if (originalTableDataMap[row.id]) {
          let originalRow = originalTableDataMap[row.id];
          isSegmentUpdated = customSegmentsList.some(
            segment => row[segment] !== originalRow[segment]
          );

          return (
            row.id !== null &&
            (row.company !== originalRow.company ||
              row.start_date !== originalRow.start_date ||
              row.end_date !== originalRow.end_date ||
              isSegmentUpdated ||
              row.goal_name !== originalRow.goal_name ||
              row.goal_value !== originalRow.goal_value ||
              row.source !== originalRow.source ||
              row.campaign !== originalRow.campaign ||
              row.kpi !== originalRow.kpi)
          );
        }
        return false;
      };
      let updatedRows = R.filter(isUpdated, tableData);
      setUpdatedRows(updatedRows);

      let newNewRows = R.filter(row => {
        if (
          row.id ||
          row.start_date === null ||
          row.end_date === null ||
          customSegmentsList.every(segment => row[segment] === null) ||
          row.goal_name === null ||
          row.goal_value === null
        ) {
          return false;
        }
        return true;
      }, tableData);

      setNewRows(newNewRows);

      let newDeletedRows = R.filter(row => {
        return Boolean(row.id);
      }, deletedRows);
      setDeletedBudget(newDeletedRows);
    }
  }, [customSegmentsList, deletedRows, originalTableDataMap, tableData]);

  const hasPendingChanges: boolean = useMemo(() => {
    const hasPendingChangesResult =
      Boolean(newRows.length) || Boolean(updatedRows.length) || Boolean(deletedBudget.length);

    if (!hasPendingChangesResult) {
      setShowPendingChanges(false);
    }

    return hasPendingChangesResult;
  }, [newRows, deletedBudget, updatedRows]);

  const clearAllChanges = useCallback(() => {
    setTableData(originalTableData);
    setDeletedRows([]);
    setNewRows([]);
    setUpdatedRows([]);
  }, [originalTableData]);

  const bulkImport = useCallback(
    async file => {
      try {
        let res = await ToolsLambdaFetch("/getBudgetIntakeData", {
          params: {
            company,
          },
        });
        let jsonResponse = await awaitJSON<GetBudgetIntakeRowsResponse>(res);
        const importedChanges = processBulkImport(
          file,
          jsonResponse.mappedBudgetEntries,
          company,
          customSegmentsList || []
        );

        importedChanges.length > 0
          ? setTableData(importedChanges)
          : setError({
              message: "No updated rows or new changes",
            });
      } catch (e) {
        setError({
          message: `Failed to import changes ${e.message}`,
          reportError: e,
        });
      }
    },
    [company, customSegmentsList, setError]
  );

  const getCampaignTypesData = useCallback(async () => {
    try {
      let res = await ToolsLambdaFetch("/getCampaignTypes", {
        params: { company },
      });
      let resultsOptions = await awaitJSON(res);

      setCampaignTypes(resultsOptions);
    } catch (e) {
      const reportError = e as Error;
      setError({
        message: `Failed to get Campaigns Data. ERROR: ${reportError.message}`,
        reportError,
      });
    }
  }, [company, setError]);

  useEffect(() => {
    if (!campaignTypes) {
      (async () => {
        await getCampaignTypesData();
      })();
    }
  }, [setError, getCampaignTypesData, campaignTypes]);

  return (
    <Page
      app2Redesign
      title={
        <div className="budgetIntakeTitle">
          <div>Budget Intake Tool</div>
        </div>
      }
      pageType="Budget Intake Tool"
      navs={enabledNavs}
      selectedNav={tab}
      onNav={goToTab}
    >
      <Router className="fullPageRouter BudgetIntakeToolGoalsPage">
        <BudgetIntakeToolGoals
          company={company}
          tableData={tableData || []}
          setTableData={setTableData}
          originalTableData={originalTableData || []}
          originalTableDataMap={originalTableDataMap || {}}
          customSegmentsList={customSegmentsList || []}
          budgetIntakeHeadersCustom={budgetIntakeHeadersCustom || []}
          budgetIntakeSelectorOptionsPull={budgetIntakeSelectorOptionsPull}
          showTableData={showTableData}
          saving={saving}
          saveChanges={saveChanges}
          clearAllChanges={clearAllChanges}
          bulkImport={bulkImport}
          hasPendingChanges={hasPendingChanges}
          showPendingChanges={showPendingChanges}
          setShowPendingChanges={setShowPendingChanges}
          deletedBudget={deletedBudget}
          updatedRows={updatedRows}
          newRows={newRows}
          deletedRows={deletedRows}
          setDeletedRows={setDeletedRows}
          shouldEnableBudgetIntakeTool={shouldEnableBudgetIntakeTool}
          path={"/"}
        ></BudgetIntakeToolGoals>
        {shouldEnableBudgetIntakeTool && campaignTypes && campaignTypes.length > 0 && (
          <BudgetIntakeToolSettings
            company={company}
            campaignTypes={campaignTypes}
            path={TabKey.SETTINGS}
          ></BudgetIntakeToolSettings>
        )}
      </Router>
    </Page>
  );
};

export default BudgetIntakeTool;

import React, { useContext, useState, useEffect, useCallback, useMemo } from "react";
import { FormContext, metaBuyingFormDefault } from "../MetaBuyingContext";
import { MdArrowForwardIos } from "react-icons/md";
import { Form } from "react-bootstrap";
import * as R from "ramda";
import { AD_SET_PLACEMENTS, PLATFORM_OPTIONS, PUBLISHER_PLATFORMS } from "../MetaBuyingConstants";
import { Position, PlatformPublisher, Objective } from "@blisspointmedia/bpm-types/dist/MetaBuying";

export const MetaBuyingPlacements = (): JSX.Element => {
  const { metaBuyingForm, setMetaBuyingForm } = useContext(FormContext);

  const metaAdSetForm = metaBuyingForm.ad_set;
  const metaCampaignForm = metaBuyingForm.campaign;

  // @ts-ignore
  const placements: {
    // @ts-ignore
    category: string;
    positions: {
      label: string;
      position: Position;
      publisher: PlatformPublisher;
      restrictions?: {
        objective?: Objective[];
      };
      valid: boolean;
    }[];
    valid: boolean;
  }[] = useMemo(
    () =>
      AD_SET_PLACEMENTS.map(({ category, positions }): {
        // @ts-ignore
        category: string;
        positions: {
          label: string;
          position: Position;
          publisher: PlatformPublisher;
          restrictions?: {
            objective?: Objective[];
          };
          valid: boolean;
        };
      } => {
        let validCount = 0;
        let validatedPositions = positions.reduce((newPositions, position) => {
          // @ts-ignore
          const { restrictions } = position;

          let valid = true;
          if (
            restrictions &&
            restrictions.objective &&
            R.includes(metaCampaignForm.objective, restrictions.objective)
          ) {
            valid = false;
          }
          let newPosition: {
            label: string;
            position: Position;
            publisher: PlatformPublisher;
            restrictions?: {
              objective?: Objective[];
            };
            valid: boolean;
          } = {
            ...position,
            valid,
          };

          if (valid) {
            validCount++;
          }

          // @ts-ignore
          newPositions.push(newPosition);
          // @ts-ignore
          return newPositions;
        }, []);

        return {
          // @ts-ignore
          category,
          // @ts-ignore
          positions: validatedPositions,
          valid: validCount > 0,
        };
      }),
    [metaCampaignForm]
  );

  const setMetaAdSetForm = useCallback(
    value => {
      let newMetaAdSetForm = R.clone(value);
      PUBLISHER_PLATFORMS.forEach(publisher => {
        if (
          newMetaAdSetForm[`${publisher}_positions`].length &&
          !R.includes(publisher, newMetaAdSetForm.publisher_platforms)
        ) {
          newMetaAdSetForm.publisher_platforms.push(publisher);
        } else if (
          !newMetaAdSetForm[`${publisher}_positions`].length &&
          R.includes(publisher, newMetaAdSetForm.publisher_platforms)
        ) {
          newMetaAdSetForm.publisher_platforms = R.remove(
            R.indexOf(publisher, newMetaAdSetForm.publisher_platforms),
            1,
            newMetaAdSetForm.publisher_platforms
          );
        }
      });
      setMetaBuyingForm((current: any): typeof metaBuyingFormDefault => {
        return { ...current, ad_set: newMetaAdSetForm };
      });
    },
    [setMetaBuyingForm]
  );

  const updatePlacementsCheckboxes = useCallback(
    () =>
      placements.map(({ positions }) => ({
        categoryChecked: R.all(
          isChecked => isChecked,
          positions.map(({ position, publisher }) =>
            R.includes(position, metaAdSetForm[`${publisher}_positions`])
          )
        ),
        positionsChecked: positions.map(({ position, publisher }) =>
          R.includes(position, metaAdSetForm[`${publisher}_positions`])
        ),
      })),
    [metaAdSetForm, placements]
  );

  const updatePlatformsCheckboxes = useCallback(() => {
    let platformCheckboxes: Record<string, any> = {};
    placements.forEach(({ positions }) => {
      positions.forEach(({ position, publisher, valid }) => {
        let checked = R.includes(position, metaAdSetForm[`${publisher}_positions`]);
        if (R.isNil(platformCheckboxes[publisher])) {
          platformCheckboxes[publisher] = checked;
        } else if (checked) {
          platformCheckboxes[publisher] = true;
        }
      });
    });
    return platformCheckboxes;
  }, [metaAdSetForm, placements]);

  const [showPlacementCategories, setShowPlacementCategories] = useState(() =>
    placements.map(() => false)
  );

  const [checkedBoxesPlacements, setCheckedBoxesPlacements] = useState(() =>
    updatePlacementsCheckboxes()
  );
  const [checkedBoxesPlatforms, setCheckedBoxesPlatforms] = useState(() =>
    updatePlatformsCheckboxes()
  );

  useEffect(() => {
    const newPlatformsCheckboxes = updatePlatformsCheckboxes();
    const newPlacementsCheckboxes = updatePlacementsCheckboxes();
    if (!R.equals(newPlatformsCheckboxes, checkedBoxesPlatforms)) {
      setCheckedBoxesPlatforms(newPlatformsCheckboxes);
    }
    if (!R.equals(newPlacementsCheckboxes, checkedBoxesPlacements)) {
      setCheckedBoxesPlacements(newPlacementsCheckboxes);
    }
  }, [
    checkedBoxesPlacements,
    updatePlacementsCheckboxes,
    setCheckedBoxesPlacements,
    checkedBoxesPlatforms,
    updatePlatformsCheckboxes,
    setCheckedBoxesPlatforms,
  ]);

  const [positionsInitialized, setPositionsInitialized] = useState(false);

  useEffect(() => {
    let newMetaAdSetForm = R.clone(metaAdSetForm);
    placements.forEach(({ positions }) => {
      positions.forEach(({ position, publisher, valid }) => {
        const positionIndex = R.indexOf(position, newMetaAdSetForm[`${publisher}_positions`]);
        if (!valid && positionIndex !== -1) {
          newMetaAdSetForm[`${publisher}_positions`] = R.remove(
            positionIndex,
            1,
            newMetaAdSetForm[`${publisher}_positions`]
          );
        }
      });
    });
    if (!R.equals(newMetaAdSetForm, metaAdSetForm)) {
      setMetaAdSetForm(newMetaAdSetForm);
    }
  }, [placements, metaAdSetForm, setMetaAdSetForm]);

  useEffect(() => {
    if (!positionsInitialized) {
      let newMetaAdSetForm = R.clone(metaAdSetForm);
      placements.forEach(({ positions }) => {
        positions.forEach(({ position, publisher, valid }) => {
          if (!valid) {
            const positionIndex = R.indexOf(position, newMetaAdSetForm[`${publisher}_positions`]);
            newMetaAdSetForm[`${publisher}_positions`] = R.remove(
              positionIndex,
              1,
              newMetaAdSetForm[`${publisher}_positions`]
            );
          }
        });
      });
      setPositionsInitialized(true);
      setMetaAdSetForm(newMetaAdSetForm);
    }
  }, [placements, metaAdSetForm, positionsInitialized, setMetaAdSetForm]);

  const Platforms = (): JSX.Element => (
    <>
      {PLATFORM_OPTIONS.map(({ label, value }) => (
        <Form.Check
          key={value}
          label={label}
          checked={checkedBoxesPlatforms[value]}
          id={value}
          onChange={() => {
            const newMetaAdSetForm = R.clone(metaAdSetForm);
            placements.forEach(({ positions }) => {
              positions.forEach(({ position, publisher, valid }) => {
                const positionIndex = R.indexOf(
                  position,
                  newMetaAdSetForm[`${publisher}_positions`]
                );
                if (publisher === value && checkedBoxesPlatforms[publisher] && positionIndex > -1) {
                  newMetaAdSetForm[`${publisher}_positions`] = R.remove(
                    positionIndex,
                    1,
                    newMetaAdSetForm[`${publisher}_positions`]
                  );
                } else if (
                  publisher === value &&
                  !checkedBoxesPlatforms[publisher] &&
                  positionIndex === -1 &&
                  valid
                ) {
                  newMetaAdSetForm[`${publisher}_positions`] = [
                    ...newMetaAdSetForm[`${publisher}_positions`],
                    position,
                  ];
                }
              });
            });
            setMetaAdSetForm(newMetaAdSetForm);
          }}
        />
      ))}
    </>
  );

  const Placements = (): JSX.Element => (
    <>
      {placements.map(({ category, positions, valid }, categoryIndex) => (
        <div key={categoryIndex}>
          <div className="metaBuyingCollapsible">
            <button
              onClick={() => {
                const newShowPlacementCategories = R.clone(showPlacementCategories);
                newShowPlacementCategories[categoryIndex] = !newShowPlacementCategories[
                  categoryIndex
                ];
                setShowPlacementCategories(newShowPlacementCategories);
              }}
            >
              <div className="arrowWrapper">
                <MdArrowForwardIos
                  className={`arrow ${showPlacementCategories[categoryIndex] ? "up" : "down"}`}
                />
              </div>
            </button>
            <Form.Check
              type="checkbox"
              label={category}
              checked={checkedBoxesPlacements[categoryIndex].categoryChecked}
              id={category}
              disabled={!valid}
              onChange={() => {
                const newMetaAdSetForm = R.clone(metaAdSetForm);
                positions.forEach(({ position, publisher }) => {
                  const positionIndex = R.indexOf(
                    position,
                    newMetaAdSetForm[`${publisher}_positions`]
                  );
                  if (checkedBoxesPlacements[categoryIndex].categoryChecked && positionIndex > -1) {
                    newMetaAdSetForm[`${publisher}_positions`] = R.remove(
                      positionIndex,
                      1,
                      newMetaAdSetForm[`${publisher}_positions`]
                    );
                  } else if (
                    !checkedBoxesPlacements[categoryIndex].categoryChecked &&
                    positionIndex === -1
                  ) {
                    newMetaAdSetForm[`${publisher}_positions`] = [
                      ...newMetaAdSetForm[`${publisher}_positions`],
                      position,
                    ];
                  }
                });
                setMetaAdSetForm(newMetaAdSetForm);
              }}
            />
          </div>
          {showPlacementCategories[categoryIndex] && (
            <div className="metaBuyingPositions">
              {positions.map(({ label, position, publisher, valid }, positionIndex) => (
                <Form.Check
                  key={positionIndex}
                  type="checkbox"
                  label={label}
                  checked={checkedBoxesPlacements[categoryIndex].positionsChecked[positionIndex]}
                  id={position}
                  disabled={!valid}
                  onChange={() => {
                    const newMetaAdSetForm = R.clone(metaAdSetForm);
                    const positionIndex = R.indexOf(
                      position,
                      newMetaAdSetForm[`${publisher}_positions`]
                    );
                    if (positionIndex > -1) {
                      newMetaAdSetForm[`${publisher}_positions`] = R.remove(
                        positionIndex,
                        1,
                        newMetaAdSetForm[`${publisher}_positions`]
                      );
                    } else if (positionIndex === -1) {
                      newMetaAdSetForm[`${publisher}_positions`] = [
                        ...newMetaAdSetForm[`${publisher}_positions`],
                        position,
                      ];
                    }
                    setMetaAdSetForm(newMetaAdSetForm);
                  }}
                />
              ))}
            </div>
          )}
        </div>
      ))}
    </>
  );

  return (
    <div className="metaBuyingFormSection">
      <h3>Platforms and Placements</h3>
      <Form.Group>
        <div className="metaBuyingFormSubsection">
          <h4>Platforms</h4>
          <Platforms />
        </div>
        <h4>Placements</h4>
        <Placements />
      </Form.Group>
    </div>
  );
};

import React, { useState, useCallback, useEffect } from "react";
import * as R from "ramda";
import { Card, InfoTooltip, Spinner } from "../../Components";
import { Button, Form, InputGroup } from "react-bootstrap";
import useLocation from "../../utils/hooks/useLocation";
import { CommerceToolProps } from "../CommerceTool";
import { createAmcInstanceIdMapping, getAmcInstanceIdMapping } from "../CommerceToolUtils";

const AmcInstanceMapping: React.FC<CommerceToolProps> = ({ utils }) => {
  const [activeAPICall, setActiveAPICall] = useState(false);
  const { setError, setModalMessage, setShowModalConfirmation } = utils;
  const { company } = useLocation();
  const [amcInstanceId, setAmcInstanceId] = useState<string | null>(null);
  const [instancePlaceholder, setInstancePlaceholder] = useState<string>("Loading...");

  useEffect(() => {
    if (amcInstanceId === null) {
      getAmcInstanceIdMapping(company).then(res => {
        if (R.isEmpty(res)) {
          setInstancePlaceholder("AMC Instance ID");
          return;
        }
        setAmcInstanceId(res[0]?.amcInstanceId);
      });
    }
  }, [company, amcInstanceId]);

  const createMapping = useCallback(async () => {
    if (amcInstanceId === null) {
      return;
    }
    try {
      setActiveAPICall(true);
      await createAmcInstanceIdMapping({ company, amcInstanceId });
      setShowModalConfirmation(true);
      setModalMessage("AMC Instance ID Mapping created successfully.");
    } catch (e) {
      setError({
        message: (e as Error).message,
        reportError: e as { message: string },
      });
    }
    setActiveAPICall(false);
  }, [company, amcInstanceId, setError, setModalMessage, setShowModalConfirmation]);

  return (
    <Card className="commerceToolCard">
      <div className="commerceToolCardTitle">
        Add or Replace AMC Instance ID Mapping
        <InfoTooltip size="reg">
          Please input the AMC Instance ID for the company you are mapping. This ID can be found in
          the AMC UI. Note that if you are replacing an existing mapping, the old mapping will be
          deleted.
        </InfoTooltip>
      </div>
      <InputGroup size="sm" className="commerceToolElem">
        <InputGroup.Prepend>
          <InputGroup.Text className="inputLabel">Company ID</InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control value={company} disabled />
      </InputGroup>
      <InputGroup size="sm" className="commerceToolElem">
        <InputGroup.Prepend>
          <InputGroup.Text className="inputLabel">AMC Instance ID</InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control
          value={amcInstanceId ?? ""}
          placeholder={instancePlaceholder}
          onChange={e => setAmcInstanceId(e.target.value)}
        />
      </InputGroup>
      <Button className="linearcommerceToolElem" onClick={createMapping}>
        {activeAPICall ? <Spinner color="white" /> : "Add"}
      </Button>
    </Card>
  );
};

export default AmcInstanceMapping;

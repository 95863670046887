import {
  CDN,
  CellData,
  ColumnMetaData,
  DimensionMap,
  getCreativeThumbnail,
  GlossaryItem,
  toPretty1000sInteger,
  toPrettyCPX,
  toPrettyNumber,
  toPrettyPercent,
  toPrettySpend,
} from "../MetricsTable/metricsTableUtils";
import { CreativeMap } from "../../redux/creative";
import { DerivedNetworkMap } from "../../redux/networks";
import { DimensionColumn } from "@blisspointmedia/bpm-types/dist/MetricsTable";
import * as L from "@blisspointmedia/bpm-types/dist/LinearPerformance";
import * as R from "ramda";

const MAX_FRACTIONAL_CPV = 1000;

export const dimensionColumnMetaDataMap: Partial<Record<L.DimensionColumnType, ColumnMetaData>> = {
  Avail: {
    contentType: "text",
    dimensionVarName: "Avail",
    displayName: "Avail",
  },
  Creative: {
    contentType: "text",
    dimensionVarName: "Creative",
    displayName: "Creative Name",
    iconStyle: "thumbnail",
  },
  Daypart: {
    contentType: "text",
    dimensionVarName: "Daypart",
    displayName: "Daypart",
  },
  Length: {
    contentType: "number",
    dimensionVarName: "Length",
    displayName: "Creative Length",
  },
  "Network Name": {
    contentType: "text",
    dimensionVarName: "Network",
    displayName: "Network",
    iconStyle: "logo",
  },
  Campaign: {
    contentType: "text",
    dimensionVarName: "Campaign",
    displayName: "Campaign",
  },
  "Media Classification": {
    contentType: "text",
    dimensionVarName: "Media Classification",
    displayName: "Media Classification",
  },
};

export const columnMetaDataMap: Partial<Record<L.ColumnType, ColumnMetaData>> = {
  spend: {
    displayName: "Spend",
    formatValue: toPrettySpend,
  },
  ratedSpend: {
    displayName: "Rated Spend",
    formatValue: toPrettySpend,
  },
  spots: {
    displayName: "Spots",
    formatValue: toPrettyNumber,
  },
  impressions: {
    displayName: "Imp's ('000s)",
    formatValue: toPretty1000sInteger,
  },
  eCPM: {
    aggregator: agg =>
      agg.impressions && agg.ratedSpend
        ? ((agg.ratedSpend as number) * 1000) / (agg.impressions as number)
        : "--",
    displayName: "eCPM",
    decimals: 2,
    fetchGetter: fetch =>
      fetch.impressions && fetch.ratedSpend
        ? ((fetch.ratedSpend as number) * 1000) / (fetch.impressions as number)
        : "--",
    formatValue: toPrettyCPX,
    minIsBest: true,
    requiredTotalsColumns: ["impressions", "ratedSpend"],
  },
  volume: {
    displayName: "KPI Volume",
    formatValue: toPrettyNumber,
  },
  cpvDirect: {
    aggregator: agg =>
      agg.volume && agg.spend ? (agg.spend as number) / (agg.volume as number) : "--",
    displayName: "CPX, Direct",
    decimals: 2,
    fetchGetter: fetch =>
      fetch.volume && fetch.spend ? (fetch.spend as number) / (fetch.volume as number) : "--",
    formatValue: toPrettyCPX,
    minIsBest: true,
    requiredTotalsColumns: ["spend", "volume"],
  },
  cpvFractional: {
    aggregator: (agg: Record<string, string | number>): number | string => {
      const cpvDirect =
        (agg.volume as number) > 0 ? (agg.spend as number) / (agg.volume as number) : 0;
      const cpvFractional =
        (agg.volumeAll as number) > 0 ? (agg.spend as number) / (agg.volumeAll as number) : 0;
      const cpv =
        (agg.volumeAll as number) > 0
          ? R.min(R.min(cpvDirect, cpvFractional), MAX_FRACTIONAL_CPV)
          : R.min(cpvDirect, MAX_FRACTIONAL_CPV);
      return cpv === 0 ? "--" : cpv;
    },
    displayName: "CPX, +Frac",
    decimals: 2,
    fetchGetter: (fetch: Record<string, string | number>): number | string => {
      const cpvDirect =
        (fetch.volume as number) > 0 ? (fetch.spend as number) / (fetch.volume as number) : 0;
      const cpvFractional =
        (fetch.volumeAll as number) > 0 ? (fetch.spend as number) / (fetch.volumeAll as number) : 0;
      const cpv =
        (fetch.volumeAll as number) > 0
          ? R.min(R.min(cpvDirect, cpvFractional), MAX_FRACTIONAL_CPV)
          : R.min(cpvDirect, MAX_FRACTIONAL_CPV);
      return cpv === 0 ? "--" : cpv;
    },
    minIsBest: true,
    formatValue: toPrettyCPX,
    requiredTotalsColumns: ["spend", "volume", "volumeAll"],
  },
  responseIndex: {
    aggregator: () => 1,
    displayName: "Resp. Index",
    decimals: 2,
    fetchGetter: (
      fetch: Record<string, string | number>,
      agg: Record<string, any>
    ): number | string => {
      const responseRatioAvg = (agg.responseRatioAvg as number) / agg.numRows;
      if (fetch.ratedAdRawEffect && fetch.impressions && responseRatioAvg > 0) {
        const responseRatio =
          (fetch.impressions as number) > 0
            ? (fetch.ratedAdRawEffect as number) / (fetch.impressions as number)
            : 0;
        const responseIndex = responseRatio / responseRatioAvg;
        return responseIndex === 0 ? "--" : responseIndex;
      }
      return "--";
    },
    formatValue: toPrettyNumber,
    requiredTotalsColumns: [
      "impressions",
      "ratedAdRawEffect",
      "numRows",
      {
        dataVarName: "responseRatioAvg",
        fetchGetter: fetch =>
          (fetch.impressions as number) > 0
            ? (fetch.ratedAdRawEffect as number) / (fetch.impressions as number)
            : 0,
      },
    ],
  },
  targetRatingPoints: {
    displayName: "Target Rating Points",
    formatValue: toPrettyNumber,
  },
  impsConversionRate: {
    aggregator: agg =>
      agg.impressions && (agg.impressions as number) > 0 && agg.volume
        ? Number(agg.volume as number) / R.defaultTo(0.0, agg.impressions as number)
        : "--",
    displayName: "Imps Conversion Rate",
    decimals: 2,
    fetchGetter: fetch =>
      fetch.impressions && (fetch.impressions as number) > 0 && fetch.volume
        ? Number(fetch.volume as number) / R.defaultTo(0.0, fetch.impressions as number)
        : "--",
    formatValue: toPrettyPercent,
    requiredTotalsColumns: ["impressions", "volume"],
  },
};

export const getDimensionCell = (
  dimensionData: DimensionMap,
  dimensionHeader: DimensionColumn,
  creativeMap?: CreativeMap,
  derivedNetworkMap?: DerivedNetworkMap
): CellData => {
  const { dimensionTypeName, dimensionVarName, icon } = dimensionHeader;
  const resolvedDimensionData = dimensionData as Record<L.Dimension, string>;
  const resolvedCreativeMap = R.defaultTo({}, creativeMap);
  const dimensionValue = resolvedDimensionData[dimensionVarName];
  const defaultCell = {
    value: dimensionValue,
    label: dimensionValue,
  };
  if (dimensionVarName === "Network") {
    const overlayText =
      !R.isNil(icon) || dimensionTypeName === "Network Logo" ? dimensionValue : undefined;
    const url =
      !R.isNil(icon) || dimensionTypeName === "Network Logo"
        ? `${CDN}/networks/${dimensionValue}.png`
        : undefined;
    if (dimensionTypeName === "Network Logo") {
      const url = `${CDN}/networks/${dimensionValue}.png`;
      return {
        label: dimensionValue,
        overlayText,
        value: dimensionValue,
        url,
      };
    } else if (dimensionTypeName === "Network Name") {
      return { label: dimensionValue, overlayText, value: dimensionValue, url };
    } else {
      return defaultCell;
    }
  } else if (dimensionVarName === "Creative") {
    const creative = resolvedCreativeMap[dimensionValue];
    if (creative) {
      const overlayText =
        !R.isNil(icon) || dimensionTypeName === "Creative Thumbnail" ? dimensionValue : undefined;
      const url =
        !R.isNil(icon) || dimensionTypeName === "Creative Thumbnail"
          ? getCreativeThumbnail(creative.company, creative.isci)
          : undefined;
      if (dimensionTypeName === "Creative Thumbnail") {
        return {
          ...defaultCell,
          overlayText,
          url,
        };
      } else if (dimensionTypeName === "Creative") {
        return {
          label: creative.name,
          value: creative.name,
          overlayText,
          url,
        };
      }
    }
    return defaultCell;
  } else if (dimensionVarName === "Length") {
    return {
      ...defaultCell,
      label: `${dimensionValue}s`,
    };
  } else if (dimensionVarName === "Avail") {
    return {
      ...defaultCell,
      label: dimensionValue === "N" ? "National" : dimensionValue === "L" ? "Local" : "Unknown",
    };
  }

  return defaultCell;
};

export const GLOSSARY: GlossaryItem[] = [
  {
    term: "eCPM",
    definition:
      "Effective Cost Per Thousand Impressions. This is the total spend times 1,000 divided by the number of impressions delivered.",
  },
  { term: "Imp's ('000s)", definition: "Number of impressions in the thousands." },
  {
    term: "Resp.Index",
    definition:
      "Response Index. It's the measure of a row's response rate relative to the overall response rate.",
  },
  {
    term: "Volume",
    definition: "KPI Volume. The number of trued-up KPIs attributed to our impressions.",
  },
  {
    term: "Direct",
    definition:
      "Direct KPIs. These are people who saw our ad and directly converted without seeing media from other marketing channels.",
  },
  {
    term: "CPX, +Fractional",
    definition:
      "Cost per KPI based on matched users responding via all referring domains. This metric thus incorporates multi-touch marketing effects.",
  },
  {
    term: "CPX",
    definition:
      'Cost per KPI. The average cost for a single KPI. The "X" is usually an abbreviation for the KPI, such as "CPV" for "visits" or "CPO" for "orders".',
  },
  {
    term: "Target Rating Point (TRP)",
    definition:
      "Rating points are a metric used to identify the scale of a campaign. TRP measures the total exposure to a target audience.",
  },
];

import React, { useState, useEffect, useCallback, useMemo } from "react";

import {
  Page,
  Skeleton,
  TableSkeleton,
  ModalEditTable,
  PendingChangesControls,
  PendingChangesPanel,
  Spinner,
} from "../Components";
import { Button } from "react-bootstrap";
import { awaitJSON, SheetsLambdaFetch } from "../utils/fetch-utils";
import { MdTableView, MdPlayArrow } from "react-icons/md";
import { useSetError } from "../redux/modals";
import * as R from "ramda";
import * as cronParser from "cron-parser";
import "./QueryExporter.scss";
import * as UserRedux from "../redux/user";
import { useSelector } from "react-redux";

export interface QueryRow {
  id: number;
  company: string;
  name: string;
  query: string;
  cron: string;
  tz: string;
  sheet_id: string;
  tab_name: string;
  last_executed: string;
  last_completed: string;
  completed_status: string;
  last_modified: string;
  last_user: string;
}

const DEFAULT_NEW_ROW = {};
const MAIN_MODAL_WIDTH = 200;
const UNVIEWABLE_WIDTH = 0;

const WIDTHS = {
  COMPANY: 200,
  NAME: 200,
  QUERY: 300,
  CRON: 150,
  TZ: 150,
  SHEET_ID: 80,
  TAB_NAME: 150,
  LAST_EXECUTED: 200,
  LAST_COMPLETED: 200,
  COMPLETED_STATUS: 200,
  RUN: 80,
  ID: 80,
};
const MODAL_ROWS = {
  COMPANY: 0,
  NAME: 0,
  QUERY: 3,
  CRON: 1,
  TZ: 1,
  SHEET_ID: 2,
  TAB_NAME: 2,
  LAST_EXECUTED: 4,
  LAST_COMPLETED: 4,
  COMPLETED_STATUS: 4,
  LAST_MODIFIED: 5,
  LAST_USER: 5,
  RUN: 5,
};

export interface QueryRowParams {
  insert: QueryRow[];
  update: QueryRow[];
  delete: QueryRow[];
}

const TIMEZONE_OPTIONS = ["America/New_York", "America/Los_Angeles", "ETC/UTC"];
const selectorOptions = {
  tz: R.map(e => ({ label: e, value: e }), TIMEZONE_OPTIONS),
};

const QueryExporter = (): JSX.Element => {
  const [tableData, setTableData] = useState<QueryRow[]>();
  const [originalTableData, setOriginalTableData] = useState<Record<string, QueryRow>>();

  const [updatedRows, setUpdatedRows] = useState<QueryRow[]>([]);
  const [newRows, setNewRows] = useState<QueryRow[]>([]);
  const [deletedRows, setDeletedRows] = useState<QueryRow[]>([]);

  const [saving, setSaving] = useState(false);
  const [invalidText, setInvalidText] = useState<string>();
  const [showPendingChanges, setShowPendingChanges] = useState(false);
  const [showTableData, setShowTableData] = useState(false);
  const setError = useSetError();
  const isNOC = useSelector(UserRedux.roleSelector) === 3;

  const getFreshQueryData = useCallback(async () => {
    try {
      setShowTableData(false);
      setNewRows([]);
      setDeletedRows([]);
      setUpdatedRows([]);

      let res = await SheetsLambdaFetch("/get_export_query_sheets");
      let jsonResponse = await awaitJSON<QueryRow[]>(res);
      if (jsonResponse) {
        setTableData(jsonResponse);

        let originalTableData: Record<string, QueryRow> = {};
        for (let row of jsonResponse) {
          originalTableData[row.id] = row;
        }
        setOriginalTableData(originalTableData);
        setShowTableData(true);
      }
    } catch (e) {
      const reportError = e as Error;
      setError({
        message: `Failed to get query export data. ERROR: ${reportError.message}`,
        reportError,
      });
    }
  }, [setError]);

  useEffect(() => {
    if (!tableData) {
      (async () => {
        await getFreshQueryData();
      })();
    }
  }, [setError, getFreshQueryData, tableData]);

  const runQueryExport = useCallback(
    async id => {
      if (id) {
        await SheetsLambdaFetch("/run_export_query_sheets", {
          method: "POST",
          body: { id },
        });
        if (tableData) {
          setTableData(
            R.map(row => {
              if (row.id === id) {
                return { ...row, completed_status: "STARTED" };
              } else {
                return row;
              }
            }, tableData)
          );
        }
        await getFreshQueryData();
      } else {
        setError({ message: "Cannot run a query that hasn't been saved" });
      }
    },
    [setError, getFreshQueryData, tableData, setTableData]
  );

  useEffect(() => {
    if (tableData && originalTableData) {
      let isUpdated = (row: QueryRow) => {
        if (originalTableData[row.id]) {
          let originalRow = originalTableData[row.id];
          return (
            row.id !== null &&
            (row.company !== originalRow.company ||
              row.name !== originalRow.name ||
              row.query !== originalRow.query ||
              row.cron !== originalRow.cron ||
              row.tz !== originalRow.tz ||
              row.sheet_id !== originalRow.sheet_id ||
              row.tab_name !== originalRow.tab_name)
          );
        }
        return false;
      };
      let updatedRows = R.filter(isUpdated, tableData);
      setUpdatedRows(updatedRows);

      let insertedRows = R.filter(row => R.isNil(row.id), tableData);
      setNewRows(insertedRows);
    }
  }, [originalTableData, tableData]);

  const hasPendingChanges: boolean = useMemo(() => {
    const isChanged = !(R.isEmpty(updatedRows) && R.isEmpty(newRows) && R.isEmpty(deletedRows));

    if (!isChanged) {
      // if there are no pending changes force the pending pane to
      // close
      setShowPendingChanges(false);
    }

    return isChanged;
  }, [deletedRows, newRows, updatedRows]);

  const save = useCallback(async () => {
    try {
      setSaving(true);
      const internalBody: QueryRowParams = {
        insert: newRows || [],
        update: updatedRows || [],
        delete: deletedRows || [],
      };
      await SheetsLambdaFetch<QueryRowParams>("/update_export_query_sheets", {
        method: "POST",
        body: internalBody,
      });

      await getFreshQueryData();
      setSaving(false);
    } catch (e) {
      const reportError = e as Error;
      setError({
        message: `Failed to update exported queries. \n ERROR: ${reportError.message}`,
        reportError,
      });
    }
  }, [newRows, updatedRows, deletedRows, getFreshQueryData, setError]);

  const checkIsValid = (data: QueryRow) => {
    const { name } = data;
    if (!name) {
      setInvalidText("Name field cannot be empty");
      return false;
    }

    return true;
  };

  const headers = [
    {
      label: "ID",
      field: "id",
      type: "text",
      readOnly: true,
      width: WIDTHS.ID,
      nonInteractive: true,
      uneditable: true,
    },
    {
      label: "Company",
      field: "company",
      type: "text",
      width: WIDTHS.COMPANY,
      modalRow: MODAL_ROWS.COMPANY,
      modalWidth: MAIN_MODAL_WIDTH,
    },
    {
      label: "Name",
      field: "name",
      type: "text",
      width: WIDTHS.NAME,
      modalRow: MODAL_ROWS.NAME,
      modalWidth: MAIN_MODAL_WIDTH,
    },
    {
      label: "Query",
      field: "query",
      type: "query",
      width: WIDTHS.QUERY,
      modalRow: MODAL_ROWS.QUERY,
      modalWidth: MAIN_MODAL_WIDTH,
      modalFlex: 1,
      renderer: row => <div className="queryTable">{row.query}</div>,
    },
    {
      label: "Sheet ID",
      field: "sheet_id",
      type: "text",
      width: WIDTHS.SHEET_ID,
      modalRow: MODAL_ROWS.SHEET_ID,
      modalWidth: MAIN_MODAL_WIDTH,
      renderer: row => (
        <Button
          id={row.id}
          variant="outline-secondary"
          onClick={() => {
            window.open(
              `https://docs.google.com/spreadsheets/d/${row.sheet_id}`,
              "_blank", // <- This is what makes it open in a new window.
              "noreferrer"
            );
          }}
        >
          <MdTableView />
        </Button>
      ),
    },
    {
      label: "Tab Name",
      field: "tab_name",
      type: "text",
      width: WIDTHS.TAB_NAME,
      modalRow: MODAL_ROWS.TAB_NAME,
      modalWidth: MAIN_MODAL_WIDTH,
    },
    {
      label: "Cron",
      field: "cron",
      type: "text",
      width: WIDTHS.CRON,
      modalRow: MODAL_ROWS.CRON,
      modalWidth: MAIN_MODAL_WIDTH,
      renderer: row => <div className="queryCron">{row.cron}</div>,
      isInvalid: cron => {
        if (cron) {
          try {
            cronParser.parseExpression(cron, { tz: "ETC/UTC" });
            return false;
          } catch (e) {
            return true;
          }
        } else {
          return true;
        }
      },
    },
    {
      label: "Timezone",
      field: "tz",
      type: "select",
      options: "tz",
      width: WIDTHS.TZ,
      modalRow: MODAL_ROWS.TZ,
      modalWidth: MAIN_MODAL_WIDTH,
    },
    {
      label: "Last Completed",
      field: "last_completed",
      type: "text",
      readOnly: true,
      width: WIDTHS.LAST_COMPLETED,
      nonInteractive: true,
      uneditable: true,
    },
    {
      label: "Status",
      field: "completed_status",
      type: "text",
      modalRow: MODAL_ROWS.COMPLETED_STATUS,
      modalFlex: 1,
      width: WIDTHS.COMPLETED_STATUS,
      readOnly: true,
      nonInteractive: true,
    },
    {
      label: "Run",
      field: "id",
      type: "text",
      modalRow: MODAL_ROWS.RUN,
      width: WIDTHS.RUN,
      readOnly: true,
      nonInteractive: true,
      uneditable: true,
      renderer: row => {
        if (
          row &&
          row.completed_status &&
          (row.completed_status.indexOf("FAILED") >= 0 ||
            row.completed_status.indexOf("SUCCESS") >= 0)
        ) {
          return (
            <Button
              id={row.id}
              variant="outline-secondary"
              onClick={() => {
                runQueryExport(row.id);
              }}
            >
              <MdPlayArrow />
            </Button>
          );
        } else {
          return <Spinner />;
        }
      },
    },
    {
      label: "Last Modified",
      field: "last_modified",
      type: "text",
      readOnly: true,
      modalRow: MODAL_ROWS.LAST_MODIFIED,
      modalFlex: 1,
      width: UNVIEWABLE_WIDTH,
      nonInteractive: true,
    },
    {
      label: "Last User",
      field: "last_user",
      type: "text",
      readOnly: true,
      modalRow: MODAL_ROWS.LAST_USER,
      modalFlex: 1,
      width: UNVIEWABLE_WIDTH,
      nonInteractive: true,
    },
  ];

  const clearAllChanges = useCallback(() => {
    setNewRows([]);
    setUpdatedRows([]);
    setDeletedRows([]);
    setTableData(Object.values(originalTableData || {}));
  }, [setNewRows, setUpdatedRows, setDeletedRows, setTableData, originalTableData]);

  return (
    <Page
      title="Query Exporter"
      pageType="Query Exporter"
      actions={
        <div className="queryExporterActions">
          <div className="actionButtons">
            {!isNOC && (
              <PendingChangesControls
                hasPendingChanges={hasPendingChanges}
                setShowPendingChanges={setShowPendingChanges}
                saveChanges={save}
                isSaving={saving}
                clearAllChanges={clearAllChanges}
              />
            )}
          </div>
        </div>
      }
    >
      <div className="queryExporterPageContainer">
        {" "}
        {showTableData && tableData ? (
          <ModalEditTable<QueryRow>
            className="queryExporterTable"
            headers={headers}
            tableData={tableData}
            setTableData={newTableData => setTableData(newTableData)}
            selectorOptions={selectorOptions}
            /// @ts-ignore - Can delete when ModalEditTable is TypeScripted
            setDeletedRows={setDeletedRows}
            filterBar
            enableDelete={true}
            enableAdd={!isNOC}
            checkIsValid={checkIsValid}
            invalidText={invalidText}
            rowHeight={60}
            showModalOnAdd={true}
            defaultNewRow={DEFAULT_NEW_ROW}
            readOnly={isNOC}
          />
        ) : (
          <Skeleton>
            <TableSkeleton />
          </Skeleton>
        )}
        {showPendingChanges && (
          <PendingChangesPanel
            pendingChanges={{
              newRows,
              editedRows: updatedRows,
              deletedRows,
            }}
            showPendingChanges={showPendingChanges}
            setShowPendingChanges={setShowPendingChanges}
            headers={headers}
          />
        )}
      </div>
    </Page>
  );
};
export default QueryExporter;

const customerInsightsExperimentCompanies = [
  "instacart",
  "hims",
  "hers",
  "culligan",
  "tinuititest",
];

// Experiment to allow clients to access customer insights tool
export const shouldEnableCustomerInsights = (company: string, userRole: number): boolean => {
  return userRole <= 2 && customerInsightsExperimentCompanies.includes(company);
};

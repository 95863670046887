import "./PresetDropdown.scss";
import { Button, ButtonType, Dropdown, OverlayTrigger } from "../Components";
import { ButtonFrameworkVariant } from "../Components/ButtonFramework";
import { MdAdd, MdContentCopy, MdDelete, MdEdit, MdMoreVert, MdStar } from "react-icons/md";
import { ReactComponent as RenameIcon } from "./RenameIcon.svg";
import { STANDARD_GROUP_NAME } from "../Performance/performanceUtils";
import { transformEmailToFullName } from "./MetricsTable/metricsTableUtils";
import { useSelector } from "react-redux";
import { useSetError } from "../redux/modals";
import * as Dfns from "date-fns/fp";
import * as R from "ramda";
import * as UserRedux from "../redux/user";
import React, { useEffect, useMemo, useState } from "react";

export interface PresetDropdownProps {
  addPresetOnClick?: () => void;
  addPresetText?: string;
  allowNoSelections?: boolean;
  applyOnClick: (preset: any) => void;
  cancelOnClick: () => void;
  className?: string;
  defaultPresetNames?: string[];
  deleteItemOnClick?: (presetID: number) => void;
  duplicateItemOnClick?: (preset: any) => void;
  editItemOnClick?: (preset: any) => void;
  hasPresetGroups?: boolean;
  hasTicks?: boolean;
  label?: string;
  presets: any[];
  renameItemOnClick?: (presetID: any) => void;
  selectedPreset?: any;
  subTitles?: string[];
}

export const PresetDropdown: React.FC<PresetDropdownProps> = React.memo(
  ({
    addPresetOnClick = () => {},
    addPresetText = "Create New Preset",
    allowNoSelections = false,
    applyOnClick = () => {},
    cancelOnClick = () => {},
    className = "",
    defaultPresetNames = [],
    deleteItemOnClick = () => {},
    duplicateItemOnClick = () => {},
    editItemOnClick = () => {},
    hasPresetGroups = false,
    hasTicks = true,
    label = "Load Preset",
    presets,
    renameItemOnClick = () => {},
    selectedPreset,
    subTitles,
  }) => {
    const isInternal = useSelector(UserRedux.isInternalSelector);
    const setError = useSetError();
    const [presetMap, presetGroupMap] = useMemo(() => {
      let standardGroupCount = 0;
      const presetMap = {};
      const presetGroupMap = { All: true };
      for (const preset of presets) {
        presetMap[preset.id] = preset;
        if (preset.presetGroup) {
          if (preset.presetGroup === STANDARD_GROUP_NAME) {
            standardGroupCount++;
          }
          presetGroupMap[preset.presetGroup] = true;
        }
      }
      return [
        presetMap,
        {
          ...presetGroupMap,
          ...(standardGroupCount > 1 ? { Custom: true } : {}),
        },
      ];
    }, [presets]);

    const [selectedOptionTrigger, setSelectedOptionTrigger] = useState<number | undefined>(
      undefined
    );
    const [showPresetDropdown, setShowPresetDropdown] = useState<boolean>(false);
    const [tempSelectedPreset, setTempSelectedPreset] = useState<any | null | undefined>(
      selectedPreset
    );
    const [tempSelectedPresetGroup, setTempSelectedPresetGroup] = useState<any | null | undefined>(
      selectedPreset ? selectedPreset.presetGroup : undefined
    );

    useEffect(() => {
      if (selectedPreset) {
        setTempSelectedPreset(selectedPreset);
      }
    }, [selectedPreset]);

    const filteredPresets = useMemo(() => {
      const filteredPresets: any[] = [];
      if (tempSelectedPresetGroup === "All" || R.isNil(tempSelectedPresetGroup)) {
        return presets;
      }
      for (const preset of presets) {
        if (tempSelectedPresetGroup === "Custom" && preset.presetGroup !== STANDARD_GROUP_NAME) {
          filteredPresets.push(preset);
        } else if (preset.presetGroup === tempSelectedPresetGroup) {
          filteredPresets.push(preset);
        }
      }

      return filteredPresets;
    }, [presets, tempSelectedPresetGroup]);

    const presetOptions = useMemo(
      () =>
        R.map(preset => {
          const isDefault =
            (!R.isNil(preset) && !R.isNil(preset.id) && preset.id <= 0) ||
            (!R.defaultTo(preset.name) && !defaultPresetNames.includes(preset.name.toLowerCase()));
          const last_modified =
            !R.isNil(preset) && !R.isNil(preset.last_modified || preset.lastmodified)
              ? Dfns.format(
                  "MM/dd/yyyy",
                  new Date((preset.last_modified || preset.lastmodified).slice(0, 10))
                )
              : "N/A";
          const last_user =
            !R.isNil(preset) && !R.isNil(preset.last_user || preset.lastuser)
              ? transformEmailToFullName(preset.last_user || preset.lastuser)
              : !R.isNil(preset) && !R.isNil(preset.id) && preset.id <= 0
              ? "Default Preset"
              : "N/A";
          return {
            className: `${selectedOptionTrigger === preset.id ? "dropdownOpen" : ""}`,
            label: preset && preset.temporary ? "Temporary Preset" : preset.name,
            value: `${preset.id}` as string,
            itemOptions: !isInternal ? undefined : (
              <OverlayTrigger
                customTrigger={selectedOptionTrigger === preset.id}
                placement="right top"
                xoffset={0}
                yoffset={9}
                overlay={
                  <div className="presetOptionsPresetDropdown">
                    <div className="presetOption" onClick={() => editItemOnClick(preset)}>
                      <div className="presetOptionIcon">
                        <MdEdit />
                      </div>
                      <div className="presetOptionText">Edit</div>
                    </div>
                    <div
                      className="presetOption"
                      onClick={() => {
                        if (isDefault) {
                          setError({ message: "Cannot rename default presets!" });
                        } else {
                          renameItemOnClick(preset);
                        }
                      }}
                    >
                      <div className="presetOptionIcon">
                        <RenameIcon />
                      </div>
                      <div className="presetOptionText">Rename</div>
                    </div>
                    <div
                      className="presetOption"
                      onClick={() => {
                        if (isDefault && setError) {
                          setError({ message: "Cannot delete default presets" });
                        } else {
                          deleteItemOnClick(preset.id);
                        }
                      }}
                    >
                      <div className="presetOptionIcon">
                        <MdDelete />
                      </div>
                      <div className="presetOptionText">Delete</div>
                    </div>
                    <div className="presetOption" onClick={() => duplicateItemOnClick(preset)}>
                      <div className="presetOptionIcon">
                        <MdContentCopy />
                      </div>
                      <div className="presetOptionText">Duplicate</div>
                    </div>
                    <div className="presetLastModifiedContainer">
                      <div className="presetLastModifiedText">Last updated:</div>
                      <div className="presetLastModifiedText">
                        {last_modified} by {last_user}
                      </div>
                    </div>
                  </div>
                }
              >
                <div
                  className="presetOptionExpander"
                  onClick={() =>
                    setSelectedOptionTrigger(
                      selectedOptionTrigger === preset.id ? undefined : preset.id
                    )
                  }
                >
                  <MdMoreVert />
                </div>
              </OverlayTrigger>
            ),
            selected:
              tempSelectedPreset === null
                ? false
                : tempSelectedPreset
                ? tempSelectedPreset.id === preset.id
                : selectedPreset && selectedPreset.id === preset.id,
          };
        }, filteredPresets),
      [
        defaultPresetNames,
        deleteItemOnClick,
        duplicateItemOnClick,
        editItemOnClick,
        filteredPresets,
        isInternal,
        renameItemOnClick,
        selectedOptionTrigger,
        selectedPreset,
        setError,
        tempSelectedPreset,
      ]
    );

    const presetGroupOptions = useMemo(() => {
      let selectedGroup;
      const presetGroups = R.uniq(
        R.map(preset => {
          const selected =
            tempSelectedPreset === null
              ? false
              : tempSelectedPreset
              ? tempSelectedPreset.id === preset.id
              : selectedPreset && selectedPreset.id === preset.id;
          if (selected) {
            selectedGroup = preset.presetGroup;
          }
          return preset.presetGroup;
        }, R.defaultTo([], presets) as any[])
      );
      const presetGroupOptions = [
        {
          label: "All",
          value: "All",
          selected:
            (R.isNil(selectedGroup) && R.isNil(tempSelectedPresetGroup)) ||
            (tempSelectedPresetGroup ? tempSelectedPresetGroup === "All" : selectedGroup === "All"),
        },
        ...(presetGroupMap.Custom
          ? [
              {
                label: "Custom",
                value: "Custom",
                selected: tempSelectedPresetGroup
                  ? tempSelectedPresetGroup === "Custom"
                  : selectedGroup === "Custom",
              },
            ]
          : []),
      ];
      for (const presetGroup of presetGroups) {
        if (presetGroup && presetGroup.length) {
          presetGroupOptions.push({
            label: presetGroup,
            selected: tempSelectedPresetGroup
              ? tempSelectedPresetGroup === presetGroup
              : selectedGroup === presetGroup,
            value: presetGroup,
          });
        }
      }
      return presetGroupOptions;
    }, [presetGroupMap, presets, selectedPreset, tempSelectedPreset, tempSelectedPresetGroup]);

    const addNewPresetButton = (
      <Button
        className="addNewPresetButton"
        icon={<MdAdd />}
        type={ButtonType.EMPTY}
        variant={ButtonFrameworkVariant.LEADING_ICON}
      >
        {addPresetText}
      </Button>
    );

    return (
      <div className={`presetDropdown ${className} ${selectedPreset ? "presetSelected" : ""}`}>
        {presets && (
          <Dropdown
            applyOnClick={selectedItems => {
              applyOnClick(
                allowNoSelections &&
                  (R.isNil(selectedItems) ||
                    R.isEmpty(selectedItems) ||
                    (selectedItems.length === 1 &&
                      (R.isNil(selectedItems[0]) || R.isEmpty(selectedItems[0]))))
                  ? undefined
                  : tempSelectedPreset
                  ? tempSelectedPreset
                  : selectedPreset
              );
            }}
            cancelOnClick={() => cancelOnClick()}
            footer={addNewPresetButton}
            footerOnClick={() => addPresetOnClick()}
            hasFilterBar={false}
            hasTicks={hasTicks}
            label={<div>{label}</div>}
            leadingIcon={<MdStar />}
            menuItems={hasPresetGroups ? [presetGroupOptions, presetOptions] : presetOptions}
            multiSelect={[false, false]}
            onChange={value => {
              if (tempSelectedPreset && `${tempSelectedPreset.id}` === value) {
                setTempSelectedPreset(null);
                setTempSelectedPresetGroup(null);
              } else if (value && presetMap[value]) {
                setTempSelectedPreset(presetMap[value]);
                setTempSelectedPresetGroup((presetMap[value] as any).presetGroup);
              }
              if (presetGroupMap[value]) {
                setTempSelectedPresetGroup(value);
              }
            }}
            show={showPresetDropdown}
            setShow={setShowPresetDropdown}
            subTitles={subTitles}
            onToggle={isOpen => {
              if (!R.isNil(selectedOptionTrigger)) {
                setSelectedOptionTrigger(undefined);
                setShowPresetDropdown(true);
              } else {
                setShowPresetDropdown(isOpen);
              }
            }}
            options={[]}
            value={""}
          />
        )}
      </div>
    );
  }
);

export default PresetDropdown;

import "./Companies.scss";
import { Button, Form } from "react-bootstrap";
import { FilterTableContainer, Img, Page, Spinner, ToggleCheckboxes } from "../Components";
import { Link } from "@reach/router";
import {
  UsersLambdaFetch,
  awaitJSON,
  DagLambdaFetch,
  MiscLambdaFetch,
  pollS3,
} from "../utils/fetch-utils";
import { useSelector } from "react-redux";
import { useSetError } from "../redux/modals";
import { validDate } from "../utils/date-utils";
import * as Dfns from "date-fns/fp";
import * as R from "ramda";
import * as UserRedux from "../redux/user";
import React, { useState, useEffect, useMemo, useCallback } from "react";

const agencyOptions = [
  {
    label: "All",
    key: "all",
  },
  {
    label: "Bliss Point",
    key: "bpm",
  },
  { label: "Prospect Point", key: "ppm" },
  { label: "Tinuiti", key: "tinuiti" },
];

const INGESTION_BUTTON_PROPS = [
  {
    type: "linear",
    variant: "info",
    label: "Linear",
    disableTypes: ["linear", "both"],
  },
];

const startDag = async ({ dag, ingestionType, setError }) => {
  try {
    let body = { dag, include_last_run_info: false };

    if (ingestionType === "streaming" || ingestionType === "linear") {
      body.ingestion_type = ingestionType;
    }
    await DagLambdaFetch("/start", {
      method: "POST",
      body,
      retries: 0,
    });
  } catch (e) {
    setError({
      message: `Failed to start dag ${dag} with ingestion type ${ingestionType}`,
      reportError: e,
    });
  }
};

const Companies = () => {
  const setError = useSetError();
  const [companyInfoMap, setCompanyInfoMap] = useState();
  const [buildStatusMap, setBuildStatusMap] = useState();
  const [ingestStatusesByCompany, setIngestStatusesByCompany] = useState();
  const isInternal = useSelector(UserRedux.isInternalSelector);
  const isAdmin = useSelector(UserRedux.isAdminSelector);
  const email = useSelector(UserRedux.emailSelector);
  const [agencies, setAgencies] = useState(
    R.map(option => {
      return option.key;
    }, agencyOptions)
  );

  useEffect(() => {
    if (!isInternal || ingestStatusesByCompany) {
      return; // already loaded
    }

    (async () => {
      try {
        let statusesByClient = await DagLambdaFetch("/check", {
          params: { getAll: true },
        });

        let parsedResult = await awaitJSON(statusesByClient);
        let result = R.prop("result", parsedResult);
        setIngestStatusesByCompany(result);
      } catch (e) {
        console.error("Failed to fetch ingestions statuses, ERROR: ", e);
        return null;
      }
    })();
  }, [setError, ingestStatusesByCompany, isInternal]);
  useEffect(() => {
    if (!companyInfoMap) {
      (async () => {
        try {
          const result = await MiscLambdaFetch("/kickOffLambda", {
            method: "POST",
            body: {
              fileType: "txt",
              lambdaArgs: { email },
              lambdaName: "users-getBasicCompanyInfo",
            },
          });
          const cometUuid = await awaitJSON(result);
          const content = await pollS3({
            autoDownload: false,
            bucket: "bpm-cache",
            filename: `${cometUuid}.txt`,
            mimeType: "text/plain",
          });
          const textContent = await content.text();
          let companyInfo = JSON.parse(textContent);

          if (companyInfo.length === 1) {
            window.location.href = `/companies/${companyInfo[0].cid}`;
          }
          companyInfo = R.uniq(R.sortBy(row => row.name, companyInfo));
          const companyInfoMap = {};
          for (let company of companyInfo) {
            if (company.agency) {
              if (R.isNil(companyInfoMap[company.agency])) {
                companyInfoMap[company.agency] = [];
              }
              companyInfoMap[company.agency].push(company);
            }
          }
          setCompanyInfoMap(companyInfoMap);
        } catch (e) {
          setError({
            message: `Failed to load company info: ${e.message}`,
            reportError: e,
          });
          setCompanyInfoMap(null);
        }
      })();
    }
  }, [companyInfoMap, setError, email]);

  const getBuildStatus = useCallback(() => {
    (async () => {
      try {
        let buildStatus = await UsersLambdaFetch("/buildStatus");
        buildStatus = await awaitJSON(buildStatus);
        setBuildStatusMap(R.groupBy(row => row.company_id, buildStatus));
      } catch (e) {
        setError({
          message: `Failed to load build status info: ${e.message}`,
          reportError: e,
        });
        setBuildStatusMap(null);
      }
    })();
  }, [setError]);

  useEffect(() => {
    if (isInternal && !buildStatusMap) {
      getBuildStatus();
    }
  }, [buildStatusMap, getBuildStatus, isInternal]);

  const companyInfo = useMemo(
    () =>
      R.isNil(companyInfoMap)
        ? []
        : isAdmin
        ? R.flatten(R.values(companyInfoMap))
        : R.flatten(
            R.filter(
              companyInfoMapped => !R.isNil(companyInfoMapped),
              R.map(agency => {
                return companyInfoMap[agency];
              }, agencies)
            )
          ),
    [agencies, companyInfoMap, isAdmin]
  );

  const disableIngestionButtons = useCallback(
    ({ types = [], cid }) => {
      let currStatuses = { ...ingestStatusesByCompany };
      for (let type of types) {
        if (R.path([cid, type], currStatuses)) {
          currStatuses[cid][type] = false;
        }
      }
      setIngestStatusesByCompany(currStatuses);
    },
    [ingestStatusesByCompany]
  );
  const leftTableHeaders = useMemo(() => {
    let headers = [
      {
        label: "Name",
        name: "name",
        sortPriority: 0,
        sortAscending: true,
        flex: 1,
        renderer: data => (
          <div className="companies-name-column">
            <div className="companies-logo">
              <a href={data.cid}>
                <Img
                  className="logo company"
                  src={`https://cdn.blisspointmedia.com/companies/${data.cid}/logo.png`}
                  title={data.name}
                />
              </a>
            </div>
            <div className="companies-link">
              <Link to={`/${data.cid}`}>{data.name}</Link>
            </div>
          </div>
        ),
      },
    ];
    if (isInternal) {
      headers.push({
        label: <span>Ingest Now</span>,
        nonInteractive: true,
        width: 250,
        renderer: data => (
          <div className="companies-ingest-buttons">
            {isInternal &&
              R.map(details => {
                let disabled = !R.path([data.cid, details.type], ingestStatusesByCompany);
                return (
                  <Button
                    key={`${data.cid}_${details.type}`}
                    variant={disabled ? "secondary" : details.variant}
                    size="xs"
                    onClick={() => {
                      disableIngestionButtons({
                        types: details.disableTypes,
                        cid: data.cid,
                      });
                      startDag({
                        dag: data.cid,
                        ingestionType: details.type,
                        setError,
                      });
                    }}
                    disabled={disabled}
                  >
                    {details.label}
                  </Button>
                );
              }, INGESTION_BUTTON_PROPS)}
          </div>
        ),
      });
    }
    return headers;
  }, [isInternal, ingestStatusesByCompany, disableIngestionButtons, setError]);

  const nonMobiusKPIs = useMemo(() => {
    if (!companyInfo || !buildStatusMap) {
      return null;
    }
    if (!R.pipe(R.keys, R.length)(buildStatusMap)) {
      return [];
    }
    return R.pipe(
      R.map(company =>
        R.map(kpi => {
          return R.map(
            build => R.merge(build, { ...kpi, company: company.cid }),
            buildStatusMap[kpi.id] || []
          );
        }, company.KPIs)
      ),
      R.flatten
    )(companyInfo);
  }, [buildStatusMap, companyInfo]);

  // These kpis don't have a company or specific kpi attached to them
  const mobiusKPIs = useMemo(() => {
    if (!buildStatusMap) {
      return null;
    }
    if (!R.pipe(R.keys, R.length)(buildStatusMap)) {
      return [];
    }
    return R.uniq(
      R.map(
        buildRow => R.merge(buildRow, { kpi: "N/A", company: "N/A" }),
        R.filter(
          build => build.batch_job_name.includes("MOBIUS"),
          R.flatten(R.values(buildStatusMap))
        )
      )
    );
  }, [buildStatusMap]);

  const kpis = useMemo(
    () => R.concat(R.defaultTo([], nonMobiusKPIs), R.defaultTo([], mobiusKPIs)),
    [mobiusKPIs, nonMobiusKPIs]
  );

  const defaultHeaders = [
    {
      label: "Company",
      name: "company",
      width: 150,
      renderer: data => data.company,
    },
    {
      label: "ID",
      name: "id",
      flex: 1,
      minFlexWidth: 200,
      renderer: data => data.id,
    },
    {
      label: "Build",
      name: "batch_job_name",
      flex: 3,
      minFlexWidth: 300,
      renderer: data => (
        <a href={data.batch_log_url} target="_blank" rel="noopener noreferrer">
          {data.batch_job_name}
        </a>
      ),
    },
    {
      label: "Status",
      name: "status_batch",
      width: 80,
      renderer: data => data.status_batch,
    },
    {
      label: "Expected Completion (Local)",
      name: "lastmodified",
      width: 260,
      renderer: data => {
        if (
          data.status_batch === "SUCCEEDED" ||
          data.status_batch === "FAILED" ||
          !validDate(data.now)
        ) {
          return "-";
        } else {
          return Dfns.format(
            "yyyy-MM-dd hh:mma",
            Dfns.addSeconds(data.total_expected_runtime, new Date(data.now))
          );
        }
      },
    },
  ];

  return (
    <Page
      title={"Companies"}
      pageType={"Companies"}
      minHeight={600}
      actions={
        <Form.Group hidden={!isAdmin}>
          <ToggleCheckboxes
            block
            selectedOptions={agencies}
            options={agencyOptions}
            onChange={newAgencies => {
              if (agencies.includes("all")) {
                if (!newAgencies.includes("all")) {
                  setAgencies([]);
                } else {
                  setAgencies(
                    R.filter(elem => {
                      return elem !== "all";
                    }, newAgencies)
                  );
                }
              } else if (
                (newAgencies.includes("all") && !agencies.includes("all")) ||
                (!newAgencies.includes("all") && agencyOptions.length === newAgencies.length + 1)
              ) {
                setAgencies(
                  R.map(option => {
                    return option.key;
                  }, agencyOptions)
                );
              } else {
                setAgencies(newAgencies);
              }
            }}
          />
        </Form.Group>
      }
    >
      <div className="bpm-companies">
        {companyInfoMap && companyInfo ? (
          <div className="reconcile-body companies">
            <FilterTableContainer data={companyInfo} headers={leftTableHeaders} />
          </div>
        ) : (
          <Spinner size={100} />
        )}
        {(() => {
          if (kpis) {
            return (
              <div className="reconcile-body builds">
                <FilterTableContainer
                  data={kpis}
                  defaultTokens={{
                    advanced: ["Status", "is not like", "SUCCEEDED"],
                  }}
                  defaultAdvancedFilter
                  headers={defaultHeaders}
                />
              </div>
            );
          }
          return <Spinner size={100} />;
        })()}
      </div>
    </Page>
  );
};

export default Companies;

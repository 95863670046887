import React, { useEffect, useMemo, useState } from "react";
import * as R from "ramda";
import * as Dfns from "date-fns/fp";
import { Modal } from "react-bootstrap";
import "./MetaCreativeAssetModal.scss";
import { Button, ButtonType, Dropdown, DropdownToggleType, FilterBar } from "../../../Components";
import { MetaCreativeThumbnail } from "./MetaCreativeThumbnail";
import { awaitJSON, MetaLambdaFetch } from "../../../utils/fetch-utils";
import { useSetError } from "../../../redux/modals";
import { MetaBuyingImage, MetaBuyingVideo } from "@blisspointmedia/bpm-types/dist/MetaBuying";
import { useStateFunction } from "../../../utils/hooks/useData";

interface MetaCreativeAssetModalProps {
  show: boolean;
  closeModal: any;
  accountID: string;
  isVideo: boolean;
  selectCreative: (asset?: MetaBuyingImage | MetaBuyingVideo) => void;
}

enum SortValues {
  ABC = "Abc",
  NEWEST = "Newest",
  OLDEST = "Oldest",
}

const sortByNameCaseInsensitive = R.sortBy(R.compose(R.toLower, R.prop("name")));
const sortByTitleCaseInsensitive = R.sortBy(R.compose(R.toLower, R.prop("title")));
const sortByNewestCreatedAt = R.sort(
  (a: MetaBuyingImage | MetaBuyingVideo, b: MetaBuyingImage | MetaBuyingVideo) =>
    Dfns.isAfter(Dfns.parseISO(a.created), Dfns.parseISO(b.created)) ? 1 : -1
);
const sortByOldestCreatedAt = R.sortBy(R.compose(Dfns.parseISO, R.prop("created")));
const sortAssets = (assets, sortVal: SortValues, isVideo) => {
  let sortedAssets = assets;
  try {
    switch (sortVal) {
      case "Abc":
        sortedAssets = isVideo
          ? sortByTitleCaseInsensitive(assets)
          : sortByNameCaseInsensitive(assets);
        break;
      case "Newest":
        sortedAssets = sortByNewestCreatedAt(assets);
        break;
      case "Oldest":
        sortedAssets = sortByOldestCreatedAt(assets);
        break;
    }
  } catch (e) {}
  return sortedAssets;
};

export const MetaCreativeAssetModal: React.FC<MetaCreativeAssetModalProps> = ({
  show,
  closeModal,
  accountID,
  isVideo = false,
  selectCreative,
}) => {
  const [sortVal, setSortVal] = useState<SortValues>(SortValues.NEWEST);
  const [loading, setLoading] = useState<boolean>(false);
  const [availableAssets, setAvailableAssets] = useState<MetaBuyingImage[] | MetaBuyingVideo[]>([]);
  const [selectedAsset, setSelectedAsset] = useState<MetaBuyingImage | MetaBuyingVideo>();
  const [filter, setFilter] = useStateFunction<(line) => boolean>(() => true);
  const [retryOnErrorCount, setRetryOnErrorCount] = useState<number>(0);
  const [assetPointer, setAssetPointer] = useState(0);
  const [assetThumbails, setAssetThumbails] = useState<JSX.Element[]>([]);
  const setError = useSetError();

  const resetState = () => {
    setSortVal(SortValues.NEWEST);
    setLoading(false);
    setAvailableAssets([]);
    setSelectedAsset(undefined);
    setRetryOnErrorCount(0);
    setAssetPointer(0);
    setAssetThumbails([]);
  };

  const filteredAssets = useMemo(() => {
    return R.filter(filter, availableAssets);
  }, [filter, availableAssets]);

  useEffect(() => {
    resetState();
  }, [isVideo]);

  useEffect(() => {
    if (!loading && R.isEmpty(availableAssets) && retryOnErrorCount < 5) {
      setLoading(true);
      (async () => {
        try {
          const path = isVideo ? "/getMetaBuyingVideos" : "/getMetaBuyingImages";
          const res = await MetaLambdaFetch(path, {
            params: { accountIDs: accountID },
          });
          const assets = await awaitJSON(res);
          const sortedResults = sortAssets(assets[accountID] || [], SortValues.NEWEST, isVideo);
          setAvailableAssets(sortedResults);
          setAssetPointer(60);
        } catch (e) {
          const error = e as Error;
          setRetryOnErrorCount(c => {
            const currCount = c + 1;
            if (currCount > 4) {
              setError({
                message: `Error fetching meta assets for accountId: ${accountID}, Error: ${error.message}`,
              });
            }
            return currCount;
          });
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [setError, loading, availableAssets, accountID, retryOnErrorCount, isVideo]);

  useEffect(() => {
    setAssetThumbails(
      filteredAssets.slice(0, assetPointer).map(el => {
        return (
          <MetaCreativeThumbnail
            key={`creativeAssetThumbnail${isVideo ? el.video_id : el.hash}`}
            isVideo={isVideo}
            selected={R.equals(selectedAsset, el)}
            url={isVideo ? el.picture : el.permalink_url}
            name={isVideo ? el.title : el.name}
            length={el.length}
            createdAt={el.created}
            onClick={() => {
              setSelectedAsset(el);
            }}
          />
        );
      })
    );
  }, [filteredAssets, isVideo, selectedAsset, assetPointer]);

  return (
    <Modal
      show={show}
      onHide={() => closeModal()}
      className="MetaBuyingCreativeAssetModal"
      size={"xl"}
    >
      <Modal.Header closeButton>
        <Modal.Title>{isVideo ? "Choose Video" : "Choose Image"}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="creativeAssetModalBody">
        <div className="filterOptions">
          <FilterBar
            options={isVideo ? ["title", "created"] : ["name", "created"]}
            onFilter={setFilter}
            size="lg"
          />
          <Dropdown
            type={DropdownToggleType.OUTLINED}
            label={"Sort by"}
            value={sortVal}
            onChange={option => {
              setSortVal(option);
              setAvailableAssets(a => sortAssets(a, option, isVideo));
            }}
            options={[SortValues.ABC, SortValues.NEWEST, SortValues.OLDEST]}
          />
        </div>
        <div className="thumbnailContainer">
          {assetThumbails}
          <div className="spacer"></div>
          {filteredAssets.length > assetPointer && (
            <div className="loadMoreButton">
              <Button
                type={ButtonType.OUTLINED}
                onClick={() => {
                  setAssetPointer(a => a + 60);
                }}
              >
                Load more
              </Button>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type={ButtonType.OUTLINED}
          onClick={() => {
            resetState();
            closeModal();
          }}
        >
          Cancel
        </Button>
        <Button
          type={ButtonType.FILLED}
          onClick={() => {
            resetState();
            selectCreative(selectedAsset);
            closeModal();
          }}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

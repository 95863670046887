import React from "react";
import { Button, ButtonType, DropdownMenu, MenuItem } from "../../Components";
import { RouteComponentProps } from "@reach/router";
import "../MetaBuying.scss";
import * as R from "ramda";
import { TAB_CAMPAIGN, TAB_AD_SET, TAB_AD } from "../MetaBuyingConstants";

interface MetaBuyingCreationPromptProps {
  adAccountOptions: {
    account_name: string;
    business_manager: string;
    account_id: string;
  }[];
  selectedAdAccount: {
    account_id: string;
    account_name: string;
    business_manager: string;
  };
  selectAdAccount: (adAccount: {
    account_id: string;
    account_name: string;
    business_manager: string;
  }) => void;
  creationTabSelections: {
    [TAB_CAMPAIGN]: boolean;
    [TAB_AD_SET]: boolean;
    [TAB_AD]: boolean;
  };
  setCreationTabSelections: React.Dispatch<
    React.SetStateAction<{
      [TAB_CAMPAIGN]: boolean;
      [TAB_AD_SET]: boolean;
      [TAB_AD]: boolean;
    }>
  >;
  setSelectionsSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
}

const CREATION_MENU = [TAB_CAMPAIGN, TAB_AD_SET, TAB_AD];

export const MetaBuyingCreationPrompt = ({
  adAccountOptions,
  selectedAdAccount,
  selectAdAccount,
  creationTabSelections,
  setCreationTabSelections,
  setSelectionsSubmitted,
}: MetaBuyingCreationPromptProps & RouteComponentProps): JSX.Element => (
  <div className="metaBuyingWidgetWrapper">
    <div className="metaBuyingWidget">
      <h2>Options</h2>
      <div className="metaBuyingWidgetBody">
        <div className="metaBuyingMenuWrapper">
          <h3>What do you want to create?</h3>
          <DropdownMenu
            applyOnClick={null}
            cancelOnClick={null}
            menuItems={R.map(option => {
              let menuItem: MenuItem<string> = {
                label: option,
                selected: creationTabSelections[option],
                value: option,
              };
              if (
                (option === TAB_CAMPAIGN &&
                  creationTabSelections[TAB_AD] &&
                  !creationTabSelections[TAB_AD_SET]) ||
                (option === TAB_AD &&
                  creationTabSelections[TAB_CAMPAIGN] &&
                  !creationTabSelections[TAB_AD_SET]) ||
                (option === TAB_AD_SET &&
                  creationTabSelections[TAB_CAMPAIGN] &&
                  creationTabSelections[TAB_AD])
              ) {
                menuItem.className = "disabled";
              }
              return menuItem;
            }, CREATION_MENU as any)}
            standalone
            itemOnClick={item => {
              let newCreationTabSelections = R.clone(creationTabSelections);
              newCreationTabSelections[item.value] = item.selected;
              setCreationTabSelections(newCreationTabSelections);
            }}
            multiSelect
          />
        </div>
        <div className="metaBuyingMenuWrapper">
          <h3>Which ad account?</h3>
          <DropdownMenu
            applyOnClick={null}
            cancelOnClick={null}
            menuItems={R.map(
              account => ({
                label: `${account.account_name} (${account.account_id})`,
                selected: account.account_id === selectedAdAccount.account_id,
                value: account.account_id,
              }),
              adAccountOptions as any
            )}
            standalone
            itemOnClick={item =>
              selectAdAccount(R.indexBy(R.prop("account_id"), adAccountOptions)[item.value])
            }
            hasTicks={true}
          />
        </div>
      </div>
      <Button
        className="submitSelections"
        type={ButtonType.OUTLINED}
        disabled={
          (!creationTabSelections[TAB_CAMPAIGN] &&
            !creationTabSelections[TAB_AD_SET] &&
            !creationTabSelections[TAB_AD]) ||
          R.isEmpty(selectedAdAccount.account_id)
        }
        onClick={e => setSelectionsSubmitted(true)}
      >
        Go
      </Button>
    </div>
  </div>
);

export default MetaBuyingCreationPrompt;

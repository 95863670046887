import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { TODAY } from "@blisspointmedia/bpm-types/dist/RelativeDatePicker";
import * as Dfns from "date-fns/fp";
import * as R from "ramda";
import {
  MetaBuyingTableRow,
  CampaignRow,
  AdSetRow,
  AdRow,
  MBApprovalStage,
  AdAccountInfo,
  Level,
  CreationTab,
  FormField,
  Budget,
  ConversionGoal,
  Objective,
} from "@blisspointmedia/bpm-types/dist/MetaBuying";
import { BPMTable, CheckBox, FilterBar, Header, FilterBarTokens } from "../Components";
import ReviewModal, {
  ReviewModalTab,
  ReviewModalVariant,
} from "./DraftsAndPublished/Components/ReviewModal";
import { useStateFunction } from "../utils/hooks/useData";
import {
  MdCheck,
  MdClose,
  MdDelete,
  MdNotificationImportant,
  MdRefresh,
  MdRemoveRedEye,
  MdSmsFailed,
} from "react-icons/md";
import { Form } from "react-bootstrap";
import { MetaLambdaFetch } from "../utils/fetch-utils";
import { useSelector } from "react-redux";
import { fullNameSelector, emailSelector } from "../redux/user";
import AlertDialog, { AlertDialogVariants } from "../Components/AlertDialog/AlertDialog";
import {
  TAB_CAMPAIGN,
  TAB_AD_SET,
  TAB_AD,
  CLIENT,
  AGENCY,
  SEGMENTATION_ID,
  SEGMENT_SELECTION,
  ATTR_WINDOW_COMBOS,
  ATTR_WINDOW_OPTIONS,
  OUTCOME_SALES,
} from "./MetaBuyingConstants";

import { FormContext, metaBuyingFormDefault } from "./MetaBuyingContext";
import { PageTab } from "./MetaBuying";
import { useSetError } from "../redux/modals";
import { Danger800 } from "../utils/colors";
import ChangeLogModal from "./DraftsAndPublished/Components/ChangeLogModal";

export const BLUE_META_LOGO = "https://cdn.blisspointmedia.com/assets/img/BlueMetaLogo.png";
export const WHITE_META_LOGO = "https://cdn.blisspointmedia.com/assets/img/WhiteMetaLogo.png";

const formatSelectedLevel = (selectedLevel: string): string => {
  if (selectedLevel === "ad") {
    return "Ad";
  } else if (selectedLevel === "adset") {
    return "Ad Set";
  } else {
    return "Campaign";
  }
};

export const SELECT_ROW = false;
export const DESELECT_ROW = true;

// SELECT ROW
enum LEVEL_HEIRARCHY {
  campaign = 0,
  adset = 1,
  ad = 2,
}
interface SelectRowParams {
  setSelectedRows: React.Dispatch<
    React.SetStateAction<Record<string, Record<string, MetaBuyingTableRow>>>
  >;
  selectedLevel: string;
}
export const useSelectRow = ({
  setSelectedRows,
  selectedLevel,
}: SelectRowParams): ((row: MetaBuyingTableRow, remove?: boolean) => void) => {
  const updateDownstreamSelections = useCallback(
    (selections: Record<string, Record<string, MetaBuyingTableRow>>) => {
      if (!R.isEmpty(selections[selectedLevel])) {
        // If current level has no selections, no need to update downstream selections.
        // If current level has selections, update downstream selections to only include
        // rows that contain the id of rows in the current level.
        const selectedLevelObj = selections[selectedLevel];
        for (let i = LEVEL_HEIRARCHY[selectedLevel] + 1; i < 3; i++) {
          const subLevel = LEVEL_HEIRARCHY[i];
          const subLevelObj = selections[subLevel];
          for (let row of Object.values(subLevelObj)) {
            if (!R.has(row[`${selectedLevel}_id`], selectedLevelObj)) {
              delete subLevelObj[row.id];
            }
          }
        }
      }

      return selections;
    },
    [selectedLevel]
  );

  return useCallback(
    (row: MetaBuyingTableRow, remove?: boolean) => {
      const { id } = row;

      setSelectedRows(current => {
        let updatedSelections: Record<string, Record<string, MetaBuyingTableRow>> = { ...current };

        if ((R.has(id, current[selectedLevel]) && remove === undefined) || remove) {
          // Removing the row from selectedRows
          const currLevelObj = current[selectedLevel];
          const newObj = R.omit([id], currLevelObj);
          updatedSelections = { ...current, [selectedLevel]: newObj };
        } else if ((!R.has(id, current[selectedLevel]) && remove === undefined) || !remove) {
          // Adding the row to selectedRows
          const currLevelObj: { [id: string]: MetaBuyingTableRow } = current[selectedLevel];
          const newObj = { ...currLevelObj, [id]: row };
          updatedSelections = { ...current, [selectedLevel]: newObj };
        }

        // Update downstream selections
        return updateDownstreamSelections(updatedSelections);
      });
    },
    [setSelectedRows, selectedLevel, updateDownstreamSelections]
  );
};

// TABLE HEADERS RENDERER
interface TableHeadersRendererParams {
  selectedRows: Record<string, Record<string, MetaBuyingTableRow>>;
  setSelectedRows: React.Dispatch<
    React.SetStateAction<Record<string, Record<string, MetaBuyingTableRow>>>
  >;
  selectAll: Record<string, boolean>;
  setSelectAll: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
  selectedLevel: string;
  filteredTableData: MetaBuyingTableRow[];
}
export const useTableHeadersRenderer = ({
  filteredTableData,
  selectAll,
  setSelectAll,
  selectedRows,
  setSelectedRows,
  selectedLevel,
}: TableHeadersRendererParams): (({ data, columnIndex }: any) => JSX.Element) => {
  return useCallback(
    ({ data, columnIndex }) => {
      switch (columnIndex) {
        case 0:
          return (
            <CheckBox
              className="metaBuyingCheckbox"
              checked={selectAll[selectedLevel]}
              onCheck={() => {
                if (selectAll[selectedLevel]) {
                  setSelectedRows({ ...selectedRows, [selectedLevel]: {} });
                } else {
                  let selectAllMap = { [selectedLevel]: {} };

                  for (let row of filteredTableData) {
                    selectAllMap[selectedLevel][row.id] = row;
                  }

                  setSelectedRows(current => ({ ...current, ...selectAllMap }));
                }
                setSelectAll({ ...selectAll, [selectedLevel]: !selectAll[selectedLevel] });
              }}
            />
          );
        default:
          return <div>{data}</div>;
      }
    },
    [filteredTableData, selectAll, setSelectAll, selectedRows, setSelectedRows, selectedLevel]
  );
};

// FILTER BAR
interface FilterBarParams {
  pageTab: "drafts" | "published";
  tableData: any[];
  isInternal: boolean;
  selectedLevel: string;
  setFilter: (func: (line: any) => boolean) => void;
  tokens: FilterBarTokens;
  setTokens: React.Dispatch<React.SetStateAction<FilterBarTokens>>;
}
export const useFilterBar = ({
  pageTab,
  tableData,
  isInternal,
  selectedLevel,
  setFilter,
  tokens,
  setTokens,
}: FilterBarParams): JSX.Element => {
  return useMemo(() => {
    let filterOptions: {
      label: string;
      name: string;
    }[] = [];

    // Set filter options based on user type, selected level, and page tab
    if (!isInternal) {
      filterOptions = [
        { label: "Ad", name: "ad_name" },
        { label: "Ad Set", name: "adset_name" },
        { label: "Campaign", name: "campaign_name" },
        { label: "Created On", name: "created" },
      ];
      if (pageTab === "published") {
        filterOptions.push({ label: "Status", name: "adsmanager_status" });
      }
    } else if (selectedLevel === "ad") {
      filterOptions = [
        { label: "Ad", name: "ad_name" },
        { label: "Ad Set", name: "adset_name" },
        { label: "Campaign", name: "campaign_name" },
        { label: "Created On", name: "created" },
        { label: "Creator", name: "lastuser" },
      ];
      if (pageTab === "drafts") {
        filterOptions.push(
          { label: "Client-Facing", name: "client_facing" },
          { label: "Approval Status", name: "approval_stage" }
        );
      } else {
        filterOptions.push({ label: "Status", name: "adsmanager_status" });
      }
    } else if (selectedLevel === "adset") {
      filterOptions = [
        { label: "Ad Set", name: "adset_name" },
        { label: "Campaign", name: "campaign_name" },
        { label: "Created On", name: "created" },
        { label: "Creator", name: "lastuser" },
      ];
      if (pageTab === "published") {
        filterOptions.push({ label: "Status", name: "adsmanager_status" });
      }
    } else {
      filterOptions = [
        { label: "Campaign", name: "campaign_name" },
        { label: "Created On", name: "created" },
        { label: "Creator", name: "lastuser" },
      ];
      if (pageTab === "published") {
        filterOptions.push({ label: "Status", name: "adsmanager_status" });
      }
    }

    return (
      <FilterBar
        width={1034}
        hasAdvanced={true}
        lines={tableData}
        onFilter={setFilter}
        options={filterOptions}
        tokens={tokens}
        onChange={setTokens}
      />
    );
  }, [isInternal, selectedLevel, tableData, setFilter, pageTab, tokens, setTokens]);
};

// TABLE DATA
export interface TableDataParams {
  isInternal: boolean;
  campaignRows: CampaignRow[];
  adSetRows: AdSetRow[];
  adRows: AdRow[];
}
export interface UseTableData {
  setFilter: (func: (line: any) => boolean) => void;
  selectedLevel: string;
  setSelectedLevel: React.Dispatch<React.SetStateAction<string>>;
  selectedRows: Record<string, Record<string, MetaBuyingTableRow>>;
  setSelectedRows: Dispatch<SetStateAction<Record<string, Record<string, MetaBuyingTableRow>>>>;
  selectAll: Record<string, boolean>;
  setSelectAll: Dispatch<SetStateAction<Record<string, boolean>>>;
  tableData: MetaBuyingTableRow[];
  filteredTableData: MetaBuyingTableRow[];
}
export const useTableData = ({
  isInternal,
  campaignRows,
  adSetRows,
  adRows,
}: TableDataParams): UseTableData => {
  const [filter, setFilter] = useStateFunction<(line) => boolean>(() => true);
  const [selectedLevel, setSelectedLevel] = useState<string>(isInternal ? "campaign" : "ad");
  const [selectedRows, setSelectedRows] = useState<
    Record<string, Record<string, MetaBuyingTableRow>>
  >({
    campaign: {},
    adset: {},
    ad: {},
  });
  const [selectAll, setSelectAll] = useState<Record<string, boolean>>({
    campaign: false,
    adset: false,
    ad: false,
  });

  // Map of each level to its rows. Data is filtered based on selected rows.
  const viewableDataMap: Record<string, MetaBuyingTableRow[]> = useMemo(() => {
    let viewableAds: AdRow[] = isInternal ? adRows : adRows.filter(row => row.client_facing);
    if (!R.isEmpty(selectedRows.campaign) && isInternal) {
      viewableAds = adRows.filter(row => {
        return R.has(row.campaign_id, selectedRows.campaign);
      });
    }
    if (!R.isEmpty(selectedRows.adset) && isInternal) {
      viewableAds = viewableAds.filter(row => {
        return R.has(row.adset_id, selectedRows.adset);
      });
    }

    let viewableAdSets: AdSetRow[] = [];
    if (isInternal) {
      viewableAdSets = R.isEmpty(selectedRows.campaign)
        ? adSetRows
        : adSetRows.filter(row => {
            return R.has(row.campaign_id, selectedRows.campaign);
          });
    }

    return {
      campaign: isInternal ? campaignRows : [],
      adset: viewableAdSets,
      ad: viewableAds,
    };
  }, [adRows, adSetRows, campaignRows, selectedRows, isInternal]);

  // Update selectAll state when viewableDataMap changes
  useEffect(() => {
    for (let level of Object.keys(selectedRows)) {
      const currObj = selectedRows[level];
      if (Object.keys(currObj).length === viewableDataMap[level].length) {
        setSelectAll(current => ({ ...current, [level]: true }));
      } else {
        setSelectAll(current => ({ ...current, [level]: false }));
      }
    }
  }, [selectedRows, setSelectAll, viewableDataMap]);

  // Rows to display in the table based on selected level
  const tableData: MetaBuyingTableRow[] = useMemo(() => {
    if (selectedLevel === "ad") {
      return viewableDataMap.ad;
    } else if (selectedLevel === "adset") {
      return viewableDataMap.adset;
    } else {
      return viewableDataMap.campaign;
    }
  }, [selectedLevel, viewableDataMap]);

  // Apply filter bar to table data
  const filteredTableData: MetaBuyingTableRow[] = useMemo(() => {
    return tableData?.filter(filter) || [];
  }, [tableData, filter]);

  return {
    setFilter,
    selectedLevel,
    setSelectedLevel,
    selectedRows,
    setSelectedRows,
    selectAll,
    setSelectAll,
    tableData,
    filteredTableData,
  };
};

// TABLE COMPONENT
interface TableParams {
  className: string;
  filteredTableData: MetaBuyingTableRow[];
  columns: Header[];
  headersRenderer: ({ data, columnIndex }: any) => JSX.Element;
}
export const useMetaBuyingTable = ({
  className,
  filteredTableData,
  columns,
  headersRenderer,
}: TableParams): JSX.Element => {
  return useMemo(() => {
    return (
      <BPMTable
        className={className}
        alternateColors={false}
        data={filteredTableData}
        headers={columns}
        headersRenderer={headersRenderer}
        filterBar={false}
        noRowsRenderer={() => <div>No rows to show.</div>}
      ></BPMTable>
    );
  }, [className, filteredTableData, columns, headersRenderer]);
};

// MORE ACTIONS BUTTON POSITION
interface GetButtonPositionParams {
  buttonRefs: React.MutableRefObject<{}>;
  selectedLevel: string;
}
// Returns the position of the more actions button based on the rowId
export const useGetButtonPosition = ({
  buttonRefs,
  selectedLevel,
}: GetButtonPositionParams): ((
  rowId: any
) => {
  top: number;
  left: number;
}) => {
  return useCallback(
    rowId => {
      const rect = buttonRefs.current[rowId]?.getBoundingClientRect();
      const menuHeight = selectedLevel === "ad" ? 190 : 156;
      const menuWidth = selectedLevel === "ad" ? 167 : 138;
      const buttonHeight = 28;
      return rect
        ? {
            top:
              window.innerHeight - rect.top > menuHeight
                ? rect.top
                : rect.top - menuHeight + buttonHeight,
            left: rect.left - menuWidth,
          }
        : { top: 0, left: 0 };
    },
    [buttonRefs, selectedLevel]
  );
};

// ALERT DIALOG
export enum MBAlertDialogType {
  CLIENT_FACING_ENABLED = "CLIENT_FACING_ENABLED",
  CLIENT_FACING_DISABLED = "CLIENT_FACING_DISABLED",
  REQUEST_APPROVAL = "REQUEST_APPROVAL",
  PUBLISH_AS_PAUSED = "PUBLISH_AS_PAUSED",
  APPROVED = "APPROVED",
  CHANGES_REQUESTED = "CHANGES_REQUESTED",
  STATUS_REMOVED = "STATUS_REMOVED",
  DELETE = "DELETE",
  EDIT = "EDIT",
}
interface AlertDialogManagerParams {
  isInternal: boolean;
  accountID: string;
  businessManager: string;
  selectedLevel: string;
  selectedRows: Record<string, Record<string, MetaBuyingTableRow>>;
  selectRow: (row: MetaBuyingTableRow, remove?: boolean) => void;
  campaignRows: CampaignRow[];
  adSetRows: AdSetRow[];
  adRows: AdRow[];
  refreshData?: (tab: PageTab) => void;
  goToTab?: (tab: PageTab) => void;
  setTabIndex: React.Dispatch<React.SetStateAction<number>>;
  setCreationTabSelections: React.Dispatch<
    React.SetStateAction<{
      [TAB_CAMPAIGN]: boolean;
      [TAB_AD_SET]: boolean;
      [TAB_AD]: boolean;
    }>
  >;
  setSelectionsSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
}
export interface UseAlertDialog {
  alertDialogType: MBAlertDialogType | undefined;
  alertDialogActivatedRow: MetaBuyingTableRow | undefined;
  openAlertDialog: (type: MBAlertDialogType, row?: MetaBuyingTableRow) => void;
  alertDialogComponent: JSX.Element | undefined;
}
export const useAlertDialog = ({
  isInternal,
  accountID,
  businessManager,
  selectedLevel,
  selectedRows,
  selectRow,
  campaignRows,
  adSetRows,
  adRows,
  refreshData = () => {},
  goToTab,
  setTabIndex,
  setCreationTabSelections,
  setSelectionsSubmitted,
}: AlertDialogManagerParams): UseAlertDialog => {
  const { metaBuyingForm, setMetaBuyingForm } = useContext(FormContext);

  const metaCampaignForm = metaBuyingForm.campaign;
  const metaAdSetForm = metaBuyingForm.ad_set;

  const setMetaCampaignForm = useCallback(
    value =>
      setMetaBuyingForm((current: any): typeof metaBuyingFormDefault => {
        return { ...current, campaign: value };
      }),
    [setMetaBuyingForm]
  );

  const setMetaAdSetForm = useCallback(
    value =>
      setMetaBuyingForm((current: any): typeof metaBuyingFormDefault => {
        return { ...current, ad_set: value };
      }),
    [setMetaBuyingForm]
  );

  const [alertDialogType, setAlertDialogType] = useState<MBAlertDialogType>();
  const [alertDialogActivatedRow, setAlertDialogActivatedRow] = useState<MetaBuyingTableRow>();

  const openAlertDialog = useCallback((type: MBAlertDialogType, row?: MetaBuyingTableRow) => {
    setAlertDialogType(type);
    setAlertDialogActivatedRow(row);
  }, []);

  const closeAlertDialog = useCallback(() => {
    setAlertDialogType(undefined);
    setAlertDialogActivatedRow(undefined);
  }, []);

  // Keeps track of notes in alert dialog
  const [notes, setNotes] = useState<string>("");
  const fullName = useSelector(fullNameSelector);
  const email = useSelector(emailSelector);
  const setError = useSetError();
  const [awaitingLambda, setAwaitingLambda] = useState<boolean>(false);

  const updateAdApprovalStage = useCallback(
    async (newStage: MBApprovalStage, notesRequired?: boolean) => {
      try {
        if (!notes && notesRequired) {
          setError({
            title: "Notes missing!",
            message: "Please add a note before proceeding.",
          });
          return;
        }
        await MetaLambdaFetch("/updateAdApprovalStage", {
          method: "POST",
          body: {
            newStage,
            adRows: Object.values(selectedRows.ad) as AdRow[],
            requester: `${fullName} (${email})`,
            isInternal,
            notes,
          },
        });

        refreshData(PageTab.DRAFTS);
      } catch (e) {
        setError({
          message: e.message,
          reportError: e,
        });
      }
    },
    [email, fullName, isInternal, selectedRows, notes, refreshData, setError]
  );

  const updateClientFacingStatus = useCallback(
    async (newStatus: boolean) => {
      try {
        await MetaLambdaFetch("/updateClientFacingStatus", {
          method: "POST",
          body: {
            newStatus,
            adRows: Object.values(selectedRows.ad) as AdRow[],
          },
        });

        refreshData(PageTab.DRAFTS);
      } catch (e) {
        closeAlertDialog();
        setError({
          message: e.message,
          reportError: e,
        });
      }
    },
    [selectedRows, refreshData, setError, closeAlertDialog]
  );

  const deleteDrafts = useCallback(
    async (campaignRows: CampaignRow[], adSetRows: AdSetRow[], adRows: AdRow[]) => {
      try {
        await MetaLambdaFetch("/deleteDrafts", {
          method: "POST",
          body: {
            campaignRows,
            adSetRows,
            adRows,
          },
        });

        refreshData(PageTab.DRAFTS);
      } catch (e) {
        closeAlertDialog();
        setError({
          message: e.message,
          reportError: e,
        });
      }
    },
    [refreshData, setError, closeAlertDialog]
  );

  const publishToAdsManager = useCallback(
    async (campaignRows: CampaignRow[], adSetRows: AdSetRow[], adRows: AdRow[]) => {
      try {
        await MetaLambdaFetch("/publishToAdsManager", {
          method: "POST",
          body: {
            account_id: accountID,
            businessManager,
            campaigns: campaignRows,
            adSets: adSetRows,
            ads: adRows,
          },
        });

        refreshData(PageTab.PUBLISHED);
      } catch (e) {
        closeAlertDialog();
        setError({
          message: e.message,
          reportError: e,
        });
      }
    },
    [accountID, businessManager, setError, refreshData, closeAlertDialog]
  );

  const {
    alertDialogVariant,
    alertDialogTitle,
    alertDialogIcon,
    alertDialogBody,
    alertDialogPrimaryAction,
    alertDialogPrimaryLabel,
    alertDialogSecondaryAction,
    alertDialogSecondaryLabel,
  } = useMemo(() => {
    const currentLevelSelections = Object.values(selectedRows[selectedLevel]);
    let alertDialogVariant,
      alertDialogTitle,
      alertDialogIcon,
      alertDialogBody,
      alertDialogPrimaryAction,
      alertDialogPrimaryLabel,
      alertDialogSecondaryAction,
      alertDialogSecondaryLabel;

    // Set variables based on alert dialog type
    if (alertDialogType === MBAlertDialogType.CLIENT_FACING_ENABLED && alertDialogActivatedRow) {
      /*
       * CLIENT FACING ENABLED
       */
      const adNames = currentLevelSelections.map(ad => (ad as AdRow).ad_name).join(", ");

      alertDialogVariant = AlertDialogVariants.INFO;
      alertDialogTitle = "Your client can now see the following ad(s):";
      alertDialogIcon = <MdRemoveRedEye size={72} />;
      alertDialogBody = adNames;
      alertDialogPrimaryAction = () => {
        updateClientFacingStatus(true);
      };
      alertDialogPrimaryLabel = "Got it!";
      alertDialogSecondaryAction = () => {
        selectRow(alertDialogActivatedRow, DESELECT_ROW);
        closeAlertDialog();
      };
      alertDialogSecondaryLabel = "Undo";
    } else if (
      alertDialogType === MBAlertDialogType.CLIENT_FACING_DISABLED &&
      alertDialogActivatedRow
    ) {
      /*
       * CLIENT FACING DISABLED
       */
      const adNames = currentLevelSelections.map(ad => (ad as AdRow).ad_name).join(", ");

      alertDialogVariant = AlertDialogVariants.INFO;
      alertDialogTitle = "Your client can no longer see the following ad(s):";
      alertDialogIcon = <MdRemoveRedEye size={72} />;
      alertDialogBody = adNames;
      alertDialogPrimaryAction = () => {
        setAwaitingLambda(true);
        updateClientFacingStatus(false);
      };
      alertDialogPrimaryLabel = "Got it!";
      alertDialogSecondaryAction = () => {
        selectRow(alertDialogActivatedRow, DESELECT_ROW);
        closeAlertDialog();
      };
      alertDialogSecondaryLabel = "Undo";
    } else if (alertDialogType === MBAlertDialogType.REQUEST_APPROVAL && alertDialogActivatedRow) {
      /*
       * REQUEST APPROVAL
       */
      const adNames = currentLevelSelections.map(ad => (ad as AdRow).ad_name).join(", ");

      alertDialogVariant = AlertDialogVariants.INFO;
      alertDialogTitle = "Request approval for the following ad(s):";
      alertDialogIcon = <MdNotificationImportant size={72} />;
      alertDialogBody = (
        <div className="inputFieldBody">
          <div className="inputFieldDescription">
            <div className="inputFieldDescriptionText">{adNames}</div>
          </div>
          <Form.Group>
            <Form.Label className="inputFieldFormLabel">{"Notes (optional):"}</Form.Label>
            <Form.Control
              className="inputFieldFormControl"
              as="textarea"
              value={notes}
              placeholder="Anything you'd like to say?"
              onChange={e => {
                setNotes(e.target.value);
              }}
            />
          </Form.Group>
        </div>
      );
      alertDialogPrimaryAction = () => {
        setAwaitingLambda(true);
        updateAdApprovalStage(MBApprovalStage.IN_REVIEW);
      };
      alertDialogPrimaryLabel = "Got it!";
      alertDialogSecondaryAction = () => {
        selectRow(alertDialogActivatedRow, DESELECT_ROW);
        setNotes("");
        closeAlertDialog();
      };
    } else if (alertDialogType === MBAlertDialogType.PUBLISH_AS_PAUSED) {
      /*
       * PUBLISH AS PAUSED
       */
      let campaignRowsToPublish: CampaignRow[] = [],
        adSetRowsToPublish: AdSetRow[] = [],
        adRowsToPublish: AdRow[] = [],
        campaignNames = "",
        adSetNames = "",
        adNames = "",
        descriptionText: JSX.Element;
      /*
       * If alert dialog activated row is present, we are publishing that row
       * and any upstream rows. Otherwise, we take into account all selected
       * rows in else block.
       */
      if (alertDialogActivatedRow && selectedLevel === "ad") {
        adRowsToPublish.push(alertDialogActivatedRow as AdRow);
        adNames = (alertDialogActivatedRow as AdRow).ad_name;
        // Looking for ad set that shares adset id with ad selection
        adSetRows.forEach(adSetRow => {
          if (adSetRow.id === (alertDialogActivatedRow as AdRow).adset_id) {
            adSetRowsToPublish.push(adSetRow);
            adSetNames = adSetRow.adset_name;
          }
        });
        // Looking for campaign that shares campaign id with ad selection
        campaignRows.forEach(campaignRow => {
          if (campaignRow.id === (alertDialogActivatedRow as AdRow).campaign_id) {
            campaignRowsToPublish.push(campaignRow);
            campaignNames = campaignRow.campaign_name;
          }
        });
      } else if (alertDialogActivatedRow && selectedLevel === "adset") {
        adSetRowsToPublish = [alertDialogActivatedRow as AdSetRow];
        adSetNames = (alertDialogActivatedRow as AdSetRow).adset_name;
        // Looking for campaign that shares campaign id with ad set selection
        campaignRows.forEach(campaignRow => {
          if (campaignRow.id === (alertDialogActivatedRow as AdSetRow).campaign_id) {
            campaignRowsToPublish.push(campaignRow);
            campaignNames = campaignRow.campaign_name;
          }
        });
      } else if (alertDialogActivatedRow && selectedLevel === "campaign") {
        campaignRowsToPublish = [alertDialogActivatedRow as CampaignRow];
        campaignNames = (alertDialogActivatedRow as CampaignRow).campaign_name;
      } else {
        let campaignRowsMap: { [id: string]: CampaignRow } =
          selectedLevel === "campaign"
            ? ({ ...selectedRows.campaign } as {
                [id: string]: CampaignRow;
              })
            : {};
        let adSetRowsMap: { [id: string]: AdSetRow } =
          selectedLevel === "adset"
            ? ({ ...selectedRows.adset } as {
                [id: string]: AdSetRow;
              })
            : {};
        let adRowsMap: { [id: string]: AdRow } =
          selectedLevel === "ad"
            ? ({ ...selectedRows.ad } as {
                [id: string]: AdRow;
              })
            : {};

        adRowsToPublish = Object.values(adRowsMap) as AdRow[];
        adNames = adRowsToPublish.map(row => row.ad_name).join(", ");

        // Looking for ad set(s) that share ad set id with ad selection(s)
        adRowsToPublish.forEach(adRow => {
          adSetRows.forEach(adSetRow => {
            if (adSetRow.id === adRow.adset_id) {
              adSetRowsMap[adSetRow.id] = adSetRow;
            }
          });
        });
        adSetRowsToPublish = Object.values(adSetRowsMap) as AdSetRow[];
        adSetNames = adSetRowsToPublish.map(row => row.adset_name).join(", ");

        // Looking for campaign(s) that share campaign id with ad set selection(s)
        adSetRowsToPublish.forEach(adSetRow => {
          campaignRows.forEach(campaignRow => {
            if (campaignRow.id === adSetRow.campaign_id) {
              campaignRowsMap[campaignRow.id] = campaignRow;
            }
          });
        });
        campaignRowsToPublish = Object.values(campaignRowsMap) as CampaignRow[];
        campaignNames = campaignRowsToPublish.map(row => row.campaign_name).join(", ");
      }

      descriptionText = (
        <>
          {adNames && <div>{`ad(s): ${adNames}`}</div>}
          {adNames && adSetNames && <br />}
          {adSetNames && <div>{`ad set(s): ${adSetNames}`}</div>}
          {adSetNames && campaignNames && <br />}
          {campaignNames && <div>{`campaign(s): ${campaignNames}`}</div>}
        </>
      );

      alertDialogVariant = AlertDialogVariants.INFO;
      alertDialogTitle = "Published to Meta as paused!";
      alertDialogIcon = (
        <img
          src={BLUE_META_LOGO}
          alt="icon"
          style={{ width: "72px", height: "72px", padding: "0px 6px", marginRight: "24px" }}
        />
      );
      alertDialogBody = (
        <div className="inputFieldBody">
          <div className="inputFieldDescription">
            <div className="inputFieldDescriptionHeader">
              Go to Ads Manager to complete the publishing process.
            </div>
            <div className="inputFieldDescriptionText">{descriptionText}</div>
          </div>
        </div>
      );
      alertDialogPrimaryAction = () => {
        setAwaitingLambda(true);
        publishToAdsManager(campaignRowsToPublish, adSetRowsToPublish, adRowsToPublish);
      };
      alertDialogPrimaryLabel = "Got it!";
      alertDialogSecondaryAction = () => {
        if (alertDialogActivatedRow) {
          selectRow(alertDialogActivatedRow, DESELECT_ROW);
        }
        closeAlertDialog();
      };
      alertDialogSecondaryLabel = "Cancel";
    } else if (alertDialogType === MBAlertDialogType.APPROVED && alertDialogActivatedRow) {
      /*
       * APPROVE
       */
      const adNames = currentLevelSelections.map(ad => (ad as AdRow).ad_name).join(", ");

      alertDialogVariant = AlertDialogVariants.INFO;
      alertDialogTitle = "Approved!";
      alertDialogIcon = <MdCheck size={72} />;
      alertDialogBody = (
        <div className="inputFieldBody">
          <div className="inputFieldDescription">
            <div className="inputFieldDescriptionHeader">
              Your team will be notified that this ad is approved:
            </div>
            <div className="inputFieldDescriptionText">{adNames}</div>
          </div>
          <Form.Group>
            <Form.Label className="inputFieldFormLabel">{"Notes (optional):"}</Form.Label>
            <Form.Control
              className="inputFieldFormControl"
              as="textarea"
              value={notes}
              placeholder="Anything you'd like to say?"
              onChange={e => {
                setNotes(e.target.value);
              }}
            />
          </Form.Group>
        </div>
      );
      alertDialogPrimaryAction = () => {
        setAwaitingLambda(true);
        updateAdApprovalStage(MBApprovalStage.APPROVED);
      };
      alertDialogPrimaryLabel = "Got it!";
      alertDialogSecondaryAction = () => {
        setNotes("");
        selectRow(alertDialogActivatedRow, DESELECT_ROW);
        closeAlertDialog();
      };
      alertDialogSecondaryLabel = "Cancel";
    } else if (alertDialogType === MBAlertDialogType.CHANGES_REQUESTED && alertDialogActivatedRow) {
      /*
       * REQUEST CHANGES
       */
      const adNames = currentLevelSelections.map(ad => (ad as AdRow).ad_name).join(", ");

      alertDialogVariant = AlertDialogVariants.INFO;
      alertDialogTitle = "We've flagged this ad as 'Not Approved'";
      alertDialogIcon = <MdClose size={72} style={{ color: Danger800 }} />;
      alertDialogBody = (
        <div className="inputFieldBody">
          <div className="inputFieldDescription">
            <div className="inputFieldDescriptionHeader">
              Your team will be notified this ad is not approved:
            </div>
            <div className="inputFieldDescriptionText">{adNames}</div>
          </div>
          <Form.Group>
            <Form.Label className="inputFieldFormLabel">
              {"Tell us why this is not approved (required):"}
            </Form.Label>
            <Form.Control
              className="inputFieldFormControl"
              as="textarea"
              value={notes}
              placeholder="For example: 'Incorrect copy, please fix typo'"
              onChange={e => {
                setNotes(e.target.value);
              }}
            />
          </Form.Group>
        </div>
      );
      alertDialogPrimaryAction = () => {
        setAwaitingLambda(true);
        updateAdApprovalStage(MBApprovalStage.CHANGES_REQUESTED, true);
      };
      alertDialogPrimaryLabel = "Got it!";
      alertDialogSecondaryAction = () => {
        setNotes("");
        selectRow(alertDialogActivatedRow, DESELECT_ROW);
        closeAlertDialog();
      };
      alertDialogSecondaryLabel = "Cancel";
    } else if (alertDialogType === MBAlertDialogType.STATUS_REMOVED && alertDialogActivatedRow) {
      /*
       * REMOVE STATUS
       */
      const adNames = currentLevelSelections.map(ad => (ad as AdRow).ad_name).join(", ");

      alertDialogVariant = AlertDialogVariants.INFO;
      alertDialogTitle = "Approval status removed";
      alertDialogIcon = <MdRefresh size={72} />;
      alertDialogBody = (
        <div className="inputFieldBody">
          <div className="inputFieldDescription">
            <div className="inputFieldDescriptionHeader">
              Your team will be notified that this ad is now awaiting approval:
            </div>
            <div className="inputFieldDescriptionText">{adNames}</div>
          </div>
        </div>
      );
      alertDialogPrimaryAction = () => {
        setAwaitingLambda(true);
        updateAdApprovalStage(MBApprovalStage.IN_REVIEW);
      };
      alertDialogPrimaryLabel = "Got it!";
      alertDialogSecondaryAction = () => {
        selectRow(alertDialogActivatedRow, DESELECT_ROW);
        closeAlertDialog();
      };
      alertDialogSecondaryLabel = "Cancel";
    } else if (alertDialogType === MBAlertDialogType.DELETE) {
      /*
       * DELETE
       */
      /*
       * campaignRowsToDelete, adSetRowsToDelete, and adRowsToDelete are maps used to
       * set up rows to delete. campaignNames, adSetNames, and adNames are strings used
       * to display the names of the rows to delete in the alert dialog.
       */
      let campaignRowsToDelete: { [id: string]: CampaignRow } = {},
        adSetRowsToDelete: { [id: string]: AdSetRow } = {},
        adRowsToDelete: { [id: string]: AdRow } = {};

      /*
       * If alert dialog activated row is present, we are deleting that row
       * and any downstream rows. Otherwise, we take into account all selected
       * rows in else block.
       */
      if (alertDialogActivatedRow && selectedLevel === "ad") {
        const activeAdRow = alertDialogActivatedRow as AdRow;
        adRowsToDelete = { [activeAdRow.id]: activeAdRow };
      } else if (alertDialogActivatedRow && selectedLevel === "adset") {
        const activeAdSetRow = alertDialogActivatedRow as AdSetRow;
        adSetRowsToDelete = { [activeAdSetRow.id]: activeAdSetRow };

        // Looking for ad(s) that share ad set id with ad set selection
        adRows.forEach(adRow => {
          if (adRow.adset_id === activeAdSetRow.id) {
            adRowsToDelete[adRow.id] = adRow;
          }
        });
      } else if (alertDialogActivatedRow && selectedLevel === "campaign") {
        const activeCampaignRow = alertDialogActivatedRow as CampaignRow;
        campaignRowsToDelete = { [activeCampaignRow.id]: activeCampaignRow };

        // Looking for ad sets that share campaign id with campaign selection
        adSetRows.forEach(adSetRow => {
          if (adSetRow.campaign_id === activeCampaignRow.id) {
            adSetRowsToDelete[adSetRow.id] = adSetRow;
          }
        });
        const adSetRowsToDeleteArr = Object.values(adSetRowsToDelete);

        // Looking for ads that share ad set id with ad sets to delete
        adSetRowsToDeleteArr.forEach(adSetRow => {
          adRows.forEach(adRow => {
            if (adRow.adset_id === adSetRow.id) {
              adRowsToDelete[adRow.id] = adRow;
            }
          });
        });
      } else {
        // Set rows to delete based on selected rows within the selected level
        campaignRowsToDelete =
          selectedLevel === "campaign"
            ? ({ ...selectedRows.campaign } as { [id: string]: CampaignRow })
            : {};
        adSetRowsToDelete =
          selectedLevel === "adset"
            ? ({ ...selectedRows.adset } as { [id: string]: AdSetRow })
            : {};
        adRowsToDelete =
          selectedLevel === "ad" ? ({ ...selectedRows.ad } as { [id: string]: AdRow }) : {};
        const campaignRowsToDeleteArr = Object.values(campaignRowsToDelete) as CampaignRow[];

        // Looking for ad sets that share campaign id with campaign selection(s)
        campaignRowsToDeleteArr.forEach(row => {
          adSetRows.forEach(adSetRow => {
            if (adSetRow.campaign_id === row.id) {
              adSetRowsToDelete[adSetRow.id] = adSetRow;
            }
          });
        });
        const adSetRowsToDeleteArr = Object.values(adSetRowsToDelete) as AdSetRow[];

        // Looking for ad(s) that share ad set id with ad sets to delete
        adSetRowsToDeleteArr.forEach(adSetRow => {
          adRows.forEach(adRow => {
            if (adRow.adset_id === adSetRow.id) {
              adRowsToDelete[adRow.id] = adRow;
            }
          });
        });
      }

      // Convert maps to arrays for deletion and set names + description text for alert dialog
      const adRowsToDeleteArr = Object.values(adRowsToDelete) as AdRow[];
      const adNames = Object.values(adRowsToDelete)
        .map(row => row.ad_name)
        .join(", ");
      const adSetRowsToDeleteArr = Object.values(adSetRowsToDelete) as AdSetRow[];
      const adSetNames = adSetRowsToDeleteArr.map(row => row.adset_name).join(", ");
      const campaignRowsToDeleteArr = Object.values(campaignRowsToDelete) as CampaignRow[];
      const campaignNames = campaignRowsToDeleteArr.map(row => row.campaign_name).join(", ");
      const descriptionText = (
        <div>
          {adNames && <div>{`ad(s): ${adNames}`}</div>}
          {adNames && adSetNames && <br />}
          {adSetNames && <div>{`ad set(s): ${adSetNames}`}</div>}
          {adSetNames && campaignNames && <br />}
          {campaignNames && <div>{`campaign(s): ${campaignNames}`}</div>}
        </div>
      );

      /*
       * Will show a different deletion alert dialog based on whether the selected
       * level has children
       */
      let hasChildren =
        (campaignRowsToDeleteArr.length > 0 && adSetRowsToDeleteArr.length > 0) ||
        (adSetRowsToDeleteArr.length > 0 && adRowsToDeleteArr.length > 0);

      // Set up props for alert dialog modal
      alertDialogBody = (
        <div className="inputFieldBody">
          <div className="inputFieldDescription">
            <div className="inputFieldDescriptionHeader">You won't be able to undo this.</div>
            <div className="inputFieldDescriptionText">{descriptionText}</div>
          </div>
        </div>
      );
      alertDialogPrimaryAction = () => {
        setAwaitingLambda(true);
        deleteDrafts(campaignRowsToDeleteArr, adSetRowsToDeleteArr, adRowsToDeleteArr);
      };
      alertDialogPrimaryLabel = "Delete";
      alertDialogSecondaryAction = () => {
        if (alertDialogActivatedRow) {
          selectRow(alertDialogActivatedRow, DESELECT_ROW);
        }
        closeAlertDialog();
      };
      alertDialogSecondaryLabel = "Cancel";

      // Set title and variant based on whether the selected level has children
      if (!hasChildren) {
        alertDialogVariant = AlertDialogVariants.WARNING;
        alertDialogTitle = `Delete ${selectedLevel}(s)?`;
        alertDialogIcon = <MdDelete size={72} />;
      } else {
        alertDialogVariant = AlertDialogVariants.ERROR;
        const innerText =
          selectedLevel === "campaign"
            ? `ad sets${adRowsToDeleteArr.length ? " and ads" : ""}`
            : "ads";
        alertDialogTitle = `This will delete all of the ${innerText} within this ${selectedLevel}!`;
        alertDialogIcon = <MdSmsFailed size={72} />;
      }
    } else if (alertDialogType === MBAlertDialogType.EDIT && alertDialogActivatedRow && goToTab) {
      /*
       * EDIT
       * TODO: Revisit this portion later. For now we won't allow editing of campaigns and ad sets.
       */
      alertDialogVariant = AlertDialogVariants.ERROR;
      alertDialogTitle = `If you make any edits to this ${formatSelectedLevel(
        selectedLevel
      )}, all downstream ${
        selectedLevel === "campaign" ? "Ad Sets and Ads" : "Ads"
      } will be deleted.`;
      alertDialogIcon = <MdSmsFailed size={72} />;
      alertDialogBody = (
        <div className="inputFieldBody">
          <div className="inputFieldDescription">
            <div className="inputFieldDescriptionHeader">You won't be able to undo this.</div>
          </div>
        </div>
      );
      alertDialogPrimaryAction = () => {
        if (selectedLevel === "campaign") {
          const campaignToEdit = alertDialogActivatedRow as CampaignRow;
          // Downstream content will be deleted in lambda call for saving edits
          setCreationTabSelections({ Campaign: true, "Ad Set": false, Ad: false });
          setMetaCampaignForm({
            ...metaCampaignForm,
            campaign_name: campaignToEdit.campaign_name,
            objective: OUTCOME_SALES,
            special_ad_categories: campaignToEdit.special_ad_categories,
            budget_type: campaignToEdit.lifetime_budget
              ? Budget.CAMPAIGN_LIFETIME_BUDGET
              : campaignToEdit.daily_budget
              ? Budget.CAMPAIGN_DAILY_BUDGET
              : Budget.AD_SET_BUDGET,
            budget: campaignToEdit.lifetime_budget || campaignToEdit.daily_budget || null,
            bid_strategy: campaignToEdit.bid_strategy || null,
            // ...row
          });
        } else if (selectedLevel === "adset") {
          setCreationTabSelections({ Campaign: false, "Ad Set": true, Ad: false });
          setMetaAdSetForm({
            ...metaAdSetForm,
            adset_name: "test edit ad set",
            // ...row
          });
        }
        setTabIndex(0);
        setSelectionsSubmitted(true);
        goToTab(PageTab.CREATE);
      };
      alertDialogPrimaryLabel = "Got it!";
      alertDialogSecondaryAction = () => {
        closeAlertDialog();
      };
      alertDialogSecondaryLabel = "Cancel";
    }

    return {
      alertDialogVariant,
      alertDialogTitle,
      alertDialogIcon,
      alertDialogBody,
      alertDialogPrimaryAction,
      alertDialogPrimaryLabel,
      alertDialogSecondaryAction,
      alertDialogSecondaryLabel,
    };
  }, [
    adRows,
    adSetRows,
    alertDialogActivatedRow,
    alertDialogType,
    campaignRows,
    closeAlertDialog,
    notes,
    selectRow,
    selectedLevel,
    selectedRows,
    setCreationTabSelections,
    updateAdApprovalStage,
    deleteDrafts,
    updateClientFacingStatus,
    publishToAdsManager,
    setTabIndex,
    setMetaCampaignForm,
    setMetaAdSetForm,
    metaAdSetForm,
    metaCampaignForm,
    setSelectionsSubmitted,
    goToTab,
  ]);

  const alertDialogComponent = useMemo(() => {
    return (
      alertDialogType && (
        <AlertDialog
          variant={alertDialogVariant}
          className="mbAlertDialog"
          show={true}
          onHide={() => {
            if (alertDialogActivatedRow) {
              selectRow(alertDialogActivatedRow, DESELECT_ROW);
            }
            closeAlertDialog();
          }}
          icon={alertDialogIcon}
          title={alertDialogTitle}
          body={alertDialogBody}
          primaryAction={alertDialogPrimaryAction}
          primaryButtonLabel={alertDialogPrimaryLabel}
          secondaryAction={alertDialogSecondaryAction}
          secondaryButtonLabel={alertDialogSecondaryLabel}
          loading={awaitingLambda}
        />
      )
    );
  }, [
    alertDialogActivatedRow,
    alertDialogBody,
    alertDialogIcon,
    alertDialogPrimaryAction,
    alertDialogPrimaryLabel,
    alertDialogSecondaryAction,
    alertDialogSecondaryLabel,
    alertDialogTitle,
    alertDialogType,
    alertDialogVariant,
    closeAlertDialog,
    selectRow,
    awaitingLambda,
  ]);

  return {
    alertDialogType,
    alertDialogActivatedRow,
    openAlertDialog,
    alertDialogComponent,
  };
};

// REVIEW MODAL
interface ReviewModalParams {
  reviewModalVariant: ReviewModalVariant;
  selectedAdAccount: AdAccountInfo;
  selectedLevel: string;
  isInternal: boolean;
  selectRow: (row: MetaBuyingTableRow, remove?: boolean) => void;
  openAlertDialog?: (type: MBAlertDialogType, row?: MetaBuyingTableRow) => void;
  goToTab?: (tab: PageTab) => void;
  setTabIndex?: React.Dispatch<React.SetStateAction<number>>;
  setCreationTabSelections?: React.Dispatch<
    React.SetStateAction<{
      Campaign: boolean;
      "Ad Set": boolean;
      Ad: boolean;
    }>
  >;
  metaAdForm?: any;
  setMetaAdForm?: (value: any) => any;
  setSelectionsSubmitted?: React.Dispatch<React.SetStateAction<boolean>>;
}
export interface UseReviewModal {
  setReviewModalActivatedRow: React.Dispatch<React.SetStateAction<MetaBuyingTableRow | undefined>>;
  reviewModalSetSelectedTab: React.Dispatch<React.SetStateAction<ReviewModalTab>>;
  reviewModalComponent: JSX.Element | undefined;
}
export const useReviewModal = ({
  reviewModalVariant,
  selectedAdAccount,
  selectedLevel,
  isInternal,
  selectRow,
  openAlertDialog,
  goToTab,
  setTabIndex = () => {},
  setCreationTabSelections = () => {},
  metaAdForm,
  setMetaAdForm = () => {},
  setSelectionsSubmitted = () => {},
}: ReviewModalParams): UseReviewModal => {
  const [reviewModalActivatedRow, setReviewModalActivatedRow] = useState<MetaBuyingTableRow>();
  const [reviewModalSelectedTab, reviewModalSetSelectedTab] = useState<ReviewModalTab>(
    ReviewModalTab.NONE
  );

  const { reviewModalCampaign, reviewModalAdSet, reviewModalAd } = useMemo(() => {
    return {
      /*
        Set campaign based on selected level. If level is ad and user is internal,
        choose campaign based on ad's campaign_id. If level is adset and user is internal,
        choose campaign based on adset's campaign_id.
      */
      reviewModalCampaign:
        selectedLevel === "campaign" ? (reviewModalActivatedRow as CampaignRow) : undefined,
      /*
        Set adset based on selected level. If level is ad and user is internal,
        choose ad set based on ad's adset_id.
      */
      reviewModalAdSet:
        selectedLevel === "adset" ? (reviewModalActivatedRow as AdSetRow) : undefined,
      // Set ad based on selected level.
      reviewModalAd: selectedLevel === "ad" ? (reviewModalActivatedRow as AdRow) : undefined,
    };
  }, [reviewModalActivatedRow, selectedLevel]);

  const reviewModalComponent = useMemo(() => {
    return (
      reviewModalActivatedRow && (
        <ReviewModal
          isInternal={isInternal}
          variant={reviewModalVariant}
          accountID={selectedAdAccount.account_id}
          businessManager={selectedAdAccount.business_manager}
          selectedTab={reviewModalSelectedTab}
          setSelectedTab={reviewModalSetSelectedTab}
          onHide={() => {
            setReviewModalActivatedRow(undefined);
            selectRow(reviewModalActivatedRow, DESELECT_ROW);
          }}
          campaign={reviewModalCampaign}
          adset={reviewModalAdSet}
          ad={reviewModalAd}
          approve={() => {
            if (reviewModalVariant === ReviewModalVariant.DRAFT && openAlertDialog) {
              openAlertDialog(MBAlertDialogType.APPROVED, reviewModalActivatedRow);
              setReviewModalActivatedRow(undefined);
            }
          }}
          disapprove={() => {
            if (reviewModalVariant === ReviewModalVariant.DRAFT && openAlertDialog) {
              openAlertDialog(MBAlertDialogType.CHANGES_REQUESTED, reviewModalActivatedRow);
              setReviewModalActivatedRow(undefined);
            }
          }}
          publishAsPaused={() => {
            // TODO: update logic to handle publishing as paused
            if (reviewModalVariant === ReviewModalVariant.DRAFT && openAlertDialog) {
              openAlertDialog(MBAlertDialogType.PUBLISH_AS_PAUSED, reviewModalActivatedRow);
              setReviewModalActivatedRow(undefined);
            }
          }}
          remove={() => {
            if (reviewModalVariant === ReviewModalVariant.DRAFT && openAlertDialog) {
              openAlertDialog(MBAlertDialogType.DELETE, reviewModalActivatedRow);
              setReviewModalActivatedRow(undefined);
            }
          }}
          edit={() => {
            if (reviewModalVariant === ReviewModalVariant.DRAFT && openAlertDialog && goToTab) {
              setReviewModalActivatedRow(undefined);
              // TODO: Revisit setting the form data
              if (selectedLevel === "ad") {
                const adRow = reviewModalActivatedRow as AdRow;
                setCreationTabSelections({
                  Campaign: false,
                  "Ad Set": false,
                  Ad: true,
                });
                setTabIndex(0);
                setMetaAdForm({
                  ...metaAdForm,
                  ad_id: adRow.id,
                  meta_id: adRow.meta_id,
                  ad_name: adRow.ad_name,
                  adset_id: adRow.adset_id,
                  adset_name: adRow.adset_name,
                  campaign_id: adRow.campaign_id,
                  campaign_name: adRow.campaign_name,
                  facebook_page_id: adRow.page_id,
                  // instagram_account_id: adRow.instagram_actor_id,
                  creative_format: adRow.format,
                  creative_asset:
                    adRow.format === "IMAGE"
                      ? {
                          hash: adRow.image_hash,
                          name: "Placeholder",
                        }
                      : {
                          video_id: adRow.video_id,
                          hash: adRow.image_hash,
                          title: "Placeholder",
                        },
                  message: adRow.message,
                  headline: adRow.headline,
                  description: adRow.description,
                  call_to_action_type: adRow.call_to_action,
                  url: adRow.link?.split("?")[0],
                  url_params: `?${adRow.link?.split("?")[1]}`,
                  status: adRow.adsmanager_status,
                  client_facing: adRow.client_facing,
                  approval_stage: adRow.approval_stage,
                });
                setSelectionsSubmitted(true);
                goToTab(PageTab.CREATE);
              } else {
                openAlertDialog(MBAlertDialogType.EDIT, reviewModalActivatedRow);
              }
            }
          }}
        />
      )
    );
  }, [
    reviewModalActivatedRow,
    isInternal,
    reviewModalVariant,
    reviewModalSelectedTab,
    reviewModalCampaign,
    reviewModalAdSet,
    reviewModalAd,
    selectRow,
    openAlertDialog,
    goToTab,
    selectedLevel,
    setCreationTabSelections,
    setMetaAdForm,
    setTabIndex,
    setSelectionsSubmitted,
    selectedAdAccount,
    metaAdForm,
  ]);

  return {
    setReviewModalActivatedRow,
    reviewModalSetSelectedTab,
    reviewModalComponent,
  };
};

// MESSAGE ICON
interface MessageIconProps {
  color: string;
}
export const MessageIcon = ({ color }: MessageIconProps): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path
        d="M15.3897 2H2.58454C1.70853 2 1 2.70853 1 3.59742V18L4.19485 14.8052H15.3897C16.2786 14.8052 17 14.0709 17 13.2077V3.59742C17 2.70853 16.2786 2 15.3897 2ZM9.37359 13.6457C8.71659 13.6457 8.2657 13.1562 8.2657 12.525C8.2657 11.8937 8.72947 11.3913 9.37359 11.3913C10.0564 11.3913 10.4815 11.868 10.4815 12.525C10.4815 13.182 10.0564 13.6457 9.37359 13.6457ZM9.91465 10.5539H8.781L7.80193 3.26248H10.8422L9.91465 10.5539Z"
        fill={color}
      />
    </svg>
  );
};

interface ChangeLogModalParams {
  variant: "drafts" | "published";
  accountId: string;
  selectRow: (row: MetaBuyingTableRow, remove?: boolean) => void;
}
export interface UseChangeLogModal {
  setChangeLogModalActivatedRow: React.Dispatch<React.SetStateAction<AdRow | undefined>>;
  changeLogModalComponent: JSX.Element | undefined;
}
export const useChangeLogModal = ({
  variant,
  accountId,
  selectRow,
}: ChangeLogModalParams): UseChangeLogModal => {
  const [changeLogModalActivatedRow, setChangeLogModalActivatedRow] = useState<AdRow>();

  const changeLogModalComponent = useMemo(() => {
    return (
      changeLogModalActivatedRow && (
        <ChangeLogModal
          variant={variant}
          accountId={accountId}
          adId={changeLogModalActivatedRow.id}
          onHide={() => {
            setChangeLogModalActivatedRow(undefined);
            selectRow(changeLogModalActivatedRow, DESELECT_ROW);
          }}
        />
      )
    );
  }, [variant, accountId, changeLogModalActivatedRow, selectRow, setChangeLogModalActivatedRow]);

  return {
    setChangeLogModalActivatedRow,
    changeLogModalComponent,
  };
};

export const selectionsToArray = (selections: Record<string, boolean>): string[] =>
  R.keys(R.pickBy((val: boolean) => val, selections));

export const dimensionToFieldName = (dimension: string): string =>
  `${dimension.charAt(0).toUpperCase()}${dimension.slice(1)}`.replace("_", " ");

export const filterSegmentationIds = (
  nameFields: Record<string, any>,
  dimensions: Record<string, any>,
  segmentationDimensionId?: string | number
): string[] =>
  segmentationDimensionId
    ? R.keys(
        R.filter(
          (segments: Record<string, any>) =>
            R.all(key => segments[key] === nameFields[key], R.keys(segments)),
          dimensions[segmentationDimensionId].options
        )
      )
    : [];

export const generateFormattedDate = (dateFormat?: string): string => {
  let formattedDate = TODAY;
  try {
    formattedDate = R.pipe(Dfns.format(dateFormat))(new Date());
  } catch (e) {
    console.error(`Invalid date format: ${dateFormat}`);
  }
  return formattedDate;
};

export const generateNameFields = (
  namingConvention: number[],
  allNameFields: Record<string, any>
): Record<string, any> => {
  let fields = {};
  namingConvention.forEach(dimensionId => {
    fields[dimensionId] = allNameFields[dimensionId].value;
  });
  return fields;
};

export const filterInheritedFields = (fields: Record<string, any>): number[] =>
  R.keys(
    R.filter(
      (field: { value: string; inheritedFrom?: string }) => R.has("inheritedFrom", field),
      fields
    )
  ).map(field => Number(field));

export const formatAge = (age: number): string => `${age >= 65 ? "65+" : age}`;

export const mapLabelsByValues: Record<string, any> = R.pipe(
  //@ts-ignore
  R.indexBy(R.prop("value")),
  //@ts-ignore
  R.map(R.prop("label"))
);

export const mapRowsByProp = (prop: string, data: Record<string, any>[]): Record<string, any> =>
  data.reduce((map: Record<string, any>, row) => {
    map[row[prop]] = row;
    return map;
  }, {});

export const validateName = (
  thisName: string,
  thisLevel: Level,
  namingConventions: {
    campaign: number[];
    ad_group: number[];
    ad: number[];
  },
  dimensions: Record<string, any>,
  client: string,
  agency: string,
  segmentationLevel?: Level,
  segmentationLevelName?: string
): boolean => {
  const thisNameElements = thisName.split("_");
  const thisNamingConvention = namingConventions[thisLevel];
  let segLevelNameElements =
    segmentationLevel && segmentationLevelName ? segmentationLevelName.split("_") : [];
  let segLevelNamingConvention =
    segmentationLevel && segmentationLevelName ? namingConventions[segmentationLevel] : [];
  let isValid = true;

  if (
    thisNameElements.length !== thisNamingConvention.length ||
    (segmentationLevel &&
      segmentationLevelName &&
      segLevelNameElements.length !== segLevelNamingConvention.length)
  ) {
    isValid = false;
  } else {
    thisNameElements.forEach((thisElement, thisIndex) => {
      const dimension = dimensions[thisNamingConvention[thisIndex]];

      if (segmentationLevel && segmentationLevelName) {
        const segLevelElementIndex = R.findIndex(
          segLevelElement => segLevelElement === thisNamingConvention[thisIndex],
          segLevelNamingConvention
        );
        if (
          segLevelElementIndex > -1 &&
          segLevelNameElements[segLevelElementIndex] !== thisElement
        ) {
          isValid = false;
        }
      }

      switch (dimension.type) {
        case SEGMENTATION_ID:
          if (!R.includes(thisElement, R.keys(dimension.options))) {
            isValid = false;
          }
          break;
        case CLIENT:
          if (thisElement !== client) {
            isValid = false;
          }
          break;
        case AGENCY:
          if (thisElement !== agency) {
            isValid = false;
          }
          break;
        case SEGMENT_SELECTION:
          if (!R.includes(thisElement, dimension.options)) {
            isValid = false;
          }
          break;
        default:
          break;
      }
    });
  }

  return isValid;
};

export const capitalizeFirstLetter = (word: string): string =>
  `${word.charAt(0).toUpperCase()}${word.slice(1).toLowerCase()}`;

export const formatBudget = (budget: string | number | null): string =>
  !R.isNil(budget) ? `$${(Number(budget) / 100).toFixed(2)}` : "";

export const updateBudget = (
  value: string,
  updateFormField: (key: string, value: FormField) => any
): void => {
  let newVal: number | null = parseFloat(value.replace(/[^0-9]/g, ""));
  updateFormField("budget", newVal);
};

export const getCreationTabs = (creationTabSelections: {
  Campaign: boolean;
  "Ad Set": boolean;
  Ad: boolean;
  // @ts-ignore
}): CreationTab[] => R.keys(R.pickBy((val: boolean) => val, creationTabSelections));
// @ts-ignore

export const getAttributionOptions = (
  campaignObjective: Objective,
  optimizationGoal: ConversionGoal
): {
  click_through: number[];
  view_through: number[];
} => {
  const {
    ALL_OPTIONS_BOTH,
    ALL_OPTIONS_CLICK,
    ALL_OPTIONS_VIEW,
    NULL_OPTIONS_BOTH,
  } = ATTR_WINDOW_OPTIONS[campaignObjective];

  let attrWindowCombo = ATTR_WINDOW_COMBOS.OPTIONS_DEFAULT;
  if (!R.isNil(ALL_OPTIONS_BOTH) && R.includes(optimizationGoal, ALL_OPTIONS_BOTH)) {
    attrWindowCombo = ATTR_WINDOW_COMBOS.ALL_OPTIONS_BOTH;
  } else if (!R.isNil(ALL_OPTIONS_CLICK) && R.includes(optimizationGoal, ALL_OPTIONS_CLICK)) {
    attrWindowCombo = ATTR_WINDOW_COMBOS.ALL_OPTIONS_CLICK;
  } else if (!R.isNil(ALL_OPTIONS_VIEW) && R.includes(optimizationGoal, ALL_OPTIONS_VIEW)) {
    attrWindowCombo = ATTR_WINDOW_COMBOS.ALL_OPTIONS_VIEW;
  } else if (!R.isNil(NULL_OPTIONS_BOTH) && R.includes(optimizationGoal, NULL_OPTIONS_BOTH)) {
    attrWindowCombo = ATTR_WINDOW_COMBOS.NULL_OPTIONS_BOTH;
  }

  return attrWindowCombo;
};

export const removeIsoTZ = (time: string): string | null =>
  time ? time.substring(0, time.lastIndexOf("-")) : null;

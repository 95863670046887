import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { IndividualMonth } from "../IndividualMonth";
import { DateRange } from "../../../utils/types";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import * as R from "ramda";
import * as Dfns from "date-fns/fp";
import { DATE_FORMAT, makeMemoizedGetter } from "../../../utils/data";
import "./DPCalendar.scss";
import { FOCUS_OPTIONS, YEAR_MONTH_FORMAT } from "../DatePickerButton/DatePickerConstants";

const TODAY = Dfns.format(DATE_FORMAT, new Date());
const format = Dfns.format(DATE_FORMAT);

export interface DateInfo {
  date: string;
  display: string;
  span?: boolean;
  selectedLeft?: boolean;
  selectedRight?: boolean;
  lastWasFirst?: boolean;
  nextIsLast?: boolean;
  invalid?: boolean;
}

export interface MonthInfo {
  year: number;
  date: Date;
  dates: (DateInfo | null)[];
}

const getMonthInfo = makeMemoizedGetter({
  calculate: (parsedDate: Date): MonthInfo => {
    const startOfMonth = Dfns.startOfMonth(parsedDate);
    const endOfMonth = Dfns.endOfMonth(parsedDate);
    const dowStart = Dfns.getDay(startOfMonth);
    const dowEnd = Dfns.getDay(endOfMonth);
    const dates = Dfns.eachDayOfInterval({
      start: startOfMonth,
      end: endOfMonth,
    }).map(rawDate => {
      const date = format(rawDate);
      return { date, display: Dfns.format("d", rawDate) };
    });
    // Fill in days from other months with nulls
    dates.unshift(...new Array(dowStart).fill(null));
    dates.push(...new Array(6 - dowEnd).fill(null));
    return {
      year: parseInt(Dfns.format("yyyy", parsedDate)) || 2020,
      dates,
      date: startOfMonth,
    };
  },
  makeKey: Dfns.format("yyyy-MM"),
});

interface DPCalendarProps {
  start?: string;
  end?: string;
  focus?: typeof FOCUS_OPTIONS[number];
  onChange: (dateRange: Partial<DateRange>, focus: typeof FOCUS_OPTIONS[number]) => void;
  isOutsideRange: (date: string) => boolean;
  isDayBlocked: (date: string) => boolean;
  years: number[];
  currentMonthSecond?: boolean;
  maxDate?: string;
  comparison?: boolean;
}

export const DPCalendar: React.FC<DPCalendarProps> = ({
  start,
  end,
  focus,
  onChange,
  isOutsideRange,
  isDayBlocked,
  years,
  currentMonthSecond,
  maxDate,
  comparison = false,
}) => {
  const [selectedMonth, setSelectedMonth] = useState(start);
  const visibleMonthDigits = useRef<Set<string>>();
  const monthInfo = useMemo(() => {
    let parsedStart = Dfns.parseISO(selectedMonth || TODAY);
    let nextMonth = Dfns.addMonths(1, parsedStart);
    let thirdMonth = Dfns.addMonths(2, parsedStart);
    if (currentMonthSecond) {
      thirdMonth = nextMonth;
      nextMonth = parsedStart;
      parsedStart = Dfns.subMonths(1, parsedStart);
    }

    // possibly wrap as a memo
    const monthDigits = new Set<string>();
    monthDigits.add(Dfns.format(YEAR_MONTH_FORMAT, parsedStart));
    monthDigits.add(Dfns.format(YEAR_MONTH_FORMAT, nextMonth));
    monthDigits.add(Dfns.format(YEAR_MONTH_FORMAT, thirdMonth));
    visibleMonthDigits.current = monthDigits;

    return [getMonthInfo(parsedStart), getMonthInfo(nextMonth), getMonthInfo(thirdMonth)].map(
      (info: MonthInfo) => ({
        ...info,
        dates: info.dates.map(date => {
          if (!date) {
            return date;
          }
          return {
            ...date,
            invalid: isOutsideRange(date.date) || isDayBlocked(date.date),
          };
        }),
      })
    );
  }, [selectedMonth, isOutsideRange, isDayBlocked, currentMonthSecond]);

  useEffect(() => {
    const monthDigitSet = visibleMonthDigits.current;
    if (start && end) {
      const parsedToday = Dfns.parseISO(TODAY);
      const parsedStart = Dfns.parseISO(start);
      const parsedEnd = Dfns.parseISO(end);
      const startMonthYearStr = Dfns.format(YEAR_MONTH_FORMAT, parsedStart);
      const endMonthYearStr = Dfns.format(YEAR_MONTH_FORMAT, parsedEnd);

      if (
        monthDigitSet &&
        monthDigitSet.has(startMonthYearStr) &&
        monthDigitSet.has(endMonthYearStr)
      ) {
        return;
      }

      if (
        Dfns.differenceInCalendarMonths(parsedStart, parsedEnd) === 0 &&
        Dfns.differenceInCalendarMonths(parsedStart, parsedToday) === 0
      ) {
        setSelectedMonth(format(Dfns.subMonths(2, parsedStart)));
      } else if (Dfns.differenceInCalendarMonths(parsedStart, parsedToday) < 2) {
        setSelectedMonth(format(Dfns.subMonths(1, parsedStart)));
      } else {
        setSelectedMonth(start);
      }
    } else if (start) {
      const parsedStart = Dfns.parseISO(start);
      const startMonthYearStr = Dfns.format(YEAR_MONTH_FORMAT, parsedStart);
      if (monthDigitSet && monthDigitSet.has(startMonthYearStr)) {
        return;
      }
      setSelectedMonth(format(parsedStart));
    } else if (end) {
      const parsedEnd = Dfns.parseISO(end);
      const endMonthYearStr = Dfns.format(YEAR_MONTH_FORMAT, parsedEnd);
      if (monthDigitSet && monthDigitSet.has(endMonthYearStr)) {
        return;
      }
      setSelectedMonth(format(Dfns.subMonths(2, parsedEnd)));
    }
  }, [start, end]);

  const adjustSelectedMonth = useCallback(
    (adjustment: number, date = Dfns.parseISO(selectedMonth || TODAY)) => {
      setSelectedMonth(R.pipe(Dfns.addMonths(adjustment), format)(date));
    },
    [selectedMonth]
  );
  const onSelect = useCallback(
    (date: string) => {
      if (!end && start) {
        const newRange: Partial<DateRange> = {};
        if (date < start) {
          newRange.start = date;
          newRange.end = start;
        } else if (date >= start) {
          newRange.start = start;
          newRange.end = date;
        } else {
          newRange.start = start;
        }
        onChange(newRange, "START");
      } else if (!start && end) {
        const newRange: Partial<DateRange> = {};
        if (date <= end) {
          newRange.start = date;
          newRange.end = end;
        } else if (date > end) {
          newRange.start = end;
          newRange.end = date;
        } else {
          newRange.end = end;
        }
        onChange(newRange, "END");
      } else if (start && end && end === start && date > start) {
        let newRange: Partial<DateRange> = {
          start: start,
          end: date,
        };
        onChange(newRange, "START");
      } else if (start && end && end === start && date < start) {
        let newRange: Partial<DateRange> = {
          start: date,
          end: end,
        };
        onChange(newRange, "END");
      } else if (date === start) {
        let newRange: Partial<DateRange> = {
          start: start,
          end: date,
        };
        onChange(newRange, "START");
      } else if (date === end) {
        let newRange: Partial<DateRange> = {
          start: date,
          end: end,
        };
        onChange(newRange, "END");
      } else if (focus === "END") {
        let newRange: Partial<DateRange> = {
          end: date,
        };
        if (!start || start <= date) {
          newRange.start = start;
        }
        onChange(newRange, "START");
      } else {
        let newRange: Partial<DateRange> = {
          start: date,
        };
        if (!end || end >= date) {
          newRange.end = end;
        }
        onChange(newRange, "END");
      }
    },
    [start, end, focus, onChange]
  );
  return (
    <div className="DPCalendar">
      {/* TODO: we could probably take a flag and do n months tbh */}
      <IndividualMonth
        monthInfo={monthInfo[0]}
        range={{ start, end }}
        onSelect={onSelect}
        focus={focus}
        setSelectedMonth={month => adjustSelectedMonth(0, month)}
        years={years}
        maxDate={maxDate}
        classname={comparison ? "comparison" : ""}
      />
      <IndividualMonth
        monthInfo={monthInfo[1]}
        range={{ start, end }}
        onSelect={onSelect}
        focus={focus}
        setSelectedMonth={month => adjustSelectedMonth(-1, month)}
        years={years}
        maxDate={maxDate}
        classname={comparison ? "comparison" : ""}
      />
      <IndividualMonth
        monthInfo={monthInfo[2]}
        range={{ start, end }}
        onSelect={onSelect}
        focus={focus}
        setSelectedMonth={month => adjustSelectedMonth(-2, month)}
        years={years}
        maxDate={maxDate}
        classname={comparison ? "comparison" : ""}
      />
      <div className="navArrow left" onClick={() => adjustSelectedMonth(-1)}>
        <MdChevronLeft />
      </div>
      <div className="navArrow right" onClick={() => adjustSelectedMonth(1)}>
        <MdChevronRight />
      </div>
    </div>
  );
};

export default DPCalendar;

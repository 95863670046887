import { KnowYourCustomerData } from "@blisspointmedia/bpm-types/dist/KnowYourCustomer";
import { BPMTable, Header } from "../Components";
import { formatDecimal, formatNumber, formatPercent } from "../utils/format-utils";
import { TabKey } from "./customerInsightsConstants";

interface CustomerInsightsTableProps {
  tab: TabKey;
  fieldData: KnowYourCustomerData[];
  companyName: string;
}

const CustomerInsightsTable: React.FC<CustomerInsightsTableProps> = ({
  tab,
  fieldData,
  companyName,
}) => {
  const tableHeaders: Header[] = [
    {
      name: "levels",
      label: "",
      flex: true,
      nonInteractive: true,
      renderer: ({ levels }) => {
        return <span className="tableLevels">{levels}</span>;
      },
    },
  ];

  switch (tab) {
    case TabKey.CUSTOMER_BASE:
      tableHeaders.push(
        {
          flex: true,
          nonInteractive: true,
          name: "clientPopulation",
          label: `${companyName}'s Population`,
          renderer: (data: KnowYourCustomerData) => formatNumber(Math.round(data.clientPopulation)),
        },
        {
          flex: true,
          nonInteractive: true,
          name: "clientPopulationFraction",
          label: "Distribution",
          renderer: (data: KnowYourCustomerData) => formatPercent(data.clientPopulationFraction, 2),
        }
      );
      break;
    case TabKey.INDEX:
      tableHeaders.push(
        {
          flex: true,
          nonInteractive: true,
          name: "index",
          label: "Index",
          renderer: (data: KnowYourCustomerData) =>
            formatDecimal(data.clientPopulationFraction / data.tuPopulationFraction, 2),
        },
        {
          flex: true,
          nonInteractive: true,
          name: "clientPopulationFraction",
          label: `${companyName}'s Distribution`,
          renderer: (data: KnowYourCustomerData) => formatPercent(data.clientPopulationFraction, 2),
        },
        {
          flex: true,
          nonInteractive: true,
          name: "tuPopulationFraction",
          label: "US Market Distribution",
          renderer: (data: KnowYourCustomerData) => formatPercent(data.tuPopulationFraction, 2),
        }
      );
      break;
    case TabKey.PENETRATION:
      tableHeaders.push(
        {
          flex: true,
          nonInteractive: true,
          name: "penetration",
          label: "Penetration",
          renderer: (data: KnowYourCustomerData) =>
            formatPercent(data.clientPopulation / data.tuPopulation, 1),
        },
        {
          flex: true,
          nonInteractive: true,
          name: "clientPopulation",
          label: `${companyName}'s Population`,
          renderer: (data: KnowYourCustomerData) => formatNumber(Math.round(data.clientPopulation)),
        },
        {
          flex: true,
          nonInteractive: true,
          name: "tuPopulation",
          label: "US Market Population",
          renderer: (data: KnowYourCustomerData) => formatNumber(Math.round(data.tuPopulation)),
        }
      );
      break;
  }

  return <BPMTable data={fieldData} headers={tableHeaders} filterBar={false} />;
};

export default CustomerInsightsTable;

import { engineeringUsers, productUsers } from "./experiment-utils";

// Experiment to allow clients to access customer insights tool
export const shouldEnableSearchShopping = (userEmail: string): boolean => {
  const users: string[] = [
    ...productUsers,
    ...engineeringUsers,

    "andrea.cruz@tinuiti.com",
    "annemarie.malazita@tinuiti.com",
    "holly.block@tinuiti.com",
    "patrick.ortenzio@tinuiti.com",
  ];
  return users.includes(userEmail);
};

import "./SegmentationLabeling.scss";
import React, { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import * as R from "ramda";
import Papa from "papaparse";
import cn from "classnames";
import SegmentationLabelingTable, { SegmentRowEdit } from "./SegmentationLabelingTable";
import { CampaignRow } from "@blisspointmedia/bpm-types/dist/CampaignLabelingTool";
import {
  awaitJSON,
  CrossChannelLambdaFetch,
  MiscLambdaFetch,
  pollS3,
  SegmentationMappingLambdaFetch,
} from "../utils/fetch-utils";
import { useCompanyInfo } from "../redux/company";
import { useSetError } from "../redux/modals";
import { RouteComponentProps } from "@reach/router";
import {
  Button,
  ButtonType,
  FilterBar,
  FullPageSpinner,
  OverlayTrigger,
  Spinner,
  Tooltip,
} from "../Components";
import { useStateFunction } from "../utils/hooks/useData";
import PendingSegmentationChanges from "./PendingSegmentationChanges";
import { CustomSegmentsData } from "./SegmentationMapping";
import IncompleteUpdatesModal from "./IncompleteUpdatesModal";
import pako from "pako";
import { Nav } from "react-bootstrap";
import { MdDelete, MdErrorOutline, MdOutlineFileDownload, MdSave, MdSend } from "react-icons/md";
import { ButtonFrameworkVariant } from "../Components/ButtonFramework";
import { Mixpanel, MxE } from "../utils/mixpanelWrapper";
import { formatNumber } from "../utils/format-utils";
import { downloadJSONToCSV } from "../utils/download-utils";

const LABELED_EDITS_STORAGE_KEY = "labeledCustomSegmentsEdits";
const UNLABELED_EDITS_STORAGE_KEY = "unlabeledCustomSegmentsEdits";

export interface SegmentRow extends CampaignRow {
  ad_group_id?: string;
  ad_group_name?: string;
  ad_id?: string;
  ad_name?: string;
  ingested_seg_id?: string;
  pg_seg_id?: string;
  Channel?: string;
  Platform?: string;
  channel_id?: number;
  platform_id?: number;
}

export interface SegmentEditsMap {
  [campaign_id: string]: SegmentRow;
}

interface SegmentationLabelingProps {
  data: any[] | null;
  customSegments: CustomSegmentsData[] | undefined;
  fetchRawData: () => void;
  dataGranularity: "ad" | "ad_group" | "campaign";
}

enum TabOptions {
  LABELED = "Labeled",
  UNLABELED = "Unlabeled",
}

interface UpdatedRow {
  campaign_id: string;
  ad_group_id?: string;
  ad_id?: string;
  segmentMap: Record<string, number>;
}

const saveToLocalStorage = (key, data) => {
  try {
    const compressed = Papa.unparse([data]);
    localStorage.setItem(key, compressed);
  } catch (e) {
    console.error(`Error saving to local storage ${key}:`, e);
  }
};

export interface EditsMapAction {
  type:
    | "UPDATE_SEGMENT_EDIT"
    | "DELETE_SEGMENT_EDIT"
    | "SET_STATE_FROM_CACHE"
    | "SET_MAP"
    | "RESET";
  cachedState?: SegmentEditsMap;
  editsMap?: SegmentEditsMap;
  row?: SegmentRow;
  edits?: SegmentRowEdit;
  campaign_key?: string;
  segmentName?: string;
}

const editsMapReducer = (state: SegmentEditsMap, action: EditsMapAction) => {
  const { type, cachedState, editsMap, row, edits, campaign_key, segmentName } = action;

  switch (type) {
    case "UPDATE_SEGMENT_EDIT": {
      if (!campaign_key) {
        throw new Error("No campaign_key provided for updating segment edit operation.");
      }
      if (!row) {
        throw new Error("No row provided for updating segment edit operation.");
      }

      const newState = R.clone(state);

      // If the row is not already in the state's edits map, add it
      if (!newState[campaign_key]) {
        newState[campaign_key] = R.clone(row);
      }
      const newStateRow = newState[campaign_key];

      // Set the row's edits to equal the provided edits
      newStateRow.edits = { ...newStateRow.edits, ...edits };
      const newStateRowEdits = newStateRow.edits;

      // If any values in the rows edits are equivilant to the initial values, delete them
      for (const segmentName of Object.keys(newStateRowEdits)) {
        const segmentEdit: { label: string; value: number } = newStateRowEdits?.[segmentName];

        if (segmentEdit.label === row[segmentName]) {
          delete newStateRowEdits[segmentName];
        }
      }

      // If the rows edits is empty, remove the row
      if (Object.keys(newStateRowEdits).length === 0) {
        delete newState[campaign_key];
      }

      return newState;
    }
    case "DELETE_SEGMENT_EDIT": {
      if (!campaign_key) {
        throw new Error("No campaign_key provided for deleting segment edit operation.");
      }

      if (!segmentName) {
        throw new Error("No segmentName provided for deleting segment edit operation.");
      }

      const newState = R.clone(state);
      const updatedRow = newState[campaign_key];
      const updatedRowEdits = updatedRow.edits ?? {};
      delete updatedRowEdits[segmentName];

      if (Object.keys(updatedRowEdits).length === 0) {
        delete newState[campaign_key];
      }

      return newState;
    }
    case "SET_STATE_FROM_CACHE":
      return { ...cachedState, ...state };
    case "SET_MAP":
      return { ...editsMap };
    case "RESET":
      return {};
  }
};

const SegmentationLabeling = ({
  data,
  customSegments,
  fetchRawData,
  dataGranularity,
}: SegmentationLabelingProps & RouteComponentProps): JSX.Element => {
  const { cid } = useCompanyInfo();
  const setError = useSetError();

  const [labeledEditsMap, dispatchLabeledEditsMap] = useReducer(editsMapReducer, {});
  const [unlabeledEditsMap, dispatchUnlabeledEditsMap] = useReducer(editsMapReducer, {});
  const [selectedRows, setSelectedRows] = useState<Record<string, SegmentRow>>({});
  const [activeTab, setActiveTab] = useState<string>(TabOptions.UNLABELED);
  const [showIncompleteUpdatesModal, setShowIncompleteUpdatesModal] = useState(false);
  const [cacheRetrieved, setCacheRetrieved] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    if (!cacheRetrieved) {
      const cachedLabeledEdits = localStorage.getItem(`${cid}_${LABELED_EDITS_STORAGE_KEY}`);
      if (cachedLabeledEdits) {
        try {
          const parsedCachedLabeledEdits = Papa.parse(cachedLabeledEdits)[0];
          dispatchLabeledEditsMap({
            type: "SET_STATE_FROM_CACHE",
            cachedState: parsedCachedLabeledEdits,
          });
        } catch (e) {
          localStorage.removeItem(`${cid}_${LABELED_EDITS_STORAGE_KEY}`);
          console.error("Error parsing cached labeled edits:", e);
        }
      }

      const cachedUnlabeledEdits = localStorage.getItem(`${cid}_${UNLABELED_EDITS_STORAGE_KEY}`);
      if (cachedUnlabeledEdits) {
        try {
          const parsedCachedUnlabeledEdits = Papa.parse(cachedUnlabeledEdits)[0];
          dispatchUnlabeledEditsMap({
            type: "SET_STATE_FROM_CACHE",
            cachedState: parsedCachedUnlabeledEdits,
          });
        } catch (e) {
          localStorage.removeItem(`${cid}_${UNLABELED_EDITS_STORAGE_KEY}`);
          console.error("Error parsing cached unlabeled edits:", e);
        }
      }

      setCacheRetrieved(true);
    }
  }, [cacheRetrieved, cid]);

  useEffect(() => {
    if (Object.keys(labeledEditsMap).length > 0) {
      saveToLocalStorage(`${cid}_${LABELED_EDITS_STORAGE_KEY}`, labeledEditsMap);
    }
  }, [cid, labeledEditsMap]);

  useEffect(() => {
    if (Object.keys(unlabeledEditsMap).length > 0) {
      saveToLocalStorage(`${cid}_${UNLABELED_EDITS_STORAGE_KEY}`, unlabeledEditsMap);
    }
  }, [cid, unlabeledEditsMap]);

  const filterBarOptions = useMemo(() => {
    const uniqueSegmentOptions =
      customSegments?.map(segment => ({
        name: segment.segmentName,
        label: segment.segmentName,
      })) ?? [];

    const options = [
      ...uniqueSegmentOptions,
      { name: "account_id", label: "Account ID" },
      { name: "account_name", label: "Account Name" },
      { name: "campaign_id", label: "Campaign ID" },
      { name: "campaign_name", label: "Campaign" },
    ];

    if (dataGranularity === "ad_group" || dataGranularity === "ad") {
      options.push({ name: "ad_group_id", label: "Ad Group ID" });
      options.push({ name: "ad_group_name", label: "Ad Group" });
    }

    if (dataGranularity === "ad") {
      options.push({ name: "ad_id", label: "Ad ID" });
      options.push({ name: "ad_name", label: "Ad" });
    }
    return options;
  }, [customSegments, dataGranularity]);

  const unlabeledData = useMemo(() => {
    return data?.filter(row => !(row.pg_seg_id || row.ingested_seg_id));
  }, [data]);

  const labeledData = useMemo(() => {
    return data?.filter(row => row.pg_seg_id || row.ingested_seg_id);
  }, [data]);

  const incompleteEditsMap = useMemo(() => {
    if (!customSegments) {
      return {};
    }

    const unlabeledEditsMapCopy = R.clone(unlabeledEditsMap);
    const segmentKeys = Object.keys(unlabeledEditsMap);
    const segmentNames = customSegments.map(segment => segment.segmentName);

    for (const key of segmentKeys) {
      const value = unlabeledEditsMapCopy[key];

      let hasAllSegments = true;
      for (const segmentName of segmentNames) {
        hasAllSegments =
          hasAllSegments && value.edits && (value.edits[segmentName] ?? value[segmentName]);
      }

      if (hasAllSegments) {
        delete unlabeledEditsMapCopy[key];
      }
    }

    return unlabeledEditsMapCopy;
  }, [customSegments, unlabeledEditsMap]);

  const completeUnlabeledEditsMap = useMemo(() => {
    if (!customSegments) {
      return {};
    }

    const unlabeledEditsMapCopy = R.clone(unlabeledEditsMap);
    const segmentKeys = Object.keys(unlabeledEditsMap);
    const segmentNames = customSegments.map(segment => segment.segmentName);

    for (const key of segmentKeys) {
      const value = unlabeledEditsMapCopy[key];

      let hasAllSegments = true;
      for (const segmentName of segmentNames) {
        hasAllSegments =
          hasAllSegments && value.edits && (value.edits[segmentName] ?? value[segmentName]);
      }

      if (!hasAllSegments) {
        delete unlabeledEditsMapCopy[key];
      }
    }

    return unlabeledEditsMapCopy;
  }, [customSegments, unlabeledEditsMap]);

  const dataToUse = useMemo(() => {
    return activeTab === TabOptions.UNLABELED
      ? {
          data: unlabeledData,
          editsMap: unlabeledEditsMap,
          dispatchEditsMap: dispatchUnlabeledEditsMap,
        }
      : { data: labeledData, editsMap: labeledEditsMap, dispatchEditsMap: dispatchLabeledEditsMap };
  }, [labeledData, labeledEditsMap, activeTab, unlabeledData, unlabeledEditsMap]);

  const [filter, setFilter] = useStateFunction<(line) => boolean>(() => true);

  const filteredData = useMemo(() => {
    return dataToUse.data?.filter(filter) || [];
  }, [filter, dataToUse]);

  const [showPendingChanges, setShowPendingChanges] = useState<boolean>(false);
  const hasPendingChanges = useMemo(() => {
    return !R.isEmpty(unlabeledEditsMap) || !R.isEmpty(labeledEditsMap);
  }, [labeledEditsMap, unlabeledEditsMap]);

  const pendingChangesCount = useMemo(() => {
    return Object.keys(dataToUse.editsMap).length;
  }, [dataToUse.editsMap]);

  const clearAllChanges = () => {
    dispatchUnlabeledEditsMap({
      type: "RESET",
    });
    dispatchLabeledEditsMap({
      type: "RESET",
    });
    localStorage.removeItem(`${cid}_${LABELED_EDITS_STORAGE_KEY}`);
    localStorage.removeItem(`${cid}_${UNLABELED_EDITS_STORAGE_KEY}`);
    setShowPendingChanges(false);
  };

  const [saving, setSaving] = useState(false);

  const save = useCallback(async () => {
    if (!customSegments) {
      return;
    }

    setSaving(true);

    try {
      const combinedValues = [
        ...Object.values(completeUnlabeledEditsMap ?? {}),
        ...Object.values(labeledEditsMap ?? {}),
      ];
      const updatedRows = combinedValues?.map(edit => {
        const segmentMap: Record<string, number> = {};
        customSegments.forEach(segment => {
          if (!edit.edits) {
            return;
          }

          // Grab the id from the edits
          let updatedValueSegmentId = edit.edits[segment.segmentName]?.value;

          // If it hasn't been edited, build the segment map with the id of the original value
          if (!updatedValueSegmentId) {
            const segmentValueName = edit[segment.segmentName];
            const match = segment.values.find(val => val.valueName === segmentValueName);
            updatedValueSegmentId = match?.valueId;
          }

          segmentMap[segment.segmentId] = updatedValueSegmentId;
        });

        return {
          ad_id: edit.ad_id,
          ad_group_id: edit.ad_group_id,
          campaign_id: edit.campaign_id,
          account_id: edit.account_id,
          segmentMap,
        };
      });

      const sendToLambda = async (rows: UpdatedRow[]) => {
        const compressedPayload = pako.gzip(JSON.stringify(rows), { to: "string" });
        const base64Payload = btoa(compressedPayload);

        if (process.env.NODE_ENV === "development") {
          console.info(
            "In development mode, posting updateManualSegmentationMaps to non-comet endpoint"
          );
          const body = {
            company: cid,
            updatedRows: base64Payload,
          };
          await SegmentationMappingLambdaFetch("/updateManualSegmentationMaps", {
            method: "POST",
            body,
          });
        } else {
          const lambdaArgs = {
            company: cid,
            updatedRows: base64Payload,
          };

          const result = await MiscLambdaFetch("/kickOffLambda", {
            method: "POST",
            body: {
              fileType: "txt",
              lambdaArgs,
              lambdaName: "segmentationmapping-updateManualSegmentationMaps",
            },
          });

          const uuid = await result.json();
          const content = await pollS3({
            autoDownload: false,
            bucket: "bpm-cache",
            filename: `${uuid}.txt`,
            mimeType: "text/plain",
            noTimeout: true,
          });
          const textContent = await content.text();
          const lambdaResult = JSON.parse(textContent);

          if (result.status !== 200) {
            throw new Error(textContent);
          }

          if (lambdaResult.cometErrorMessage) {
            throw new Error(lambdaResult.cometErrorMessage);
          }
        }
      };

      const batchRows: UpdatedRow[] = [];
      let byteSizeNum = 0;
      for (const updatedRow of updatedRows) {
        const compressedPayload = pako.gzip(JSON.stringify(updatedRow), { to: "string" });
        const base64Payload = btoa(compressedPayload);
        const byteSize = new TextEncoder().encode(base64Payload).length;
        if (byteSizeNum + byteSize > 155000 && batchRows.length > 0) {
          await sendToLambda(batchRows);
          batchRows.length = 0;
          byteSizeNum = 0;
        }

        batchRows.push(updatedRow);
        byteSizeNum += byteSize;
      }

      if (batchRows.length > 0) {
        await sendToLambda(batchRows);
      }

      await fetchRawData();
      dispatchUnlabeledEditsMap({
        type: "SET_MAP",
        editsMap: incompleteEditsMap,
      });
      dispatchLabeledEditsMap({
        type: "RESET",
      });
      setSelectedRows({});

      localStorage.removeItem(`${cid}_${LABELED_EDITS_STORAGE_KEY}`);
      saveToLocalStorage(`${cid}_${UNLABELED_EDITS_STORAGE_KEY}`, incompleteEditsMap);
      setSaving(false);
    } catch (e) {
      setSaving(false);
      const reportError = e as Error;
      setError({ message: reportError.message, reportError });
    }
  }, [
    customSegments,
    completeUnlabeledEditsMap,
    labeledEditsMap,
    fetchRawData,
    incompleteEditsMap,
    cid,
    setError,
  ]);

  const validChangesCount = useMemo(() => {
    return Object.keys(completeUnlabeledEditsMap).length + Object.keys(labeledEditsMap).length;
  }, [completeUnlabeledEditsMap, labeledEditsMap]);

  const refreshCrossChannel = useCallback(() => {
    (async () => {
      try {
        setRefreshing(true);
        Mixpanel.track(MxE.REFRESH_CC_VIEWS);
        const res = await CrossChannelLambdaFetch("/refreshCrossChannelViews", {
          method: "POST",
          body: { company: cid },
        });
        const resJson = await awaitJSON(res);
        const { message, status } = resJson;
        setRefreshing(false);
        setError({
          variant: status === "IN_PROGRESS" ? "warning" : "success",
          title: status === "IN_PROGRESS" ? "Publish already in progress" : "Publish started",
          message: <div>{message}</div>,
        });
      } catch (e: any) {
        setRefreshing(false);
        setError({
          message: `Failed to publish to dashboards for ${cid}. Error: ${e.message}`,
          reportError: e,
        });
      }
    })();
  }, [cid, setError]);

  const granularityPrettyName = useMemo(() => {
    switch (dataGranularity) {
      case "campaign":
        return "Campaign";
      case "ad_group":
        return "Ad Group";
      case "ad":
        return "Ad";
      default:
        return "";
    }
  }, [dataGranularity]);

  return (
    <div className="segmentationLabeling">
      <IncompleteUpdatesModal
        show={showIncompleteUpdatesModal}
        handleClose={() => setShowIncompleteUpdatesModal(false)}
        saveChanges={save}
        incompleteEditsMap={incompleteEditsMap}
        customSegments={customSegments}
        validChangesCount={validChangesCount}
        dataGranularity={dataGranularity}
      />
      <Nav
        activeKey={activeTab}
        onSelect={value => {
          const valueTab = value as keyof typeof value;

          if (!valueTab || valueTab === activeTab) {
            return;
          }

          setActiveTab(valueTab);
          setSelectedRows({});
        }}
      >
        <Nav.Item key={TabOptions.UNLABELED}>
          <Nav.Link eventKey={TabOptions.UNLABELED}>{TabOptions.UNLABELED}</Nav.Link>
        </Nav.Item>
        <Nav.Item key={TabOptions.LABELED}>
          <Nav.Link eventKey={TabOptions.LABELED}>{TabOptions.LABELED}</Nav.Link>
        </Nav.Item>
      </Nav>
      <div className="segmentationLabelingBody">
        {dataToUse.data ? (
          <>
            <div className="filterBarContainer">
              <FilterBar
                options={filterBarOptions}
                lines={dataToUse.data || []}
                onFilter={setFilter}
                hasAdvanced
              />
              <div className="pendingChangesControls">
                <OverlayTrigger
                  placement={OverlayTrigger.PLACEMENTS.TOP.RIGHT}
                  delay={500}
                  overlay={
                    <Tooltip>
                      {hasPendingChanges
                        ? "Please save any labeling changes before publishing to other parts of the app."
                        : "Publish the latest mapping changes to dashboards now.\nChanges will auto-update by the next day if not manually published."}
                    </Tooltip>
                  }
                >
                  <Button
                    type={ButtonType.OUTLINED}
                    variant={ButtonFrameworkVariant.LEADING_ICON}
                    icon={<MdSend color="#6B2DEF" />}
                    onClick={() => refreshCrossChannel()}
                    disabled={hasPendingChanges}
                  >
                    {refreshing ? <Spinner /> : "Publish to dashboards"}
                  </Button>
                </OverlayTrigger>
                <Button
                  type={ButtonType.FILLED}
                  variant={ButtonFrameworkVariant.ICON_ONLY}
                  icon={<MdOutlineFileDownload />}
                  onClick={() => {
                    const { data, editsMap } = dataToUse;
                    const date = new Date();
                    const csvData = R.map(row => editsMap[row.campaign_key] ?? row, data || []).map(
                      row => {
                        const cleanedRow = {
                          ...row,
                          segmentation_id: row.ingested_seg_id ?? row.pg_seg_id,
                        };
                        delete cleanedRow.ingested_seg_id;
                        delete cleanedRow.pg_seg_id;
                        delete cleanedRow.platform_id;
                        delete cleanedRow.channel_id;
                        return cleanedRow;
                      }
                    );

                    downloadJSONToCSV(
                      csvData,
                      `SegmentationLabeling ${cid} ${activeTab.toLowerCase()} ${date.toUTCString()}`
                    );
                  }}
                />
              </div>
            </div>
            <SegmentationLabelingTable
              data={filteredData}
              customSegments={customSegments}
              editsMap={dataToUse.editsMap}
              selectedRows={selectedRows}
              dispatchEditsMap={dataToUse.dispatchEditsMap}
              setSelectedRows={setSelectedRows}
              dataGranularity={dataGranularity}
            />
          </>
        ) : (
          <FullPageSpinner />
        )}
        <div className="segmentationLabelingControls">
          <div className="pendingChangesControls">
            <div className="unsavedChanges">
              <span className="unlabeledCounter">
                <span className={cn({ unsavedCount: hasPendingChanges })}>
                  {formatNumber(labeledData?.length ?? 0 + pendingChangesCount)}
                </span>
                /{formatNumber((unlabeledData?.length ?? 0) + (labeledData?.length ?? 0))}{" "}
                {granularityPrettyName}s Labeled
              </span>
              {hasPendingChanges && (
                <Button
                  type={ButtonType.OUTLINED}
                  variant={ButtonFrameworkVariant.LEADING_ICON}
                  design="secondary"
                  className="danger"
                  icon={<MdErrorOutline />}
                  onClick={() => {
                    setShowPendingChanges(true);
                  }}
                >
                  See {pendingChangesCount} Unsaved Change{pendingChangesCount > 1 ? "s" : ""}
                </Button>
              )}
            </div>
            {hasPendingChanges && (
              <>
                <Button
                  type={ButtonType.OUTLINED}
                  variant={ButtonFrameworkVariant.LEADING_ICON}
                  onClick={clearAllChanges}
                  disabled={!hasPendingChanges}
                  icon={<MdDelete color="#6B2DEF" />}
                >
                  Discard Changes
                </Button>
                <Button
                  type={ButtonType.FILLED}
                  variant={ButtonFrameworkVariant.LEADING_ICON}
                  onClick={
                    Object.keys(incompleteEditsMap).length > 0
                      ? () => {
                          setShowIncompleteUpdatesModal(true);
                        }
                      : save
                  }
                  disabled={saving || !hasPendingChanges}
                  icon={<MdSave />}
                >
                  {saving ? <Spinner /> : "Save"}
                </Button>
              </>
            )}
          </div>
        </div>
        {showPendingChanges && hasPendingChanges && (
          <PendingSegmentationChanges
            incompleteUnlabeledEditsMap={incompleteEditsMap}
            completeUnlabeledEditsMap={completeUnlabeledEditsMap}
            labeledEditsMap={labeledEditsMap}
            dispatchUnlabeledEditsMap={dispatchUnlabeledEditsMap}
            dispatchLabeledEditsMap={dispatchLabeledEditsMap}
            setShowPendingChanges={setShowPendingChanges}
            customSegments={customSegments}
            dataGranularity={dataGranularity}
          />
        )}
      </div>
    </div>
  );
};

export default SegmentationLabeling;

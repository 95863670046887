import "./ModelInsights.scss";
import { useCallback, useMemo, useState } from "react";
import WidgetContainer from "../../Components/WidgetContainer";
import ChartContainer from "../../Components/ChartContainer";
import MoreInfoTooltip from "../../MMM/MoreInfo";
import { DownloadDropdown } from "../../Components/DownloadDropdown";
import * as R from "ramda";
import { downloadPNG, exportToExcel } from "../../utils/download-utils";
import { useDynamicYAxisWidth } from "../../Components/Charts/ChartUtils";
import { colorMap as colors } from "../../utils/colors";
import { numberFormatter, capitalizeWords, currencyFormatter } from "../../MMM/MMMUtils";
import { CartesianGrid, Line, LineChart as LC, Tooltip, YAxis, XAxis } from "recharts";
import AutoSizer from "react-virtualized-auto-sizer";
import {
  CARTESIAN_GRID_STROKE,
  CARTESIAN_GRID_STROKE_WIDTH,
  TICK_STYLE,
} from "../../Components/Charts/ChartConstants";
import LineChart from "../../Components/Charts/LineChart";
import { decayDataFormatter, saturationDataFormatterByName } from "./BrandImpactUtils";
import { formatModelOverviewOutcomeVariable } from "../BrandEquityUtils";
import { SaturationDataUnformatted } from "@blisspointmedia/bpm-types/dist/BrandEquity";
import { INACTIVE_COLOR } from "../../TVADCrossChannel/homePageConstants";

const MEDIAN_DOT_SIZE = 7;
const GRAY_COLOR = "#94a0b8";

interface ModelInsightsProps {
  company: string;
  groupByMetric: string;
  groupByLevel: string;
  saturationDataUnformatted: SaturationDataUnformatted[];
  decayDataUnformmatted: DecayDataUnformatted[];
}

interface DecayDataUnformatted {
  client: string;
  kpi: string;
  refresh_date: string;
  period: number;
  name: string;
  channel: string;
  platform: string;
  tactic: string;
  brand_nonbrand: string;
  incrementality_test: boolean;
  spend_carryover: number;
  max_lag: number;
}

const ModelInsights: React.FC<ModelInsightsProps> = ({
  company,
  groupByMetric,
  groupByLevel,
  saturationDataUnformatted,
  decayDataUnformmatted,
}) => {
  const [activeGroup, setActiveGroup] = useState(null);

  const saturationDataNew = useMemo(() => {
    if (saturationDataUnformatted.length !== 0) {
      let finalSaturationData;
      finalSaturationData = saturationDataFormatterByName(saturationDataUnformatted);
      return finalSaturationData;
    }
    return [];
  }, [saturationDataUnformatted]);

  const decayDataNew = useMemo(() => {
    if (decayDataUnformmatted.length !== 0) {
      let finalDecayData;
      finalDecayData = (decayDataFormatter(
        decayDataUnformmatted,
        "spend_carryover"
      ) as unknown) as {
        newD: any[];
        lines: any[];
      };
      return finalDecayData;
    }
    return [];
  }, [decayDataUnformmatted]);

  const [activeLine, setActiveLine] = useState(null);
  const [hoveredLegend, setHoveredLegend] = useState(null);

  const { xAxisHeight, yAxisWidth, setChartRef } = useDynamicYAxisWidth({
    xAxisHeightModifier: x => x + 5,
    yAxisWidthModifier: x => x + 3,
  });

  const colorMap = useMemo(() => {
    if (!R.isEmpty(saturationDataNew)) {
      return colors(saturationDataNew.newD);
    }
  }, [saturationDataNew]);

  const handleLegendClick = useCallback(
    (line, group?) => {
      if (activeLine === line.name) {
        setActiveLine(null);
        setActiveGroup(null);
      } else {
        setActiveLine(line.name);
        setActiveGroup(group.channel);
      }
    },
    [activeLine]
  );

  const handleLegendEnter = useCallback(
    line => {
      hoveredLegend === line.name ? setHoveredLegend(null) : setHoveredLegend(line.name);
    },
    [hoveredLegend]
  );

  const CustomizedDot = props => {
    const { cx, cy, stroke, payload, saturationData } = props;
    if (
      saturationData.medians[payload.name].dataPoint !== payload.spend ||
      saturationData.medians[payload.name].median === null
    ) {
      return null;
    }

    return (
      <svg>
        <polygon
          points={`${cx},${cy - MEDIAN_DOT_SIZE} ${cx + MEDIAN_DOT_SIZE},${cy} ${cx},${
            cy + MEDIAN_DOT_SIZE
          } ${cx - MEDIAN_DOT_SIZE},${cy}`}
          fill={stroke}
        />
      </svg>
    );
  };

  const tooltip = (
    <Tooltip
      content={({ active, label, payload }) => {
        if (active && payload && payload.length) {
          let items = R.sortBy(
            (item: any) => -Math.abs(item.value),
            payload?.map(item => ({
              label: item.name as string,
              value: item.value as number,
              color: item.stroke,
            })) || []
          );

          return (
            <div className="satChartTooltip">
              <div className="itemList">
                {items.map((item, i) => {
                  const { label, value, color } = item;
                  if (!value && value !== 0) {
                    return <></>;
                  }

                  return (
                    <div className="itemRow" key={i}>
                      <div className="itemWrapper dataLabel">
                        <div></div>
                        <div style={{ display: "flex", alignItems: "center", padding: "0 12px" }}>
                          <div className="line" style={{ backgroundColor: color }} />
                          <div className="name">{`${label}: `}</div>
                        </div>
                      </div>
                      <div className="itemWrapper">
                        <div className="satTooltipHeaderItem">Median Spend:</div>
                        <div className="value">
                          {currencyFormatter.format(
                            saturationDataNew.medians[payload[0].payload.name].median,
                            0
                          )}
                        </div>
                      </div>
                      <div className="itemWrapper">
                        <div className="satTooltipHeaderItem">Spend:</div>
                        <div className="value">
                          {currencyFormatter.format(payload[0].payload.spend, 0)}
                        </div>
                      </div>
                      <div className="itemWrapper">
                        <div className="satTooltipHeaderItem">{`${"Revenue"}:`}</div>
                        <div className="value">{payload[0].payload.response},</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        }
        return null;
      }}
      isAnimationActive={false}
    />
  );

  const excelDownloadSat = useCallback(() => {
    exportToExcel(saturationDataUnformatted, `${company}_saturation`);
  }, [saturationDataUnformatted, company]);

  const pngDownloadSat = useCallback(async () => {
    await downloadPNG(".section4 .left .chartContainer", `${company}_saturation`);
  }, [company]);

  const excelDownloadDecay = useCallback(() => {
    exportToExcel(decayDataUnformmatted, `${company}_decay`);
  }, [decayDataUnformmatted, company]);

  const pngDownloadDecay = useCallback(async () => {
    await downloadPNG(".section4 .right .chartContainer", `${company}_decay`);
  }, [company]);

  const buildLegendByGroupedArea = useCallback(
    groupedAreas => {
      return groupedAreas.map((group, i) => {
        return (
          <div className="legendItem" key={`satDecayLegenditem${i}`}>
            <div className="legendValue">
              <div>
                <div className="legendHeaderWrapper" style={{ cursor: "default" }}>
                  <div
                    className={`legendHeader ${
                      activeGroup === group.channel || activeGroup === null ? "" : "inactiveLegend"
                    }`}
                  >
                    {group.channel}
                  </div>
                </div>
                {group.areas.map((area, j) => {
                  const isActiveArea = activeLine === null ? true : activeLine === area.name;
                  const isHoveredLegend =
                    hoveredLegend === null ? true : hoveredLegend === area.name;
                  const activeColor = isActiveArea ? colorMap[area.name] : INACTIVE_COLOR;
                  const hoverColor = isHoveredLegend ? colorMap[area.name] : INACTIVE_COLOR;
                  return (
                    <div
                      key={`satDecayLegendsubHeader${j}`}
                      className="subHeaderWrapper"
                      onClick={() => handleLegendClick(area, group)}
                      onMouseEnter={() => handleLegendEnter(area)}
                      onMouseLeave={() => setHoveredLegend(null)}
                    >
                      <div
                        className="line"
                        style={{
                          backgroundColor: `${activeLine ? activeColor : hoverColor}`,
                        }}
                      />
                      <div className="subHeaderTextWrap">
                        <div className={`subHeader1 ${isActiveArea ? "" : "inactiveLegend"}`}>
                          {area.grouping.platform}
                        </div>
                        {area.grouping.platform &&
                          (area.grouping.tactic || area.grouping.brand_nonbrand) && (
                            <div style={{ whiteSpace: "pre-wrap" }}> – </div>
                          )}
                        <div className={`subHeader2 ${isActiveArea ? "" : "inactiveLegend"}`}>{`${
                          area.grouping.tactic ? area.grouping.tactic : ""
                        }${area.grouping.tactic && area.grouping.brand_nonbrand ? ", " : ""}${
                          area.grouping.brand_nonbrand ? area.grouping.brand_nonbrand : ""
                        }`}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      });
    },
    [activeLine, activeGroup, colorMap, handleLegendClick, handleLegendEnter, hoveredLegend]
  );

  return (
    <WidgetContainer
      collapsible
      header={`Model Insights for ${formatModelOverviewOutcomeVariable(groupByMetric)}`}
      subHeader="The model estimation process yields business insights related to channel saturation and the lagged nature of consumer response."
    >
      {
        <div className="section4">
          <div className="left">
            <ChartContainer
              enableHoverDesign
              title={`Saturation Curves (${formatModelOverviewOutcomeVariable(groupByMetric)})`}
              rightActions={
                <>
                  <MoreInfoTooltip size="reg">
                    Most media tactics will exhibit some form of diminishing returns, due to
                    additional investment reaching less qualified audiences and/or additional user
                    frequency having diminished impact. The model training process estimates the
                    shape of these saturation curves on a channel by channel basis. Examining these
                    curves yields insight into the scalability of each channel and the optimality of
                    incremental investments (see above section).
                  </MoreInfoTooltip>
                  <DownloadDropdown size="sm" onClickOptions={[excelDownloadSat, pngDownloadSat]} />
                </>
              }
            >
              {!R.isEmpty(saturationDataNew) && (
                <>
                  <div className="satWrapper">
                    <div className="satChartTitle">
                      {formatModelOverviewOutcomeVariable(groupByMetric)}
                    </div>
                    <AutoSizer>
                      {({ width, height }) => (
                        <LC width={width} height={height} ref={setChartRef}>
                          <CartesianGrid
                            stroke={CARTESIAN_GRID_STROKE}
                            strokeWidth={CARTESIAN_GRID_STROKE_WIDTH}
                            vertical={false}
                          />
                          <XAxis
                            axisLine={false}
                            dataKey="spend"
                            height={xAxisHeight}
                            tick={TICK_STYLE}
                            tickFormatter={val => `${currencyFormatter.format(val, 0)}`}
                            type="number"
                          />
                          <YAxis
                            axisLine={false}
                            dataKey="response"
                            tick={TICK_STYLE}
                            tickLine={false}
                            width={yAxisWidth}
                          />
                          {activeLine && tooltip}

                          {saturationDataNew.newD.map(line => {
                            const isActiveLine =
                              activeLine === null ? true : activeLine === line.name;
                            const isHoveredLegend =
                              hoveredLegend === null ? true : hoveredLegend === line.name;
                            const activeColor = isActiveLine ? colorMap[line.name] : GRAY_COLOR;
                            const hoverColor = isHoveredLegend ? colorMap[line.name] : GRAY_COLOR;

                            if (!isActiveLine && activeLine !== null) {
                              return null;
                            }
                            return (
                              <Line
                                data={line.data}
                                dataKey="response"
                                dot={<CustomizedDot saturationData={saturationDataNew} />}
                                isAnimationActive={false}
                                key={line.name}
                                name={line.name}
                                stroke={activeLine ? activeColor : hoverColor}
                                strokeWidth={3}
                              />
                            );
                          })}
                        </LC>
                      )}
                    </AutoSizer>
                  </div>
                  <div className="rightOfChart">
                    <div className="legend">
                      {buildLegendByGroupedArea(
                        R.sortBy(R.compose(R.toLower, R.prop("channel")))(
                          saturationDataNew.groupedLines
                        )
                      )}
                    </div>
                    <div className="medianLegendLabel">
                      <svg width="15" height="15" viewBox="0 0 15 15">
                        <polygon points="7.5,0 15,7.5 7.5,15 0,7.5" fill="#1F003F" />
                      </svg>
                      <div className="medianText">
                        Median <br /> spend
                      </div>
                    </div>
                    <div className="satChartXAxisTitle">Spend</div>
                  </div>
                </>
              )}
            </ChartContainer>
          </div>
          <div className="right">
            <ChartContainer
              enableHoverDesign
              rightActions={
                <>
                  <MoreInfoTooltip size="reg">
                    Few consumers, upon being exposed to an advertisement, drop everything and
                    respond immediately; there is almost always some lag between exposure and
                    response. The model training process estimates the extent of this response lag
                    on a channel by channel basis using a technique known as Adstock. Typically
                    (though not always), we see upper-funnel tactics having longer response lags and
                    lower-funnel tactics converting more quickly. Examining these curves can help us
                    understand channel properties and set appropriate expectations for channel
                    investments.
                  </MoreInfoTooltip>
                  <DownloadDropdown
                    size="sm"
                    onClickOptions={[excelDownloadDecay, pngDownloadDecay]}
                  />
                </>
              }
              title={`Effect Decays (${formatModelOverviewOutcomeVariable(groupByMetric)})`}
            >
              <div className="decayWrapper">
                <div className="decayChartTitle">Effect Carryover</div>
                {Object.keys(decayDataNew).length && (
                  <LineChart
                    customTooltipHeader={val => `Week ${val} Spend Carryover`}
                    data={decayDataNew?.newD}
                    lineDataKeys={decayDataNew?.lines}
                    groupedAreas={R.sortBy(R.compose(R.toLower, R.prop("channel")))(
                      decayDataNew?.groupedLines
                    )}
                    tooltipLabelFormatter={val => capitalizeWords(val)}
                    tooltipFormatter={val => (val ? `${numberFormatter.format(val, 0)}%` : val)}
                    usePercentageYAxis={true}
                    xAxisDataKey="date"
                    xAxisTickFormatter={val => `Week ${val}`}
                  />
                )}
              </div>
            </ChartContainer>
          </div>
        </div>
      }
    </WidgetContainer>
  );
};

export default ModelInsights;

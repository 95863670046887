import React, { useCallback, useMemo } from "react";
import * as R from "ramda";
import { KnowYourCustomerData } from "@blisspointmedia/bpm-types/dist/KnowYourCustomer";
import WidgetContainer from "../Components/WidgetContainer";
import { CBSA_TO_MSA_MAP, CbsaMap } from "../Components/CbsaMap";
import { Brand20, Brand30, Brand40, Brand50, Brand60, Brand70 } from "../utils/colors";
import { formatDecimal, formatPercent } from "../utils/format-utils";
import { TabKey, ViewOptions } from "./customerInsightsConstants";
import "../Components/ChartContainer/ChartContainer.scss";
import ChartContainer from "../Components/ChartContainer";
import {
  Button,
  ButtonFrameworkVariant,
  ButtonType,
  InfoTooltip,
  InfoTooltipDirection,
} from "../Components";
import { MdOutlineFileDownload } from "react-icons/md";
import { downloadJSONToCSV } from "../utils/download-utils";
import { useSetError } from "../redux/modals";
import { useCompanyInfo } from "../redux/company";
import CustomerInsightsTable from "./CustomerInsightsTable";
import {
  getColorForIndexedVolume,
  getThresholdBinsWithStdDevNonAdjusted,
} from "../BrandHealthMetrics/BrandHealthMetricsUtils";

const mapColors = [Brand20, Brand30, Brand40, Brand50, Brand60, Brand70];

interface CustomerInsightsGeoGroupProps {
  data: KnowYourCustomerData[];
  totalPopulation: number;
  header: string;
  tab: TabKey;
  graphView: ViewOptions;
}

const CustomerInsightsGeoGroup: React.FC<CustomerInsightsGeoGroupProps> = ({
  data,
  totalPopulation,
  header,
  tab,
  graphView,
}) => {
  const setError = useSetError();
  const { name: companyName } = useCompanyInfo();

  const knownPopulation = useMemo(() => {
    const unknownPop = data.find(row => row.levels === "Unknown")?.clientPopulation ?? 0;
    return totalPopulation - unknownPop;
  }, [data, totalPopulation]);

  const getMetricFromData = useCallback(
    (data: KnowYourCustomerData, tabInput: TabKey = tab): number => {
      switch (tabInput) {
        case TabKey.CUSTOMER_BASE:
          return data.clientPopulation / knownPopulation;
        case TabKey.INDEX:
          return data.clientPopulationFraction / data.tuPopulationFraction;
        case TabKey.PENETRATION:
          return data.clientPopulation / data.tuPopulation;
      }
    },
    [tab, knownPopulation]
  );

  const mappedData: Record<string, KnowYourCustomerData> = useMemo(
    () => R.indexBy(R.prop("levels"), data),
    [data]
  );

  const formatAsPercent = tab !== TabKey.INDEX;

  const thresholdBins = useMemo(
    () =>
      getThresholdBinsWithStdDevNonAdjusted(
        data.map(val =>
          getMetricFromData(mappedData[val.levels] ?? mappedData[CBSA_TO_MSA_MAP[val.levels]])
        )
      ),
    [data, getMetricFromData, mappedData]
  );

  const getRenderColor = useCallback(
    (cbsa: string): string => {
      const metricVal = getMetricFromData(mappedData[cbsa] ?? mappedData[CBSA_TO_MSA_MAP[cbsa]]);
      if (!metricVal) {
        console.error(`No value found for cbsa ${cbsa} / mapped msa ${CBSA_TO_MSA_MAP[cbsa]}`);
        return "transparent";
      }

      return getColorForIndexedVolume(metricVal, thresholdBins, mapColors);
    },
    [getMetricFromData, mappedData, thresholdBins]
  );

  const explanationText = useMemo(() => {
    switch (tab) {
      case TabKey.CUSTOMER_BASE:
        return "((Number of customers) /\n(ingested emails)) per MSA";
      case TabKey.INDEX:
        return "((percent of our customers) /\n(percent of total population)) per MSA";
      case TabKey.PENETRATION:
        return "((Number of customers) /\n(total population)) per MSA";
    }
  }, [tab]);

  const legendTitle = useMemo(() => {
    switch (tab) {
      case TabKey.CUSTOMER_BASE:
        return "Customers";
      case TabKey.INDEX:
        return "Index";
      case TabKey.PENETRATION:
        return "Penetration";
    }
  }, [tab]);

  const widgetTitle = useMemo(() => {
    switch (tab) {
      case TabKey.CUSTOMER_BASE:
        return "Customer Population per MSA";
      case TabKey.INDEX:
        return "Customer Index per MSA";
      case TabKey.PENETRATION:
        return "Customer Penetration per MSA";
    }
  }, [tab]);

  const tooltipCopy = useMemo(() => {
    switch (tab) {
      case TabKey.CUSTOMER_BASE:
        return "The percentage of your customer base residing in each MSA.";
      case TabKey.INDEX:
        return "The relative percentage of your customer base residing in each MSA, as compared to the percentage of the total U.S. market.";
      case TabKey.PENETRATION:
        return "The percentage of the total MSA population that is represented in your customer base.";
    }
  }, [tab]);

  const renderTable = useMemo(
    () => (
      <div className="geoTable">
        <CustomerInsightsTable tab={tab} fieldData={data} companyName={companyName} />
      </div>
    ),
    [companyName, data, tab]
  );

  const renderGraph = useMemo(
    () => (
      <div className="geoMap">
        <div className="cbsaMapContainer">
          <CbsaMap
            renderColor={getRenderColor}
            renderTooltip={cbsa => {
              const data = getMetricFromData(mappedData[cbsa] ?? mappedData[CBSA_TO_MSA_MAP[cbsa]]);
              return (
                <div>
                  {cbsa}: {formatAsPercent ? formatPercent(data, 1) : formatDecimal(data, 2)}
                </div>
              );
            }}
          />
        </div>
        <div className="legend">
          <div className="legendTitle">{legendTitle}</div>
          {thresholdBins.map((maxVal, index) => {
            if (index === 0) {
              return null;
            }

            const minVal = thresholdBins[index - 1];
            const color = mapColors[index - 1];

            const label = formatAsPercent
              ? `${formatPercent(minVal, 2)} – ${formatPercent(maxVal, 2)}`
              : `${formatDecimal(minVal, 2)} – ${formatDecimal(maxVal, 2)}`;

            return (
              <div className="legendItem" key={color}>
                <div className="color" style={{ background: color }} />
                <div className="range">{label}</div>
              </div>
            );
          })}
        </div>
      </div>
    ),
    [formatAsPercent, getMetricFromData, getRenderColor, legendTitle, mappedData, thresholdBins]
  );

  return (
    <WidgetContainer header={header} collapsible headerCollapsibleOnclick enableHoverDesign={false}>
      <div className="customerInsightsGroup fullWidth">
        <ChartContainer
          title={widgetTitle}
          enableHoverDesign
          rightActions={
            <>
              <InfoTooltip direction={InfoTooltipDirection.BOTTOM_LEFT}>
                {tooltipCopy}
                <p>Calculated as: {explanationText}</p>
              </InfoTooltip>
              <Button
                type={ButtonType.FILLED}
                variant={ButtonFrameworkVariant.ICON_ONLY}
                icon={<MdOutlineFileDownload />}
                onClick={() => {
                  if (!data) {
                    setError({ message: "No data available to download" });
                    return;
                  }

                  const formattedData = data.map(row => ({
                    "MSA Name": row.levels,
                    [`${companyName} Population`]: Math.round(row.clientPopulation),
                    [`${companyName} Distribution`]: row.clientPopulationFraction,
                    "US Market": Math.round(row.tuPopulation),
                    "US Market Distribution": row.tuPopulationFraction,
                    Index: getMetricFromData(row, TabKey.INDEX),
                    Penetration: getMetricFromData(row, TabKey.PENETRATION),
                  }));
                  const date = new Date();
                  downloadJSONToCSV(
                    formattedData,
                    `CustomerInsights ${companyName} MSAs ${date.toLocaleString()}`
                  );
                }}
              />
            </>
          }
        >
          {graphView === "showGraph" && renderGraph}
          {graphView === "showTable" && renderTable}
        </ChartContainer>
      </div>
    </WidgetContainer>
  );
};

export default CustomerInsightsGeoGroup;

import "./TextToggleButton.scss";
import React, { useMemo } from "react";
import { ButtonGroup } from "react-bootstrap";
import { ButtonFramework, ButtonFrameworkVariant } from "../ButtonFramework";
import { typedReactMemo } from "../../utils/types";

interface Option<T> {
  key: T;
  label?: string;
  renderLabel?: (string) => JSX.Element;
}

interface Props<T> {
  options: readonly (Option<T> | T)[];
  selectedOption: T;
  onChange: (key: T) => void;
  design?: "primary-light" | "primary-dark" | "secondary-light";
  size?: "sm" | "lg";
  className?: string;
  toolTipText?: string[];
}

export const TextToggleButton = typedReactMemo(
  <T extends string>({
    className = "",
    design = "primary-light",
    size = "lg",
    options,
    selectedOption,
    onChange,
    toolTipText,
  }: Props<T>): JSX.Element => {
    const buttonGroupClassName = useMemo(() => {
      let classes = ["toggle-button", design, size];

      if (className) {
        classes.push(className);
      }

      return classes.join(" ");
    }, [className, design, size]);

    return (
      <ButtonGroup toggle className={buttonGroupClassName}>
        {options.map((option, i) => {
          let key: T;
          let label: string | undefined;
          let renderLabel = label => <>{label}</>;
          if (typeof option === "string") {
            key = option;
            label = option;
          } else {
            ({ key } = option);
            label = option.label || option.key;
            if (option.renderLabel) {
              ({ renderLabel } = option);
            }
          }
          let active = selectedOption === key;
          const position = i === 0 ? "left-toggle" : "right-toggle"; // TODO: will need to revisit when there are more than 2 options

          return (
            <ButtonFramework
              className={`toggle-item ${design} ${position} ${active ? "selected" : "unselected"}`}
              key={key}
              variant={ButtonFrameworkVariant.NO_ICON}
              onClick={() => {
                if (!active) {
                  onChange(key);
                }
              }}
              tooltipText={toolTipText && toolTipText[i] ? toolTipText[i] : undefined}
            >
              {renderLabel(label)}
            </ButtonFramework>
          );
        })}
      </ButtonGroup>
    );
  }
);

import { Button, ButtonType } from "../Components";
import { Form, Modal } from "react-bootstrap";
import React, { useState } from "react";

export interface RenameModalProps {
  applyOnClick: (newName: string) => void;
  applyText: string;
  cancelText: string;
  disabled?: boolean;
  onCancel: () => void;
  onHide: () => void;
  placeholder: string;
  subTitle: string;
  title: string;
}

export const RenameModal: React.FC<RenameModalProps> = React.memo(
  ({
    applyOnClick,
    applyText,
    cancelText,
    disabled,
    onCancel,
    onHide,
    placeholder,
    subTitle,
    title,
  }) => {
    const [newName, setNewName] = useState("");
    return (
      <Modal show centered size="lg" onHide={onHide} className="renameSaveAsNewModal">
        <Modal.Header>
          <Modal.Title className="renameSaveAsHeader">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="renameSaveAsText">{subTitle}</div>
          <div className="renameSaveAsInputContainer">
            <Form.Control
              className="renameSaveAsInput"
              onChange={e => setNewName(e.target.value)}
              placeholder={placeholder}
              value={newName}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancelButton"
            design="secondary"
            onClick={onCancel}
            size={"lg"}
            type={ButtonType.EMPTY}
          >
            {cancelText}
          </Button>
          <Button
            className="applyButton"
            disabled={disabled}
            onClick={() => applyOnClick(newName)}
            size={"lg"}
            type={ButtonType.FILLED}
          >
            {applyText}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
);

export default RenameModal;

import "./Overview.scss";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import WidgetContainer from "../../Components/WidgetContainer";
import ChartContainer from "../../Components/ChartContainer";
import AreaChart from "../../Components/Charts/AreaChart";
import * as R from "ramda";
import { exportToExcel, downloadPNG } from "../../utils/download-utils";
import { DownloadDropdown, Dropdown, DropdownToggleType } from "../../Components";
import MoreInfo from "../../MMM/MoreInfo";
import LineChart from "../../Components/Charts/LineChart";
import { Channel1, Channel2, Channel3, Channel4 } from "../../utils/colors";
import { capitalizeWords, currencyFormatter, numberFormatter } from "../../MMM/MMMUtils";
import {
  DATE_GROUPING_OPTIONS_BRAND_EQUITY,
  formatTitleDatesToStartOfDayMddy,
  formatModelOverviewOutcomeVariable,
  groupChartDataByDate,
} from "../BrandEquityUtils";
import {
  IncrementalRoasTakeaways,
  BrandHealthTimeSeries,
} from "@blisspointmedia/bpm-types/dist/BrandEquity";
import { keyGeneratorForChannelTacticBrandGrouping } from "./BrandImpactUtils";

interface ModelOverview {
  client: string;
  kpi: string;
  kpi_type: string;
  date_granularity: string;
  refresh_date: string;
  refresh_timestamp: string;
  train_start_date: string;
  train_end_date: string;
  test_start_date: string;
  test_end_date: string;
  train_size: number;
  test_size: number;
  train_rsquared: number;
  test_rsquared: number;
  test_mape: number;
  test_mae: number;
  n_observations: number;
  mean_spend: number;
  median_spend: number;
}

interface WeeklyBrandHealthAreaChart {
  date: string;
  channel: string;
  platform?: string;
  tactic?: string;
  ["brand/nonbrand"]?: string;
  spend: number;
}

interface OverviewProps {
  company: string;
  groupByMetric: string;
  groupByLevel: string;
  weeklyBrandHealthTimeSeries: BrandHealthTimeSeries[];
  weeklyBrandHealthAreaChartSpend: Record<string, WeeklyBrandHealthAreaChart[]>;
  modelOverviewAll: Record<string, {}>;
  incrementalRoasTakeaways: IncrementalRoasTakeaways;
  isGqv: boolean;
}

const colors = [Channel1, Channel3, Channel2, Channel4];

export const Overview: React.FC<OverviewProps> = ({
  company,
  groupByMetric,
  groupByLevel,
  weeklyBrandHealthTimeSeries,
  weeklyBrandHealthAreaChartSpend,
  modelOverviewAll,
  incrementalRoasTakeaways,
  isGqv,
}) => {
  const [weeklyBrandHealthChartDateGrouping, setWeeklyBrandHealthChartDateGrouping] = useState(
    "Week"
  );
  const [weeklySpendChartDateGrouping, setWeeklySpendChartDateGrouping] = useState("Week");
  const { res: weeklyBrandHealthTimeSeriesByDate } = useMemo(() => {
    if (weeklyBrandHealthTimeSeries.length > 0) {
      const groupByDateGqvData = groupChartDataByDate(
        weeklyBrandHealthTimeSeries,
        weeklyBrandHealthChartDateGrouping
      );
      return { res: groupByDateGqvData };
    }
    return { res: [] };
  }, [weeklyBrandHealthChartDateGrouping, weeklyBrandHealthTimeSeries]);

  const keysWithoutDate = useMemo(
    () =>
      Object.keys(weeklyBrandHealthTimeSeries[0])
        .filter(key => key !== "date")
        .sort(),
    [weeklyBrandHealthTimeSeries]
  );

  const modelOverview = modelOverviewAll[groupByMetric] as ModelOverview;
  const [areaChartKeys, setAreaChartKeys] = useState<any[]>([]);

  const weeklyBrandHealthSpendAreaChart = useMemo(() => {
    if (Object.values(weeklyBrandHealthAreaChartSpend).length !== 0) {
      let finalAreaChart: any[] = [];
      switch (groupByLevel) {
        case "channel":
          finalAreaChart = weeklyBrandHealthAreaChartSpend[groupByMetric].reduce(
            (total, current) => {
              const existingRow = total.find(r => r.date === current.date);
              if (existingRow) {
                const levelName = current[groupByLevel];
                if (levelName in existingRow) {
                  existingRow[levelName] += current.spend;
                } else {
                  existingRow[levelName] = current.spend;
                }
              } else {
                const levelName = current[groupByLevel];
                total.push({ date: current.date, [levelName]: current.spend });
              }
              return total;
            },
            [] as any[]
          );
          break;
        case "channel, platform":
          finalAreaChart = weeklyBrandHealthAreaChartSpend[groupByMetric].reduce(
            (total, current) => {
              const existingRow = total.find(r => r.date === current.date);
              const key = keyGeneratorForChannelTacticBrandGrouping(
                current.channel,
                current.platform
              );
              if (existingRow) {
                if (key in existingRow) {
                  existingRow[key] += current.spend;
                } else {
                  existingRow[key] = current.spend;
                }
              } else {
                total.push({
                  date: current.date,
                  [key]: current.spend,
                });
              }
              return total;
            },
            [] as any[]
          );
          break;
        default:
          finalAreaChart = weeklyBrandHealthAreaChartSpend[groupByMetric].reduce(
            (total, current) => {
              const existingRow = total.find(r => r.date === current.date);

              const key = keyGeneratorForChannelTacticBrandGrouping(
                current.channel,
                current.platform,
                current.tactic || "",
                current["brand/nonbrand"] || ""
              );

              if (existingRow) {
                if (key in existingRow) {
                  existingRow[key] += current.spend;
                } else {
                  existingRow[key] = current.spend;
                }
              } else {
                total.push({
                  date: current.date,
                  [key]: current.spend,
                });
              }

              return total;
            },
            [] as any[]
          );
      }
      return finalAreaChart;
    }
    return [];
  }, [groupByLevel, groupByMetric, weeklyBrandHealthAreaChartSpend]);

  const { res: finalWeeklyBrandHealthAreaChartData } = useMemo(() => {
    if (weeklyBrandHealthSpendAreaChart.length > 0) {
      const groupByDateGqvData = groupChartDataByDate(
        weeklyBrandHealthSpendAreaChart,
        weeklySpendChartDateGrouping
      );
      return { res: groupByDateGqvData };
    }
    return { res: [] };
  }, [weeklySpendChartDateGrouping, weeklyBrandHealthSpendAreaChart]);

  useEffect(() => {
    const areaChartEntry = finalWeeklyBrandHealthAreaChartData[0];
    const filteredAreaChartEntry = Object.fromEntries(
      Object.entries(areaChartEntry).filter(([key]) => key !== "date" && key !== "covariates")
    );
    const finalAreaChartKeys = Object.entries(filteredAreaChartEntry).map(([key, row], index) => ({
      name: key
        .split("_")
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" "),
      dataKey: key,
    }));

    setAreaChartKeys(finalAreaChartKeys);
  }, [finalWeeklyBrandHealthAreaChartData]);

  const excelDownloadWeeklyBrandHealth = useCallback(() => {
    exportToExcel(weeklyBrandHealthTimeSeriesByDate, "weekly_brand_health");
  }, [weeklyBrandHealthTimeSeriesByDate]);

  const excelDownloadWeeklySpend = useCallback(() => {
    exportToExcel(finalWeeklyBrandHealthAreaChartData, "weekly_spend");
  }, [finalWeeklyBrandHealthAreaChartData]);

  const excelDownloadModelOverview = useCallback(() => {
    exportToExcel([modelOverview], "model_overview");
  }, [modelOverview]);

  const pngDownloadWeeklyBrandHealth = useCallback(async () => {
    await downloadPNG(".brandEquityleft .chartContainer", "weekly_brand_health");
  }, []);

  const pngDownloadWeeklySpend = useCallback(async () => {
    await downloadPNG(".brandEquityleft > .chartContainer:nth-child(2)", "weekly_spend");
  }, []);

  const pngDownloadModelOverview = useCallback(async () => {
    await downloadPNG(".brandEquityOverview .brandEquityRight", "weekly_spend");
  }, []);

  return (
    <WidgetContainer
      collapsible
      header={"Overview"}
      subHeader={
        <>
          Assessing the impact of paid media on discrete brand health outcomes
          <MoreInfo rightLabel="More info" size="sm">
            The goal is to understand and quantify the independent effect of paid media on a set of
            brand health outcomes. We employ a multivariate time series model that regresses each
            brand outcome on the set of paid media activities and a series of control variables. In
            the estimation, the model accounts for non-linearities in effects and the often
            significant timing differences between inputs and outputs.
          </MoreInfo>
        </>
      }
    >
      {!R.isEmpty(modelOverview) &&
        !R.isEmpty(weeklyBrandHealthTimeSeriesByDate) &&
        !R.isEmpty(finalWeeklyBrandHealthAreaChartData) &&
        !R.isEmpty(areaChartKeys) && (
          <div className="brandEquityOverview">
            <div className="brandEquityleft">
              <ChartContainer
                enableHoverDesign
                rightActions={
                  <DownloadDropdown
                    size="sm"
                    onClickOptions={[excelDownloadWeeklyBrandHealth, pngDownloadWeeklyBrandHealth]}
                  />
                }
                title={
                  <>
                    <Dropdown
                      type={DropdownToggleType.WIDGET_TITLE}
                      value={weeklyBrandHealthChartDateGrouping}
                      options={DATE_GROUPING_OPTIONS_BRAND_EQUITY}
                      onChange={option => setWeeklyBrandHealthChartDateGrouping(option)}
                    />
                    <div>Brand Health</div>
                  </>
                }
                titleAfterDashText={`${formatTitleDatesToStartOfDayMddy(
                  weeklyBrandHealthTimeSeries[0].date
                )} – ${formatTitleDatesToStartOfDayMddy(
                  weeklyBrandHealthTimeSeries[weeklyBrandHealthTimeSeries.length - 1].date
                )}`}
                beforeTooltipText={isGqv ? "Search Intent" : "Positive Response"}
                tooltipText={
                  isGqv
                    ? "Google query volume (GQV) measures changes in the volume of branded search intent over time. We use GQV to proxy for brand equity as it represents a funnel step between awareness and an actual site visit or install."
                    : "Positive response indicates the percentage of survey respondents expressing favorable perceptions or experiences related to each brand health metric."
                }
              >
                <LineChart
                  customTooltipHeader={val => `Week of ${formatTitleDatesToStartOfDayMddy(val)}`}
                  data={weeklyBrandHealthTimeSeriesByDate}
                  lineDataKeys={keysWithoutDate.map(key => ({
                    name: isGqv
                      ? key.toLocaleUpperCase()
                      : String(key).charAt(0).toUpperCase() + String(key).slice(1),
                    dataKey: key,
                    toolTipText: "ToolTipText",
                  }))}
                  colorMappings={keysWithoutDate.map((key, index) => ({
                    name: isGqv
                      ? key.toLocaleUpperCase()
                      : String(key).charAt(0).toUpperCase() + String(key).slice(1),
                    color: colors[index],
                  }))}
                  tooltipLabelFormatter={val =>
                    isGqv ? val.toLocaleUpperCase() : capitalizeWords(val)
                  }
                  tooltipFormatter={val =>
                    isGqv ? val.toFixed(2) : `${numberFormatter.format(val, 0)}%`
                  }
                  tooltipShape="line"
                  usePercentageYAxis={!isGqv}
                  useLeftYAxis={isGqv}
                  xAxisDataKey="date"
                  xAxisTickFormatter={val => formatTitleDatesToStartOfDayMddy(val)}
                />
              </ChartContainer>
              <ChartContainer
                enableHoverDesign
                rightActions={
                  <DownloadDropdown
                    size="sm"
                    onClickOptions={[excelDownloadWeeklySpend, pngDownloadWeeklySpend]}
                  />
                }
                title={
                  <>
                    <Dropdown
                      type={DropdownToggleType.WIDGET_TITLE}
                      value={weeklySpendChartDateGrouping}
                      options={DATE_GROUPING_OPTIONS_BRAND_EQUITY}
                      onChange={option => setWeeklySpendChartDateGrouping(option)}
                    />
                    <div>Spend</div>
                  </>
                }
                titleAfterDashText={`${formatTitleDatesToStartOfDayMddy(
                  weeklyBrandHealthSpendAreaChart[0].date
                )} – ${formatTitleDatesToStartOfDayMddy(
                  weeklyBrandHealthSpendAreaChart[weeklyBrandHealthSpendAreaChart.length - 1].date
                )}`}
              >
                <AreaChart
                  data={finalWeeklyBrandHealthAreaChartData}
                  xAxisDataKey="date"
                  xAxisTickFormatter={val => formatTitleDatesToStartOfDayMddy(val)}
                  dateGrouping="Week"
                  yAxisWidth={75}
                  yAxisTickFormatter={val => `${currencyFormatter.format(val, 0)}`}
                  tooltipFormatter={val => {
                    if (!val) {
                      return val;
                    }
                    return val < 1000
                      ? currencyFormatter.format(val, 0)
                      : currencyFormatter.format(val, 1);
                  }}
                  tooltipShape="line"
                  areas={areaChartKeys}
                ></AreaChart>
              </ChartContainer>
            </div>
            <div className="brandEquityRight">
              <ChartContainer
                enableHoverDesign
                title="Model Overview"
                rightActions={
                  <DownloadDropdown
                    size="sm"
                    onClickOptions={[excelDownloadModelOverview, pngDownloadModelOverview]}
                    menuOptions={["XLSX", "PNG"]}
                  />
                }
              >
                <div
                  className="chartBody"
                  style={{ display: "flex", flexDirection: "column", gap: "32px" }}
                >
                  <div className="brandEquityDataSection">
                    <div className="brandEquityDataSectionTitle">Training Data</div>
                    <div className="dataBody">
                      <div className="brandEquityDataSectionRow">
                        <div className="brandEquityLabel">First model observation:</div>
                        <div className="brandEquityValue">
                          {formatTitleDatesToStartOfDayMddy(modelOverview.train_start_date)}
                        </div>
                      </div>
                      <div className="brandEquityDataSectionRow">
                        <div className="brandEquityLabel">Last model observation:</div>
                        <div className="brandEquityValue">
                          {formatTitleDatesToStartOfDayMddy(modelOverview.test_end_date)}
                        </div>
                      </div>
                      <div className="brandEquityDataSectionRow">
                        <div className="brandEquityLabel">Average weekly spend:</div>
                        <div className="brandEquityValue">
                          {currencyFormatter.format(modelOverview.mean_spend, 0)}
                        </div>
                      </div>
                      <div className="brandEquityDataSectionRow">
                        <div className="brandEquityLabel">Outcome variable:</div>
                        <div className="brandEquityValue brandEquityOutcomeVariable">
                          {formatModelOverviewOutcomeVariable(groupByMetric)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="brandEquityDataSection">
                    <div className="brandEquityDataSectionTitle">Recency</div>
                    <div
                      className="dataBody"
                      style={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <div className="brandEquityDataSectionRow">
                        <div className="brandEquityLabel">Last refresh date:</div>
                        <div className="brandEquityValue">
                          {formatTitleDatesToStartOfDayMddy(modelOverview.refresh_date)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="brandEquityDataSection">
                    <div className="brandEquityDataSectionTitle">Statistics</div>
                    <div className="dataBody">
                      <div className="brandEquityDataSectionRow">
                        <div className="brandEquityLabel">
                          Out-of-sample MAPE:
                          <MoreInfo size="reg">
                            The percentage of variation in the unseen test data outcomes that is
                            accounted for by the model's explanatory variables. Formally, this is 1
                            - (residual sum of squares) / (total sum of squares)
                          </MoreInfo>
                        </div>
                        <div className="brandEquityValue">{modelOverview.test_mape.toFixed(2)}</div>
                      </div>
                      <div className="brandEquityDataSectionRow">
                        <div className="brandEquityLabel">
                          Out-of-sample MAE:
                          <MoreInfo size="reg">
                            The percentage of variation in the unseen test data outcomes that is
                            accounted for by the model's explanatory variables. Formally, this is 1
                            - (residual sum of squares) / (total sum of squares)
                          </MoreInfo>
                        </div>
                        <div className="brandEquityValue">{modelOverview.test_mae.toFixed(2)}</div>
                      </div>
                    </div>
                  </div>
                  <div className="brandEquityDataSection">
                    <div className="brandEquityDataSectionTitle">Key Results</div>
                    <div className="dataBody">
                      <div className="brandEquityDataSectionRow">
                        <div className="brandEquityLabel">
                          Incremental revenue from media-driven brand equity
                        </div>
                      </div>
                      <div className="brandEquityDataSectionRow">
                        <div className="brandEquityLabel">TTM Incremental Revenue:</div>
                        <div className="brandEquityValue">
                          $
                          {(incrementalRoasTakeaways.spendInducedBrandRevenue / 1000000).toFixed(2)}
                          M
                        </div>
                      </div>
                      <div className="brandEquityDataSectionRow">
                        <div className="brandEquityLabel">TTM Media Spend:</div>
                        <div className="brandEquityValue">
                          ${(incrementalRoasTakeaways.paidMediaSpend / 1000000).toFixed(2)}M
                        </div>
                      </div>
                      <div className="brandEquityDataSectionRow">
                        <div className="brandEquityLabel">TTM Incremental ROAS:</div>
                        <div className="brandEquityValue">
                          {incrementalRoasTakeaways.iRoasTtm.toFixed(2)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ChartContainer>
            </div>
          </div>
        )}
    </WidgetContainer>
  );
};

export default Overview;

import React, { useState, useEffect, useRef, useMemo } from "react";
import { Form, Tooltip, OverlayTrigger } from "react-bootstrap";
import { MdOutlineInsertLink } from "react-icons/md";
import { Dropdown, DropdownToggleType } from "../Dropdown";
import { Button, ButtonType } from "../Button/Button";
import QuestionCardTextAreaInput from "./QuestionCardTextArea";
import QuestionCardRadio from "./QuestionCardRadio";
import QuestionCardUploader from "./QuestionCardUploader";
import QuestionCardCheckbox from "./QuestionCardCheckbox";
import "./QuestionCard.scss";

export interface NestedOptionConfig {
  inputType: "textarea" | "checkbox";
  options?: string[];
  label?: string;
  placeholder?: string;
}

export interface NestedValues {
  [parentLabel: string]: { [nestedLabel: string]: string | boolean };
}

export interface QuestionCardProps {
  id: string;
  questionNumber: string;
  title: string;
  subtitle?: string;
  url?: string;
  children?: React.ReactNode;
  type: "radio" | "checkbox" | "textarea" | "upload" | "custom" | "dropdown";
  textLabel?: string;
  options?: string[];
  value?: any;
  textValue?: string;
  nestedValues?: NestedValues;
  placeholder?: string;
  hideAdditionalInfo?: boolean;
  readOnly?: boolean;
  nestedOptionsConfig?: { [option: string]: NestedOptionConfig[] };
  onChange?: (value: any) => void;
  className?: string;
  s3Bucket?: string;
  s3Path?: string;
  multiselect?: boolean;
}

const QuestionCard = ({
  id,
  questionNumber,
  title,
  subtitle,
  url = window.location.href.split("#")[0],
  type,
  textLabel,
  options = [],
  value,
  textValue = "",
  nestedValues = {},
  placeholder,
  hideAdditionalInfo = false,
  readOnly = false,
  nestedOptionsConfig = {},
  onChange,
  className,
  children,
  s3Bucket = "",
  s3Path = "",
  multiselect,
}: QuestionCardProps): JSX.Element => {
  const [focused, setFocused] = useState(false);
  const [parent, setParent] = useState<string | string[]>(value || "");
  const [nested, setNested] = useState<NestedValues>(nestedValues);
  const [text, setText] = useState<string>(textValue);
  const questionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleHashChange = () => {
      if (window.location.hash === `#${questionNumber}`) {
        setFocused(true);
        questionRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
      } else {
        setFocused(false);
      }
    };

    handleHashChange();
    window.addEventListener("hashchange", handleHashChange);

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, [questionNumber]);

  const renderAdditionalInfo = useMemo(() => !hideAdditionalInfo && type !== "textarea", [
    hideAdditionalInfo,
    type,
  ]);

  const updateStateAndTriggerChange = (
    updatedParent: string | string[],
    updatedNested: NestedValues,
    updatedText: string
  ) => {
    setParent(updatedParent);
    setNested(updatedNested);
    setText(updatedText);
    onChange?.({
      parentValue: updatedParent,
      nestedValue: updatedNested,
      textAreaValue: updatedText,
    });
  };

  const formElementMap = {
    radio: () => (
      <QuestionCardRadio
        questionNumber={questionNumber}
        options={options}
        nestedOptionsConfig={nestedOptionsConfig}
        value={Array.isArray(parent) ? parent.join(", ") : parent}
        nestedValues={nested}
        readOnly={readOnly}
        onChange={({ parentValue, nestedValues }) =>
          updateStateAndTriggerChange(parentValue, nestedValues, text)
        }
      />
    ),
    checkbox: () => (
      <QuestionCardCheckbox
        questionNumber={questionNumber}
        options={options}
        nestedOptionsConfig={nestedOptionsConfig}
        values={Array.isArray(parent) ? parent : [parent]}
        nestedValues={nested}
        readOnly={readOnly}
        onChange={({ parentValues, nestedValues }) =>
          updateStateAndTriggerChange(parentValues, nestedValues, text)
        }
      />
    ),
    textarea: () => (
      <QuestionCardTextAreaInput
        questionNumber={questionNumber}
        value={text}
        onChange={updatedTextValue => updateStateAndTriggerChange(parent, nested, updatedTextValue)}
        placeholder={placeholder}
        readOnly={readOnly}
        label={textLabel}
        isAdditionalInfo={renderAdditionalInfo}
      />
    ),
    upload: () => (
      <QuestionCardUploader
        questionNumber={questionNumber}
        value={value || []}
        onChange={parentValue => updateStateAndTriggerChange(parentValue, nested, text)}
        s3Bucket={s3Bucket}
        s3Path={s3Path}
        readOnly={readOnly}
      />
    ),
    dropdown: () => {
      const currentParent = Array.isArray(parent) ? parent : [parent];
      const menuItems = options.map(item => ({
        value: item,
        selected: currentParent.includes(item),
      }));

      const handleDropdownChange = (value: string) => {
        const updatedParent = multiselect
          ? currentParent.includes(value)
            ? currentParent.filter(val => val !== value)
            : [...currentParent, value]
          : [value];
        updateStateAndTriggerChange(updatedParent, nested, text);
      };

      return (
        <Dropdown
          options={menuItems}
          value={currentParent.join(", ")}
          type={DropdownToggleType.OUTLINED}
          disabled={readOnly}
          className="questionCardDropdown"
          multiSelect={multiselect}
          onChange={handleDropdownChange}
        />
      );
    },
    custom: () => <Form.Group>{children}</Form.Group>,
  };

  return (
    <div
      id={questionNumber}
      className={`questionCard ${focused ? "focused" : ""} ${className || ""}`}
      ref={questionRef}
    >
      <div className="questionHeader">
        <p className="questionNumber">{questionNumber}</p>
        <OverlayTrigger overlay={<Tooltip id={`${questionNumber}-copyButton`}>Copy</Tooltip>}>
          <Button
            id={`${questionNumber}-copyButton`}
            type={ButtonType.EMPTY}
            className="onboardingButton"
            onClick={() => navigator.clipboard.writeText(`${url}#${questionNumber}`)}
            icon={<MdOutlineInsertLink />}
          />
        </OverlayTrigger>
      </div>
      <div className="cardContent">
        <h2 className="cardTitle">{title}</h2>
        {subtitle && <p className="cardSubtitle">{subtitle}</p>}
        {type && formElementMap[type]?.()}
        {renderAdditionalInfo && formElementMap.textarea?.()}
      </div>
    </div>
  );
};

export default QuestionCard;

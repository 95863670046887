import { mapRowsByProp } from "./MetaBuyingUtils";
import {
  Dimension,
  CreationTab,
  Creative,
  CallToAction,
  Level,
  Objective,
  AudienceSubtype,
  BidStrategy,
  Budget,
  ConversionGoal,
  ConversionEvent,
  SpecialAdCategory,
  PlatformPublisher,
  Position,
  AdsManagerStatus,
  Gender,
  GenderId,
} from "@blisspointmedia/bpm-types/dist/MetaBuying";
import * as R from "ramda";

export const { ALL, MEN, WOMEN } = Gender;
export const { ID_MEN, ID_WOMEN } = GenderId;

export const { TAB_CAMPAIGN, TAB_AD_SET, TAB_AD } = CreationTab;
export const { CAMPAIGN, AD_SET, AD } = Level;
export const { OUTCOME_SALES } = Objective;
export const { CUSTOM, WEBSITE } = AudienceSubtype;
export const {
  LOWEST_COST_WITHOUT_CAP,
  LOWEST_COST_WITH_BID_CAP,
  LOWEST_COST_WITH_MIN_ROAS,
  COST_CAP,
} = BidStrategy;
export const {
  CAMPAIGN_DAILY_BUDGET,
  CAMPAIGN_LIFETIME_BUDGET,
  AD_SET_DAILY_BUDGET,
  AD_SET_LIFETIME_BUDGET,
  AD_SET_BUDGET,
} = Budget;
export const {
  OFFSITE_CONVERSIONS,
  LINK_CLICKS,
  LANDING_PAGE_VIEWS,
  VALUE,
  REACH,
  IMPRESSIONS,
} = ConversionGoal;
export const {
  ADD_PAYMENT_INFO,
  ADD_TO_CART,
  ADD_TO_WISHLIST,
  COMPLETE_REGISTRATION,
  CONTACT,
  CUSTOMIZE_PRODUCT,
  DONATE,
  FIND_LOCATION,
  INITIATE_CHECKOUT,
  LEAD,
  PURCHASE,
  SCHEDULE,
  START_TRIAL,
  SUBMIT_APPLICATION,
  VIEW_CONTENT,
  OTHER,
} = ConversionEvent;
export const EVENT_SUBSCRIBE = ConversionEvent.SUBSCRIBE;
export const EVENT_SEARCH = ConversionEvent.SEARCH;
export const { CLIENT, AGENCY, SEGMENTATION_ID, SEGMENT_SELECTION, CURRENT_DATE } = Dimension;
export const { VIDEO, IMAGE } = Creative;
export const {
  APPLY_NOW,
  BOOK_NOW,
  CONTACT_US,
  DONATE_NOW,
  DOWNLOAD,
  GET_OFFER,
  GET_QUOTE,
  GET_SHOWTIMES,
  LEARN_MORE,
  LISTEN_NOW,
  ORDER_NOW,
  PLAY_GAME,
  REQUEST_TIME,
  SEE_MENU,
  SHOP_NOW,
  SIGN_UP,
  SUBSCRIBE,
  WATCH_MORE,
} = CallToAction;
export const {
  NONE,
  EMPLOYMENT,
  HOUSING,
  CREDIT,
  ISSUES_ELECTIONS_POLITICS,
  ONLINE_GAMBLING_AND_GAMING,
  FINANCIAL_PRODUCTS_SERVICES,
} = SpecialAdCategory;
export const { FACEBOOK, INSTAGRAM, MESSENGER, AUDIENCE_NETWORK } = PlatformPublisher;
export const {
  MARKETPLACE,
  STORY,
  SEARCH,
  FEED,
  REELS,
  PROFILE_FEED,
  PROFILE_REELS,
  RIGHT_HAND_COLUMN,
  FACEBOOK_REELS,
  FACEBOOK_REELS_OVERLAY,
  FACEBOOK_BUSINESS_EXPLORE,
  INSTREAM_VIDEO,
  VIDEO_FEEDS,
  STREAM,
  IG_SEARCH,
  CLASSIC,
  REWARDED_VIDEO,
  MESSENGER_HOME,
  SPONSORED_MESSAGES,
  EXPLORE,
  EXPLORE_HOME,
} = Position;
export const { LIVE, PAUSED } = AdsManagerStatus;
export const STATUS_NONE = AdsManagerStatus.NONE;

export const BUDGET_TYPES = {
  [CAMPAIGN]: [
    {
      label: "Ad Set Budget (ABO)",
      value: AD_SET_BUDGET,
    },
    {
      label: "Campaign Daily Budget (CBO)",
      value: CAMPAIGN_DAILY_BUDGET,
    },
    {
      label: "Campaign Lifetime Budget (CBO)",
      value: CAMPAIGN_LIFETIME_BUDGET,
    },
  ],
  [AD_SET]: [
    {
      label: "Ad Set Daily Budget",
      value: AD_SET_DAILY_BUDGET,
    },
    {
      label: "Ad Set Lifetime Budget",
      value: AD_SET_LIFETIME_BUDGET,
    },
  ],
};

export const CONVERSION_GOAL_OPTIONS = [
  {
    label: "Offsite Conversions",
    value: OFFSITE_CONVERSIONS,
    validOptions: {
      objectives: [OUTCOME_SALES],
      bidStrategies: [LOWEST_COST_WITHOUT_CAP, COST_CAP, LOWEST_COST_WITH_BID_CAP],
    },
  },
  {
    label: "Value",
    value: VALUE,
    validOptions: {
      objectives: [OUTCOME_SALES],
      bidStrategies: [LOWEST_COST_WITHOUT_CAP, LOWEST_COST_WITH_MIN_ROAS],
    },
  },
  {
    label: "Link Clicks",
    value: LINK_CLICKS,
    validOptions: {
      objectives: [OUTCOME_SALES],
      bidStrategies: [LOWEST_COST_WITHOUT_CAP, COST_CAP, LOWEST_COST_WITH_BID_CAP],
    },
  },
  {
    label: "Landing Page Views",
    value: LANDING_PAGE_VIEWS,
    validOptions: {
      objectives: [OUTCOME_SALES],
      bidStrategies: [LOWEST_COST_WITHOUT_CAP, COST_CAP, LOWEST_COST_WITH_BID_CAP],
    },
  },
  {
    label: "Reach",
    value: REACH,
    validOptions: {
      objectives: [OUTCOME_SALES],
      bidStrategies: [LOWEST_COST_WITHOUT_CAP, COST_CAP, LOWEST_COST_WITH_BID_CAP],
    },
  },
];

//@ts-ignore
export const CONVERSION_GOALS = mapRowsByProp("value", CONVERSION_GOAL_OPTIONS);
//@ts-ignore

export const CONVERSION_EVENT_OPTIONS = [
  {
    label: "Add Payment Info",
    value: ADD_PAYMENT_INFO,
    validOptions: {
      objectives: [OUTCOME_SALES],
      performanceGoals: [OFFSITE_CONVERSIONS],
    },
  },
  {
    label: "Add to Cart",
    value: ADD_TO_CART,
    validOptions: {
      objectives: [OUTCOME_SALES],
      performanceGoals: [OFFSITE_CONVERSIONS],
    },
  },
  {
    label: "Add to Wishlist",
    value: ADD_TO_WISHLIST,
    validOptions: {
      objectives: [OUTCOME_SALES],
      performanceGoals: [OFFSITE_CONVERSIONS],
    },
  },
  {
    label: "Complete Registration",
    value: COMPLETE_REGISTRATION,
    validOptions: {
      objectives: [OUTCOME_SALES],
      performanceGoals: [OFFSITE_CONVERSIONS],
    },
  },
  {
    label: "Contact",
    value: CONTACT,
    validOptions: {
      objectives: [],
      performanceGoals: [OFFSITE_CONVERSIONS],
    },
  },
  {
    label: "Customize Product",
    value: CUSTOMIZE_PRODUCT,
    validOptions: {
      objectives: [],
      performanceGoals: [OFFSITE_CONVERSIONS],
    },
  },
  {
    label: "Donate",
    value: DONATE,
    validOptions: {
      objectives: [OUTCOME_SALES],
      performanceGoals: [OFFSITE_CONVERSIONS],
    },
  },
  {
    label: "Find Location",
    value: FIND_LOCATION,
    validOptions: {
      objectives: [],
      performanceGoals: [OFFSITE_CONVERSIONS],
    },
  },
  {
    label: "Initiate Checkout",
    value: INITIATE_CHECKOUT,
    validOptions: {
      objectives: [OUTCOME_SALES],
      performanceGoals: [OFFSITE_CONVERSIONS],
    },
  },
  {
    label: "Lead",
    value: LEAD,
    validOptions: {
      objectives: [],
      performanceGoals: [OFFSITE_CONVERSIONS],
    },
  },
  {
    label: "Purchase",
    value: PURCHASE,
    validOptions: {
      objectives: [OUTCOME_SALES],
      performanceGoals: [OFFSITE_CONVERSIONS, VALUE],
    },
  },
  {
    label: "Schedule",
    value: SCHEDULE,
    validOptions: {
      objectives: [],
      performanceGoals: [OFFSITE_CONVERSIONS],
    },
  },
  {
    label: "Search",
    value: EVENT_SEARCH,
    validOptions: {
      objectives: [OUTCOME_SALES],
      performanceGoals: [OFFSITE_CONVERSIONS],
    },
  },
  {
    label: "Start Trial",
    value: START_TRIAL,
    validOptions: {
      objectives: [OUTCOME_SALES],
      performanceGoals: [OFFSITE_CONVERSIONS],
    },
  },
  {
    label: "Submit Application",
    value: SUBMIT_APPLICATION,

    validOptions: {
      objectives: [],
      performanceGoals: [OFFSITE_CONVERSIONS],
    },
  },
  {
    label: "Subscribe",
    value: EVENT_SUBSCRIBE,
    validOptions: {
      objectives: [OUTCOME_SALES],
      performanceGoals: [OFFSITE_CONVERSIONS],
    },
  },
  {
    label: "View Content",
    value: VIEW_CONTENT,
    validOptions: {
      objectives: [OUTCOME_SALES],
      performanceGoals: [OFFSITE_CONVERSIONS],
    },
  },
  {
    label: "Other",
    value: OTHER,
    validOptions: {
      objectives: [OUTCOME_SALES],
      performanceGoals: [OFFSITE_CONVERSIONS],
    },
  },
];

export const BID_STRATEGY_OPTIONS = [
  {
    label: "Cost Per Result Goal",
    value: COST_CAP,
    inputLabel: "Cost Per Result Goal",
  },
  {
    label: "Bid Cap",
    value: LOWEST_COST_WITH_BID_CAP,
    inputLabel: "Bid Control",
  },
  {
    label: "Highest Volume or Value",
    value: LOWEST_COST_WITHOUT_CAP,
  },
  {
    label: "ROAS Goal",
    value: LOWEST_COST_WITH_MIN_ROAS,
  },
];
export const BID_STRATEGIES = mapRowsByProp("value", BID_STRATEGY_OPTIONS);

export const GENDER_OPTIONS = [ALL, MEN, WOMEN];

export const AUDIENCE_SUBTYPE_MAP = {
  [CUSTOM]: "Customer\u00A0List",
  [WEBSITE]: "Website",
};

export const AGE_OPTIONS = R.range(18, 66);

export const OPTIONS_NOTE_CARD_HEADERS = {
  [CAMPAIGN]: "Campaign Options to Populate in Meta",
  [AD_SET]: "Ad Set Options to Populate in Ads Manager",
  [AD]: "Ad Options to Populate in Ads Manager",
};
export const OPTIONS_NOTE_CARD_TEXTS = {
  [CAMPAIGN]:
    "A list of the objectives that people will not be able to set campaigns for in our tool",
  [AD_SET]:
    "If you need to adjust these, you’ll need to build your campaign here and adjust it in meta once you’ve published as paused in Bliss Point.",
};
export const OPTIONS_NOTE_CARD_OPTIONS = {
  [CAMPAIGN]: ["App Promotion", "Engagements", "Leads", "Awareness", "Traffic"],
  [AD_SET]: [
    "Catalog Ads",
    "Additional Text Options",
    "Promo Codes",
    "Advantage+ Creative",
    "Instant Experiences",
    "Facebook Events",
  ],
  [AD]: [
    "Catalog Ads",
    "Additional Text Options",
    "Promo Codes",
    "Advantage+ Creative",
    "Instant Experiences",
    "Facebook Events",
  ],
};

export const OBJECTIVE_TYPES = [
  {
    label: "Outcome Sales",
    value: OUTCOME_SALES,
  },
];

export const SPECIAL_AD_CATEGORY_OPTIONS = [
  {
    label: "Employment",
    value: EMPLOYMENT,
    limitAudienceTargeting: true,
  },
  {
    label: "Housing",
    value: HOUSING,
    limitAudienceTargeting: true,
  },
  {
    label: "Issues/Elections/Politics",
    value: ISSUES_ELECTIONS_POLITICS,
  },
  {
    label: "Financial Products/Services",
    value: FINANCIAL_PRODUCTS_SERVICES,
    limitAudienceTargeting: true,
  },
];
export const SPECIAL_AD_CATEGORIES = mapRowsByProp("value", SPECIAL_AD_CATEGORY_OPTIONS);

export const CREATIVE_TYPES = [
  {
    label: "Single Image",
    value: IMAGE,
  },
  {
    label: "Single Video",
    value: VIDEO,
  },
];

export const CTA_OPTIONS = [
  {
    label: "Apply Now",
    value: APPLY_NOW,
  },
  {
    label: "Book now",
    value: BOOK_NOW,
  },
  {
    label: "Contact us",
    value: CONTACT_US,
  },
  {
    label: "Donate now",
    value: DONATE_NOW,
  },
  {
    label: "Download",
    value: DOWNLOAD,
  },
  {
    label: "Get Offer",
    value: GET_OFFER,
  },
  {
    label: "Get quote",
    value: GET_QUOTE,
  },
  {
    label: "Get showtimes",
    value: GET_SHOWTIMES,
  },
  {
    label: "Learn More",
    value: LEARN_MORE,
  },
  {
    label: "Listen now",
    value: LISTEN_NOW,
  },
  {
    label: "Order now",
    value: ORDER_NOW,
  },
  {
    label: "Play game",
    value: PLAY_GAME,
  },
  {
    label: "Request time",
    value: REQUEST_TIME,
  },
  {
    label: "See menu",
    value: SEE_MENU,
  },
  {
    label: "Shop now",
    value: SHOP_NOW,
  },
  {
    label: "Sign up",
    value: SIGN_UP,
  },
  {
    label: "Subscribe",
    value: SUBSCRIBE,
  },
  {
    label: "Watch more",
    value: WATCH_MORE,
  },
];

export const ATTR_WINDOW_COMBOS = {
  ALL_OPTIONS_BOTH: {
    click_through: [1, 7],
    view_through: [0, 1],
  },
  ALL_OPTIONS_CLICK: {
    click_through: [1, 7],
    view_through: [0],
  },
  ALL_OPTIONS_VIEW: {
    click_through: [1],
    view_through: [0, 1],
  },
  NULL_OPTIONS_BOTH: {
    click_through: [0],
    view_through: [0],
  },
  OPTIONS_DEFAULT: {
    click_through: [1],
    view_through: [0],
  },
};

export const ATTR_WINDOW_OPTIONS = {
  OUTCOME_SALES: {
    ALL_OPTIONS_BOTH: [OFFSITE_CONVERSIONS, VALUE],
  },
};

export const PUBLISHER_PLATFORMS = [FACEBOOK, INSTAGRAM, AUDIENCE_NETWORK, MESSENGER];

export const PLATFORM_OPTIONS = [
  {
    label: "Facebook",
    value: FACEBOOK,
  },
  {
    label: "Instagram",
    value: INSTAGRAM,
  },
  {
    label: "Audience Network",
    value: AUDIENCE_NETWORK,
  },
  {
    label: "Messenger",
    value: MESSENGER,
  },
];

export const AD_SET_PLACEMENTS: {
  category: string;
  positions: {
    label: string;
    position: Position;
    publisher: PlatformPublisher;
    restrictions?: {
      objective?: Objective[];
    };
  }[];
}[] = [
  {
    category: "Feeds",
    positions: [
      {
        label: "Facebook Feed",
        position: FEED,
        publisher: FACEBOOK,
      },
      {
        label: "Facebook Profile Feed",
        position: PROFILE_FEED,
        publisher: FACEBOOK,
      },
      {
        label: "Instagram Feed",
        position: STREAM,
        publisher: INSTAGRAM,
      },
      {
        label: "Instagram Profile Feed",
        position: PROFILE_FEED,
        publisher: INSTAGRAM,
      },
      {
        label: "Facebook Marketplace",
        position: MARKETPLACE,
        publisher: FACEBOOK,
      },
      {
        label: "Facebook Video Feeds",
        position: VIDEO_FEEDS,
        publisher: FACEBOOK,
      },
      {
        label: "Facebook Right Column",
        position: RIGHT_HAND_COLUMN,
        publisher: FACEBOOK,
      },
      {
        label: "Instagram Explore",
        position: EXPLORE,
        publisher: INSTAGRAM,
      },
      {
        label: "Instagram Explore Home",
        position: EXPLORE_HOME,
        publisher: INSTAGRAM,
      },
      {
        label: "Messenger Inbox",
        position: MESSENGER_HOME,
        publisher: MESSENGER,
      },
      {
        label: "Facebook Business Explore",
        position: FACEBOOK_BUSINESS_EXPLORE,
        publisher: FACEBOOK,
      },
    ],
  },
  {
    category: "Stories and Reels",
    positions: [
      {
        label: "Instagram Stories",
        position: STORY,
        publisher: INSTAGRAM,
      },
      {
        label: "Facebook Stories",
        position: STORY,
        publisher: FACEBOOK,
      },
      {
        label: "Messenger Stories",
        position: STORY,
        publisher: MESSENGER,
      },
      {
        label: "Instagram Reels",
        position: REELS,
        publisher: INSTAGRAM,
      },
      {
        label: "Instagram Profile Reels",
        position: PROFILE_REELS,
        publisher: INSTAGRAM,
      },
      {
        label: "Facebook Reels",
        position: FACEBOOK_REELS,
        publisher: FACEBOOK,
      },
    ],
  },
  {
    category: "In-Stream Ads for Videos and Reels",
    positions: [
      {
        label: "Facebook In-Stream Videos",
        position: INSTREAM_VIDEO,
        publisher: FACEBOOK,
      },
      {
        label: "Ads on Facebook Reels",
        position: FACEBOOK_REELS_OVERLAY,
        publisher: FACEBOOK,
      },
    ],
  },
  {
    category: "Search Results",
    positions: [
      {
        label: "Facebook Search Results",
        position: SEARCH,
        publisher: FACEBOOK,
      },
      {
        label: "Instagram Search Results",
        position: IG_SEARCH,
        publisher: INSTAGRAM,
      },
    ],
  },
  {
    category: "Messages",
    positions: [
      {
        label: "Messenger Sponsored Messages",
        position: SPONSORED_MESSAGES,
        publisher: MESSENGER,
        restrictions: {
          objective: [OUTCOME_SALES],
        },
      },
    ],
  },
  {
    category: "Apps and Sites",
    positions: [
      {
        label: "Audience Network Native, Banner, and Interstitial",
        position: CLASSIC,
        publisher: AUDIENCE_NETWORK,
      },
      {
        label: "Audience Network Rewarded Videos",
        position: REWARDED_VIDEO,
        publisher: AUDIENCE_NETWORK,
      },
    ],
  },
];

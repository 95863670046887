import { useEffect } from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import { useSetError } from "../redux/modals";
import { AmazonLambdaFetch } from "../utils/fetch-utils";
import { decodeState } from "@blisspointmedia/bpm-types/dist/AmazonSPAPI";

/**
 * This is a dummy component that will redirect from the Amazon callback to
 * the Retail Ops Channels page after generating a refresh token.
 */
const AmazonRedirect: React.FC<RouteComponentProps> = () => {
  const setError = useSetError();

  useEffect(() => {
    (async () => {
      // Check for URL paramters after redirect back from Amazon. If they exist, hit the lambda to generate and store a new refresh token.
      const params = new URLSearchParams(window.location.search);
      const authCode = params.get("spapi_oauth_code");
      const sellingPartnerId = params.get("selling_partner_id");
      const state = params.get("state");

      const decodedState = decodeState(state || "");
      const { company, applicationType, marketplace } = decodedState;

      if (!authCode || !state || !sellingPartnerId) {
        console.error("Missing required parameters");
      }

      try {
        if (authCode && sellingPartnerId) {
          await AmazonLambdaFetch("/generateRefreshToken", {
            method: "POST",
            body: JSON.stringify({
              company,
              authCode,
              sellingPartnerId,
              applicationType,
              marketplace,
            }),
          });
        }
      } catch (e) {
        const reportError = e as Error;
        setError({
          message: `Failed to generate refresh token: ${reportError.message}`,
          reportError,
        });
      }

      navigate(`https://blisspoint.tinuiti.com/${company}/retail-ops-channels`);
    })();
  }, [setError]);

  return null;
};

export default AmazonRedirect;

import { Form, Button } from "react-bootstrap";
import { HeaderInfo } from "./configUtils";
import { MdDeleteForever, MdSkipPrevious, MdSkipNext } from "react-icons/md";
import { StateSetter } from "../../utils/types";
import { SuperHeader as PresetSuperHeader } from "@blisspointmedia/bpm-types/dist/MetricsTable";
import React, { useCallback, useEffect, useMemo, useState } from "react";

interface SuperHeaderProps {
  colCount: number;
  columnI: number;
  info: Record<string, HeaderInfo | true | undefined>;
  onSuperHeaderChange: StateSetter<PresetSuperHeader[]>;
  superHeaders: PresetSuperHeader[];
}

const SuperHeader: React.FC<SuperHeaderProps> = ({
  colCount,
  columnI,
  info,
  onSuperHeaderChange,
  superHeaders,
}) => {
  const ourInfo = info[columnI];

  const onUpdateText = useCallback(
    text => {
      if (ourInfo && typeof ourInfo !== "boolean") {
        const newSuperHeaders = [...superHeaders];
        newSuperHeaders[ourInfo.superHeaderIndex].data.text = text;
        onSuperHeaderChange(newSuperHeaders);
      }
    },
    [onSuperHeaderChange, ourInfo, superHeaders]
  );

  const onDelete = useCallback(() => onUpdateText(""), [onUpdateText]);

  const onResize = useCallback(
    (grow: boolean, side: "end" | "start") => {
      if (ourInfo && typeof ourInfo !== "boolean") {
        const newSuperHeaders = [...superHeaders];
        newSuperHeaders[ourInfo.superHeaderIndex].span += grow ? 1 : -1;
        if (side === "start") {
          newSuperHeaders[ourInfo.superHeaderIndex - 1].span += grow ? -1 : 1;
        } else if (newSuperHeaders[ourInfo.superHeaderIndex + 1]) {
          newSuperHeaders[ourInfo.superHeaderIndex + 1].span += grow ? -1 : 1;
        }
        onSuperHeaderChange(newSuperHeaders);
      }
    },
    [onSuperHeaderChange, ourInfo, superHeaders]
  );

  const { canLeft, canRight, size } = useMemo(() => {
    if (ourInfo && typeof ourInfo !== "boolean") {
      const [lastSuperHeader, superHeader, nextSuperHeader] = [
        superHeaders[ourInfo.superHeaderIndex - 1],
        superHeaders[ourInfo.superHeaderIndex],
        superHeaders[ourInfo.superHeaderIndex + 1],
      ];
      return {
        canLeft: ourInfo.start > 0 && !lastSuperHeader.data.text,
        canRight: ourInfo.end < colCount - 1 && (!nextSuperHeader || !nextSuperHeader.data.text),
        size: superHeader.span,
      };
    }
    return {};
  }, [ourInfo, superHeaders, colCount]);

  const [localText, setLocalText] = useState(
    ourInfo && typeof ourInfo !== "boolean" ? ourInfo.text : ""
  );

  useEffect(() => {
    setLocalText(ourInfo && typeof ourInfo !== "boolean" ? ourInfo.text : "");
  }, [ourInfo]);

  return ourInfo ? (
    typeof ourInfo !== "boolean" ? (
      <div className="superHeaderContainer">
        <div style={{ width: `${ourInfo.width}px` }}>
          <div className="sizerBox">
            {canLeft && (
              <Button onClick={() => onResize(true, "start")} size="sm" variant="outline-primary">
                <MdSkipPrevious />
              </Button>
            )}
            {(size || 0) > 1 && (
              <Button onClick={() => onResize(false, "start")} size="sm" variant="outline-primary">
                <MdSkipNext />
              </Button>
            )}
          </div>
          <Form.Control
            onBlur={() => onUpdateText(localText)}
            onChange={e => setLocalText(e.currentTarget.value)}
            size="sm"
            value={localText}
          />
          <Button size="sm" variant="danger" onClick={onDelete}>
            <MdDeleteForever />
          </Button>
          <div className="sizerBox">
            {(size || 0) > 1 && (
              <Button onClick={() => onResize(false, "end")} size="sm" variant="outline-primary">
                <MdSkipPrevious />
              </Button>
            )}
            {canRight && (
              <Button onClick={() => onResize(true, "end")} size="sm" variant="outline-primary">
                <MdSkipNext />
              </Button>
            )}
          </div>
        </div>
      </div>
    ) : null
  ) : null;
};

export default SuperHeader;

import React from "react";
import "./MoreActionsMenu.scss";
import { Button, ButtonType } from "../../../Components";
import { ButtonFrameworkVariant } from "../../../Components/ButtonFramework";
import { MdDelete, MdEdit, MdList, MdOutlineMoreTime, MdRemoveRedEye } from "react-icons/md";
import { MetaBuyingTableRow } from "@blisspointmedia/bpm-types/dist/MetaBuying";

interface MoreActionsMenuProps {
  selectedRows: Record<string, Record<string, MetaBuyingTableRow>>;
  selectedLevel: string;
  tab: "drafts" | "published";
  changeLogAction?: () => void;
  editAction?: () => void;
  viewDetailsAction?: () => void;
  viewAdPreviewAction?: () => void;
  deleteAction?: () => void;
}

const MoreActionsMenu = ({
  selectedRows,
  selectedLevel,
  tab,
  changeLogAction,
  editAction,
  viewDetailsAction,
  viewAdPreviewAction,
  deleteAction,
}: MoreActionsMenuProps): JSX.Element => {
  return (
    <div
      className="mamContainer"
      style={{
        height: selectedLevel === "ad" ? 190 : 156,
        width: selectedLevel === "ad" ? 163 : 134,
      }}
    >
      <Button
        className="mamButton"
        type={ButtonType.EMPTY}
        size="sm"
        variant={ButtonFrameworkVariant.LEADING_ICON}
        icon={<MdOutlineMoreTime />}
        onClick={changeLogAction}
        disabled={selectedLevel !== "ad"}
      >
        Change Log
      </Button>
      <Button
        className="mamButton"
        type={ButtonType.EMPTY}
        size="sm"
        variant={ButtonFrameworkVariant.LEADING_ICON}
        icon={<MdEdit />}
        disabled={tab === "published" || selectedLevel !== "ad"}
        onClick={editAction}
      >
        Edit
      </Button>
      <div className="divider"></div>
      <Button
        className="mamButton"
        type={ButtonType.EMPTY}
        size="sm"
        variant={ButtonFrameworkVariant.LEADING_ICON}
        icon={<MdList />}
        onClick={viewDetailsAction}
      >
        View Details
      </Button>
      {selectedLevel === "ad" && (
        <Button
          className="mamButton"
          type={ButtonType.EMPTY}
          size="sm"
          variant={ButtonFrameworkVariant.LEADING_ICON}
          icon={<MdRemoveRedEye />}
          onClick={viewAdPreviewAction}
        >
          View Ad Preview
        </Button>
      )}
      <div className="divider"></div>
      <Button
        className="mamButton"
        type={ButtonType.EMPTY}
        size="sm"
        variant={ButtonFrameworkVariant.LEADING_ICON}
        icon={<MdDelete />}
        disabled={tab === "published"}
        onClick={deleteAction}
      >
        Delete
      </Button>
    </div>
  );
};

export default MoreActionsMenu;

import React, { useState, useCallback } from "react";
import * as XLSX from "xlsx";
import { RouteComponentProps } from "@reach/router";
import { Button, ButtonType, Spinner } from "../Components";
import { MetaLambdaFetch } from "../utils/fetch-utils";
import { AdAccountInfo } from "@blisspointmedia/bpm-types/dist/MetaBuying";
import { PageTab } from "./MetaBuying";

const TAB_OPTIONS = ["Upload File"];

interface MetaBuyingUploadProps {
  selectedAdAccount: AdAccountInfo;
  refreshData: (tab: PageTab) => void;
}

const MetaBuyingUpload: React.FC<MetaBuyingUploadProps & RouteComponentProps> = ({
  path,
  selectedAdAccount,
  refreshData,
}): JSX.Element => {
  // const [fileType, setFileType] = useState<any>(null);
  // const [filePath, setFilePath] = useState<any>(null);
  // const [fileLink, setFileLink] = useState<string>("");
  // const [selectedTab, setSelectedTab] = useState("Link File(s)");
  // const [rows, setRows] = useState<Record<string, string>[]>([]);

  const [file, setFile] = useState<File | undefined>();
  const [importing, setImporting] = useState<boolean>(false);

  const saveChanges = useCallback(
    async rows => {
      try {
        await MetaLambdaFetch("/saveMetaUpload", {
          method: "POST",
          body: { rows, accountId: selectedAdAccount.account_id },
        });
      } catch (e) {
        console.error(e);
      }
    },
    [selectedAdAccount.account_id]
  );

  const importPlan = useCallback(() => {
    setImporting(true);

    const reader = new FileReader();
    reader.onload = async e => {
      const binaryString = e?.target?.result;

      const rows = convertFileToJson(binaryString as ArrayBuffer);

      const isValid = validateUpload(rows);

      if (isValid) {
        await saveChanges(rows);
      }

      setImporting(false);
      refreshData(PageTab.DRAFTS);
    };
    reader.readAsArrayBuffer(file as Blob);
  }, [file, saveChanges, refreshData]);

  return (
    <div className="metaBuyingWidgetWrapper">
      <div className="metaBuyingWidget">
        <h2>Upload</h2>
        <div className="metaBuyingWidgetBody">
          <Button
            size="sm"
            className="metaBuyingButton upload"
            type={ButtonType.EMPTY}
            onClick={() => downloadTemplate()}
          >
            Download Template
          </Button>
          <div
            className="metaBuyingUploadPrompt"
            style={{
              border: "1px solid #CBD2E1",
              borderRadius: "4px",
              padding: "16px 24px 24px 24px",
            }}
          >
            <div className="metaBuyingTabs" style={{ paddingLeft: "0px" }}>
              {TAB_OPTIONS.map(tab => (
                <Button
                  type={ButtonType.EMPTY}
                  // className={selectedTab === tab ? "selected" : ""}
                  onClick={() => {}}
                  key={tab}
                >
                  {tab}
                </Button>
              ))}
            </div>

            {/* <Form.Group>
              <Form.Label>Link your file below and grant us view access.</Form.Label>
              <Form.Control value={fileLink} onChange={e => setFileLink(e.target.value)} />
            </Form.Group> */}

            <input
              className="metaUploadFileInput"
              type="file"
              id="file_input_id"
              onChange={e => {
                const uploadedFile = e.target.files;
                const file = uploadedFile?.[0];
                setFile(file);
              }}
              style={{ marginTop: "16px", paddingLeft: "16px" }}
            />
          </div>
        </div>

        {importing ? (
          <Spinner />
        ) : (
          <Button className="submitSelections" type={ButtonType.OUTLINED} onClick={importPlan}>
            Save as Draft
          </Button>
        )}
      </div>
    </div>
  );
};

/**
 * Download template for the Meta Buying upload.
 */
const downloadTemplate = (): void => {
  const fileName = "meta-upload-template.xlsx";

  let workbook: XLSX.WorkBook = { SheetNames: [], Sheets: {} };
  let worksheet = XLSX.utils.json_to_sheet([
    {
      "Ad Set ID": "",
      "Ad Name": "",
      "Creative Type": "",
      "Primary Text": "",
      "URL Parameters": "",
      "Asset ID": "",
      Headline: "",
      Description: "",
      "Call to Action": "",
      "Website URL": "",
      "Facebook Page": "",
      // "Instagram Account": "",
    },
  ]);

  workbook.SheetNames.push(fileName);
  workbook.Sheets[fileName] = worksheet;

  XLSX.writeFile(workbook, fileName);
};

/**
 * Convert the uploaded Excel file to JSON.
 */
const convertFileToJson = (file: ArrayBuffer): Record<string, string>[] => {
  // Create a workbook using the imported file.
  const workbook = XLSX.read(file, { type: "buffer" });
  // Get first sheet of workbook
  const worksheetName = workbook.SheetNames[0];
  const worksheet = workbook.Sheets[worksheetName];

  // Convert worksheet to JSON
  const rows: Record<string, string>[] = XLSX.utils.sheet_to_json(worksheet);
  return rows;
};

/**
 * Validate that the uploaded rows meet requirements.
 */
const validateUpload = (rows: Record<string, string>[]): boolean => {
  rows.forEach(row => {
    const {
      "Ad Set ID": adSetId,
      "Ad Name": adName,
      "Asset ID": assetId,
      "Creative Type": creativeType,
      Headline: headline,
    } = row;

    // Check if valud creative type
    validateCreativeType(creativeType);
    // Check if all Ad Set IDs are valid
    validateAdSetId(adSetId);
    // Check if all Asset IDs are valid
    validateAssetId(assetId);
    // Check if Ad Names meet requirements
    validateAdName(adName);
    // Check if Headline meets requirements
    validateHeadline(headline);
  });

  return true;
};

// TODO: Add logic to these validation functions
const validateCreativeType = (creativeType: string) => {
  // Check if Creative Type is valid
  return true;
};

const validateAdSetId = (adSetId: string) => {
  // Check if Ad Set ID exists (either in Meta or our own internal id)
  return true;
};

const validateAssetId = (assetId: string) => {
  // Check if Asset ID exists (in Meta)
  return true;
};

const validateAdName = (adName: string) => {
  // Check if Ad Name meets requirements (will be delimited by _ to parse and validate
  // that the right number of dimensions are used and they are valid dimension values)
  return true;
};

const validateHeadline = (headline: string) => {
  // Check if Headline meets requirements (255 char limit)
  return true;
};

export default MetaBuyingUpload;

import React, { useState, useMemo, useEffect } from "react";
import * as R from "ramda";

import { useSetError } from "../redux/modals";

import { Spinner } from "./Spinner";
import { Tooltip } from "./Tooltip";

import "./DmaMap.scss";

const MAP_WIDTH = 1100;
const MAP_HEIGHT = 550;
export const MAP_ASPECT_RATIO = MAP_WIDTH / MAP_HEIGHT;

const BORDER_COLOR = "#c0c0c0";
const BORDER_WIDTH = 1;
const BORDER_SELECTED_COLOR = "#666";
const BORDER_SELECTED_WIDTH = 4;

export type DMACode = string;
export interface GeoData {
  impressions: number;
  responses: number;
  households: number;
  name: string;
  dma: DMACode;
  index: number;
}

export type GeoDataMap = Record<DMACode, GeoData>;

export type GeoDataObject = GeoDataMap | false | undefined;

interface DMAPath {
  path: string;
  name: string;
}

type DMAPathMap = Record<DMACode, DMAPath>;

interface TooltipData {
  x: number;
  y: number;
  dma: DMACode;
}

interface DmaMapProps {
  renderColor?: (dma: string) => string;
  width?: number;
  height?: number;
  renderTooltip?: (dma: DMACode) => React.ReactNode;
  selection?: DMACode;
  onPathLoad?: (loaded: boolean) => void;
  onClick?: (dma: string) => void;
  borderColor?: string;
}

export const DmaMap = React.forwardRef<SVGSVGElement | null, DmaMapProps>(
  (
    {
      renderColor,
      width = MAP_WIDTH,
      height = MAP_HEIGHT,
      renderTooltip = R.identity,
      selection: externalSelection,
      onPathLoad,
      onClick,
      borderColor,
    },
    ref
  ) => {
    const setError = useSetError();

    const [tooltipData, setTooltipData] = useState<TooltipData>();
    const [dmaPaths, setDmaPaths] = useState<DMAPathMap>();

    const [pathsLoading, setPathsLoading] = useState(false);

    useEffect(() => {
      if (!dmaPaths && !pathsLoading) {
        (async () => {
          setPathsLoading(true);

          try {
            let dmaData = await fetch("https://cdn.blisspointmedia.com/geo/DmaPaths.json");
            let dmaPaths = await dmaData.json();
            setDmaPaths(dmaPaths);
          } catch (e) {
            setError({
              message: `Failed to get dma path information: ${e.message}`,
              reportError: e,
            });
          }

          setPathsLoading(false);
          if (onPathLoad) {
            onPathLoad(true);
          }
        })();
      }
    }, [pathsLoading, dmaPaths, setError, onPathLoad]);

    const dmaMap = useMemo(
      () =>
        dmaPaths && renderColor
          ? R.keys(dmaPaths).map(dma => {
              let isSelected = ((tooltipData && tooltipData.dma) || externalSelection) === dma;
              return (
                <path
                  className="dmaPath"
                  key={dma}
                  d={dmaPaths[dma]?.path}
                  stroke={
                    isSelected ? borderColor || BORDER_SELECTED_COLOR : borderColor || BORDER_COLOR
                  }
                  strokeWidth={isSelected ? BORDER_SELECTED_WIDTH : BORDER_WIDTH}
                  fill={renderColor(dma)}
                  onMouseEnter={e =>
                    setTooltipData({
                      x: e.pageX,
                      y: e.pageY,
                      dma,
                    })
                  }
                  // eslint-disable-next-line no-undefined
                  onMouseLeave={() => setTooltipData(undefined)}
                  onClick={onClick ? () => onClick(dma) : () => {}}
                />
              );
            })
          : null,
      [dmaPaths, renderColor, tooltipData, externalSelection, borderColor, onClick]
    );

    return (
      <div className="dmaMap" style={{ width, height }}>
        {pathsLoading || !dmaMap ? (
          <Spinner />
        ) : (
          <svg ref={ref} viewBox={`0 0 ${MAP_WIDTH} ${MAP_HEIGHT}`}>
            {dmaMap}
          </svg>
        )}
        {tooltipData && (
          <Tooltip coords={{ x: tooltipData.x, y: tooltipData.y }} horizontalShift>
            {renderTooltip(tooltipData.dma)}
          </Tooltip>
        )}
      </div>
    );
  }
);

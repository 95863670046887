import "./RetailOpsForm.scss";
import React, { useEffect, useState } from "react";
import ChartContainer from "../Components/ChartContainer";
import Select from "react-select/creatable";
import { Dropdown } from "../Components";
import {
  DummyCountries,
  SellerCountries,
  VendorCountries,
  SellingOptions,
  VendorOptions,
} from "./FormDropDownOptions";
import { CloseButton as ClearButton } from "react-bootstrap";

interface RetailOpsFormProps {
  accountId: string | undefined;
  setChannelType: React.Dispatch<React.SetStateAction<string>>;
  title: string;
}

const countries = {
  "Dummy Channel": DummyCountries,
  "Amazon Selling Partner - Seller": SellerCountries,
  "Amazon Brand Analytics - Vendor": VendorCountries,
};

const RetailOpsForm = ({ accountId, setChannelType, title }: RetailOpsFormProps): JSX.Element => {
  // Dummy Channel
  const [channelName, updateChannelName] = useState<string>("");
  const [sellerOrVendor, setSellerOrVendor] = useState<string>("Seller");
  // Vendor and Seller
  const [, setType] = useState<string[]>([]);
  const [, setIntegrationName] = useState<string>("");
  // All
  const [country, setCountry] = useState<string>("US");

  useEffect(() => {
    setCountry("US");
  }, [title]);

  return (
    <ChartContainer
      enableHoverDesign={true}
      rightActions={
        <>
          <div className="create">Create</div>
          <div className="clearButton">
            <ClearButton
              onClick={() => {
                updateChannelName("");
                setSellerOrVendor("Seller");
                setCountry("US");
                setIntegrationName("");
                setChannelType("");
              }}
            />
          </div>
        </>
      }
      title={title}
    >
      <div className="retail-ops-form" style={{ height: "30px", display: "flex", gap: "10px" }}>
        {title === "Dummy Channel" && (
          <>
            <input className="account-id" defaultValue={accountId} readOnly width="200px" />
            <input
              className="channel-name"
              value={channelName}
              placeholder="Channel Name"
              onChange={val => updateChannelName(val.target.value)}
              width="200px"
            />
            <Dropdown
              onChange={option => setSellerOrVendor(option)}
              options={["Vendor", "Seller"]}
              placeholder={sellerOrVendor}
              value={sellerOrVendor}
            />
          </>
        )}

        {title === "Amazon Selling Partner - Seller" && (
          <>
            <input
              className="integration-name"
              onChange={ele => setIntegrationName(ele.target.value)}
              placeholder="Integration Name"
              width="200px"
            />
            <Select
              className="retail-ops-type-select"
              closeMenuOnSelect={false}
              isMulti
              placeholder="Select Reports..."
              options={SellingOptions}
              onChange={value => setType(value.map(v => v.value))}
            />
          </>
        )}
        {title === "Amazon Brand Analytics - Vendor" && (
          <>
            <input
              className="integration-name"
              onChange={ele => setIntegrationName(ele.target.value)}
              placeholder="Integration Name"
              width="200px"
            />
            <Select
              className="retail-ops-type-select"
              closeMenuOnSelect={false}
              isMulti
              placeholder="Select Reports..."
              options={VendorOptions}
              onChange={value => setType(value.map(v => v.value))}
            />
          </>
        )}

        <Dropdown
          onChange={option => setCountry(option)}
          options={countries[title]}
          placeholder={country}
          value={country}
        />
      </div>
    </ChartContainer>
  );
};

export default RetailOpsForm;

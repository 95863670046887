import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { Button, ButtonType, Spinner } from "../../Components";
import { ButtonFrameworkVariant } from "../../Components/ButtonFramework";
import { mapRowsByProp } from "../MetaBuyingUtils";
import * as R from "ramda";

export const DROPDOWN_BTN = "dropdownBtn";
export const SEARCH_MENU = "searchMenu";

interface SingleSelectMenuProps {
  placeholder: string;
  menuId: string;
  data: Record<string, any>[];
  nameProp: string;
  closeMenu: () => void;
  showMenu: boolean;
  applySelection: (value: string | number) => void;
  removeSelection: () => void;
  appliedSelection: number;
  loading?: boolean;
}

export const SingleSelectMenu = ({
  placeholder,
  menuId,
  data,
  nameProp = "name",
  closeMenu,
  showMenu,
  applySelection,
  removeSelection,
  appliedSelection,
  loading = false,
}: SingleSelectMenuProps): JSX.Element => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedId, setSelectedId] = useState<number>(-1);

  const dropdownBtnId = [menuId, DROPDOWN_BTN].join("_");
  const searchMenuId = [menuId, SEARCH_MENU].join("_");

  const idMap = mapRowsByProp("id", data);

  return (
    <div id={dropdownBtnId} className="metaBuyingSearchMenu menuToggle">
      <Button className="dropdown-toggle" type={ButtonType.OUTLINED}>
        {appliedSelection && idMap[appliedSelection] && idMap[appliedSelection][nameProp] ? (
          <div
            className="appliedSelection"
            onClick={() => {
              removeSelection();
              setSelectedId(-1);
              closeMenu();
            }}
          >
            <span>{idMap[appliedSelection][nameProp]}</span>
          </div>
        ) : (
          <span className="placeholder">{placeholder}</span>
        )}
      </Button>
      {showMenu && (
        <div id={searchMenuId} className="searchMenu menuToggle">
          {" "}
          <div>
            <Form.Control
              size="sm"
              className="searchBar"
              placeholder="Search"
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
            />
            <div className="searchMenuRows">
              {loading ? (
                <Spinner size={50} />
              ) : (
                data
                  .filter(({ name }) => new RegExp(searchQuery, "ig").test(name))
                  .map((option, index) => (
                    <div key={index} className="searchMenuRow">
                      <Form.Check
                        className="searchMenuOption"
                        key={index}
                        type="radio"
                        label={option[nameProp]}
                        checked={selectedId === option.id}
                        id={option[nameProp]}
                        onChange={() => setSelectedId(option.id)}
                      />
                    </div>
                  ))
              )}
            </div>
            <div className="searchMenuControls">
              <Button
                type={ButtonType.OUTLINED}
                variant={ButtonFrameworkVariant.NO_ICON}
                onClick={closeMenu}
              >
                Cancel
              </Button>
              <Button
                disabled={loading || R.isEmpty(selectedId)}
                type={ButtonType.FILLED}
                variant={ButtonFrameworkVariant.NO_ICON}
                onClick={() => {
                  applySelection(selectedId);
                  closeMenu();
                }}
              >
                Apply
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

interface MultiSelectMenuProps {
  placeholder: string;
  menuId: string;
  data: Record<string, any>[];
  closeMenu: () => void;
  showMenu: boolean;
  applySelections: (value: number[]) => void;
  appliedSelections: number[];
  subTypeMap: Record<string, any>;
  idProp?: string;
  loading?: boolean;
}

export const MultiSelectMenu = ({
  placeholder,
  menuId,
  data,
  closeMenu,
  showMenu,
  applySelections,
  appliedSelections,
  subTypeMap,
  idProp = "id",
  loading = false,
}: MultiSelectMenuProps): JSX.Element => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedIds, setSelectedIds] = useState<number[]>([]);

  const dropdownBtnId = [menuId, DROPDOWN_BTN].join("_");
  const searchMenuId = [menuId, SEARCH_MENU].join("_");

  const idMap = mapRowsByProp(idProp, data);

  useEffect(() => {
    setSelectedIds(R.clone(appliedSelections));
  }, [showMenu, appliedSelections]);

  return (
    <div id={dropdownBtnId} className="metaBuyingSearchMenu menuToggle">
      <Button className="dropdown-toggle" type={ButtonType.OUTLINED}>
        {appliedSelections.length > 0 ? (
          appliedSelections.map((audienceId, index) => (
            <div
              key={index}
              className="appliedSelection"
              onClick={() => {
                const newAppliedSelections = R.remove(index, 1, appliedSelections);
                applySelections(newAppliedSelections);
                setSelectedIds(newAppliedSelections);
                closeMenu();
              }}
            >
              <span>{idMap[audienceId] ? idMap[audienceId].name : ""}</span>
            </div>
          ))
        ) : (
          <span className="placeholder">{placeholder}</span>
        )}
      </Button>
      {showMenu && (
        <div id={searchMenuId} className="searchMenu menuToggle">
          {" "}
          <div>
            <Form.Control
              size="sm"
              className="searchBar"
              placeholder="Search"
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
            />
            <div className="searchMenuRows">
              {loading ? (
                <Spinner size={50} />
              ) : (
                data
                  .filter(({ name }) => new RegExp(searchQuery, "ig").test(name))
                  .map((option, index) => (
                    <div key={index} className="searchMenuRow">
                      <Form.Check
                        className="searchMenuOption"
                        key={index}
                        type="checkbox"
                        label={option.name}
                        checked={R.includes(option[idProp], selectedIds)}
                        id={option.name}
                        onChange={() =>
                          setSelectedIds(
                            R.includes(option[idProp], selectedIds)
                              ? selectedIds.filter(id => id !== option[idProp])
                              : [...selectedIds, option[idProp]]
                          )
                        }
                      />
                      <span>{subTypeMap[option.subtype]}</span>
                    </div>
                  ))
              )}
            </div>
            <div className="searchMenuControls">
              <Button
                type={ButtonType.OUTLINED}
                variant={ButtonFrameworkVariant.NO_ICON}
                onClick={closeMenu}
              >
                Cancel
              </Button>
              <Button
                disabled={loading || R.isEmpty(selectedIds)}
                type={ButtonType.FILLED}
                variant={ButtonFrameworkVariant.NO_ICON}
                onClick={() => {
                  applySelections(selectedIds);
                  closeMenu();
                }}
              >
                Apply
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

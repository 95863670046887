import React, { useEffect, useState } from "react";
import { Modal, Table } from "react-bootstrap";
import "./ChangeLogModal.scss";
import { awaitJSON, MetaLambdaFetch } from "../../../utils/fetch-utils";
import { Spinner } from "../../../Components";
import { MetaAdChanges } from "@blisspointmedia/bpm-types/dist/MetaBuying";

export interface ChangeLogProps {
  variant: "drafts" | "published";
  accountId: string;
  adId: string;
  onHide: () => void;
}

export const ChangeLogModal = ({
  variant,
  accountId,
  adId,
  onHide = () => {},
}: ChangeLogProps): JSX.Element => {
  const [changes, setChanges] = useState<MetaAdChanges[]>();
  useEffect(() => {
    if (!changes) {
      (async () => {
        const res = await MetaLambdaFetch("/getMetaBuyingChangeLog", {
          params: { accountId, adId },
        });
        const parsedRes = await awaitJSON(res);
        setChanges(parsedRes);
      })();
    }
  }, [accountId, adId, changes, setChanges]);

  return (
    <Modal className={"changeLogModal"} show={true} onHide={onHide} centered backdrop keyboard>
      <Modal.Header closeButton className="changeLogModalHeader">
        Change Log
      </Modal.Header>
      <Modal.Body className="changeLogModalBody">
        {!changes && <Spinner />}
        {changes && changes.length > 0 && (
          <Table responsive className="changeLogTable">
            <thead>
              <tr>
                <th>Edit Description</th>
                <th>Editor</th>
                <th>Date & Time</th>
              </tr>
            </thead>
            <tbody>
              {changes &&
                changes.map((change, index) => (
                  <tr key={index}>
                    {/* Edit Description */}
                    <td>
                      {Object.entries(change.changes).map(([key, value], idx) => (
                        <div key={idx}>
                          <strong>{key}</strong> changed from "<em>{value.old_value}</em>" to "
                          <em>{value.new_value}</em>"
                        </div>
                      ))}
                    </td>
                    {/* Editor */}
                    <td>{change.lastuser}</td>
                    {/* Date & Time */}
                    <td>{new Date(change.created).toLocaleString()}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        )}
        {changes && changes.length === 0 && (
          <div className="altText">No revisions have been made for this ad.</div>
        )}
      </Modal.Body>
      {variant === "published" && changes && changes.length > 0 && (
        <Modal.Footer className="changeLogModalFooter">
          Details may not be correct here if edits have been made in Ads Manager
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default ChangeLogModal;

import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Page, FullPageSpinner } from "../Components";
import { RouteComponentProps, Router } from "@reach/router";
import { useTabbedNav } from "../utils/hooks/useNav";
import MetaBuyingCreate from "./MetaBuyingCreate/MetaBuyingCreate";
import MetaBuyingUpload from "./MetaBuyingUpload";
import MetaBuyingDrafts from "./DraftsAndPublished/MetaBuyingDrafts";
import MetaBuyingPublished from "./DraftsAndPublished/MetaBuyingPublished";
import "./MetaBuying.scss";
import { useSelector } from "react-redux";
import * as UserRedux from "../redux/user";
import { MetaBuyingProvider } from "./MetaBuyingContext";
import { awaitJSON, MetaLambdaFetch } from "../utils/fetch-utils";
import { useSetError } from "../redux/modals";
import {
  MetaBuyingTableData,
  MetaBuyingOptions,
  Level,
} from "@blisspointmedia/bpm-types/dist/MetaBuying";
import { CAMPAIGN, AD_SET, AD } from "./MetaBuyingConstants";
import { useCompanyInfo } from "../redux/company";

export const enum PageTab {
  CREATE = "create",
  UPLOAD = "upload",
  DRAFTS = "drafts",
  PUBLISHED = "published",
}

const NAVS = [
  { label: "Create", key: PageTab.CREATE },
  { label: "Upload", key: PageTab.UPLOAD },
  { label: "Drafts", key: PageTab.DRAFTS },
  { label: "Published", key: PageTab.PUBLISHED },
];

const MetaBuying = React.memo(({ navigate }: RouteComponentProps) => {
  const { tab, goToTab } = useTabbedNav({
    navigate,
    baseURL: "social/meta-buying",
    defaultKey: PageTab.CREATE,
  });
  const { cid } = useCompanyInfo();
  const setError = useSetError();
  const isInternal = useSelector(UserRedux.isInternalSelector);
  const [selectedAdAccount, setSelectedAdAccount] = useState<{
    account_id: string;
    account_name: string;
    business_manager: string;
  }>({
    account_id: "",
    account_name: "",
    business_manager: "",
  });
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [creationTabSelections, setCreationTabSelections] = useState({
    Campaign: false,
    "Ad Set": false,
    Ad: false,
  });
  const [selectionsSubmitted, setSelectionsSubmitted] = useState(false);
  const [metaBuyingOptions, setMetaBuyingOptions] = useState<MetaBuyingOptions>();
  const [metaBuyingDataMap, setMetaBuyingDataMap] = useState<MetaBuyingTableData>();

  const [clientInfo, setClientInfo] = useState<{
    granularity: Level;
    namingConventions: {
      [CAMPAIGN]: number[];
      [AD_SET]: number[];
      [AD]: number[];
    };
    accounts: {
      account_id: string;
      account_name: string;
      business_manager: string;
    }[];
    dimensions: Record<string, any>;
  }>();

  useEffect(() => {
    if (!clientInfo && cid) {
      (async () => {
        try {
          let res = await MetaLambdaFetch("/getClientInfo", { params: { company: cid } });
          let { data } = await awaitJSON(res);

          setClientInfo(data);
          setSelectedAdAccount(data.accounts[0]);
        } catch (e) {
          setError({ message: e.message, reportError: e });
        }
      })();
    }
  }, [cid, setError, clientInfo]);

  useEffect(() => {
    if (tab !== PageTab.CREATE) {
      setTabIndex(0);
      setSelectionsSubmitted(false);
      setCreationTabSelections({
        Campaign: false,
        "Ad Set": false,
        Ad: false,
      });
    }
  }, [tab]);

  useEffect(() => {
    if (clientInfo && !metaBuyingOptions) {
      (async () => {
        try {
          let res = await MetaLambdaFetch("/getMetaBuyingOptions", {
            params: {
              accountIDs: clientInfo.accounts.map(account => account.account_id).join(","),
            },
          });
          const obj = await awaitJSON<any>(res);
          setMetaBuyingOptions(obj);
        } catch (e) {
          setError({ message: e.message, reportError: e });
        }
      })();
    }
  }, [metaBuyingOptions, setMetaBuyingOptions, clientInfo, setError]);

  // TODO: Set up customConversions logic
  const { customAudiences, pixels, pages } = useMemo(() => {
    if (selectedAdAccount && metaBuyingOptions) {
      const { account_id } = selectedAdAccount;
      const {
        customConversions,
        customAudiences,
        pixels,
        pages,
        // instagramAccounts,
      } = metaBuyingOptions[account_id];
      return { customConversions, customAudiences, pixels, pages };
    }
    return {
      customConversions: [],
      customAudiences: [],
      pixels: [],
      pages: [],
      // instagramAccounts: [],
    };
  }, [metaBuyingOptions, selectedAdAccount]);

  useEffect(() => {
    if (clientInfo && !metaBuyingDataMap) {
      (async () => {
        try {
          let res = await MetaLambdaFetch("/getMetaBuyingTableData", {
            params: {
              accountIDs: clientInfo.accounts.map(account => account.account_id).join(","),
            },
          });
          const obj = await awaitJSON<MetaBuyingTableData>(res);
          setMetaBuyingDataMap(obj);
        } catch (e) {
          setError({ message: e.message, reportError: e });
        }
      })();
    }
  }, [metaBuyingDataMap, setMetaBuyingDataMap, clientInfo, setError]);

  /*
   * Used to trigger refetch of data when certain actions are performed
   * in the drafts section.
   */
  const refreshData = useCallback(
    (tab: PageTab) => {
      setMetaBuyingDataMap(undefined);
      goToTab(tab);
    },
    [setMetaBuyingDataMap, goToTab]
  );

  const { campaigns, campaignDrafts, publishedCampaigns } = useMemo(() => {
    if (metaBuyingDataMap && selectedAdAccount) {
      const campaigns = metaBuyingDataMap[selectedAdAccount.account_id]?.campaign || [];
      return {
        campaigns,
        campaignDrafts: campaigns.filter(campaign => campaign.meta_id === null),
        publishedCampaigns: campaigns.filter(campaign => campaign.meta_id !== null),
      };
    } else {
      return { campaigns: [], campaignDrafts: [], publishedCampaigns: [] };
    }
  }, [metaBuyingDataMap, selectedAdAccount]);

  const { adSets, adSetDrafts, publishedAdSets } = useMemo(() => {
    if (metaBuyingDataMap && selectedAdAccount) {
      const adSets = metaBuyingDataMap[selectedAdAccount.account_id]?.adset || [];

      return {
        adSets,
        adSetDrafts: adSets.filter(adSet => adSet.meta_id === null),
        publishedAdSets: adSets.filter(adSet => adSet.meta_id !== null),
      };
    } else {
      return { adSets: [], adSetDrafts: [], publishedAdSets: [] };
    }
  }, [metaBuyingDataMap, selectedAdAccount]);

  const { adDrafts, publishedAds } = useMemo(() => {
    if (metaBuyingDataMap && selectedAdAccount) {
      const ads = metaBuyingDataMap[selectedAdAccount.account_id]?.ad || [];

      return {
        adDrafts: ads.filter(ad => ad.meta_id === null),
        publishedAds: ads.filter(ad => ad.meta_id !== null),
      };
    } else {
      return { adDrafts: [], publishedAds: [] };
    }
  }, [metaBuyingDataMap, selectedAdAccount]);

  return (
    <MetaBuyingProvider
      tab={tab as string}
      refreshData={() => {
        setMetaBuyingDataMap(undefined);
      }}
    >
      <Page
        app2Redesign
        title="Meta Buying"
        pageType="Meta Buying"
        navs={NAVS}
        selectedNav={tab}
        onNav={goToTab}
      >
        <div className="metaBuyingPage">
          {clientInfo && metaBuyingDataMap ? (
            <Router className="fullPageRouter">
              <MetaBuyingCreate
                default
                path={PageTab.CREATE}
                selectedAdAccount={selectedAdAccount}
                setSelectedAdAccount={setSelectedAdAccount}
                adAccountOptions={clientInfo.accounts}
                namingConventions={clientInfo.namingConventions}
                client={cid}
                granularity={clientInfo.granularity}
                dimensions={clientInfo.dimensions}
                tabIndex={tabIndex}
                setTabIndex={setTabIndex}
                selectionsSubmitted={selectionsSubmitted}
                setSelectionsSubmitted={setSelectionsSubmitted}
                creationTabSelections={creationTabSelections}
                setCreationTabSelections={setCreationTabSelections}
                campaigns={campaigns}
                adSets={adSets}
                customAudiences={customAudiences}
                pixels={pixels}
                facebookPages={pages}
                // instagramAccounts={instagramAccounts}
                goToTables={async (tab: PageTab) => {
                  goToTab(tab);
                  try {
                    let res = await MetaLambdaFetch("/getMetaBuyingTableData", {
                      params: {
                        accountIDs: clientInfo.accounts
                          .map(account => account.account_id)
                          .join(","),
                      },
                    });
                    const obj = await awaitJSON<MetaBuyingTableData>(res);
                    setMetaBuyingDataMap(obj);
                  } catch (e) {
                    setError({ message: e.message, reportError: e });
                  }
                }}
              />
              <MetaBuyingUpload
                path={PageTab.UPLOAD}
                selectedAdAccount={selectedAdAccount}
                refreshData={refreshData}
              />
              <MetaBuyingDrafts
                path={PageTab.DRAFTS}
                isInternal={isInternal}
                selectedAdAccount={selectedAdAccount}
                setSelectedAdAccount={setSelectedAdAccount}
                adAccountOptions={clientInfo.accounts}
                campaignRows={campaignDrafts}
                adSetRows={adSetDrafts}
                adRows={adDrafts}
                refreshData={refreshData}
                goToTab={goToTab}
                tabIndex={tabIndex}
                setTabIndex={setTabIndex}
                selectionsSubmitted={selectionsSubmitted}
                setSelectionsSubmitted={setSelectionsSubmitted}
                creationTabSelections={creationTabSelections}
                setCreationTabSelections={setCreationTabSelections}
              />
              <MetaBuyingPublished
                path={PageTab.PUBLISHED}
                isInternal={isInternal}
                selectedAdAccount={selectedAdAccount}
                setSelectedAdAccount={setSelectedAdAccount}
                adAccountOptions={clientInfo.accounts}
                campaignRows={publishedCampaigns}
                adSetRows={publishedAdSets}
                adRows={publishedAds}
              />
            </Router>
          ) : (
            <FullPageSpinner />
          )}
        </div>
      </Page>
    </MetaBuyingProvider>
  );
});

export default MetaBuying;

import React, { useMemo } from "react";
import { MdDelete, MdArrowForward } from "react-icons/md";
import { BPMButton } from "../Components";
import { EditsMapAction, SegmentEditsMap, SegmentRow } from "./SegmentationLabeling";

interface PendingSegmentationEditsProps {
  campaign_key: string;
  editsMap: SegmentEditsMap;
  dispatchEditsMap?: React.Dispatch<EditsMapAction>;
  segmentNames: string[];
  onlyShowUnset?: boolean;
  hideUnchanged?: boolean;
}

const PendingSegmentationEdits: React.FC<PendingSegmentationEditsProps> = ({
  campaign_key,
  editsMap,
  dispatchEditsMap,
  segmentNames,
  onlyShowUnset = false,
  hideUnchanged = false,
}) => {
  const row: SegmentRow = editsMap[campaign_key];
  const { account_name, account_id, campaign_name, campaign_id } = row;

  const editTitle = useMemo(() => {
    const account = account_name || account_id || "";
    const campaign = campaign_name || campaign_id || "";

    return `${account}${account && campaign ? " - " : ""}${campaign}`;
  }, [account_id, account_name, campaign_id, campaign_name]);

  return (
    <div className="editContainer">
      <div className="editTitle">{editTitle}</div>
      <div className="editsList">
        {segmentNames.map(segmentName => {
          const newValue = (row.edits && row.edits[segmentName]?.label) ?? null;
          const oldValue = row[segmentName];
          const valueIsChanged = Boolean(newValue) && oldValue !== newValue;

          // If there is no change in value, do not render
          if (newValue && newValue === oldValue) {
            return null;
          }

          // If we only want to show missing values, either a new value or an old value will suffice
          if (onlyShowUnset && (oldValue || newValue)) {
            return null;
          }

          // If there is no key in the edits map, then the value has not been altered and we might not want to show it
          if (hideUnchanged && !newValue) {
            return null;
          }

          return (
            <div className="edit" key={segmentName}>
              {newValue && dispatchEditsMap && (
                <BPMButton
                  className="deleteButton"
                  size="sm"
                  variant="outline-danger"
                  onClick={() => {
                    dispatchEditsMap({
                      type: "DELETE_SEGMENT_EDIT",
                      segmentName,
                      campaign_key,
                    });
                  }}
                >
                  <MdDelete size={20} />
                </BPMButton>
              )}
              {!newValue && dispatchEditsMap && <div className="deleteButtonSpacer" />}
              <span className="segmentName">{segmentName}:</span>
              {valueIsChanged && (
                <>
                  <span className="oldValue">{oldValue || "Not Set"}</span>
                  <MdArrowForward />
                </>
              )}
              <span className={valueIsChanged || oldValue ? "newValue" : "oldValue"}>
                {(valueIsChanged ? newValue : oldValue) ?? "Not Set"}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PendingSegmentationEdits;

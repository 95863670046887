// Experiment for Budget Intake Tool

const budgetIntakeToolUsers: string[] = [
  "miguel.garcia@tinuiti.com",
  "micah.kilimann@tinuiti.com",
  "ben.plantz@tinuiti.com",
  // Add users here!
];

export const shouldEnableBudgetIntakeTool = (userEmail: string): boolean => {
  return budgetIntakeToolUsers.includes(userEmail);
};

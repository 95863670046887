import "./CustomerInsights.scss";
import React, { useEffect, useMemo, useState } from "react";
import * as R from "ramda";
import {
  KnowYourCustomerConfigRow,
  KnowYourCustomerData,
  KnowYourCustomerDataResponse,
} from "@blisspointmedia/bpm-types/dist/KnowYourCustomer";
import {
  Button,
  ButtonFrameworkVariant,
  ButtonType,
  Dropdown,
  DropdownToggleType,
  FullPageSpinner,
  IconToggleButton,
  InfoTooltip,
  InfoTooltipDirection,
  Page,
  TextToggleButton,
} from "../Components";
import { useCompanyInfo } from "../redux/company";
import { awaitJSON, MiscLambdaFetch } from "../utils/fetch-utils";
import { useSetError } from "../redux/modals";
import CustomerInsightsGroup from "./CustomerInsightsGroup";
import { format } from "date-fns";
import CustomerInsightsGeoGroup from "./CustomerInsightsGeoGroup";
import { useTabbedNav } from "../utils/hooks/useNav";
import { RouteComponentProps } from "@reach/router";
import CustomerInsightsHeader from "./CustomerInsightsHeader";
import {
  CUSTOMER_INSIGHTS_ORDERED_GROUPS,
  CUSTOMER_INSIGHTS_PRETTY_NAMES,
  CustomerInsightsFields,
  NAVS,
  SORT_DROPDOWN_OPTIONS,
  SortOptions,
  TabKey,
  ViewOptions,
} from "./customerInsightsConstants";
import { MdOutlineFileDownload, MdOutlineTableRows, MdShowChart } from "react-icons/md";
import { downloadJSONToCSV } from "../utils/download-utils";
import { Neutral600 } from "../utils/colors";

const CustomerInsights = React.memo(({ navigate }: RouteComponentProps) => {
  const { tab, goToTab } = useTabbedNav({
    navigate,
    baseURL: "customer-insights",
    defaultKey: TabKey.CUSTOMER_BASE,
  });
  const { cid, name: companyName } = useCompanyInfo();
  const setError = useSetError();
  const [data, setData] = useState<Record<string, KnowYourCustomerData[]>>();
  const [lastUpdated, setLastUpdated] = useState<Date>();
  const [matchRate, setMatchRate] = useState<{
    matchedPopulation: number;
    totalPopulation: number;
    fraction: number;
  }>();
  const [fetching, setFetching] = useState<boolean>(true);
  const [sortValue, setSortValue] = useState<SortOptions>("alphabetical");
  const [graphView, setGraphView] = useState<ViewOptions>("showGraph");
  const [suppressUnknown, setSuppressUnknown] = useState<boolean>(true);

  useEffect(() => {
    if (!data) {
      const fetchData = async () => {
        try {
          const configList = await MiscLambdaFetch<{ company: string }>(
            "/list_know_your_customer_configs",
            {
              method: "GET",
              params: {
                company: cid,
              },
            }
          );
          const configListJson: KnowYourCustomerConfigRow[] = await awaitJSON(configList);

          const allDefaults = configListJson.filter(row => row.is_default);
          const defaultConfig = allDefaults.length > 0 ? allDefaults[0] : undefined;

          if (!defaultConfig) {
            throw new Error("No default Know Your Customer config found");
          }

          const configData = await MiscLambdaFetch<{ id: string }>(
            "/get_know_your_customer_config",
            {
              method: "GET",
              params: {
                id: String(defaultConfig.id),
              },
            }
          );
          const { rows, lastUpdated }: KnowYourCustomerDataResponse = await awaitJSON(configData);
          setLastUpdated(new Date(lastUpdated));

          const typedRows: KnowYourCustomerData[] = rows.map(row => {
            return {
              clientPopulation: Number(row.client_population),
              clientPopulationFraction: Number(row.client_population_fraction),
              fieldName: row.field_name,
              levels: row.levels,
              tuPopulation: Number(row.tu_population),
              tuPopulationFraction: Number(row.tu_population_fraction),
            };
          });
          const groupedRows = R.groupBy(row => row.fieldName, typedRows);

          const matchRateRows = groupedRows[CustomerInsightsFields.MATCH_RATE];
          if (matchRateRows && matchRateRows.length > 0) {
            const matchRate = matchRateRows[0];
            setMatchRate({
              matchedPopulation: matchRate?.clientPopulation,
              totalPopulation: Math.round(
                matchRate?.clientPopulation / matchRate?.clientPopulationFraction
              ),
              fraction: matchRate?.clientPopulationFraction,
            });
          }

          delete groupedRows[""];
          setData(groupedRows);
          setFetching(false);
        } catch (e: any) {
          setError(e.message);
          setFetching(false);
        }
      };

      fetchData();
    }
  }, [cid, data, setError]);

  const unknownSuppressedData: Record<string, KnowYourCustomerData[]> = useMemo(() => {
    if (!data) {
      return {};
    }

    const modifiedData = R.clone(data);

    Object.keys(modifiedData).forEach(key => {
      const groups = modifiedData[key];
      const unknown = groups.find(group => group.levels === "Unknown");

      if (unknown) {
        const indexOfUnknown = groups.indexOf(unknown);
        groups.splice(indexOfUnknown, 1);

        // Recalculate fractions without Unknown
        const clientPopSum = groups.reduce((acc, group) => {
          return acc + group.clientPopulation;
        }, 0);
        const tuPopSum = groups.reduce((acc, group) => {
          return acc + group.tuPopulation;
        }, 0);
        groups.forEach(group => {
          group.clientPopulationFraction = group.clientPopulation / clientPopSum;
          group.tuPopulationFraction = group.tuPopulation / tuPopSum;
        });
      }
    });

    return modifiedData;
  }, [data]);

  const body = useMemo(() => {
    if (!data && fetching) {
      return <FullPageSpinner />;
    }

    if (!data && !fetching) {
      return <div>TODO: Empty State</div>;
    }

    if (data) {
      return (
        <div className="customerInsightsBody">
          <CustomerInsightsHeader tab={tab} matchRate={matchRate} />
          <CustomerInsightsGroup
            fields={CUSTOMER_INSIGHTS_ORDERED_GROUPS.household}
            data={suppressUnknown ? unknownSuppressedData : data}
            header={`What types of households do ${companyName} customers reside in?`}
            sortValue={sortValue}
            graphView={graphView}
            tab={tab}
            companyName={companyName}
            suppressUnknown={suppressUnknown}
          />
          <CustomerInsightsGroup
            fields={CUSTOMER_INSIGHTS_ORDERED_GROUPS.individual}
            data={suppressUnknown ? unknownSuppressedData : data}
            header={`What types of individuals form ${companyName}'s customer base?`}
            sortValue={sortValue}
            graphView={graphView}
            tab={tab}
            companyName={companyName}
            suppressUnknown={suppressUnknown}
          />
          <CustomerInsightsGeoGroup
            data={
              suppressUnknown
                ? unknownSuppressedData[CustomerInsightsFields.MSA]
                : data[CustomerInsightsFields.MSA]
            }
            totalPopulation={matchRate?.totalPopulation ?? 0}
            header={`Where do ${companyName} customers reside?`}
            tab={tab}
            graphView={graphView}
          />
        </div>
      );
    }
  }, [
    data,
    fetching,
    tab,
    matchRate,
    suppressUnknown,
    unknownSuppressedData,
    sortValue,
    graphView,
    companyName,
  ]);

  const renderToggleWithInfoTooltip = (label: string): JSX.Element => {
    const isActive = suppressUnknown ? label === "Hide ´Unknown´" : label === "Show ´Unknown´";

    return isActive ? (
      <div>
        {label}
        <InfoTooltip size="reg" color={Neutral600} direction={InfoTooltipDirection.BOTTOM_RIGHT}>
          When an email address is recognized in the identity graph, specific demographic
          characteristics (e.g., ethnicity) may not be known with high confidence. In these cases, a
          value of &acute;Unknown&acute; will be returned for that characteristic for that user. You
          can suppress these values by using the Hide &acute;Unknown&acute; button to make relative
          comparisons easier to visualize.
        </InfoTooltip>
      </div>
    ) : (
      <>{label}</>
    );
  };

  return (
    <Page
      app2Redesign
      title="Customer Insights"
      pageType="Customer Insights"
      navs={NAVS}
      selectedNav={tab}
      onNav={goToTab}
      actions={
        <div className="customerInsightsActions">
          {lastUpdated && <div>Last Updated: {format(lastUpdated, "M/d/yy h:mmaaa")}</div>}
          <TextToggleButton
            design="primary-dark"
            options={[
              {
                key: "hide",
                label: "Hide ´Unknown´",
                renderLabel: renderToggleWithInfoTooltip,
              },
              { key: "show", label: "Show ´Unknown´", renderLabel: renderToggleWithInfoTooltip },
            ]}
            selectedOption={suppressUnknown ? "hide" : "show"}
            onChange={selected => setSuppressUnknown(selected === "hide")}
          />
          <div className="sortContainer">
            <Dropdown
              type={DropdownToggleType.FILLED}
              value={sortValue}
              options={SORT_DROPDOWN_OPTIONS}
              onChange={change => setSortValue(change)}
              background="dark"
            />
          </div>
          <IconToggleButton
            options={[
              { key: "showTable", icon: <MdOutlineTableRows />, label: "table view" },
              { key: "showGraph", icon: <MdShowChart />, label: "graph view" },
            ]}
            size="sm"
            background="dark"
            selectedOption={graphView}
            onChange={() =>
              setGraphView(prev => (prev === "showGraph" ? "showTable" : "showGraph"))
            }
          />
          <Button
            type={ButtonType.FILLED}
            variant={ButtonFrameworkVariant.ICON_ONLY}
            background="dark"
            icon={<MdOutlineFileDownload />}
            onClick={() => {
              if (!data) {
                setError({ message: "No data available to download" });
                return;
              }

              const formattedData = Object.keys(data)
                .map(fieldName => {
                  const rows = data[fieldName];
                  const fieldPrettyName = CUSTOMER_INSIGHTS_PRETTY_NAMES[fieldName];

                  return rows.map(row => ({
                    "Demographic Category": fieldPrettyName,
                    "Demographic Group": row.levels,
                    [`${companyName} Population`]: Math.round(row.clientPopulation),
                    [`${companyName} Distribution`]: row.clientPopulationFraction,
                    "US Market": Math.round(row.tuPopulation),
                    "US Market Distribution": row.tuPopulationFraction,
                    Index: row.clientPopulationFraction / row.tuPopulationFraction,
                    Penetration: row.clientPopulation / row.tuPopulation,
                  }));
                })
                .flat();

              const date = new Date();
              downloadJSONToCSV(
                formattedData,
                `CustomerInsights ${companyName} ${date.toLocaleString()}`
              );
            }}
          />
        </div>
      }
    >
      {body}
    </Page>
  );
});

export default CustomerInsights;

import React, { useEffect, useState, useMemo, useCallback } from "react";
import * as R from "ramda";
import * as Dfns from "date-fns/fp";
import Papa from "papaparse";
import { download } from "../utils/download-utils";
import CreatableSelect from "react-select/creatable";
import { useSetAreYouSure, useSetError } from "../redux/modals";

import {
  MdFileDownload,
  MdCheck,
  MdSave,
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdDeleteForever,
  MdEdit,
  MdError,
} from "react-icons/md";
import {
  Button,
  ToggleButton,
  ToggleButtonGroup,
  Modal,
  Form,
  Col,
  Tooltip,
  Toast,
} from "react-bootstrap";

import { useExperimentFlag } from "../utils/experiments/experiment-utils";
import useLocation from "../utils/hooks/useLocation";
import { useSelector } from "react-redux";
import { useStateFunction } from "../utils/hooks/useData";

import { LinearLambdaFetch, awaitJSON, pollS3, S3SignedUrlFetch } from "../utils/fetch-utils";

import * as UserRedux from "../redux/user";

import {
  Page,
  Spinner,
  BPMTable,
  NumberFormatter,
  DateRangePicker,
  Skeleton,
  TableSkeleton,
  OldFilterBar,
  OverlayTrigger,
} from "../Components";

import { TYPES_TO_NAMES } from "@blisspointmedia/bpm-types/dist/LinearBuying";

import "./LinearPlans.scss";

const BY_COUNT_LABEL = "Count";
const BY_SPEND_LABEL = "Spend";

const NETWORK_AVAIL_LABEL = "Network/Avail";
const EXPANDED_LABEL = "Expanded";

const DATE_FORMAT = "yyyy-MM-dd";
const CURRENT_WEEK = R.pipe(Dfns.startOfISOWeek, Dfns.format(DATE_FORMAT))(new Date());

const NOTE_PRESET_OPTIONS = [
  "Bonus",
  "Not ordered (day/daypart)",
  "Not ordered (network/length)",
  "Overpaid",
  "Paid programming",
  "Too many paid spots",
  "Bad spot separation",
  "Underpaid",
  "WideOrbit",
  "Wrong ISCI",
  "Accept",
  "Credit",
];

const ExportSpotSummaryConfirmation = ({ company, show, setShow }) => {
  const [dayType, setDayType] = useState("Calendar");
  const [start, setStart] = useState(CURRENT_WEEK);
  const [end, setEnd] = useState(
    R.pipe(Dfns.parseISO, Dfns.addDays(7), Dfns.format(DATE_FORMAT))(CURRENT_WEEK)
  );
  const [downloading, setDownloading] = useState(false);

  return (
    <Modal size="lg" keyboard={true} show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Export Network Summary</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Row>
          <Form.Group>
            <Form.Label>Day Type</Form.Label>
            <Form.Control
              as="select"
              value={dayType}
              onChange={e => setDayType(e.target.value || "")}
            >
              <option>Calendar</option>
              <option>Broadcast</option>
            </Form.Control>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group>
            <Form.Label>Start (Inclusive) - End (Exclusive)</Form.Label>
            <DateRangePicker
              startDate={start}
              endDate={end}
              startDateId="exportSpotsStartDate"
              endDateId="exportSpotsEndDate"
              onChange={({ startDate, endDate }) => {
                if (startDate && endDate) {
                  setStart(startDate);
                  setEnd(endDate);
                }
              }}
            />
          </Form.Group>
        </Form.Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="success"
          disabled={downloading}
          onClick={async () => {
            setDownloading(true);
            let lambdaData = await LinearLambdaFetch("/network_summary", {
              params: {
                company,
                start,
                end,
                calendar: dayType === "Calendar",
              },
            });
            const networkSummary = await awaitJSON(lambdaData);
            download(
              networkSummary.csv,
              `Network Summary ${company} ${dayType} ${start} - ${end}.csv`,
              "text/csv"
            );
            setDownloading(false);
            setShow(false);
          }}
        >
          {downloading ? <Spinner /> : <MdFileDownload />}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const DeleteSpotsModal = ({ deleteSelectedSpots, show, setShow }) => {
  return (
    <Modal size="lg" keyboard={true} show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Selected Rows?</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <Button
          variant="danger"
          onClick={() => {
            deleteSelectedSpots();
            setShow(false);
          }}
        >
          Delete Rows
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const EditSpotsModal = ({ selectedSpots, updateSelectedSpots, show, setShow }) => {
  const [accepted, setAccepted] = useState(selectedSpots[0] ? selectedSpots[0].accepted : false);
  const [cost, setCost] = useState(selectedSpots[0] ? selectedSpots[0].cost : 0);
  const [creditAmount, setCreditAmount] = useState(
    selectedSpots[0] ? selectedSpots[0].credit_amount : 0
  );
  const [costString, setCostString] = useState(cost);
  const [creditAmountString, setCreditAmountString] = useState(creditAmount);
  const [reconciled, setReconciled] = useState(selectedSpots[0] ? selectedSpots[0].reconciled : -1);
  const [notes, setNotes] = useState(
    selectedSpots[0] ? R.defaultTo("", selectedSpots[0].notes) : ""
  );
  const [customNote, setCustomNote] = useState("");
  const enableCAD = useExperimentFlag("enableCADLinearCompanies");

  const notesObject = useMemo(() => {
    if (notes.length === 0) {
      return [];
    }
    return notes
      .split("; ")
      .filter(notes => notes.length > 0)
      .map(notes => ({ label: notes, value: notes }));
  }, [notes]);
  const creditMemoStatus = useMemo(() => {
    if (selectedSpots[0]?.creditMemoStatus) {
      return selectedSpots[0].creditMemoStatus;
    } else {
      return null;
    }
  }, [selectedSpots]);

  const isCreditValid = useMemo(() => {
    try {
      return parseFloat(cost) >= parseFloat(creditAmount);
    } catch (err) {
      return true;
    }
  }, [cost, creditAmount]);

  if (selectedSpots.length === 0) {
    setShow(false);
    return null;
  }

  const updateTargetValue = (event, realValueSetter, formValueSetter) => {
    const target = event.target.value.replace(/[^\d.]/g, "");
    if (!isNaN(parseFloat(target))) {
      // handle allowing decimal places but overwriting leading zeros
      if (/.*\..*/.test(target)) {
        formValueSetter(target);
      } else {
        formValueSetter(String(parseFloat(target)));
      }
      realValueSetter(parseFloat(target));
    } else {
      formValueSetter("0");
      realValueSetter(0);
    }
  };

  return (
    <Modal size="lg" keyboard={true} show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Rows</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>Accepted</Form.Label>
            <Button
              className="acceptedButton"
              variant="link"
              onClick={() => setAccepted(!accepted)}
            >
              {accepted ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />}
            </Button>
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Reconciled</Form.Label>
            <Form.Control
              value={R.defaultTo("", reconciled)}
              onChange={e => updateTargetValue(e, () => {}, setReconciled)}
            />
          </Form.Group>
        </Form.Row>

        <OverlayTrigger
          placement={OverlayTrigger.PLACEMENTS.BOTTOM.CENTER}
          overlay={
            creditMemoStatus !== null ? (
              <Tooltip>
                Editing is disabled because a credit memo exists for this spot. Delete the credit
                memo to edit
              </Tooltip>
            ) : (
              <></>
            )
          }
        >
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Cost</Form.Label>
              <Form.Control
                value={enableCAD ? `CA$${costString}` : `$${costString}`}
                onChange={e => updateTargetValue(e, setCost, setCostString)}
                disabled={creditMemoStatus !== null}
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Credit Amount</Form.Label>
              <Form.Control
                value={enableCAD ? `CA$${creditAmountString}` : `$${creditAmountString}`}
                onChange={e => updateTargetValue(e, setCreditAmount, setCreditAmountString)}
                disabled={creditMemoStatus !== null}
              />
            </Form.Group>
          </Form.Row>
        </OverlayTrigger>

        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>Notes</Form.Label>
            <CreatableSelect
              isClearable
              isMulti
              inputValue={customNote}
              onInputChange={setCustomNote}
              value={notesObject}
              options={NOTE_PRESET_OPTIONS.map(op => ({ label: op, value: op }))}
              onChange={arr =>
                setNotes(
                  arr
                    ? [
                        ...new Set(
                          arr.map(el => {
                            return el.value[el.value.length - 1] === ";"
                              ? `${el.value} `
                              : `${el.value}; `;
                          })
                        ),
                      ]
                        .join("")
                        .trim()
                    : ""
                )
              }
            />
          </Form.Group>
        </Form.Row>
      </Modal.Body>
      <Modal.Footer>
        <OverlayTrigger
          placement={OverlayTrigger.PLACEMENTS.LEFT.CENTER}
          overlay={isCreditValid ? <></> : <Tooltip>Credit cannot be greater than cost</Tooltip>}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              position: "relative",
            }}
          >
            {!isCreditValid ? (
              <div
                style={{ position: "absolute", width: "100%", height: "100%", zIndex: 100 }}
              ></div>
            ) : (
              false
            )}
            <Button
              variant="success"
              disabled={!isCreditValid}
              onClick={() => {
                updateSelectedSpots({
                  accepted,
                  cost,
                  credit_amount: creditAmount,
                  reconciled,
                  notes,
                });
                setShow(false);
              }}
            >
              Done
            </Button>
          </div>
        </OverlayTrigger>
      </Modal.Footer>
    </Modal>
  );
};

const PLAN_DETAIL_KEYS_TO_SUM = [
  "booked_count",
  "booked_spend",
  "cleared_count",
  "cleared_spend",
  "booked_m",
  "booked_tu",
  "booked_w",
  "booked_th",
  "booked_f",
  "booked_sa",
  "booked_su",
  "cleared_m",
  "cleared_tu",
  "cleared_w",
  "cleared_th",
  "cleared_f",
  "cleared_sa",
  "cleared_su",
];

const PLAN_DETAIL_CSV_HEADERS = [
  { label: "Week", name: "week" },
  { label: "Network", name: "network" },
  { label: "Avail", name: "avail" },
  { label: "Length", name: "length" },
  { label: "Days", name: "dow" },
  { label: "Daypart", name: "daypart" },
  { label: "Rotation", name: "rotation" },
  { label: "Type", name: "type" },
  { label: "Unit Cost", name: "cost" },
  { label: "Booked M", name: "booked_m" },
  { label: "Booked Tu", name: "booked_tu" },
  { label: "Booked W", name: "booked_w" },
  { label: "Booked Th", name: "booked_th" },
  { label: "Booked F", name: "booked_f" },
  { label: "Booked Sa", name: "booked_sa" },
  { label: "Booked Su", name: "booked_su" },
  { label: "Booked Count", name: "booked_count" },
  { label: "Booked Spend", name: "booked_spend" },
  { label: "Cleared M", name: "cleared_m" },
  { label: "Cleared Tu", name: "cleared_tu" },
  { label: "Cleared W", name: "cleared_w" },
  { label: "Cleared Th", name: "cleared_th" },
  { label: "Cleared F", name: "cleared_f" },
  { label: "Cleared Sa", name: "cleared_sa" },
  { label: "Cleared Su", name: "cleared_su" },
  { label: "Cleared Count", name: "cleared_count" },
  { label: "Cleared Spend", name: "cleared_spend" },
  { label: "Cleared Rate", name: "cleared_rate" },
  { label: "Cleared Hist", name: "cleared_hist" },
];

const DAYS_BY_INDEX = ["M", "Tu", "W", "Th", "F", "Sa", "Su"];

const LinearPlans = React.memo(() => {
  const setError = useSetError();
  const setAreYouSure = useSetAreYouSure(true);
  const { company } = useLocation();
  const [startWeek, setStartWeek] = useState(CURRENT_WEEK);
  const [endWeek, setEndWeek] = useState(CURRENT_WEEK);
  const [data, setData] = useState();
  const [downloadingUrl, setDownloadingUrl] = useState();
  const [displayByCount, setDisplayByCount] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const [showExportSpotSummaryConfirmation, setShowExportSpotSummaryConfirmation] = useState(false);
  const [showEditSelectedRows, setShowEditSelectedRows] = useState(false);
  const [showDeleteSpotsConfirmation, setShowDeleteSpotsConfirmation] = useState(false);
  const [saving, setSending] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [planDetailFilter, setPlanDetailFilter] = useStateFunction(() => true);
  const [spotDetailFilter, setSpotDetailFilter] = useStateFunction(() => true);
  const [planFilterAdvanced, setPlanFilterAdvanced] = useState(false);
  const [spotFilterAdvanced, setSpotFilterAdvanced] = useState(false);
  const [spotDetailSortParamList, setSpotDetailSortParamList] = useState([]);
  const [nCreditMemosToast, setNCreditMemosToast] = useState(0);
  const [showMakingCreditMemoToast, setShowMakingCreditMemoToast] = useState(false);
  let isInternal = useSelector(UserRedux.isInternalSelector);
  const enableCAD = useExperimentFlag("enableCADLinearCompanies");

  const updateReconcileStatus = useCallback(
    async row => {
      let { dnr } = data;
      if (row.status === "") {
        dnr = R.filter(
          missingRow =>
            row.week !== missingRow.week ||
            row.company !== missingRow.company ||
            row.network !== missingRow.network ||
            row.avail !== missingRow.avail,
          dnr
        );
      } else if (row.status === "DNR") {
        dnr = R.concat(dnr, [row]);
      }

      setData({
        ...data,
        dnr,
      });
      await LinearLambdaFetch("/linear_reconciliation", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: [row],
      });
    },
    [data]
  );

  const fileDownloadColumn = useCallback(
    (label, name) => {
      return {
        label,
        name,
        width: 60,
        renderer: row => {
          if (!row[name]) {
            return null;
          } else if (downloadingUrl === row[name]) {
            return <Spinner size={20} />;
          } else {
            return (
              <Button
                variant="link"
                onClick={async () => {
                  if (row[name]) {
                    const parts = row[name].match(/([^/]+)\/(.*)/);
                    if (parts && parts.length >= 2) {
                      setDownloadingUrl(row[name]);
                      await pollS3({
                        bucket: parts[1],
                        filename: parts[2],
                      });
                      setDownloadingUrl();
                    } else {
                      setError({ message: `Invalid parts ${row[name]}` });
                    }
                  }
                }}
              >
                <MdFileDownload />
              </Button>
            );
          }
        },
      };
    },
    [downloadingUrl, setError]
  );

  const isEditable = useMemo(() => {
    if (!data) {
      return false;
    }

    const costs = R.pipe(
      R.filter(r => r.selected),
      R.pluck("cost"),
      R.uniq
    )(data.spots);
    return costs.length === 1;
  }, [data]);

  const updateSpots = useCallback(async () => {
    setSending(true);
    try {
      // notify user if credit memos are being made because the
      // query will take longer in that case
      if (
        data.spots.filter(row => row.credit_amount > 0 && row.creditMemoStatus === null).length > 0
      ) {
        setShowMakingCreditMemoToast(true);
      }
      const res = await LinearLambdaFetch("/update_spots", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: {
          data: R.filter(row => row.dirty, data.spots),
        },
      });
      const { spotsWithCreditMemos, nMemosCreated } = await awaitJSON(res);
      setNCreditMemosToast(nMemosCreated);

      setData({
        ...data,
        spots: R.map(spotRow => R.omit(["dirty"], spotRow), data.spots).map(spotRow => {
          if (spotsWithCreditMemos.includes(spotRow.id)) {
            spotRow.creditMemoStatus = "Pending";
          }
          return spotRow;
        }),
      });
    } catch (err) {
      setError({ message: err.message, reportError: err });
    }
    setSending(false);
  }, [data, setError]);

  const deleteSpots = useCallback(async () => {
    setSending(true);
    await LinearLambdaFetch("/delete_spots", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: R.filter(row => row.selected, data.spots),
    });
    setData({
      ...data,
      spots: R.filter(spotRow => !spotRow.selected, data.spots),
    });
    setSending(false);
  }, [data]);

  const updateSpot = useCallback(
    row => {
      setData({
        ...data,
        spots: R.map(spotRow => (spotRow.id === row.id ? row : spotRow), data.spots),
      });
    },
    [data]
  );

  const editSingleRow = useCallback(
    row => {
      setData({
        ...data,
        spots: R.map(spotRow => ({ ...spotRow, selected: spotRow.id === row.id }), data.spots),
      });
      setShowEditSelectedRows(true);
    },
    [data]
  );

  const spotDetailHeaders = useMemo(() => {
    return R.filter(R.identity, [
      isInternal && {
        label: "",
        name: "selected",
        width: 40,
        nonInteractive: true,
        renderer: row => (
          <Button
            variant="link"
            onClick={() => {
              updateSpot({ ...row, selected: !row.selected });
            }}
          >
            {row.selected ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />}
          </Button>
        ),
      },
      startWeek !== endWeek && {
        label: "Week",
        name: "week",
      },
      {
        label: "Network",
        name: "network",
        width: 160,
        renderer: row =>
          row.network.indexOf("DSH") === 0 ||
          row.network.indexOf("DT") === 0 ||
          row.network.indexOf("ITN") === 0 ||
          row.network.indexOf("CMAC") === 0 ||
          row.network.indexOf("CMFC") === 0 ||
          row.network.indexOf("CMMC") === 0 ||
          row.network.indexOf("CMSC") === 0
            ? `${row.network} / ${row.satellite_network}`
            : row.network,
      },
      { label: "Avail", name: "avail", width: 60 },
      { label: "ISCI", name: "isci", width: 120 },
      { label: "Len", name: "length", width: 60 },
      { label: "Type", name: "type", width: 70, renderer: row => TYPES_TO_NAMES[`${row.type}`] },
      {
        label: "Broadcast Time",
        name: "datetime_broadcast",
        width: 220,
        renderer: row => `${row.datetime_broadcast_text}${row.adjusted_timestamp ? " *" : ""}`,
      },
      {
        label: "Calendar Time (Eastern)",
        name: "datetime_calendar",
        width: 220,
        renderer: row => `${row.datetime_calendar_text}${row.adjusted_timestamp ? " *" : ""}`,
      },
      expanded && {
        label: "Selling Rotation",
        name: "rotation",
        width: 300,
      },
      expanded && {
        label: "Media Classification",
        name: "mediaClassification",
        flex: 1,
        minFlexWidth: 150,
      },
      expanded && {
        label: "Campaign",
        name: "campaign",
        flex: 1,
        minFlexWidth: 150,
      },
      { label: "Program", name: "program", flex: 1, minFlexWidth: 200 },
      {
        label: "Cost",
        name: "cost",
        width: 100,
        renderer: row => (
          <OverlayTrigger
            placement={OverlayTrigger.PLACEMENTS.BOTTOM.CENTER}
            overlay={<Tooltip>Click to edit</Tooltip>}
          >
            <div className="selectableCell" onClick={() => editSingleRow(row)}>
              <NumberFormatter
                prefix={enableCAD ? "CA" : ""}
                currency={enableCAD ? "CAD" : "USD"}
                locale={enableCAD ? "en-CA" : "en-US"}
                value={row.cost}
                type={"$"}
                decimals={0}
              />
            </div>
          </OverlayTrigger>
        ),
      },
      {
        label: "Credit Amount",
        name: "credit_amount",
        width: 100,
        renderer: row => (
          <OverlayTrigger
            placement={OverlayTrigger.PLACEMENTS.BOTTOM.CENTER}
            overlay={<Tooltip>Click to edit</Tooltip>}
          >
            <div className="selectableCell" onClick={() => editSingleRow(row)}>
              <NumberFormatter
                prefix={enableCAD ? "CA" : ""}
                currency={enableCAD ? "CAD" : "USD"}
                locale={enableCAD ? "en-CA" : "en-US"}
                value={row.credit_amount}
                type={"$"}
                decimals={0}
              />
            </div>
          </OverlayTrigger>
        ),
      },
      {
        label: "Accept",
        name: "accepted",
        width: 70,
        renderer: row => (
          <OverlayTrigger
            placement={OverlayTrigger.PLACEMENTS.BOTTOM.CENTER}
            overlay={<Tooltip>Click to edit</Tooltip>}
          >
            <div className="selectableCell" onClick={() => editSingleRow(row)}>
              {row.accepted ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />}
            </div>
          </OverlayTrigger>
        ),
      },
      {
        label: "Notes",
        name: "notes",
        flex: 1,
        minFlexWidth: 200,
        renderer: row => (
          <OverlayTrigger
            placement={OverlayTrigger.PLACEMENTS.BOTTOM.CENTER}
            overlay={<Tooltip>Click to edit</Tooltip>}
          >
            <div className="selectableCell" onClick={() => editSingleRow(row)}>
              {row.notes}
            </div>
          </OverlayTrigger>
        ),
      },
      fileDownloadColumn("Logs", "log_file"),
      fileDownloadColumn("Order", "order_file"),
      fileDownloadColumn("Traffic", "traffic_file"),
      { label: "BVS", name: "bvs", width: 40, renderer: row => (row.bvs ? <MdCheck /> : null) },
      {
        label: "Pre",
        name: "prelog",
        width: 40,
        renderer: row => (row.prelog ? <MdCheck /> : null),
      },
      {
        label: "Credit Memo Status",
        name: "creditMemoStatus",
        width: 180,
        renderer: row =>
          row.creditMemoStatus ? (
            <span style={{ fontWeight: 500 }}>{row.creditMemoStatus}</span>
          ) : (
            "Not Requested"
          ),
      },
      expanded && {
        label: "Reconciled",
        name: "reconciled",
        renderer: row => (
          <OverlayTrigger
            placement={OverlayTrigger.PLACEMENTS.BOTTOM.CENTER}
            overlay={<Tooltip>Click to edit</Tooltip>}
          >
            <div className="selectableCell" onClick={() => editSingleRow(row)}>
              {row.reconciled}
            </div>
          </OverlayTrigger>
        ),
      },
      expanded && {
        label: "Last Modified",
        name: "lastmodified_text",
        width: 220,
      },
      expanded && {
        label: "Last Dropped",
        name: "lastdropped_text",
        width: 220,
      },
    ]);
  }, [
    startWeek,
    endWeek,
    fileDownloadColumn,
    isInternal,
    updateSpot,
    expanded,
    enableCAD,
    editSingleRow,
  ]);

  const planDetailCsv = useMemo(() => {
    let rows = [];
    let header = R.map(R.prop("label"), PLAN_DETAIL_CSV_HEADERS);

    if (data) {
      rows = R.map(row => {
        row.booked_m = row.booked[0].count;
        row.booked_tu = row.booked[1].count;
        row.booked_w = row.booked[2].count;
        row.booked_th = row.booked[3].count;
        row.booked_f = row.booked[4].count;
        row.booked_sa = row.booked[5].count;
        row.booked_su = row.booked[6].count;
        row.cleared_m = row.cleared[0].count;
        row.cleared_tu = row.cleared[1].count;
        row.cleared_w = row.cleared[2].count;
        row.cleared_th = row.cleared[3].count;
        row.cleared_f = row.cleared[4].count;
        row.cleared_sa = row.cleared[5].count;
        row.cleared_su = row.cleared[6].count;
        row.cleared_rate = row.estimateInfo.clearanceRate;
        row.cleared_hist = row.estimateInfo.historicalClearanceRate;
        const resultRow = R.map(h => row[h.name], PLAN_DETAIL_CSV_HEADERS);
        return resultRow;
      }, data.plans);
    }

    rows.unshift(header);
    return Papa.unparse(rows);
  }, [data]);

  let actions = useMemo(() => {
    return (
      <div className="linearPlansActions">
        <div className="linearPlansLegend">
          <div className="atGoal">At Goal</div>
          <div className="belowGoal">Under Goal</div>
          <div className="aboveGoal">Above Goal</div>
        </div>

        <ToggleButtonGroup
          type="radio"
          name="showSpendOrSpotsToggle"
          value={displayByCount ? BY_COUNT_LABEL : BY_SPEND_LABEL}
          onChange={val => {
            setDisplayByCount(val === BY_COUNT_LABEL);
          }}
        >
          {[BY_COUNT_LABEL, BY_SPEND_LABEL].map(label => (
            <ToggleButton key={label} variant="primary" className="noOutline" value={label}>
              {label}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        <ToggleButtonGroup
          type="radio"
          name="showPlanDetailsToggle"
          value={expanded ? EXPANDED_LABEL : NETWORK_AVAIL_LABEL}
          onChange={val => {
            setExpanded(val === EXPANDED_LABEL);
          }}
        >
          {[EXPANDED_LABEL, NETWORK_AVAIL_LABEL].map(label => (
            <ToggleButton key={label} variant="primary" className="noOutline" value={label}>
              {label}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        <DateRangePicker
          mondayOnly
          startDate={startWeek}
          endDate={endWeek}
          startDateId="linearPlansStartDate"
          endDateId="linearPlansEndDate"
          onChange={({ startDate, endDate }) => {
            if (startDate && endDate) {
              setStartWeek(startDate);
              setEndWeek(endDate);
            }
          }}
        />
      </div>
    );
  }, [displayByCount, expanded, startWeek, endWeek]);

  useEffect(() => {
    if (startWeek && endWeek) {
      (async () => {
        try {
          setData();
          setFetching(true);
          let lambdaData = await LinearLambdaFetch("/plans_view", {
            params: {
              company,
              start: startWeek,
              end: endWeek,
            },
          });
          lambdaData = await awaitJSON(lambdaData);

          lambdaData = await S3SignedUrlFetch(lambdaData.path);
          lambdaData = await awaitJSON(lambdaData);
          setData(JSON.parse(lambdaData));
          setFetching(false);
        } catch (e) {
          setError({ message: e.message, reportError: e });
        }
      })();
    }
  }, [startWeek, endWeek, company, setError]);

  const createDayHeader = useCallback(
    (field, index, highlight) => {
      return {
        label: DAYS_BY_INDEX[index],
        name: `${field}_${DAYS_BY_INDEX[index].toLowerCase()}`,
        width: displayByCount ? 40 : 80,
        nonInteractive: true,
        renderer: row => {
          let goalStyle = "atGoal";
          if (row.clearance === "Above Goal") {
            goalStyle = "aboveGoal";
          } else if (row.clearance === "Below Goal" || row.clearance === "None") {
            goalStyle = "belowGoal";
          }

          if (!row[field] || !row[field][index]) {
            return null;
          }

          return (
            <div
              className={
                highlight && index <= R.path(["estimateInfo", "prelogLastDate"], row)
                  ? `highlightCell ${goalStyle}`
                  : ""
              }
            >
              {displayByCount ? (
                <NumberFormatter value={row[field][index].count} type={"#"} decimals={0} />
              ) : (
                <NumberFormatter
                  prefix={enableCAD ? "CA" : ""}
                  currency={enableCAD ? "CAD" : "USD"}
                  locale={enableCAD ? "en-CA" : "en-US"}
                  value={row[field][index].spend}
                  type={"$"}
                  decimals={0}
                />
              )}
            </div>
          );
        },
      };
    },
    [displayByCount, enableCAD]
  );

  const planDetailHeaders = useMemo(() => {
    return R.filter(R.identity, [
      startWeek !== endWeek && {
        label: "Week",
        name: "week",
        sortPriority: 1,
        sortAscending: true,
      },
      { label: "Network", name: "network", width: 160, sortPriority: 2, sortAscending: true },
      {
        label: "Avail",
        name: "avail",
        sortPriority: 3,
        sortAscending: true,
        width: 60,
      },
      !expanded && {
        label: "BVS",
        name: "bvs",
        width: 60,
        renderer: row => (row.bvs ? <MdCheck /> : null),
      },
      !expanded && {
        label: "Pre",
        name: "prelog",
        width: 60,
        renderer: row => (row.prelog ? <MdCheck /> : null),
      },
      !expanded && {
        label: "Post",
        name: "postlog",
        width: 60,
        renderer: row => {
          if (row.postlog) {
            return <MdCheck />;
          } else if (row.dnr) {
            return (
              <Button
                variant="link"
                onClick={async () => await updateReconcileStatus({ ...row, status: "" })}
              >
                DNR
              </Button>
            );
          } else if (row.hasPostlogs && !row.totals && row.type === 4) {
            return (
              <Button
                variant="link"
                onClick={() => {
                  setAreYouSure({
                    title: "You are marking an Upfront Buy as DNR!",
                    message:
                      "THIS IS AN UPFRONT BUY, PLEASE CHECK WITH YOUR BUYER BEFORE MARKING DNR",
                    okayText: "Save anyway",
                    onOkay: () => {
                      updateReconcileStatus({ ...row, status: "DNR" });
                    },
                  });
                }}
              >
                <MdError />
              </Button>
            );
          } else if (row.hasPostlogs && !row.totals) {
            return (
              <Button
                variant="link"
                onClick={async () => await updateReconcileStatus({ ...row, status: "DNR" })}
              >
                <MdError />
              </Button>
            );
          }
        },
      },
      !expanded && {
        label: "Clear Post",
        name: "clear_postlog",
        width: 60,
        renderer: row => (row.clear_postlog ? <MdCheck /> : null),
      },
      expanded && {
        label: "Len",
        name: "length",
        sortPriority: 4,
        sortAscending: true,
        width: 60,
      },
      expanded && {
        label: "Days",
        name: "dow",
        sortPriority: 5,
        sortAscending: true,
        width: 140,
      },
      expanded && {
        label: "Daypart",
        name: "daypart",
        sortPriority: 6,
        sortAscending: true,
        width: 120,
      },
      expanded && {
        label: "Rotation",
        name: "rotation",
        sortPriority: 7,
        width: 300,
      },
      expanded && {
        label: "Type",
        name: "type",
        width: 70,
        renderer: row => TYPES_TO_NAMES[`${row.type}`],
      },
      expanded && { label: "Media Classification", name: "mediaClassification", width: 150 },
      expanded && { label: "Campaign", name: "campaign", width: 150 },
      expanded && {
        label: "Cost",
        name: "cost",
        width: 60,
        renderer: row => (
          <NumberFormatter
            prefix={enableCAD ? "CA" : ""}
            currency={enableCAD ? "CAD" : "USD"}
            locale={enableCAD ? "en-CA" : "en-US"}
            value={row.cost}
            type={"$"}
            decimals={0}
          />
        ),
      },
      createDayHeader("booked", 0),
      createDayHeader("booked", 1),
      createDayHeader("booked", 2),
      createDayHeader("booked", 3),
      createDayHeader("booked", 4),
      createDayHeader("booked", 5),
      createDayHeader("booked", 6),
      {
        label: "Count",
        name: "booked_count",
        width: 60,
        renderer: row => <NumberFormatter value={row.booked_count} type={"#"} decimals={0} />,
      },
      {
        label: "Spend",
        name: "booked_spend",
        renderer: row => (
          <NumberFormatter
            prefix={enableCAD ? "CA" : ""}
            currency={enableCAD ? "CAD" : "USD"}
            locale={enableCAD ? "en-CA" : "en-US"}
            value={row.booked_spend}
            type={"$"}
            decimals={0}
          />
        ),
      },
      createDayHeader("cleared", 0, true),
      createDayHeader("cleared", 1, true),
      createDayHeader("cleared", 2, true),
      createDayHeader("cleared", 3, true),
      createDayHeader("cleared", 4, true),
      createDayHeader("cleared", 5, true),
      createDayHeader("cleared", 6, true),
      {
        label: "Count",
        name: "cleared_count",
        width: 60,
        renderer: row => <NumberFormatter value={row.cleared_count} type={"#"} decimals={0} />,
      },
      {
        label: "Spend",
        name: "cleared_spend",
        renderer: row => (
          <NumberFormatter
            prefix={enableCAD ? "CA" : ""}
            currency={enableCAD ? "CAD" : "USD"}
            locale={enableCAD ? "en-CA" : "en-US"}
            value={row.cleared_spend}
            type={"$"}
            decimals={0}
          />
        ),
      },
      {
        label: "Clear Rate",
        name: "clear_rate",
        width: 70,
        renderer: row => (
          <NumberFormatter
            value={R.path(["estimateInfo", "clearanceRate"], row)}
            type={"%"}
            decimals={0}
          />
        ),
      },
      {
        label: "Hist Clear",
        name: "hist_clear",
        width: 70,
        renderer: row => (
          <NumberFormatter
            value={R.path(["estimateInfo", "historicalClearanceRate"], row)}
            type={"%"}
            decimals={0}
          />
        ),
      },
      expanded && { label: "ID", name: "id" },
    ]);
  }, [
    createDayHeader,
    startWeek,
    endWeek,
    expanded,
    updateReconcileStatus,
    setAreYouSure,
    enableCAD,
  ]);

  const superHeaders = useMemo(() => {
    const bookedStart = R.findIndex(elem => elem.name.indexOf("booked") >= 0, planDetailHeaders);
    const clearedStart = R.findIndex(elem => elem.name.indexOf("cleared") >= 0, planDetailHeaders);
    const clearedEnd = R.findLastIndex(elem => elem.name.indexOf("clear") >= 0, planDetailHeaders);
    return [
      { span: bookedStart },
      { data: "Booked", span: clearedStart - bookedStart },
      { data: "Cleared", span: clearedEnd - clearedStart },
      { span: planDetailHeaders.length - clearedEnd },
    ];
  }, [planDetailHeaders]);

  const clearanceForRow = ({ dnr, estimateInfo, week, network, avail }) => {
    if (R.any(row => row.week === week && row.network === network && row.avail === avail, dnr)) {
      return "DNR";
    } else if (estimateInfo && estimateInfo.clearedSpend === 0) {
      return "None";
    } else if (
      estimateInfo &&
      estimateInfo.clearedSpend >
        (estimateInfo.goal + estimateInfo.goal_above) * estimateInfo.orderedSpend
    ) {
      return "Above Goal";
    } else if (
      estimateInfo &&
      estimateInfo.clearedSpend <
        (estimateInfo.goal - estimateInfo.goal_below) * estimateInfo.orderedSpend
    ) {
      return "Below Goal";
    } else {
      return "At Goal";
    }
  };

  const allPlanDetailData = useMemo(() => {
    if (!data) {
      return [];
    }

    return R.map(index => {
      return {
        ...data.plans[index],
        clearance: clearanceForRow({
          dnr: data.dnr,
          estimateInfo: data.plans[index].estimateInfo,
          week: data.plans[index].week,
          network: data.plans[index].network,
          avail: data.plans[index].avail,
        }),
        hasFullPrelog: data.plans[index].estimateInfo.prelogLastDate === 6,
      };
    }, R.range(0, data.plans.length));
  }, [data]);

  const planDetailData = useMemo(() => {
    if (!data || !data.plans) {
      return [];
    }
    const weekHasPostlogs = R.pipe(
      R.groupBy(row => row.week),
      R.map(list => R.filter(row => !row.prelog && !row.bvs, list).length > 0)
    )(data.spots);

    return R.pipe(
      R.groupBy(row => (expanded ? row.id : `${row.week}_${row.network}_${row.avail}`)),
      R.map(filteredPlanDetail => {
        const booked = R.map(index => ({
          count: R.sum(R.map(R.path(["booked", index, "count"]), filteredPlanDetail)),
          spend: R.sum(R.map(R.path(["booked", index, "spend"]), filteredPlanDetail)),
        }))(R.range(0, 7));
        const cleared = R.map(index => ({
          count: R.sum(R.map(R.path(["cleared", index, "count"]), filteredPlanDetail)),
          spend: R.sum(R.map(R.path(["cleared", index, "spend"]), filteredPlanDetail)),
        }))(R.range(0, 7));
        let bookedSpendRemaining = R.sum(R.map(row => row.spend, booked));
        let cleared_spend = 0;
        for (let i = 0; i < 7; i++) {
          if (cleared[i]) {
            if (cleared[i].spend >= bookedSpendRemaining) {
              cleared[i].spend = bookedSpendRemaining;
              bookedSpendRemaining = 0;
            } else {
              bookedSpendRemaining -= cleared[i].spend;
            }
            cleared_spend += cleared[i].spend;
          }
        }

        const estimateInfo = {
          clearedSpend: R.sum(R.map(R.path(["estimateInfo", "clearedSpend"]), filteredPlanDetail)),
          orderedSpend: R.sum(R.map(R.path(["estimateInfo", "orderedSpend"]), filteredPlanDetail)),
          estimatedClearedSpend: R.sum(
            R.map(R.path(["estimateInfo", "estimatedClearedSpend"]), filteredPlanDetail)
          ),
          futureOrderedSpend: R.sum(
            R.map(R.path(["estimateInfo", "futureOrderedSpend"]), filteredPlanDetail)
          ),
          historicalClearanceRate: R.path(
            [0, "estimateInfo", "historicalNetworkAvailClearanceRate"],
            filteredPlanDetail
          ),
          prelogLastDate: R.path([0, "estimateInfo", "prelogLastDate"], filteredPlanDetail),
          goal: R.path([0, "estimateInfo", "goal"], filteredPlanDetail),
          goal_above: R.path([0, "estimateInfo", "goal_above"], filteredPlanDetail),
          goal_below: R.path([0, "estimateInfo", "goal_below"], filteredPlanDetail),
        };
        estimateInfo.clearanceRate =
          estimateInfo.orderedSpend + estimateInfo.futureOrderedSpend > 0
            ? (estimateInfo.clearedSpend + estimateInfo.estimatedClearedSpend) /
              (estimateInfo.orderedSpend + estimateInfo.futureOrderedSpend)
            : 0;

        let clearance = clearanceForRow({
          dnr: data.dnr,
          estimateInfo,
          week: filteredPlanDetail[0].week,
          network: filteredPlanDetail[0].network,
          avail: filteredPlanDetail[0].avail,
        });

        return {
          ...filteredPlanDetail[0],
          bvs: R.any(R.prop("bvs"), filteredPlanDetail),
          prelog: R.any(R.prop("prelog"), filteredPlanDetail),
          postlog: R.any(R.prop("postlog"), filteredPlanDetail),
          hasPostlogs: weekHasPostlogs[filteredPlanDetail[0].week],
          dnr: R.any(
            row =>
              row.week === filteredPlanDetail[0].week &&
              row.network === filteredPlanDetail[0].network &&
              row.avail === filteredPlanDetail[0].avail,
            data.dnr
          ),
          clear_postlog: R.any(R.prop("clear_postlog"), filteredPlanDetail),
          estimateInfo,
          ...R.pipe(
            R.map(key => [key, R.sum(R.map(R.prop(key), filteredPlanDetail))]),
            R.fromPairs
          )(PLAN_DETAIL_KEYS_TO_SUM),
          cleared_spend,
          booked,
          cleared,
          clearance,
          hasFullPrelog: estimateInfo.prelogLastDate === 6,
        };
      }),
      R.values,
      R.filter(planDetailFilter)
    )(data.plans);
  }, [data, expanded, planDetailFilter]);

  const planDetailTotalsRow = useMemo(() => {
    if (!planDetailData) {
      return {};
    }

    const booked = R.map(index => ({
      booked_count: R.sum(R.map(R.path(["booked", index, "count"]), planDetailData)),
      booked_spend: R.sum(R.map(R.path(["booked", index, "spend"]), planDetailData)),
    }))(R.range(0, 7));
    const cleared = R.map(index => ({
      cleared_count: R.sum(R.map(R.path(["cleared", index, "count"]), planDetailData)),
      cleared_spend: R.sum(R.map(R.path(["cleared", index, "spend"]), planDetailData)),
    }))(R.range(0, 7));
    let bookedSpendRemaining = R.sum(R.map(row => row.booked_spend, booked));

    let cleared_spend = 0;
    for (let i = 0; i < 7; i++) {
      if (cleared[i]) {
        if (cleared[i].cleared_spend >= bookedSpendRemaining) {
          cleared[i].cleared_spend = bookedSpendRemaining;
          bookedSpendRemaining = 0;
        } else {
          bookedSpendRemaining -= cleared[i].cleared_spend;
        }
        cleared_spend += cleared[i].cleared_spend;
      }
    }

    return {
      ...R.pipe(
        R.map(key => [key, R.sum(R.map(R.prop(key), planDetailData))]),
        R.fromPairs
      )(PLAN_DETAIL_KEYS_TO_SUM),
      cleared_spend,
      booked,
      cleared,
    };
  }, [planDetailData]);

  const planDetailTotalsRenderer = useCallback(
    ({ data, columnIndex, style = {}, classes = [] }) => {
      data = planDetailHeaders[columnIndex].renderer
        ? planDetailHeaders[columnIndex].renderer({ ...planDetailTotalsRow, totals: true })
        : planDetailTotalsRow[planDetailHeaders[columnIndex].name];
      return (
        <div style={style} className={[...classes, "grandTotalCell"].join(" ")}>
          {data}
        </div>
      );
    },
    [planDetailTotalsRow, planDetailHeaders]
  );

  const allSpotDetailData = useMemo(() => {
    return data
      ? R.sortBy(
          R.pipe(
            R.props([
              "week",
              "network",
              "avail",
              "isci",
              "datetime_broadcast_text",
              "datetime_calendar_text",
            ]),
            R.join("_")
          ),
          data.spots
        )
      : [];
  }, [data]);

  const spotDetailData = useMemo(() => R.filter(spotDetailFilter, allSpotDetailData), [
    allSpotDetailData,
    spotDetailFilter,
  ]);

  const spotDetailTotalsRow = useMemo(() => {
    return {
      spend: R.sum(R.map(R.prop("cost"), spotDetailData)),
      credit_amount: R.sum(R.map(R.prop("credit_amount"), spotDetailData)),
      count: spotDetailData.length,
    };
  }, [spotDetailData]);

  const spotDetailTotalsRenderer = useCallback(
    ({ data, columnIndex, style = {}, classes = [] }) => {
      if (spotDetailHeaders[columnIndex].label === "ISCI") {
        data = <NumberFormatter value={spotDetailTotalsRow.count} type={"#"} decimals={0} />;
      } else if (spotDetailHeaders[columnIndex].label === "Cost") {
        data = (
          <NumberFormatter
            prefix={enableCAD ? "CA" : ""}
            currency={enableCAD ? "CAD" : "USD"}
            locale={enableCAD ? "en-CA" : "en-US"}
            value={spotDetailTotalsRow.spend}
            type={"$"}
            decimals={0}
          />
        );
      } else if (spotDetailHeaders[columnIndex].label === "Credit Amount") {
        data = (
          <NumberFormatter
            prefix={enableCAD ? "CA" : ""}
            currency={enableCAD ? "CAD" : "USD"}
            locale={enableCAD ? "en-CA" : "en-US"}
            value={spotDetailTotalsRow.credit_amount}
            type={"$"}
            decimals={0}
          />
        );
      } else {
        data = null;
      }

      return (
        <div style={style} className={[...classes, "grandTotalCell"].join(" ")}>
          {data}
        </div>
      );
    },
    [spotDetailTotalsRow, spotDetailHeaders, enableCAD]
  );

  const updateFiltered = useCallback(
    newVal => {
      const filteredIds = R.map(R.prop("id"), spotDetailData);
      setData({
        ...data,
        spots: R.map(
          spotRow =>
            R.contains(spotRow.id, filteredIds) ? { ...spotRow, selected: newVal } : spotRow,
          data.spots
        ),
      });
    },
    [data, spotDetailData]
  );

  const spotDetailCsv = useMemo(() => {
    const header = R.concat(R.map(R.prop("label"), spotDetailHeaders), ["Calendar Time (ET)"]);
    let rows = [];

    if (spotDetailData) {
      rows = R.map(
        row =>
          R.concat(
            R.map(h => (h.csvRenderer ? h.csvRenderer(row) : row[h.name]), spotDetailHeaders),
            [row.datetime_calendar_text]
          ),
        spotDetailData
      );
    }

    rows.unshift(header);
    return Papa.unparse(rows);
  }, [spotDetailData, spotDetailHeaders]);

  const spotsDirty = useMemo(() => {
    if (data && data.spots) {
      return R.any(row => row.dirty, data.spots);
    } else {
      return false;
    }
  }, [data]);

  const selectedSpots = useMemo(() => {
    if (data && data.spots) {
      return R.filter(row => row.selected, data.spots);
    } else {
      return [];
    }
  }, [data]);

  const validDeletionRows = useMemo(() => {
    if (data && data.spots) {
      const countByNetwork = R.pipe(
        R.groupBy(row => row.network),
        R.mapObjIndexed(list => list.length)
      )(data.spots);
      const countSelectedByNetwork = R.pipe(
        R.filter(row => row.selected),
        R.groupBy(row => row.network),
        R.mapObjIndexed(list => list.length)
      )(data.spots);

      return R.pipe(
        R.keys,
        R.map(network => countSelectedByNetwork[network] === countByNetwork[network]),
        R.all(R.identity)
      )(countSelectedByNetwork);
    } else {
      return false;
    }
  }, [data]);

  const planDetailOptions = useMemo(() => {
    return planDetailHeaders
      ? R.concat(
          [
            { label: "Clearance", name: "clearance", width: 0 },
            { label: "Full Prelog", name: "hasFullPrelog", width: 0 },
          ],
          R.filter(
            header =>
              R.contains(header.name, [
                "network",
                "avail",
                "length",
                "dow",
                "daypart",
                "rotation",
                "type",
                "week",
                "clearance",
                "hasFullPrelog",
                "mediaClassification",
                "campaign",
              ]),
            planDetailHeaders
          )
        )
      : [];
  }, [planDetailHeaders]);

  const spotDetailOptions = useMemo(() => {
    return spotDetailHeaders
      ? R.filter(
          header =>
            R.contains(header.name, [
              "network",
              "avail",
              "length",
              "type",
              "isci",
              "week",
              "rotation",
              "datetime_broadcast_text",
              "datetime_calendar_text",
              "program",
              "notes",
              "mediaClassification",
              "campaign",
            ]) ||
            (spotFilterAdvanced && R.contains(header.name, ["accepted", "creditMemoStatus"])),
          spotDetailHeaders
        )
      : [];
  }, [spotDetailHeaders, spotFilterAdvanced]);

  return (
    <Page title="Linear Plans" pageType="Linear Plans" minHeight={600} actions={actions}>
      <div className="allPanes">
        <div className="topPane">
          <div className="filterBarContainer">
            <OldFilterBar
              options={planDetailOptions}
              lines={allPlanDetailData}
              onFilter={filter => {
                setPlanDetailFilter(filter);
              }}
              advanced={planFilterAdvanced}
              onSetAdvanced={advanced => setPlanFilterAdvanced(advanced)}
            />
            <Button
              onClick={() =>
                download(
                  planDetailCsv,
                  `Plans ${company} ${startWeek} - ${endWeek}.csv`,
                  "text/csv"
                )
              }
            >
              <MdFileDownload />
            </Button>
          </div>
          <BPMTable
            alternateColors
            headers={planDetailHeaders}
            data={planDetailData}
            rowHeight={26}
            headerHeight={40}
            filterBar={false}
            noRowsRenderer={() =>
              fetching ? (
                <Skeleton>
                  <TableSkeleton />
                </Skeleton>
              ) : (
                <div>No rows to show.</div>
              )
            }
            superHeaders={superHeaders}
            superHeadersRenderer={({ data, style, classes }) => {
              // If it's an empty super header, don't add the underline
              if (data) {
                classes.push("superHeader");
              }
              return (
                <div style={style} className={classes.join(" ")}>
                  {data}
                </div>
              );
            }}
            totals={planDetailTotalsRow}
            totalsRenderer={planDetailTotalsRenderer}
          />
        </div>
        <div className="bottomPane">
          <div className="filterBarContainer">
            <div className="additionalControls">
              <Button onClick={() => updateFiltered(true)}>Select Visible</Button>
              <Button onClick={() => updateFiltered(false)}>Clear Visible</Button>
            </div>
            <OldFilterBar
              options={spotDetailOptions}
              lines={allSpotDetailData}
              onFilter={filter => {
                setSpotDetailFilter(filter);
              }}
              advanced={spotFilterAdvanced}
              onSetAdvanced={advanced => setSpotFilterAdvanced(advanced)}
            />
            <Button
              onClick={() =>
                download(
                  spotDetailCsv,
                  `Spots ${company} ${startWeek} - ${endWeek}.csv`,
                  "text/csv"
                )
              }
            >
              <MdFileDownload />
            </Button>
            {isInternal && (
              <Button disabled={!spotsDirty} onClick={() => updateSpots()}>
                {saving ? <Spinner /> : <MdSave />}
              </Button>
            )}
            {isInternal && (
              <Button
                disabled={selectedSpots.length === 0 || !isEditable}
                onClick={() => setShowEditSelectedRows(true)}
              >
                <MdEdit />
              </Button>
            )}
            {isInternal && (
              <Button
                disabled={!validDeletionRows || selectedSpots.length === 0}
                onClick={() => setShowDeleteSpotsConfirmation(true)}
              >
                {saving ? <Spinner /> : <MdDeleteForever />}
              </Button>
            )}
            <Button onClick={() => setShowExportSpotSummaryConfirmation(true)}>Summary</Button>
          </div>
          <BPMTable
            alternateColors
            headers={spotDetailHeaders}
            data={spotDetailData}
            rowHeight={26}
            filterBar={false}
            onUpdateSortParamList={sortParamList => {
              setSpotDetailSortParamList(sortParamList);
            }}
            overrideSortParamList={spotDetailSortParamList}
            noRowsRenderer={() =>
              fetching ? (
                <Skeleton>
                  <TableSkeleton />
                </Skeleton>
              ) : (
                <div>No rows to show.</div>
              )
            }
            totals={spotDetailTotalsRow}
            totalsRenderer={spotDetailTotalsRenderer}
          />
        </div>
      </div>
      {showExportSpotSummaryConfirmation && (
        <ExportSpotSummaryConfirmation
          company={company}
          show={showExportSpotSummaryConfirmation}
          setShow={setShowExportSpotSummaryConfirmation}
        />
      )}
      {showEditSelectedRows && (
        <EditSpotsModal
          selectedSpots={selectedSpots}
          updateSelectedSpots={newValues => {
            const selectedIds = R.map(R.prop("id"), selectedSpots);
            setData({
              ...data,
              spots: R.map(
                spotRow =>
                  R.contains(spotRow.id, selectedIds)
                    ? { ...spotRow, ...newValues, dirty: true, selected: false }
                    : spotRow,
                data.spots
              ),
            });
          }}
          show={showEditSelectedRows}
          setShow={setShowEditSelectedRows}
        />
      )}
      {showDeleteSpotsConfirmation && (
        <DeleteSpotsModal
          deleteSelectedSpots={() => deleteSpots()}
          show={showDeleteSpotsConfirmation}
          setShow={setShowDeleteSpotsConfirmation}
        />
      )}
      <Toast
        className="toast"
        onClose={() => setNCreditMemosToast(false)}
        show={nCreditMemosToast > 0}
        delay={5000}
        autohide
      >
        <Toast.Header>
          <strong>
            {nCreditMemosToast} Credit Memo{nCreditMemosToast === 1 ? "" : "s"} created
          </strong>
          <small style={{ marginLeft: "auto" }}>just now</small>
        </Toast.Header>
        <Toast.Body>
          Go to the Credit Memo page to view {nCreditMemosToast === 1 ? "it" : "them"}.
        </Toast.Body>
      </Toast>
      <Toast
        className="toast"
        onClose={() => setShowMakingCreditMemoToast(false)}
        show={showMakingCreditMemoToast}
        delay={5000}
        autohide
      >
        <Toast.Header>
          <strong>Creating one or more credit memos</strong>
        </Toast.Header>
        <Toast.Body>This might take a while.</Toast.Body>
      </Toast>
    </Page>
  );
});

export default LinearPlans;

import "./SegmentationLabelingTable.scss";
import React, { useState, useCallback, useMemo, useEffect } from "react";
import * as R from "ramda";
import {
  CellRenderer,
  CheckBox,
  CornerLocation,
  FrozenColumnsTable,
  Header,
  SelfFocusingSelect,
  TextToggleButton,
} from "../Components";
import { CampaignRowEdit } from "@blisspointmedia/bpm-types/dist/CampaignLabelingTool";
import { StateSetter } from "../utils/types";
import { Md123 } from "react-icons/md";
import { useMap } from "../utils/hooks/useData";
import { CustomSegmentsData } from "./SegmentationMapping";
import { EditsMapAction, SegmentEditsMap, SegmentRow } from "./SegmentationLabeling";

interface SegmentationLabelingTableProps {
  data: any[];
  customSegments: CustomSegmentsData[] | undefined;
  editsMap: SegmentEditsMap;
  selectedRows: Record<string, SegmentRow>;
  dispatchEditsMap: React.Dispatch<EditsMapAction>;
  setSelectedRows: StateSetter<Record<string, SegmentRow>>;
  dataGranularity: "ad" | "ad_group" | "campaign";
}

export interface SegmentRowEdit extends CampaignRowEdit {
  Channel?: { label: string; value: number };
  Platform?: { label: string; value: number };
}

const SegmentationLabelingTable: React.FC<SegmentationLabelingTableProps> = ({
  data,
  customSegments,
  editsMap,
  selectedRows,
  dispatchEditsMap,
  setSelectedRows,
  dataGranularity,
}) => {
  const [selectAll, setSelectAll] = useState(false);
  const [focusedCell, setFocusedCell] = useState("");
  const [resizeActive, setResizeActive] = useState(false);
  const [resizedLeftWidth, setResizedLeftWidth] = useState<number | undefined>();
  const [innerWidth, setInnerHeight] = useState<number>(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", () => setInnerHeight(window.innerWidth));

    return () => {
      window.removeEventListener("resize", () => setInnerHeight(window.innerWidth));
    };
  }, [innerWidth]);

  const selectedRowsSet = useMemo(() => new Set(Object.keys(selectedRows)), [selectedRows]);

  const selectRow = useCallback(
    (row: SegmentRow) => {
      const { campaign_key } = row;
      if (R.has(campaign_key, selectedRows)) {
        setSelectedRows(current => R.omit([campaign_key], current));
      } else {
        setSelectedRows(current => ({ ...current, [campaign_key]: row }));
      }
    },
    [selectedRows, setSelectedRows]
  );

  const dataWithEdits = useMemo(() => {
    return data.map(row => {
      let rowToUse = row;
      if (editsMap[row.campaign_key]) {
        rowToUse = editsMap[row.campaign_key];
      }

      return rowToUse;
    });
  }, [editsMap, data]);

  const dataMap = useMemo(() => {
    let output = {};
    data.forEach(row => {
      output[row.campaign_key] = row;
    });
    return output;
  }, [data]);

  const [showAllAccountIDs, setShowAllAccountIDs] = useState(false);
  const [showAccountID, setShowAccountID] = useMap<string, boolean>({});
  const [showAllCampaignIDs, setShowAllCampaignIDs] = useState(false);
  const [showCampaignID, setShowCampaignID] = useMap<string, boolean>({});
  const [showAllAdGroupIDs, setShowAllAdGroupIDs] = useState(false);
  const [showAdGroupID, setShowAdGroupID] = useMap<string, boolean>({});
  const [showAllAdIDs, setShowAllAdIDs] = useState(false);
  const [showAdID, setShowAdID] = useMap<string, boolean>({});

  const rightTableHeaders: Header[] = useMemo(() => {
    return (customSegments || []).map(segment => {
      return {
        label: segment.segmentName,
        name: segment.segmentName,
        width: 200,
        renderer: (row: SegmentRow) => {
          if (row) {
            const rowToUse = editsMap[row.campaign_key] || row;
            const { campaign_key } = rowToUse;

            const val =
              (R.has(segment.segmentName, rowToUse.edits)
                ? (rowToUse.edits as SegmentRowEdit)[segment.segmentName].label
                : rowToUse[segment.segmentName]) ||
              // platform and channel will be lowercased from redshift
              rowToUse[segment.segmentName.toLowerCase()];

            return (
              <>
                {focusedCell === `${segment.segmentName}_${campaign_key}` ? (
                  <SelfFocusingSelect
                    menuPortalTarget={document.body}
                    className="selectContainer"
                    value={
                      val
                        ? {
                            label: val,
                            value: val,
                          }
                        : null
                    }
                    placeholder="Select label"
                    onChange={selection => {
                      // Issue an update when selecting an option from the dropdown, and
                      // issue a delete when selecting the "x" from the input
                      const action = selection
                        ? (input: { row: SegmentRow; edits: SegmentRowEdit; key: string }) =>
                            dispatchEditsMap({
                              type: "UPDATE_SEGMENT_EDIT",
                              row: input.row,
                              edits: input.edits,
                              campaign_key: input.key,
                            })
                        : (input: { segmentName: string; key: string }) =>
                            dispatchEditsMap({
                              type: "DELETE_SEGMENT_EDIT",
                              segmentName: input.segmentName,
                              campaign_key: input.key,
                            });

                      if (selectedRowsSet.has(campaign_key)) {
                        // Update all selected rows
                        selectedRowsSet.forEach(key => {
                          action({
                            row: selectedRows[key],
                            edits: { [segment.segmentName]: selection },
                            key: key,
                            segmentName: segment.segmentName,
                          });
                        });
                      } else {
                        // Update only the row with the modified dropdown
                        action({
                          row: rowToUse,
                          edits: { [segment.segmentName]: selection },
                          key: campaign_key,
                          segmentName: segment.segmentName,
                        });
                      }
                    }}
                    onBlur={() => setFocusedCell("")}
                    options={segment.values.map(value => {
                      return { label: value.valueName, value: value.valueId };
                    })}
                    isClearable
                    defaultMenuIsOpen
                  ></SelfFocusingSelect>
                ) : (
                  <div
                    className="editableCell"
                    onClick={() => setFocusedCell(`${segment.segmentName}_${campaign_key}`)}
                  >
                    <span className="cellText">{val || "-"}</span>
                  </div>
                )}
              </>
            );
          } else {
            return <div></div>;
          }
        },
      };
    });
  }, [customSegments, dispatchEditsMap, editsMap, focusedCell, selectedRows, selectedRowsSet]);

  // Making this its own thing to prevent rerenders that happened when using rightTableHeaders.
  const rightHeaderNames = useMemo(() => {
    return (rightTableHeaders || []).map(header => header.name || "");
  }, [rightTableHeaders]);

  const customLeftRenderer: CellRenderer<any> = useMemo(
    () => ({ data, style = {}, classes = [] }) => {
      const {
        campaign_key,
        account_name,
        account_id,
        campaign_name,
        campaign_id,
        ad_group_name,
        ad_group_id,
        ad_name,
        ad_id,
      } = data;

      return (
        <div style={style} className={[...classes, "leftCell"].join(" ")}>
          <div className="checkBoxContainer">
            <CheckBox
              className="checkbox"
              checked={R.has(campaign_key, selectedRows)}
              onCheck={() => selectRow(dataMap[campaign_key])}
            />
          </div>
          <div className="leftCellDiv account">
            <span className="cellText">
              {showAllAccountIDs || showAccountID[campaign_key] ? account_id : account_name}
            </span>
            <div
              className="digitsIcon"
              onMouseEnter={() => setShowAccountID(campaign_key, true)}
              onMouseLeave={() => setShowAccountID(campaign_key, false)}
              onClick={() => navigator.clipboard.writeText(account_id)}
            >
              <Md123 />
            </div>
          </div>
          <div className="leftCellDiv campaign">
            <span className="cellText">
              {showAllCampaignIDs || showCampaignID[campaign_key] ? campaign_id : campaign_name}
            </span>
            <div
              className="digitsIcon"
              onMouseEnter={() => setShowCampaignID(campaign_key, true)}
              onMouseLeave={() => setShowCampaignID(campaign_key, false)}
              onClick={() => navigator.clipboard.writeText(campaign_id)}
            >
              <Md123 />
            </div>
          </div>
          {(dataGranularity === "ad_group" || dataGranularity === "ad") && (
            <div className="leftCellDiv ad_group">
              <span className="cellText">
                {showAllAdGroupIDs || showAdGroupID[campaign_key] ? ad_group_id : ad_group_name}
              </span>
              <div
                className="digitsIcon"
                onMouseEnter={() => setShowAdGroupID(campaign_key, true)}
                onMouseLeave={() => setShowAdGroupID(campaign_key, false)}
                onClick={() => navigator.clipboard.writeText(ad_group_id)}
              >
                <Md123 />
              </div>
            </div>
          )}
          {dataGranularity === "ad" && (
            <div className="leftCellDiv ad">
              <span className="cellText">
                {showAllAdIDs || showAdID[campaign_key] ? ad_id : ad_name}
              </span>
              <div
                className="digitsIcon"
                onMouseEnter={() => setShowAdID(campaign_key, true)}
                onMouseLeave={() => setShowAdID(campaign_key, false)}
                onClick={() => navigator.clipboard.writeText(ad_id)}
              >
                <Md123 />
              </div>
            </div>
          )}
        </div>
      );
    },
    [
      dataGranularity,
      dataMap,
      selectRow,
      selectedRows,
      setShowAccountID,
      setShowAdGroupID,
      setShowAdID,
      setShowCampaignID,
      showAccountID,
      showAdGroupID,
      showAdID,
      showAllAccountIDs,
      showAllAdGroupIDs,
      showAllAdIDs,
      showAllCampaignIDs,
      showCampaignID,
    ]
  );

  const [rightData, setRightData] = useState<any[][]>([]);

  const sortRightData = useCallback(
    (columnName: string, direction: string) => {
      let tempData = [...dataWithEdits];

      if (direction === "up") {
        tempData.sort((a, b) => {
          const valA = a[columnName] || "";
          const valB = b[columnName] || "";
          return valA.localeCompare(valB);
        });
      }
      if (direction === "down") {
        tempData.sort((a, b) => {
          const valA = a[columnName] || "";
          const valB = b[columnName] || "";
          return valB.localeCompare(valA);
        });
      }

      const originalIndexMap = {};
      for (let i = 0; i < dataWithEdits.length; i++) {
        originalIndexMap[dataWithEdits[i].campaign_key] = i;
      }

      let data: any[][] = [];
      for (let i = 0; i < tempData.length; ++i) {
        let obj = tempData[i];
        let row: any[] = [];
        for (let { name } of rightTableHeaders) {
          let content = obj[R.defaultTo("undefined", name)];
          row.push({
            originalRowIndex: originalIndexMap[obj.campaign_key],
            content: R.isNil(content) ? "" : content,
          });
        }
        data.push(row);
      }
      setRightData(data);
    },
    [dataWithEdits, rightTableHeaders]
  );

  const [sortMap, setSortMapValue, setSortMap] = useMap<string, string>({});

  const updateSortingMap = useMemo(() => {
    return { off: "up", up: "down", down: "off" };
  }, []);

  const onSort = useCallback(
    (propertyName, event) => {
      // Prevent sorting when clicking on the name/id toggle
      if (event.target instanceof HTMLElement) {
        const { classList } = event.target;
        if (classList.contains("bf-label") || classList.contains("toggle-item")) {
          return;
        }
      }
      setSortMap({});
      setSortMapValue(propertyName, updateSortingMap[sortMap[propertyName] || "off"]);
      sortRightData(propertyName, updateSortingMap[sortMap[propertyName] || "off"]);
    },
    [setSortMap, setSortMapValue, sortMap, sortRightData, updateSortingMap]
  );

  const customCornerRenderer = useMemo(
    () => (corner: CornerLocation) => {
      if (corner === "nw") {
        return (
          <div
            className="stickyTableCell"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <div className="checkBoxContainer">
              <CheckBox
                checked={selectAll}
                onCheck={() => {
                  if (selectAll) {
                    setSelectedRows({});
                  } else {
                    let selectAllMap = {};
                    if (data) {
                      for (let row of data) {
                        selectAllMap[row.campaign_key] = row;
                      }
                    }

                    setSelectedRows(current => ({ ...current, ...selectAllMap }));
                  }

                  setSelectAll(!selectAll);
                }}
              />
            </div>
            <div
              className={`includeSortHeaderCaret account ${sortMap.account_name}`}
              onClick={e => {
                onSort("account_name", e);
              }}
            >
              Account
              <TextToggleButton
                options={["Names", "IDs"]}
                selectedOption={showAllAccountIDs ? "IDs" : "Names"}
                onChange={() => {
                  setShowAllAccountIDs(prev => !prev);
                }}
                size="sm"
              />
            </div>
            <div
              className={`includeSortHeaderCaret campaign ${sortMap.campaign_name}`}
              onClick={e => {
                onSort("campaign_name", e);
              }}
            >
              Campaign
              <TextToggleButton
                options={["Names", "IDs"]}
                selectedOption={showAllCampaignIDs ? "IDs" : "Names"}
                onChange={() => {
                  setShowAllCampaignIDs(prev => !prev);
                }}
                size="sm"
              />
            </div>
            {(dataGranularity === "ad_group" || dataGranularity === "ad") && (
              <div
                className={`includeSortHeaderCaret ad_group ${sortMap.ad_group_name}`}
                onClick={e => {
                  onSort("ad_group_name", e);
                }}
              >
                Ad Group
                <TextToggleButton
                  options={["Names", "IDs"]}
                  selectedOption={showAllAdGroupIDs ? "IDs" : "Names"}
                  onChange={() => {
                    setShowAllAdGroupIDs(prev => !prev);
                  }}
                  size="sm"
                />
              </div>
            )}
            {dataGranularity === "ad" && (
              <div
                className={`includeSortHeaderCaret ad ${sortMap.ad_name}`}
                onClick={e => {
                  onSort("ad_name", e);
                }}
              >
                Ad
                <TextToggleButton
                  options={["Names", "IDs"]}
                  selectedOption={showAllAdIDs ? "IDs" : "Names"}
                  onChange={() => {
                    setShowAllAdIDs(prev => !prev);
                  }}
                  size="sm"
                />
              </div>
            )}
          </div>
        );
      } else {
        return <div />;
      }
    },
    [
      selectAll,
      sortMap.account_name,
      sortMap.campaign_name,
      sortMap.ad_group_name,
      sortMap.ad_name,
      showAllAccountIDs,
      showAllCampaignIDs,
      dataGranularity,
      showAllAdGroupIDs,
      showAllAdIDs,
      setSelectedRows,
      data,
      onSort,
    ]
  );

  const leftDataFields = useMemo(() => {
    let leftFields = ["campaign_key", "account_name", "account_id", "campaign_name", "campaign_id"];
    if (dataGranularity === "ad_group") {
      leftFields = leftFields.concat(["ad_group_name", "ad_group_id"]);
    }
    if (dataGranularity === "ad") {
      leftFields = leftFields.concat(["ad_group_name", "ad_group_id", "ad_name", "ad_id"]);
    }

    return leftFields;
  }, [dataGranularity]);

  const noRowsRenderer = useCallback(() => {
    return data.length === 0 ? <div className="noRows">No rows to display.</div> : <div />;
  }, [data.length]);

  const startingWidth = useMemo(() => {
    switch (dataGranularity) {
      case "ad":
        return (3 / 4) * innerWidth;
        break;
      case "ad_group":
        return (5 / 8) * innerWidth;
        break;
      case "campaign":
      default:
        return (1 / 2) * innerWidth;
    }
  }, [dataGranularity, innerWidth]);

  const leftWidth = useMemo(() => {
    return resizedLeftWidth ?? startingWidth;
  }, [resizedLeftWidth, startingWidth]);

  const leftSpacing = 24;
  const resizeFrame: React.MouseEventHandler<HTMLDivElement> = e => {
    if (resizeActive) {
      const leftNavWidth = 60;
      const halfAreaWidth = 10;
      const adjustedPosition = e.clientX - leftNavWidth - leftSpacing - halfAreaWidth;
      setResizedLeftWidth(adjustedPosition);
    }
  };

  return (
    <div
      className="segmentationLabelingTable"
      onMouseUp={() => setResizeActive(false)}
      onMouseMove={e => resizeFrame(e)}
    >
      <div className="resizeContainer">
        <div
          className="resizeArea"
          style={{ left: `${leftWidth + 24}px` }}
          onMouseDown={() => setResizeActive(true)}
        />
      </div>
      <FrozenColumnsTable
        leftWidth={leftWidth}
        leftRenderer={customLeftRenderer}
        cornerRenderer={customCornerRenderer}
        rightTableHeaders={rightTableHeaders}
        rightTableHeaderNames={rightHeaderNames}
        originalRightData={data}
        leftDataFields={leftDataFields}
        rightData={rightData}
        setRightData={setRightData}
        noRowsRenderer={noRowsRenderer}
      />
    </div>
  );
};

export default SegmentationLabelingTable;

import "./Insights.scss";
import { amcClient } from "./Cache";
import { GET_AMC_REPORT_TYPES } from "./Queries";
import { Page } from "../Components";
import { RouteComponentProps, Router } from "@reach/router";
import { useCompanyInfo } from "../redux/company";
import { useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import { useTabbedNav } from "../utils/hooks/useNav";
import * as UserRedux from "../redux/user";
import Geo from "./Pages/Geo/Geo";
import MediaMix from "./Pages/MediaMix/MediaMix";
import NewToBrand from "./Pages/NewToBrand/NewToBrand";
import PathingAnalysis from "./Pages/PathingAnalysis/PathingAnalysis";
import React, { useEffect, useMemo } from "react";
import Tableau from "./Pages/Tableau/Tableau";

interface Report {
  reportType: string;
}

interface AMCData {
  amc: {
    reports: Report[];
  };
}

const enum PageTab {
  GEO = "geo",
  MEDIA_MIX = "media-mix",
  NEW_TO_BRAND = "new-to-brand",
  PATHING_ANALYSIS = "pathing-analysis",
  TABLEAU = "tableau",
}

const NAVS = [
  { label: "Geo", key: PageTab.GEO },
  { label: "Media Mix", key: PageTab.MEDIA_MIX },
  { label: "New to Brand", key: PageTab.NEW_TO_BRAND },
  { label: "Pathing Analysis", key: PageTab.PATHING_ANALYSIS },
  { label: "Tableau", key: PageTab.TABLEAU },
];

export const AMC_TEST_INSTANCE_ID = "amcxz05xi9v"; //Blueair for the testing

export default React.memo(({ navigate }: RouteComponentProps) => {
  const isAdmin = useSelector(UserRedux.isAdminSelector);
  const { tab, goToTab } = useTabbedNav({
    navigate,
    baseURL: "amc-insights",
    defaultKey: "",
  });

  let { cid, amc_instance_id: amcInstanceId } = useCompanyInfo();
  if (cid === "tinuititest") {
    amcInstanceId = AMC_TEST_INSTANCE_ID;
  }

  const { data } = useQuery<AMCData>(GET_AMC_REPORT_TYPES, {
    client: amcClient,
    variables: {
      amcInstanceId,
    },
    skip: !amcInstanceId,
  });

  const availableTabs = useMemo(() => {
    const filteredTabs = NAVS.filter(tab =>
      data?.amc.reports.map(report => report.reportType.replaceAll("_", "-")).includes(tab.key)
    );

    // Manually add the "Tableau" tab to the filtered tabs list
    const tableauTab = NAVS.find(tab => tab.key === PageTab.TABLEAU);
    if (tableauTab && isAdmin) {
      filteredTabs.push(tableauTab);
    }

    return filteredTabs;
  }, [data, isAdmin]);

  useEffect(() => {
    if (availableTabs.length && !tab) {
      goToTab(availableTabs[0].key);
    }
  }, [availableTabs, tab, goToTab]);

  return (
    <Page
      app2Redesign
      pageType="AMC Insights"
      title="AMC Insights"
      navs={availableTabs}
      selectedNav={tab}
      onNav={goToTab}
    >
      <div className="amc-insights-page">
        <Router className="fullPageRouter">
          <Geo path={PageTab.GEO} />
          <MediaMix path={PageTab.MEDIA_MIX} />
          <NewToBrand path={PageTab.NEW_TO_BRAND} />
          <PathingAnalysis path={PageTab.PATHING_ANALYSIS} />
          {isAdmin && <Tableau path={PageTab.TABLEAU} />}
        </Router>
      </div>
    </Page>
  );
});

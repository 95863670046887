import "./GradientLegend.scss";
import React from "react";
import { formatNumber } from "../utils/format-utils";

interface GradientLegendProps {
  bins: number[];
  actual: boolean;
}

const adjustedLegendRectangleStyle = {
  background: `linear-gradient(
    90deg,
    #fd2c29 0%,
    #fd2c29 12.5%,
    #ff726e 12.5%,
    #ff726e 25%,
    #ffb1b0 25%,
    #ffb1b0 37.5%,
    #ffe5e6 37.5%,
    #ffe5e6 50%,
    #ccfaff 50%,
    #ccfaff 62.5%,
    #94eefa 62.5%,
    #94eefa 75%,
    #45d9ed 75%,
    #45d9ed 87.5%,
    #15c4db 87.5%,
    #15c4db 100%
  )`,
};

const actualLegendRectangleStyle = {
  background:
    "linear-gradient(90deg, #E8E5FF 0%, #E8E5FF 16.67%, #B5A6FF 16.67%, #B5A6FF 33.33%, #977AFF 33.33%, #977AFF 50%, #8254FF 50%, #8254FF 66.67%, #6B2DEF 66.67%, #6B2DEF 83.33%, #5200CE 83.33%, #5200CE 100%)",
};

export const GradientLegend: React.FC<GradientLegendProps> = ({ bins, actual }) => {
  return (
    <div className="gradientLegend">
      <div className="legendTitle">Legend:</div>
      <div className="legendContentsBox">
        <div className="legendContents">
          <div
            className="rectangle"
            style={actual ? actualLegendRectangleStyle : adjustedLegendRectangleStyle}
          ></div>
          <div className="legendTickContainer">
            {bins.map((value, index) => {
              const percentage = (index / (bins.length - 1)) * 100;
              if (index === 0) {
                return (
                  <div
                    className="legendTickBox"
                    key={index}
                    style={{ left: `${percentage + 0.1}%` }}
                  >
                    <div className="legendTickLine"></div>
                    <div className="legendTickText">{formatNumber(value, 2)}</div>
                  </div>
                );
              } else if (index === bins.length - 1) {
                return (
                  <div
                    className="legendTickBox"
                    key={index}
                    style={{ left: `${percentage - 0.1}%` }}
                  >
                    <div className="legendTickLine"></div>
                    <div className="legendTickText">{formatNumber(value, 2)}</div>
                  </div>
                );
              } else {
                return (
                  <div className="legendTickBox" key={index} style={{ left: `${percentage}%` }}>
                    <div className="legendTickLine"></div>
                    <div className="legendTickText">{formatNumber(value, 2)}</div>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GradientLegend;

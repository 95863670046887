import "./CustomSegments.scss";
import * as R from "ramda";
import * as uuid from "uuid";
import React, { useState, useCallback, useMemo } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { MdSave, MdDelete } from "react-icons/md";
import { Button, ButtonType, FullPageSpinner, Spinner } from "../Components";
import * as UserRedux from "../redux/user";
import { useCompanyInfo } from "../redux/company";
import { useSetError } from "../redux/modals";
import { SegmentationMappingLambdaFetch } from "../utils/fetch-utils";
import { RouteComponentProps } from "@reach/router";
import { CustomSegmentsData } from "./SegmentationMapping";
import { StateSetter } from "../utils/types";
import { useMap } from "../utils/hooks/useData";
import { ButtonFrameworkVariant } from "../Components/ButtonFramework";
import CustomSegmentCard, { NewSegmentCard, SegmentType } from "./CustomSegmentCard";
import { useSelector } from "react-redux";
import { isSparcCompany } from "../CrossChannel/crossChannelUtils";

interface CombinedNewData {
  [segmentId: string]: {
    name: string;
    values: string[];
  };
}

export interface CommittedValue {
  valueId: number;
  valueName: string;
}

export interface NewValue {
  valueId: string;
  valueName: string;
  segmentId: number | string;
}

export enum SystemGeneratedSegmentNames {
  PLATFORM = "Platform",
  CHANNEL = "Channel",
}

export enum RequiredSegmentNames {
  CAMPAIGN_OWNERSHIP = "Campaign Ownership",
  INCLUDE_IN_FEE_CALC = "Include in Fee Calc",
}

export enum PresetSegmentNames {
  TACTIC = "Tactic",
  FUNNEL_TIER = "Funnel Tier",
  PROMO = "Promo",
  BUDGET_TYPE = "Media Budget Type",
  GEO = "Geo",
}

const getSegmentTypeFromName = (name: string): SegmentType => {
  if ((Object.values(SystemGeneratedSegmentNames) as string[]).includes(name)) {
    return SegmentType.SYSTEM_GENERATED;
  }

  if ((Object.values(RequiredSegmentNames) as string[]).includes(name)) {
    return SegmentType.REQUIRED;
  }

  if ((Object.values(PresetSegmentNames) as string[]).includes(name)) {
    return SegmentType.PRESET;
  }

  return SegmentType.CUSTOM;
};

const PROTECTED_PHRASES_MAP: {
  [segmentName in PresetSegmentNames]: string[];
} = {
  [PresetSegmentNames.TACTIC]: ["prospect", "retarget", "winback", "retention", "brand"],
  [PresetSegmentNames.FUNNEL_TIER]: ["funnel", "upper", "mid", "lower"],
  [PresetSegmentNames.PROMO]: ["evergreen", "sale"],
  [PresetSegmentNames.BUDGET_TYPE]: ["performance", "co-op", "coop", "dtc"],
  [PresetSegmentNames.GEO]: [],
};

export const PRESET_VALUES_MAP: {
  [segmentName in PresetSegmentNames]: string[];
} = {
  [PresetSegmentNames.TACTIC]: [
    "ASC+",
    "Auto",
    "Brand",
    "Conquesting",
    "Demand Gen",
    "Direct",
    "Keyword Targeting",
    "Multi",
    "Nonbrand",
    "Product Targeting",
    "Prospecting",
    "Reach",
    "Retargeting",
    "Retention",
    "Test",
    "Traffic",
  ],
  [PresetSegmentNames.FUNNEL_TIER]: ["Upper Funnel", "Mid Funnel", "Lower Funnel"],
  [PresetSegmentNames.PROMO]: ["Evergreen", "Sale"],
  [PresetSegmentNames.BUDGET_TYPE]: ["Performance", "Co-op", "Brand", "DTC"],
  [PresetSegmentNames.GEO]: [],
};

const dataGranularityOptions: Record<
  string,
  { id: "ad" | "ad_group" | "campaign"; name: string; optionLabel?: string }
> = {
  campaign: {
    id: "campaign",
    name: "Campaign",
    optionLabel: "Campaign (Default)",
  },
  ad_group: {
    id: "ad_group",
    name: "Ad Group",
  },
  ad: {
    id: "ad",
    name: "Ad",
  },
};

const CustomSegments = ({
  data,
  setData,
  dataGranularity,
  setDataGranularity,
}: {
  data: CustomSegmentsData[] | undefined;
  setData: StateSetter<CustomSegmentsData[] | undefined>;
  dataGranularity: "ad" | "ad_group" | "campaign";
  setDataGranularity: React.Dispatch<
    React.SetStateAction<"ad" | "ad_group" | "campaign" | undefined>
  >;
} & RouteComponentProps): JSX.Element => {
  const setError = useSetError();
  const { cid } = useCompanyInfo();
  const [newSegments, setNewSegments] = useState<
    Record</* segmentId */ string, /* segmentName */ string>
  >({});
  const [newSegmentValuesMap, setNewSegmentValues, setNewSegmentValuesMap] = useMap<
    /* segmentId */ string,
    Record</* valueId */ string, NewValue>
  >({});
  const [editedSegmentNamesMap, setEditedSegmentName, setEditedSegmentNameMap] = useMap<
    /* segmentId */ string,
    /* newName */ string | undefined
  >({});
  const [editedSegmentValueMap, setEditedSegmentValue, setEditedSegmentValueMap] = useMap<
    /* segmentId */ string,
    Record</* valueId */ string, CommittedValue>
  >({});
  const [segmentsInEditModeMap, setSegmentsInEditModeValue, setSegmentsInEditModeMap] = useMap<
    string,
    boolean
  >({});
  const [segmentsToDelete, setSegmentsToDelete] = useState</* segmentIds */ string[]>([]);
  const [segmentValuesToDeleteMap, setSegmentValuesToDelete, setSegmentValuesToDeleteMap] = useMap<
    /* segmentId */ string,
    /* valueIds */ (string | number)[]
  >({});
  const [saving, setSaving] = useState(false);
  const [selectedGranularity, setSelectedGranularity] = useState<"ad" | "ad_group" | "campaign">(
    dataGranularity
  );
  const [inputContainsLockedValue, setInputContainsLockedValue] = useState(false);
  const role = useSelector(UserRedux.roleSelector);
  const isAdmin = useMemo(() => role === 0, [role]);

  const checkIfContainsProtectedPhrase = useCallback(
    (value: string, segmentName: string) => {
      const checkIfInList = (arr, str) => {
        return arr.reduce((acc, val) => {
          return str.toLocaleLowerCase().includes(val) ? val : acc;
        }, "");
      };

      let invalidSegment = "";
      let protectedValue = "";
      Object.keys(PROTECTED_PHRASES_MAP).forEach(lockedSegName => {
        const lockedValues = PROTECTED_PHRASES_MAP[lockedSegName];
        const foundProtectedValue = checkIfInList(lockedValues, value);
        if (value.length > 0 && foundProtectedValue && segmentName !== lockedSegName) {
          invalidSegment = lockedSegName;
          protectedValue = foundProtectedValue;
        }
      });

      if (invalidSegment && !isAdmin) {
        setError({
          message: `Please add the preset ${invalidSegment} segment to label campaigns with "${protectedValue}".`,
        });
        setInputContainsLockedValue(true);
      } else {
        setInputContainsLockedValue(false);
      }
    },
    [isAdmin, setError]
  );

  // Adds a new custom segment
  const addNewSegment = useCallback(
    (option: PresetSegmentNames | string) => {
      const segmentId = uuid.v4();
      setNewSegments(current => ({
        ...current,
        [segmentId]: option in PresetSegmentNames ? PresetSegmentNames[option] : "",
      }));

      const presetValues: Record<string, NewValue> =
        option in PresetSegmentNames
          ? PRESET_VALUES_MAP[PresetSegmentNames[option]].reduce((acc, valueName) => {
              const valueId = uuid.v4();
              acc[valueId] = {
                valueId,
                valueName,
                segmentId,
              };
              return acc;
            }, {})
          : {};
      const naValueId = uuid.v4();
      presetValues[naValueId] = {
        valueId: naValueId,
        valueName: "N/A",
        segmentId,
      };
      setNewSegmentValues(segmentId, presetValues);
    },
    [setNewSegmentValues]
  );

  // Adds a new value to a segment
  const addNewSegmentValue = useCallback(
    (valueName, segmentId) => {
      const valueId = uuid.v4();
      setNewSegmentValuesMap(current => ({
        ...current,
        [segmentId]: {
          ...current[segmentId],
          [valueId]: { valueId, valueName, segmentId },
        },
      }));
    },
    [setNewSegmentValuesMap]
  );

  // Discard all unsaved changes
  const discardChanges = useCallback(() => {
    setNewSegments({});
    setNewSegmentValuesMap({});
    setEditedSegmentNameMap({});
    setEditedSegmentValueMap({});
    setSegmentsInEditModeMap({});
    setSegmentsToDelete([]);
    setSegmentValuesToDeleteMap({});
    setSelectedGranularity(dataGranularity);
  }, [
    dataGranularity,
    setEditedSegmentNameMap,
    setEditedSegmentValueMap,
    setNewSegmentValuesMap,
    setSegmentValuesToDeleteMap,
    setSegmentsInEditModeMap,
  ]);

  const hasSegmentEdits = useMemo(() => {
    return !(
      R.isEmpty(newSegments) &&
      R.isEmpty(Object.values(newSegmentValuesMap).filter(val => Boolean(val))) &&
      R.isEmpty(Object.values(editedSegmentNamesMap).filter(val => Boolean(val))) &&
      R.isEmpty(Object.values(editedSegmentValueMap).filter(val => Boolean(val)))
    );
  }, [editedSegmentNamesMap, editedSegmentValueMap, newSegmentValuesMap, newSegments]);

  const hasGranularityEdits = useMemo(() => {
    return dataGranularity !== selectedGranularity;
  }, [dataGranularity, selectedGranularity]);

  const hasSegmentDeletionEdits = useMemo(() => {
    return !R.isEmpty(segmentsToDelete);
  }, [segmentsToDelete]);

  const hasValueDeletionEdits = useMemo(() => {
    return !R.isEmpty(segmentValuesToDeleteMap);
  }, [segmentValuesToDeleteMap]);

  const hasEdits = useMemo(() => {
    return (
      hasSegmentEdits || hasGranularityEdits || hasSegmentDeletionEdits || hasValueDeletionEdits
    );
  }, [hasGranularityEdits, hasSegmentDeletionEdits, hasSegmentEdits, hasValueDeletionEdits]);

  // Save Changes
  const saveChanges = useCallback(async () => {
    try {
      setSaving(true);

      if (hasSegmentDeletionEdits) {
        await SegmentationMappingLambdaFetch("/deleteSegments", {
          method: "DELETE",
          body: {
            segmentIds: segmentsToDelete,
          },
        });
      }

      if (hasValueDeletionEdits) {
        const segmentValuesToDelete = segmentValuesToDeleteMap;
        // Don't try to delete values from a segment that was deleted
        segmentsToDelete.forEach(segmentId => {
          delete segmentValuesToDelete[segmentId];
        });

        const valueIds = R.flatten(Object.values(segmentValuesToDelete));

        if (valueIds.length > 0) {
          await SegmentationMappingLambdaFetch("/deleteSegmentValues", {
            method: "DELETE",
            body: {
              valueIds,
            },
          });
        }
      }

      if (hasSegmentEdits) {
        let combinedNewData: CombinedNewData = {};

        for (let [segmentId, segmentName] of Object.entries(newSegments)) {
          const valuesMap = newSegmentValuesMap[segmentId] ?? {};
          combinedNewData[segmentId] = {
            name: segmentName,
            values: Object.values(valuesMap)
              .map(val => val.valueName)
              // Do not save empty string values
              .filter(val => Boolean(val)),
          };
        }

        const newValuesForExistingSegments: any[] = [];
        data?.forEach(({ segmentId }) => {
          const newValues = newSegmentValuesMap[segmentId] ?? {};
          Object.keys(newValues).forEach(key => {
            const valueName =
              (editedSegmentValueMap[segmentId] ?? {})[key]?.valueName ||
              newValues[key]?.valueName ||
              "";
            // Do not save empty string values
            if (valueName) {
              newValuesForExistingSegments.push({ name: valueName, segmentId });
            }
          });
        });

        const newSegmentNameToValuesMap = {};
        for (let obj of Object.values(combinedNewData)) {
          newSegmentNameToValuesMap[obj.name] = obj.values;
        }

        let editedSegmentNames = {};
        Object.keys(editedSegmentNamesMap).forEach(key => {
          const value = editedSegmentNamesMap[key];
          if (value) {
            editedSegmentNames[key] = value;
          }
        });

        let editedSegmentValueNames = {};
        Object.keys(editedSegmentValueMap).forEach(segmentId => {
          const values = editedSegmentValueMap[segmentId];
          if (values) {
            Object.keys(values).forEach(valueId => {
              const value = values[valueId];
              const valueIsNew = newValuesForExistingSegments
                .map(val => val.name)
                .includes(value.valueName);
              if (value && !valueIsNew) {
                editedSegmentValueNames[valueId] = value.valueName;
              }
            });
          }
        });

        await SegmentationMappingLambdaFetch("/updateCustomSegments", {
          method: "POST",
          body: {
            company: cid,
            newSegments: newSegmentNameToValuesMap,
            newValuesForExistingSegments,
            editedSegmentNames,
            editedSegmentValueNames,
          },
        });
      }

      if (hasGranularityEdits) {
        await SegmentationMappingLambdaFetch("/updateGranularity", {
          method: "POST",
          body: {
            company: cid,
            granularity: selectedGranularity,
          },
        });
        setDataGranularity(selectedGranularity);
      }

      setData(undefined);
      discardChanges();
      setSaving(false);
    } catch (e) {
      setSaving(false);
      const reportError = e as Error;
      setError({ message: `Failed to save changes: ${reportError.message}`, reportError });
    }
  }, [
    hasSegmentDeletionEdits,
    hasValueDeletionEdits,
    hasSegmentEdits,
    hasGranularityEdits,
    setData,
    discardChanges,
    segmentsToDelete,
    segmentValuesToDeleteMap,
    data,
    editedSegmentNamesMap,
    editedSegmentValueMap,
    cid,
    newSegments,
    newSegmentValuesMap,
    selectedGranularity,
    setDataGranularity,
    setError,
  ]);

  const deleteSegmentValue = (segmentId: string, valueId: number | string): void => {
    if (Object.keys(newSegmentValuesMap[segmentId] ?? []).includes(String(valueId))) {
      // If the value is net new, remove it from local state
      const newValues = newSegmentValuesMap[segmentId] as Record<string, NewValue>;
      delete newValues[String(valueId)];
      setNewSegmentValuesMap(current => ({
        ...current,
        [segmentId]: newValues,
      }));
    } else {
      // Else, prep it for a network request
      setSegmentValuesToDelete(segmentId, [
        ...(segmentValuesToDeleteMap[segmentId] ?? []),
        valueId,
      ]);
    }
  };

  const unaddedPresets = useMemo(() => {
    if (!data) {
      return Object.keys(PresetSegmentNames);
    }

    return Object.keys(PresetSegmentNames).filter(
      presetKey =>
        !data.map(segment => segment.segmentName).includes(PresetSegmentNames[presetKey]) &&
        !Object.values(newSegments).includes(PresetSegmentNames[presetKey])
    );
  }, [data, newSegments]);

  const filteredNewSegments: Record<string, string> = useMemo(() => {
    const filteredSegments = { ...newSegments };
    segmentsToDelete.forEach(segmentId => {
      if (filteredSegments[segmentId]) {
        delete filteredSegments[segmentId];
      }
    });
    return filteredSegments;
  }, [newSegments, segmentsToDelete]);

  const filteredCommittedSegments: CustomSegmentsData[] = useMemo(
    () => data?.filter(segment => !segmentsToDelete.includes(segment.segmentId)) ?? [],
    [data, segmentsToDelete]
  );

  if (!data) {
    return <FullPageSpinner />;
  }

  if (isSparcCompany(cid) && !isAdmin) {
    return (
      <div className="notAccessible">{`This config is not accessible for non-admin users for ${cid}.`}</div>
    );
  }

  return (
    <div className="customSegmentsPage">
      <div className="customSegmentsHeader">
        <div className="customSegmentsTitle">Our Segments</div>
        <DropdownButton
          drop="down"
          title={`Granularity: ${dataGranularityOptions[selectedGranularity].name}`}
        >
          <div className="granularityExplanation">
            <div>
              Set the lowest level of granularity to segment by. We recommend choosing a value when
              you first set up segments for your client, before any labeling.
            </div>
          </div>
          {Object.values(dataGranularityOptions).map(({ id, name, optionLabel }) => (
            <Dropdown.Item
              key={id}
              onSelect={() => {
                setSelectedGranularity(id);
              }}
            >
              {optionLabel ?? name}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      </div>
      <div className="segmentList">
        {filteredCommittedSegments.map(segmentObj => {
          const { segmentId, segmentName, values } = segmentObj;
          const editedValues = editedSegmentValueMap[segmentId] ?? {};
          const newAndExistingValues = [
            ...values,
            ...Object.values(newSegmentValuesMap[segmentId] ?? {}),
          ];
          const valuesWithEdits = newAndExistingValues.map(value => {
            if (editedValues[value.valueId]) {
              return {
                ...value,
                valueName: editedValues[value.valueId].valueName,
              };
            }
            return value;
          });
          const filteredValues = valuesWithEdits.filter(({ valueId }) => {
            return !(segmentValuesToDeleteMap[segmentId] ?? []).includes(valueId);
          });

          const hasEdits =
            Object.keys(newSegmentValuesMap[segmentId] ?? {}).length > 0 ||
            Object.keys(editedSegmentValueMap[segmentId] ?? {}).length > 0 ||
            Boolean(editedSegmentNamesMap[segmentId]);

          return (
            <CustomSegmentCard
              type={getSegmentTypeFromName(segmentName)}
              key={segmentId}
              id={segmentId}
              name={editedSegmentNamesMap[segmentId] ?? segmentName}
              values={filteredValues}
              dataGranularity={dataGranularity}
              isInEditMode={Boolean(segmentsInEditModeMap[segmentId])}
              setIsInEditMode={newVal => setSegmentsInEditModeValue(segmentId.toString(), newVal)}
              isAdmin={isAdmin}
              hasEdits={hasEdits}
              onEditSegmentName={newVal => {
                checkIfContainsProtectedPhrase(newVal, segmentName);
                setEditedSegmentName(segmentId.toString(), newVal);
              }}
              onEditSegmentValueName={(newVal, valueId) => {
                checkIfContainsProtectedPhrase(newVal, segmentName);
                setEditedSegmentValue(segmentId, {
                  ...editedSegmentValueMap[segmentId],
                  [valueId]: {
                    ...(editedSegmentValueMap[segmentId] ?? {})[valueId],
                    valueName: newVal,
                    valueId: Number(valueId),
                  },
                });
              }}
              addNewSegmentValue={addNewSegmentValue}
              deleteSegment={() => {
                setSegmentsToDelete(current => [...current, segmentId]);
              }}
              deleteSegmentValue={deleteSegmentValue}
            />
          );
        })}
        {Object.keys(filteredNewSegments).map(segmentId => {
          const segmentName = newSegments[segmentId] ?? "";
          const values = Object.values(newSegmentValuesMap[segmentId] ?? {});

          return (
            <CustomSegmentCard
              type={
                Object.values(PresetSegmentNames).includes(segmentName as PresetSegmentNames)
                  ? SegmentType.PRESET
                  : SegmentType.CUSTOM
              }
              key={segmentId}
              id={segmentId}
              name={segmentName}
              values={values}
              dataGranularity={dataGranularity}
              isInEditMode={true}
              isAdmin={isAdmin}
              hasEdits={true}
              onEditSegmentName={newVal => {
                setNewSegments(current => ({
                  ...current,
                  [segmentId]: newVal,
                }));
              }}
              onEditSegmentValueName={(newVal, valueId) => {
                checkIfContainsProtectedPhrase(newVal, segmentName);
                setNewSegmentValuesMap(current => ({
                  ...current,
                  [segmentId]: {
                    ...current[segmentId],
                    [valueId]: { valueId, segmentId, valueName: newVal },
                  },
                }));
              }}
              addNewSegmentValue={addNewSegmentValue}
              deleteSegment={() => {
                setNewSegments(current => {
                  delete current[segmentId];
                  return { ...current };
                });
                setNewSegmentValuesMap(current => {
                  delete current[segmentId];
                  return { ...current };
                });
              }}
              deleteSegmentValue={deleteSegmentValue}
            />
          );
        })}
        <NewSegmentCard unaddedPresets={unaddedPresets} addNewSegment={addNewSegment} />
      </div>
      <div className="customSegmentsFooter">
        <div className="customSegmentsControls pendingChangesControls">
          {hasEdits && <span className="unsavedChanges">Unsaved changes</span>}
          <Button
            type={ButtonType.OUTLINED}
            variant={ButtonFrameworkVariant.LEADING_ICON}
            onClick={discardChanges}
            disabled={!hasEdits || saving}
            icon={<MdDelete color="#6B2DEF" />}
          >
            Discard Changes
          </Button>
          <Button
            type={ButtonType.FILLED}
            variant={ButtonFrameworkVariant.LEADING_ICON}
            onClick={saveChanges}
            disabled={
              !hasEdits ||
              saving ||
              Object.values(newSegments).some(value => value === "") ||
              inputContainsLockedValue
            }
            icon={saving ? undefined : <MdSave />}
          >
            {saving ? <Spinner /> : "Save"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CustomSegments;

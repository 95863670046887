import React, { useContext, useState, useMemo, useEffect, useReducer, useCallback } from "react";
import { FormContext, metaBuyingFormDefault } from "../MetaBuyingContext";
import {
  ReviewModalVariant,
  ReviewModal,
  ReviewModalTab,
} from "../DraftsAndPublished/Components/ReviewModal";
import { RouteComponentProps } from "@reach/router";
import { useSetError } from "../../redux/modals";
import {
  CampaignRow,
  AdSetRow,
  AdRow,
  MBApprovalStage,
  CreationTab,
  AdSetPromotedObject,
  Objective,
  SpecialAdCategory,
  Position,
  PlatformPublisher,
  GenderId,
  AdCreative,
  AttributionSpecRow,
  BidConstraint,
} from "@blisspointmedia/bpm-types/dist/MetaBuying";
import { MdCheck, MdDelete } from "react-icons/md";
import { BsMeta } from "react-icons/bs";
import { awaitJSON, MetaLambdaFetch } from "../../utils/fetch-utils";
import AlertDialog, { AlertDialogVariants } from "../../Components/AlertDialog/AlertDialog";
import { PageTab } from "../MetaBuying";
import * as R from "ramda";
import "../MetaBuying.scss";
import "./MetaBuyingCreate.scss";
import {
  VIDEO,
  IMAGE,
  STATUS_NONE,
  CAMPAIGN_LIFETIME_BUDGET,
  CAMPAIGN_DAILY_BUDGET,
  AD_SET_DAILY_BUDGET,
  AD_SET_LIFETIME_BUDGET,
  TAB_CAMPAIGN,
  TAB_AD_SET,
  TAB_AD,
  MEN,
  WOMEN,
  ID_MEN,
  ID_WOMEN,
  LOWEST_COST_WITH_BID_CAP,
  COST_CAP,
  LOWEST_COST_WITH_MIN_ROAS,
} from "../MetaBuyingConstants";

export interface MetaBuyingReviewModalProps {
  show: boolean;
  closeModal: () => void;
  creationTabs: CreationTab[];
  dimensions: Record<string, any>;
  setTabIndex: React.Dispatch<React.SetStateAction<number>>;
  selectedAdAccount: {
    account_id: string;
    account_name: string;
    business_manager: string;
  };
  email: string;
  goToTables: (tab: PageTab) => void;
}

export enum ReviewModalAlertDialogTypes {
  PUBLISH_AS_PAUSED = "PUBLISH_AS_PAUSED",
  SAVED_AS_DRAFT = "SAVED_AS_DRAFT",
  DELETE = "DELETE",
}

export enum CreationState {
  IN_PROGRESS = "IN_PROGRESS",
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
}

const { PUBLISH_AS_PAUSED, SAVED_AS_DRAFT, DELETE } = ReviewModalAlertDialogTypes;
const { IN_PROGRESS, SUCCESS, FAILED } = CreationState;

export const MetaBuyingReviewModal = ({
  show,
  closeModal,
  creationTabs,
  dimensions,
  setTabIndex,
  selectedAdAccount,
  email,
  goToTables,
}: MetaBuyingReviewModalProps & RouteComponentProps): JSX.Element => {
  const { metaBuyingForm, setMetaBuyingForm } = useContext(FormContext);

  const { account_id, business_manager, account_name } = selectedAdAccount;

  const setError = useSetError();

  const creationStateDefault: Record<CreationTab, CreationState | null> = useMemo(
    () => ({
      [TAB_CAMPAIGN]: null,
      [TAB_AD_SET]: null,
      [TAB_AD]: null,
    }),
    []
  );

  const [creationState, setCreationState] = useReducer(
    (state: typeof creationStateDefault, newState: Partial<typeof creationStateDefault>) => ({
      ...state,
      ...newState,
    }),
    R.clone(creationStateDefault)
  );
  const setLevelCreationState = (level: CreationTab, state: CreationState | null) =>
    setCreationState({ [level]: state });

  const loading = useMemo(() => R.any(state => state === IN_PROGRESS, R.values(creationState)), [
    creationState,
  ]);
  const successfulCreations = useMemo(
    () => R.keys(R.filter(state => state === SUCCESS, creationState)) as CreationTab[],
    [creationState]
  );

  const [alertDialogType, setAlertDialogType] = useState<ReviewModalAlertDialogTypes | null>(null);
  const [creationType, setCreationType] = useState<"draft" | "paused" | null>(null);

  const metaCampaignForm = metaBuyingForm.campaign;
  const metaAdSetForm = metaBuyingForm.ad_set;
  const metaAdForm = metaBuyingForm.ad;

  const formatCampaignRow = () => {
    let campaignRow: CampaignRow = {
      id: "",
      campaign_name: metaCampaignForm.campaign_name,
      meta_id: null,
      objective: metaCampaignForm.objective,
      special_ad_categories: metaCampaignForm.special_ad_categories,
      special_ad_category_country: "US",
      bid_strategy: metaCampaignForm.bid_strategy,
      spend_cap: null,
      adsmanager_status: metaCampaignForm.status,
      created: null,
      lastuser: "",
    };

    if (metaCampaignForm.budget_type === CAMPAIGN_DAILY_BUDGET) {
      campaignRow.daily_budget = metaCampaignForm.budget;
    } else if (metaCampaignForm.budget_type === CAMPAIGN_LIFETIME_BUDGET) {
      campaignRow.lifetime_budget = metaCampaignForm.budget;
    }

    return campaignRow;
  };

  interface CreateMetaCampaignParams {
    business_name: string;
    ad_account_id: string;
    name: string;
    objective: Objective;
    special_ad_categories: SpecialAdCategory[];
    special_ad_category_country: string;
    bid_strategy: string;
    lastuser: string;
    is_draft: boolean;
    lifetime_budget?: number;
    daily_budget?: number;
    spend_cap?: number;
  }

  const formatCampaignPayload = useCallback(
    (is_draft: boolean): CreateMetaCampaignParams => {
      let payload: CreateMetaCampaignParams = {
        business_name: account_name,
        ad_account_id: account_id,
        name: metaCampaignForm.campaign_name,
        objective: metaCampaignForm.objective,
        special_ad_categories: metaCampaignForm.special_ad_categories,
        special_ad_category_country: "US",
        bid_strategy: metaCampaignForm.bid_strategy,
        lastuser: email,
        is_draft,
      };

      if (metaCampaignForm.budget_type === CAMPAIGN_DAILY_BUDGET) {
        payload.daily_budget = metaCampaignForm.budget;
      } else if (metaCampaignForm.budget_type === CAMPAIGN_LIFETIME_BUDGET) {
        payload.lifetime_budget = metaCampaignForm.budget;
      }

      return payload;
    },
    [account_id, account_name, email, metaCampaignForm]
  );

  const formatAdSetRow = () => {
    let adSetRow: AdSetRow = {
      id: "",
      adset_name: metaAdSetForm.adset_name,
      campaign_id: metaCampaignForm.id,
      campaign_name: metaCampaignForm.campaign_name,
      meta_id: null,
      bid_strategy: metaAdSetForm.bid_strategy,
      adsmanager_status: metaAdSetForm.status,
      created: null,
      lastuser: "",
      custom_event_type: metaAdSetForm.conversion_event,
      optimization_goal: metaAdSetForm.optimization_goal,
      pixel_id: metaAdSetForm.pixel,
    };

    if (metaAdSetForm.budget_type === AD_SET_DAILY_BUDGET) {
      adSetRow.daily_budget = metaAdSetForm.budget;
    } else if (metaAdSetForm.budget_type === AD_SET_LIFETIME_BUDGET) {
      adSetRow.lifetime_budget = metaAdSetForm.budget;
    }

    if (R.includes(metaAdSetForm.bid_strategy, [LOWEST_COST_WITH_BID_CAP, COST_CAP])) {
      adSetRow.bid_amount = metaAdSetForm.bid_amount;
    } else if (
      metaAdSetForm.bid_strategy === LOWEST_COST_WITH_MIN_ROAS &&
      metaAdSetForm.bid_constraints
    ) {
      adSetRow.roas_average_floor = metaAdSetForm.bid_constraints.roas_average_floor || null;
    }

    return adSetRow;
  };

  interface CreateMetaAdSetParams {
    business_name: string;
    ad_account_id: string;
    name: string;
    promoted_object: AdSetPromotedObject;
    campaign_id: string;
    billing_event: string;
    optimization_goal: string;
    attribution_spec: AttributionSpecRow[] | null;
    bid_strategy: string;
    bid_constraints?: BidConstraint;
    lastuser: string;
    is_draft: boolean;
    daily_budget?: number;
    lifetime_budget?: number;
    bid_amount?: number;
    start_time?: number;
    end_time?: number;
    custom_audiences?: string[];
    excluded_custom_audiences?: string[];
    facebook_positions?: Position[];
    instagram_positions?: Position[];
    messenger_positions?: Position[];
    audience_network_positions?: Position[];
    publisher_platforms?: PlatformPublisher[];
    genders?: GenderId[];
    countries?: string[];
    age_max?: number;
    age_min?: number;
  }

  const formatAdSetPayload = useCallback(
    (campaignId: string, is_draft: boolean): CreateMetaAdSetParams => {
      let payload: CreateMetaAdSetParams = {
        business_name: account_name,
        ad_account_id: account_id,
        name: metaAdSetForm.adset_name,
        promoted_object: {
          pixel_id: metaAdSetForm.pixel,
          custom_event_type: metaAdSetForm.conversion_event,
        },
        campaign_id: campaignId,
        billing_event: "IMPRESSIONS",
        optimization_goal: metaAdSetForm.optimization_goal,
        attribution_spec: [
          { event_type: "VIEW_THROUGH", window_days: metaAdSetForm.view_attr_window },
          { event_type: "CLICK_THROUGH", window_days: metaAdSetForm.click_attr_window },
        ],
        bid_strategy: metaAdSetForm.bid_strategy,
        age_min: metaAdSetForm.age_min,
        age_max: metaAdSetForm.age_max,
        countries: ["US"],
        lastuser: email,
        is_draft,
      };

      if (metaAdSetForm.budget_type === AD_SET_DAILY_BUDGET) {
        payload.daily_budget = metaAdSetForm.budget;
      } else if (metaAdSetForm.budget_type === AD_SET_LIFETIME_BUDGET) {
        payload.lifetime_budget = metaAdSetForm.budget;
      }

      if (R.includes(metaAdSetForm.bid_strategy, [LOWEST_COST_WITH_BID_CAP, COST_CAP])) {
        payload.bid_amount = metaAdSetForm.bid_amount;
      }
      if (metaAdSetForm.bid_constraints) {
        payload.bid_constraints = metaAdSetForm.bid_constraints;
      }

      if (metaAdSetForm.start_time) {
        payload.start_time = metaAdSetForm.start_time;
      }
      if (metaAdSetForm.end_time) {
        payload.end_time = metaAdSetForm.end_time;
      }

      let custom_audiences: string[] = [];
      if (metaAdSetForm.included_audiences_entered) {
        custom_audiences = [...metaAdSetForm.included_audiences_entered];
      }
      if (metaAdSetForm.included_audiences_selected) {
        custom_audiences = [...custom_audiences, ...metaAdSetForm.included_audiences_selected];
      }

      let excluded_custom_audiences: string[] = [];
      if (metaAdSetForm.excluded_audiences_entered) {
        excluded_custom_audiences = [...metaAdSetForm.excluded_audiences_entered];
      }
      if (metaAdSetForm.excluded_audiences_selected) {
        excluded_custom_audiences = [
          ...excluded_custom_audiences,
          ...metaAdSetForm.excluded_audiences_selected,
        ];
      }

      if (custom_audiences.length) {
        payload.custom_audiences = custom_audiences;
      }
      if (excluded_custom_audiences.length) {
        payload.excluded_custom_audiences = excluded_custom_audiences;
      }

      if (metaAdSetForm.facebook_positions.length) {
        payload.facebook_positions = metaAdSetForm.facebook_positions;
      }
      if (metaAdSetForm.instagram_positions.length) {
        payload.instagram_positions = metaAdSetForm.instagram_positions;
      }
      if (metaAdSetForm.messenger_positions.length) {
        payload.messenger_positions = metaAdSetForm.messenger_positions;
      }
      if (metaAdSetForm.audience_network_positions.length) {
        payload.audience_network_positions = metaAdSetForm.audience_network_positions;
      }
      if (metaAdSetForm.publisher_platforms.length) {
        payload.publisher_platforms = metaAdSetForm.publisher_platforms;
      }

      switch (metaAdSetForm.genders) {
        case MEN:
          payload.genders = [ID_MEN];
          break;
        case WOMEN:
          payload.genders = [ID_WOMEN];
          break;
        default:
          payload.genders = [ID_MEN, ID_WOMEN];
      }

      return payload;
    },
    [metaAdSetForm, account_id, account_name, email]
  );

  const formatAdRow = () => {
    let adRow: AdRow = {
      id: metaAdForm.ad_id || "",
      ad_name: metaAdForm.ad_name,
      adset_id: metaAdSetForm.id || metaAdForm.adset_id,
      // If meta ids are not in ad form then they will be fetched from campaign/adset forms
      adset_meta_id: metaAdForm.adset_meta_id || null,
      adset_name: metaAdSetForm.adset_name || metaAdForm.adset_name,
      campaign_id: metaCampaignForm.campaign_id || metaAdForm.campaign_id,
      campaign_meta_id: metaCampaignForm.meta_id || null,
      campaign_name: metaCampaignForm.campaign_name || metaAdForm.campaign_name,
      client_facing: metaAdForm.client_facing,
      approval_stage: metaAdForm.approval_stage || MBApprovalStage.NONE,
      meta_id: metaAdForm.meta_id || null,
      adsmanager_status: STATUS_NONE,
      created: null,
      lastuser: "",
      format: metaAdForm.creative_format,
      link: `${metaAdForm.url}${metaAdForm.url_params}`,
      message: metaAdForm.message,
      headline: metaAdForm.headline,
      description: metaAdForm.description,
      // instagram_actor_id: metaAdForm.instagram_account_id,
      page_id: metaAdForm.facebook_page_id,
      page_name: metaAdForm.page_name || metaAdForm.facebook_page_id, // TODO: Fix this for ad creation
      call_to_action: metaAdForm.call_to_action_type,
      video_id: metaAdForm?.creative_asset?.video_id || null,
      image_hash: metaAdForm?.creative_asset?.hash || null,
      asset_name:
        metaAdForm.creative_format === "IMAGE"
          ? metaAdForm?.creative_asset?.name
          : metaAdForm?.creative_asset?.title,
    };

    return adRow;
  };

  const formatCreative = useCallback(
    (format: typeof IMAGE | typeof VIDEO): Record<string, any> => {
      let object_story_spec: Record<string, any> = {
        page_id: metaAdForm.facebook_page_id,
      };

      const formattedObject: {
        message: string;
        call_to_action: {
          type: string;
          value?: { link: string };
        };
      } = {
        message: metaAdForm.message,
        call_to_action: {
          type: metaAdForm.call_to_action_type,
        },
      };

      // TODO: FIX INSTAGRAM ACCOUNT ISSUE
      // if (metaAdForm.instagram_account_id) {
      //   object_story_spec.instagram_actor_id = metaAdForm.instagram_account_id;
      // }
      if (format === IMAGE) {
        object_story_spec.link_data = {
          ...formattedObject,
          link: `${metaAdForm.url}${metaAdForm.url_params}`,
          name: metaAdForm.headline,
          description: metaAdForm.description,
          image_hash: metaAdForm.creative_asset.hash,
        };
      }
      if (format === VIDEO) {
        formattedObject.call_to_action.value = {
          link: `${metaAdForm.url}${metaAdForm.url_params}`,
        };
        let imageHash: string;
        /*
         * Need to set up some way to use a placeholder thumbnail for the video
         */
        if (account_id === "1504500373017084") {
          imageHash = "7774fd8f5179e070e74664085999b505";
        } else if (account_id === "323885995724895") {
          imageHash = "ccc012063062226cc09392cb376d97cb";
        } else {
          imageHash = metaAdForm.creative_asset.video_id;
        }
        object_story_spec.video_data = {
          ...formattedObject,
          image_hash: imageHash,
          link_description: metaAdForm.description,
          title: metaAdForm.headline,
          video_id: metaAdForm.creative_asset.video_id,
        };
      }

      let creativeSpec: AdCreative = {
        object_story_spec,
      };

      return creativeSpec;
    },
    [metaAdForm, account_id]
  );

  interface CreateMetaAdParams {
    business_name: string;
    ad_account_id: string;
    name: string;
    creative: AdCreative;
    adset_id: string;
    client_facing: boolean;
    lastuser: string;
    is_draft: boolean;
  }

  const formatAdPayload = useCallback(
    (adSetId: string, is_draft: boolean): CreateMetaAdParams => {
      const creative = formatCreative(metaAdForm.creative_format);

      let payload: CreateMetaAdParams = {
        business_name: account_name,
        ad_account_id: account_id,
        name: metaAdForm.ad_name,
        creative,
        adset_id: adSetId,
        client_facing: false,
        lastuser: email,
        is_draft,
      };

      return payload;
    },
    [metaAdForm, account_id, account_name, email, formatCreative]
  );

  const submit = useCallback(
    async (is_draft: boolean): Promise<void> => {
      let campaignId = metaAdSetForm.campaign_id || null;
      let adSetId = metaAdForm.adset_id || metaAdForm.adset_id || null;
      const adId = metaAdForm.ad_id || null;

      let newCreationState: Record<string, any> = {};
      creationTabs.forEach(tab => {
        newCreationState[tab] = IN_PROGRESS;
      });

      setCreationState(newCreationState);
      setCreationType(is_draft ? "draft" : "paused");

      if (R.includes(TAB_CAMPAIGN, creationTabs)) {
        try {
          const campaignPayload = formatCampaignPayload(is_draft);

          const res = await MetaLambdaFetch("/createMetaCampaign", {
            method: "POST",
            body: {
              ...campaignPayload,
              ad_account_id: account_id,
              business_name: business_manager,
            },
          });
          const { data } = await awaitJSON<any>(res);

          campaignId = data.id;
          setLevelCreationState(TAB_CAMPAIGN, SUCCESS);
        } catch (e) {
          const error = e as Error;
          setError({
            message: `Failed to save draft. Error: ${error.message}`,
            reportError: error,
          });
          setLevelCreationState(TAB_CAMPAIGN, FAILED);
        }
      }

      if (R.includes(TAB_AD_SET, creationTabs) && campaignId) {
        try {
          let adSetPayload = formatAdSetPayload(campaignId, is_draft);

          let res = await MetaLambdaFetch("/createMetaAdSet", {
            method: "POST",
            body: {
              ...adSetPayload,
              ad_account_id: account_id,
              business_name: business_manager,
            },
          });
          const { data } = await awaitJSON<any>(res);

          adSetId = data.id;
          setLevelCreationState(TAB_AD_SET, SUCCESS);
        } catch (e) {
          const error = e as Error;
          setError({
            message: `Failed to save draft. Error: ${error.message}`,
            reportError: error,
          });
          setLevelCreationState(TAB_AD_SET, FAILED);
        }
      } else if (R.includes(TAB_AD_SET, creationTabs)) {
        setLevelCreationState(TAB_AD_SET, FAILED);
      }

      if (R.includes(TAB_AD, creationTabs) && adSetId) {
        try {
          const adPayload = formatAdPayload(adSetId, is_draft);

          await MetaLambdaFetch("/createMetaAd", {
            method: "POST",
            body: {
              ...adPayload,
              ad_account_id: account_id,
              business_name: business_manager,
              ad_id: adId,
            },
          });

          setLevelCreationState(TAB_AD, SUCCESS);
        } catch (e) {
          const error = e as Error;
          setError({
            message: `Failed to save draft. Error: ${error.message}`,
            reportError: error,
          });
          setLevelCreationState(TAB_AD, FAILED);
        }
      } else if (R.includes(TAB_AD, creationTabs)) {
        setLevelCreationState(TAB_AD, FAILED);
      }
    },
    [
      account_id,
      business_manager,
      creationTabs,
      formatAdPayload,
      formatAdSetPayload,
      formatCampaignPayload,
      setError,
      metaAdForm,
      metaAdSetForm,
    ]
  );

  useEffect(() => {
    if (!loading && creationType === "paused" && alertDialogType !== PUBLISH_AS_PAUSED) {
      setAlertDialogType(PUBLISH_AS_PAUSED);
    } else if (!loading && creationType === "draft" && alertDialogType !== SAVED_AS_DRAFT) {
      setAlertDialogType(SAVED_AS_DRAFT);
    }
  }, [loading, creationType, alertDialogType, setAlertDialogType]);

  const campaign = R.includes(TAB_CAMPAIGN, creationTabs) ? formatCampaignRow() : undefined;
  const adSet = R.includes(TAB_AD_SET, creationTabs) ? formatAdSetRow() : undefined;
  const ad = R.includes(TAB_AD, creationTabs) ? formatAdRow() : undefined;

  const edit = () => {
    closeModal();
    setTabIndex(0);
  };

  const ReviewModalAlertDialog = useMemo((): JSX.Element => {
    const closeAlertDialog = () => {
      setCreationState(R.clone(creationStateDefault));
      setAlertDialogType(null);
      setCreationType(null);
    };

    let title = "";
    let body = <></>;
    let icon = <></>;
    let variant = AlertDialogVariants.INFO;
    let primaryAction = closeAlertDialog;
    let primaryButtonLabel = "Got it!";
    let secondaryAction: (() => void) | undefined;
    let secondaryButtonLabel;
    let onHide = () => {};

    const LevelNames = ({ levels }: { levels: CreationTab[] }): JSX.Element => (
      <div className="levelNames">
        {R.includes(TAB_CAMPAIGN, levels) && (
          <div className="levelRow">
            <div className="level">{TAB_CAMPAIGN}:</div>
            <div className="name">
              {metaCampaignForm.campaign_name}
              <br />
            </div>
          </div>
        )}
        {R.includes(TAB_AD_SET, levels) && (
          <div className="levelRow">
            <div className="level">{TAB_AD_SET}:</div>
            <div className="name">
              {metaAdSetForm.adset_name}
              <br />
            </div>
          </div>
        )}
        {R.includes(TAB_AD, levels) && (
          <div className="levelRow">
            <div className="level">{TAB_AD}:</div>
            <div className="name">
              {metaAdForm.ad_name}
              <br />
            </div>
          </div>
        )}
      </div>
    );

    switch (alertDialogType) {
      case PUBLISH_AS_PAUSED:
        title = "Published to Meta as paused!";
        body = (
          <div className="body">
            <LevelNames levels={successfulCreations} />
            <br />
            <b>View the published tab or go to Ads Manager to complete the publishing process.</b>
          </div>
        );
        icon = <BsMeta size={72} style={{ color: "#0081FB" }} />;
        primaryAction = () => goToTables(PageTab.PUBLISHED);
        onHide = primaryAction;
        break;
      case SAVED_AS_DRAFT:
        title = "Saved as Draft!";
        body = (
          <div className="body">
            <LevelNames levels={successfulCreations} />
            <br />
            <b>View the drafts page for more options.</b>
          </div>
        );
        icon = <MdCheck size={72} />;
        primaryAction = () => goToTables(PageTab.DRAFTS);
        onHide = primaryAction;
        break;
      case DELETE:
        title = "Delete Drafts?";
        body = (
          <div className="body">
            <LevelNames levels={creationTabs} />
            <br />
            <b>You won’t be able to undo this.</b>
          </div>
        );
        icon = <MdDelete size={72} style={{ color: "#F9A825" }} />;
        primaryAction = () => {
          closeModal();
          setMetaBuyingForm(metaBuyingFormDefault);
        };
        onHide = closeAlertDialog;
        primaryButtonLabel = "Delete";
        secondaryButtonLabel = "Cancel";
        secondaryAction = closeAlertDialog;
        variant = AlertDialogVariants.WARNING;
        break;
      default:
        title = "Creating Ad Levels...";
        body = <div className="body">Please wait.</div>;
    }

    return (
      <AlertDialog
        className="reviewModalAlert"
        variant={variant}
        escapable={true}
        show={!R.isNil(alertDialogType) || loading}
        onHide={onHide}
        title={title}
        icon={icon}
        body={body}
        primaryAction={primaryAction}
        primaryButtonLabel={primaryButtonLabel}
        secondaryAction={secondaryAction}
        secondaryButtonLabel={secondaryButtonLabel}
        loading={loading}
      />
    );
  }, [
    alertDialogType,
    closeModal,
    creationTabs,
    loading,
    setMetaBuyingForm,
    successfulCreations,
    goToTables,
    metaAdForm,
    metaAdSetForm,
    metaCampaignForm,
    creationStateDefault,
  ]);

  const [selectedTab, setSelectedTab] = useState<ReviewModalTab>(
    ad ? ReviewModalTab.DETAILS : ReviewModalTab.NONE
  );

  return (
    <div className="thing">
      {show ? (
        <ReviewModal
          accountID={account_id}
          businessManager={business_manager}
          variant={ReviewModalVariant.EDITING}
          onHide={closeModal}
          campaign={campaign}
          adset={adSet}
          ad={ad}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          dimensions={dimensions}
          publishAsPaused={() => submit(false)}
          disablePublishAsPaused={
            (adSet &&
              metaCampaignForm.status === STATUS_NONE &&
              !R.includes(TAB_CAMPAIGN, creationTabs)) ||
            (ad &&
              ((metaAdSetForm.status === STATUS_NONE && !R.includes(TAB_AD_SET, creationTabs)) ||
                (metaCampaignForm.status === STATUS_NONE && !R.includes(TAB_AD_SET, creationTabs))))
          }
          saveAsDraft={() => submit(true)}
          edit={() => edit()}
          loading={loading}
          remove={() => {
            setAlertDialogType(DELETE);
          }}
        />
      ) : null}
      {ReviewModalAlertDialog}
    </div>
  );
};

export default MetaBuyingReviewModal;

import React, { useState } from "react";
import "./BudgetIntakeToolSettings.scss";
import { RouteComponentProps } from "@reach/router";
import { Dropdown, DropdownToggleType } from "../Components";

interface BudgetIntakeToolGoalsProps {
  company: string;
  campaignTypes: string[];
}

const BudgetIntakeToolSettings = ({
  company,
  campaignTypes,
}: BudgetIntakeToolGoalsProps & RouteComponentProps): JSX.Element => {
  const [campaignTypeSelection, setCampaignTypeSelection] = useState<Record<string, string>>({});

  const handleSelectionChange = (campaignType: string, value: string) => {
    setCampaignTypeSelection(prev => ({ ...prev, [campaignType]: value }));
  };

  return (
    <div className="budgetIntakeToolSettings">
      <div className="budgetIntakeToolSettingsHeaderContainer">
        <div className="budgetIntakeToolSettingsHeaderTextContainer">
          <div className="budgetIntakeToolSettingsHeaderTextColumn">
            <div className="budgetIntakeToolSettingsHeaderText">Goal Granularity Settings</div>
            <div className="bugdetIntakeToolSettingsExplainerText">Goal Granularity Settings</div>
          </div>
        </div>
      </div>
      <div className="budgetIntakeToolSettingsLineBelowHeader"></div>
      <div className="budgetIntakeToolSettingsBodyContainer">
        <div className="budgetIntakeToolSettingsContainer">
          <div className="budgetIntakeToolSettingsTextAndDropDownContainer">
            {campaignTypes?.map((item, index) => {
              return (
                <div className="budgetIntakeToolSettingsRow" key={index}>
                  <div className="budgetIntakeToolSettingsRowHeaderContainer">
                    <div className="budgetIntakeToolSettingsHeaderText">{item} </div>
                  </div>
                  <Dropdown
                    type={DropdownToggleType.OUTLINED}
                    onChange={option => handleSelectionChange(item, option)}
                    options={[
                      { label: "Campaign and Segment Level", value: "campaign" },
                      { label: "Segment Level", value: "segment" },
                    ]}
                    value={campaignTypeSelection[item] || "segment"}
                  ></Dropdown>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BudgetIntakeToolSettings;

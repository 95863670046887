import React, { Dispatch, SetStateAction, useMemo } from "react";
import cn from "classnames";
import * as R from "ramda";
import { KnowYourCustomerData } from "@blisspointmedia/bpm-types/dist/KnowYourCustomer";
import WidgetContainer from "../Components/WidgetContainer";
import {
  Button,
  ButtonFrameworkVariant,
  ButtonType,
  InfoTooltip,
  InfoTooltipDirection,
} from "../Components";
import {
  CUSTOMER_INSIGHTS_FIELDS_TOOLTIP_COPY,
  CUSTOMER_INSIGHTS_LEVELS_PRETTY_NAMES,
  CUSTOMER_INSIGHTS_PRETTY_NAMES,
  CustomerInsightsFields,
  SortOptions,
  TabKey,
  ViewOptions,
} from "./customerInsightsConstants";
import { getCustomerInsightsAlphabeticalSort } from "./customerInsightsUtils";
import CustomerBaseChart from "./CustomerBaseChart";
import IndexChart from "./IndexChart";
import PenetrationChart from "./PenetrationChart";
import { useSetError } from "../redux/modals";
import { useCompanyInfo } from "../redux/company";
import { MdOutlineFileDownload } from "react-icons/md";
import { downloadJSONToCSV } from "../utils/download-utils";
import CustomerInsightsTable from "./CustomerInsightsTable";

const CustomerKey = () => (
  <>
    <div className="keyFilled customerKey" />
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path
        d="M9 0C10.1819 1.4094e-08 11.3522 0.232792 12.4442 0.685084C13.5361 1.13738 14.5282 1.80031 15.364 2.63604C16.1997 3.47177 16.8626 4.46392 17.3149 5.55585C17.7672 6.64778 18 7.8181 18 9L9 9V0Z"
        fill="#CBD2E1"
      />
      ``
    </svg>
    <span>Customer</span>
  </>
);

const USMarketKey = () => (
  <>
    <div className="keyFilled marketKey" />
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <circle cx="9" cy="9" r="9" fill="#E1E6EF" />
      <path
        d="M9 0C10.1819 1.4094e-08 11.3522 0.232792 12.4442 0.685084C13.5361 1.13738 14.5282 1.80031 15.364 2.63604C16.1997 3.47177 16.8626 4.46392 17.3149 5.55585C17.7672 6.64778 18 7.8181 18 9L9 9V0Z"
        fill="#CBD2E1"
      />
    </svg>
    <div>US Market</div>
  </>
);

interface CustomerInsightsGroupProps {
  fields: string[];
  data: Record<string, KnowYourCustomerData[]>;
  header: string;
  sortValue: SortOptions;
  graphView: ViewOptions;
  tab: TabKey;
  companyName: string;
  suppressUnknown: boolean;
}

// TODO: Hovering over one ChartContainer shows the top-right actions for every chart in the group
const CustomerInsightsGroup: React.FC<CustomerInsightsGroupProps> = ({
  fields,
  data,
  header,
  sortValue,
  graphView,
  tab,
  companyName,
  suppressUnknown,
}) => {
  const sortedData = useMemo(() => {
    const dataToSort = R.clone(data);

    if (sortValue === "alphabetical") {
      Object.keys(dataToSort).forEach(key => {
        const value = dataToSort[key];
        const fieldKey = key as CustomerInsightsFields;
        dataToSort[key] = getCustomerInsightsAlphabeticalSort(fieldKey, value);
      });
      return dataToSort;
    }

    let getProperty: (data: KnowYourCustomerData) => any;
    switch (tab) {
      case TabKey.CUSTOMER_BASE:
        getProperty = R.prop("clientPopulation");
        break;
      case TabKey.INDEX:
        getProperty = ({ clientPopulation, tuPopulation }) => clientPopulation / tuPopulation;
        break;
      case TabKey.PENETRATION:
        getProperty = ({ clientPopulationFraction, tuPopulationFraction }) =>
          clientPopulationFraction / tuPopulationFraction;
        break;
    }

    let by: (a: KnowYourCustomerData, b: KnowYourCustomerData) => number;
    switch (sortValue) {
      case "high to low":
        by = R.descend(getProperty);
        break;
      case "low to high":
        by = R.ascend(getProperty);
        break;
    }

    // Apply numerical sort
    Object.keys(dataToSort).forEach(fieldName => {
      const values = dataToSort[fieldName];
      dataToSort[fieldName] = R.sort(by)(values);

      // Replace original levels with pretty names
      Object.keys(CUSTOMER_INSIGHTS_LEVELS_PRETTY_NAMES[fieldName]).forEach(dataName => {
        const prettyName = CUSTOMER_INSIGHTS_LEVELS_PRETTY_NAMES[fieldName][dataName];
        const valsIndex = dataToSort[fieldName].findIndex(item => item.levels === dataName);
        if (valsIndex !== -1) {
          dataToSort[fieldName][valsIndex].levels = prettyName;
        }
      });
    });

    return dataToSort;
  }, [data, sortValue, tab]);

  const renderGraph: (field: string) => React.ReactNode = useMemo(() => {
    return field => {
      const fieldName = field as CustomerInsightsFields;
      switch (tab) {
        case TabKey.CUSTOMER_BASE:
          return <CustomerBaseChart fieldName={fieldName} data={sortedData[field]} />;
        case TabKey.INDEX:
          return <IndexChart fieldName={fieldName} data={sortedData[field]} />;
        case TabKey.PENETRATION:
          return <PenetrationChart fieldName={fieldName} data={sortedData[field]} />;
      }
    };
  }, [sortedData, tab]);

  const renderTable: (field: string) => React.ReactNode = useMemo(() => {
    return field => {
      const fieldPrettyName = CUSTOMER_INSIGHTS_PRETTY_NAMES[field];

      return (
        <div className="tableContainer">
          <div className="title">{fieldPrettyName}</div>
          <CustomerInsightsTable
            tab={tab}
            fieldData={sortedData[field]}
            companyName={companyName}
          />
        </div>
      );
    };
  }, [companyName, sortedData, tab]);

  return (
    <WidgetContainer
      header={header}
      rightActions={
        [TabKey.INDEX, TabKey.PENETRATION].includes(tab) ? (
          <div className="customerInsightsGroupActions">
            <div className="customerInsightsKey">
              <span className="customerInsightsKeyItem">
                <CustomerKey />
              </span>
              <span className="customerInsightsKeyItem">
                <USMarketKey />
              </span>
            </div>
          </div>
        ) : undefined
      }
      collapsible
      headerCollapsibleOnclick
      enableHoverDesign={false}
    >
      {fields.map(field => (
        <div
          key={field}
          className={cn("customerInsightsGroup", {
            fullWidth: field === CustomerInsightsFields.INCOME,
            incomeUnknownHeight: field === CustomerInsightsFields.INCOME && !suppressUnknown,
            incomeNoUnknownHeight: field === CustomerInsightsFields.INCOME && suppressUnknown,
            fixedHeight: field !== CustomerInsightsFields.INCOME,
          })}
        >
          {graphView === "showGraph" && renderGraph(field)}
          {graphView === "showTable" && renderTable(field)}
        </div>
      ))}
    </WidgetContainer>
  );
};

interface CustomerInsightsLegendProps {
  data: {
    name: string;
    val1: string;
    val2?: string;
    computedValue: string;
    color?: string;
  }[];
  hoveredValue?: string;
  setHoveredValue?: Dispatch<SetStateAction<string | undefined>>;
  selectedValue?: string;
  setSelectedValue?: Dispatch<SetStateAction<string | undefined>>;
}

export const CustomerInsightsLegend: React.FC<CustomerInsightsLegendProps> = ({
  data,
  hoveredValue,
  setHoveredValue,
  selectedValue,
  setSelectedValue,
}) => (
  <div className="legend">
    {data.map(({ name, val1, val2, computedValue, color }) => (
      <div
        key={name}
        className={cn("legendItem", {
          hoveredValue: name === hoveredValue,
          unhoveredValue: Boolean(hoveredValue) && name !== hoveredValue,
          selectedValue: !hoveredValue && name === selectedValue,
          unselectedValue: !hoveredValue && Boolean(selectedValue) && name !== selectedValue,
        })}
        onMouseEnter={setHoveredValue ? () => setHoveredValue(name) : undefined}
        onMouseLeave={setHoveredValue ? () => setHoveredValue(undefined) : undefined}
        onClick={
          setSelectedValue
            ? () => setSelectedValue(name === selectedValue ? undefined : name)
            : undefined
        }
      >
        {color && <div className="itemColor" style={{ backgroundColor: color }} />}
        <div className="itemLabel">
          <div>{name}</div>
        </div>
        <div className="itemComputedValue">{computedValue}</div>
        <div className="stackedItemValues">
          {val1 && <div className="itemValue">{val1}</div>}
          {val2 && <div className="itemValue">{val2}</div>}
        </div>
      </div>
    ))}
  </div>
);

export default CustomerInsightsGroup;

interface ChartContainerRightActionsProps {
  fieldName: CustomerInsightsFields;
  data: KnowYourCustomerData[];
}

export const ChartContainerRightActions: React.FC<ChartContainerRightActionsProps> = ({
  fieldName,
  data,
}) => {
  const fieldPrettyName = CUSTOMER_INSIGHTS_PRETTY_NAMES[fieldName];
  const setError = useSetError();
  const { name: companyName } = useCompanyInfo();
  const fieldTooltipCopy = CUSTOMER_INSIGHTS_FIELDS_TOOLTIP_COPY[fieldName];

  return (
    <>
      {fieldTooltipCopy && (
        <InfoTooltip direction={InfoTooltipDirection.BOTTOM_LEFT}>{fieldTooltipCopy}</InfoTooltip>
      )}
      <Button
        type={ButtonType.FILLED}
        variant={ButtonFrameworkVariant.ICON_ONLY}
        icon={<MdOutlineFileDownload />}
        onClick={() => {
          if (!data) {
            setError({ message: "No data available to download" });
            return;
          }

          const formattedData = data.map(row => ({
            "Demographic Category": fieldPrettyName,
            "Demographic Group": row.levels,
            [`${companyName} Population`]: Math.round(row.clientPopulation),
            [`${companyName} Distribution`]: row.clientPopulationFraction,
            "US Market": Math.round(row.tuPopulation),
            "US Market Distribution": row.tuPopulationFraction,
            Index: row.clientPopulationFraction / row.tuPopulationFraction,
            Penetration: row.clientPopulation / row.tuPopulation,
          }));
          const date = new Date();
          downloadJSONToCSV(
            formattedData,
            `CustomerInsights ${companyName} ${fieldPrettyName} ${date.toLocaleString()}`
          );
        }}
      />
    </>
  );
};

import React, { useEffect, useMemo, useState } from "react";
import {
  CampaignRow,
  AdSetRow,
  AdRow,
  CreationTab,
} from "@blisspointmedia/bpm-types/dist/MetaBuying";
import { TAB_CAMPAIGN, TAB_AD_SET, TAB_AD } from "../../MetaBuyingConstants";
import { Modal } from "react-bootstrap";
import { Button, ButtonType, Spinner } from "../../../Components";
import "./ReviewModal.scss";
import { ButtonFrameworkVariant } from "../../../Components/ButtonFramework";
import { MdCheck, MdClose, MdContentCopy, MdDelete, MdEdit } from "react-icons/md";
import { awaitJSON, MetaLambdaFetch } from "../../../utils/fetch-utils";
import * as R from "ramda";
import { WHITE_META_LOGO } from "../../MetaBuyingUtils";
import { formatMoney } from "../../../utils/format-utils";

export enum ReviewModalVariant {
  DRAFT = "draft",
  PUBLISHED = "published",
  EDITING = "editing",
}

export enum ReviewModalTab {
  NONE = "None",
  DETAILS = "Details",
  AD_PREVIEW = "Ad Preview",
}

const DimensionRows = ({
  tab,
  dimensions,
}: {
  tab: CreationTab;
  dimensions: Record<string, any>;
}): JSX.Element => (
  <>
    {R.toPairs(dimensions[tab]).map(([key, value]) => (
      <div className="fieldRow">
        <div className="field">{key}</div>
        <div className="value">{value}</div>
      </div>
    ))}
  </>
);

const CampaignView = ({
  campaign,
  dimensions,
  width,
  variant = ReviewModalVariant.EDITING,
}: {
  campaign: CampaignRow;
  dimensions?: Record<string, any>;
  width?: number;
  variant?: ReviewModalVariant;
}): JSX.Element => {
  return (
    <div className="innerBody" style={width ? { width: `${width}%` } : {}}>
      <div className="headerRow">
        <div className="level campaign">Campaign</div>
        <div className="name">{campaign.campaign_name}</div>
      </div>
      {variant === "editing" && dimensions && dimensions[TAB_CAMPAIGN] && (
        <DimensionRows tab={TAB_CAMPAIGN} dimensions={dimensions} />
      )}
      <div className="fieldRow">
        <div className="field">Campaign Objective</div>
        <div className="value">{campaign.objective}</div>
      </div>
      {campaign.special_ad_categories.length > 0 && (
        <div className="fieldRow">
          <div className="field">Special Ad Categories</div>
          <div className="value">{campaign.special_ad_categories?.join(", ")}</div>
        </div>
      )}
      {campaign.special_ad_category_country !== null && (
        <div className="fieldRow">
          <div className="field">Special Ad Categories Country</div>
          <div className="value">{campaign.special_ad_category_country}</div>
        </div>
      )}
      {campaign.bid_strategy && (
        <div className="fieldRow">
          <div className="field">Bid Strategy</div>
          <div className="value">{campaign.bid_strategy}</div>
        </div>
      )}
      {campaign.lifetime_budget && (
        <div className="fieldRow">
          <div className="field">Lifetime Budget</div>
          <div className="value">{formatMoney(campaign.lifetime_budget / 100)}</div>
        </div>
      )}
      {campaign.daily_budget && (
        <div className="fieldRow">
          <div className="field">Daily Budget</div>
          <div className="value">{formatMoney(campaign.daily_budget / 100)}</div>
        </div>
      )}
      {campaign.spend_cap && (
        <div className="fieldRow">
          <div className="field">Spend Cap</div>
          <div className="value">{formatMoney(campaign.spend_cap / 100)}</div>
        </div>
      )}
      {campaign.lastuser && variant !== "editing" && (
        <div className="fieldRow">
          <div className="field">Last Modified By</div>
          <div className="value">{campaign.lastuser}</div>
        </div>
      )}
      {campaign.created && variant !== "editing" && (
        <div className="fieldRow">
          <div className="field">Created On</div>
          <div className="value">{new Date(campaign.created).toLocaleString()}</div>
        </div>
      )}
    </div>
  );
};

const AdSetView = ({
  adset,
  dimensions,
  width,
  variant = ReviewModalVariant.EDITING,
}: {
  adset: AdSetRow;
  dimensions?: Record<string, any>;
  width?: number;
  variant?: ReviewModalVariant;
}): JSX.Element => {
  return (
    <div className="innerBody" style={width ? { width: `${width}%` } : {}}>
      <div className="headerRow">
        <div className="level adset">Ad Set</div>
        <div className="name">{adset.adset_name}</div>
      </div>
      <div className="fieldRow">
        <div className="field">Campaign</div>
        <div className="value">{adset.campaign_name}</div>
      </div>
      {variant === "editing" && dimensions && dimensions[TAB_AD_SET] && (
        <DimensionRows tab={TAB_AD_SET} dimensions={dimensions} />
      )}
      <div className="fieldRow">
        <div className="field">Pixel</div>
        <div className="value">{adset.pixel_name || adset.pixel_id}</div>
      </div>
      <div className="fieldRow">
        <div className="field">Conversion Event</div>
        <div className="value">
          {adset.custom_conversion_name || adset.custom_conversion_id || adset.custom_event_type}
        </div>
      </div>
      <div className="fieldRow">
        <div className="field">Optimization Goal</div>
        <div className="value">{adset.optimization_goal}</div>
      </div>
      {adset.bid_strategy && (
        <div className="fieldRow">
          <div className="field">Bid Strategy</div>
          <div className="value">{adset.bid_strategy}</div>
        </div>
      )}
      {adset.bid_amount && (
        <div className="fieldRow">
          <div className="field">Bid Amount</div>
          <div className="value">{formatMoney(adset.bid_amount / 100)}</div>
        </div>
      )}
      {adset.roas_average_floor && (
        <div className="fieldRow">
          <div className="field">Min ROAS</div>
          <div className="value">{(adset.roas_average_floor / 10000).toFixed(3)}</div>
        </div>
      )}
      {adset.daily_budget && (
        <div className="fieldRow">
          <div className="field">Daily Budget</div>
          <div className="value">{formatMoney(adset.daily_budget / 100)}</div>
        </div>
      )}
      {adset.lifetime_budget && (
        <div className="fieldRow">
          <div className="field">Lifetime Budget</div>
          <div className="value">{formatMoney(adset.lifetime_budget / 100)}</div>
        </div>
      )}
      {adset.daily_spend_cap && (
        <div className="fieldRow">
          <div className="field">Daily Spend Cap</div>
          <div className="value">{formatMoney(adset.daily_spend_cap / 100)}</div>
        </div>
      )}
      {adset.lifetime_spend_cap && (
        <div className="fieldRow">
          <div className="field">Lifetime Spend Cap</div>
          <div className="value">{formatMoney(adset.lifetime_spend_cap / 100)}</div>
        </div>
      )}
      {adset.age_min && (
        <div className="fieldRow">
          <div className="field">Age Min</div>
          <div className="value">{adset.age_min}</div>
        </div>
      )}
      {adset.age_max && (
        <div className="fieldRow">
          <div className="field">Age Max</div>
          <div className="value">{adset.age_max}</div>
        </div>
      )}
      {adset.genders && (
        <div className="fieldRow">
          <div className="field">Genders</div>
          <div className="value">{adset.genders}</div>
        </div>
      )}
      {adset.click_attribution_window && (
        <div className="fieldRow">
          <div className="field">Click Attribution Window</div>
          <div className="value">{adset.click_attribution_window}</div>
        </div>
      )}
      {adset.view_attribution_window && (
        <div className="fieldRow">
          <div className="field">View Attribution Window</div>
          <div className="value">{adset.view_attribution_window}</div>
        </div>
      )}
      {adset.publisher_platforms && (
        <div className="fieldRow">
          <div className="field">Publisher Platforms</div>
          <div className="value">{adset.publisher_platforms.join(", ")}</div>
        </div>
      )}
      {adset.facebook_positions && (
        <div className="fieldRow">
          <div className="field">Facebook Positions</div>
          <div className="value">{adset.facebook_positions.join(", ")}</div>
        </div>
      )}
      {adset.instagram_positions && (
        <div className="fieldRow">
          <div className="field">Instagram Positions</div>
          <div className="value">{adset.instagram_positions.join(", ")}</div>
        </div>
      )}
      {adset.messenger_positions && (
        <div className="fieldRow">
          <div className="field">Messenger Positions</div>
          <div className="value">{adset.messenger_positions.join(", ")}</div>
        </div>
      )}
      {adset.audience_network_positions && (
        <div className="fieldRow">
          <div className="field">Audience Network Positions</div>
          <div className="value">{adset.audience_network_positions.join(", ")}</div>
        </div>
      )}
      {adset.custom_audiences && (
        <div className="fieldRow">
          <div className="field">Custom Audiences</div>
          <div className="value">{adset.custom_audience_names || adset.custom_audiences}</div>
        </div>
      )}
      {adset.excluded_custom_audiences && (
        <div className="fieldRow">
          <div className="field">Excluded Custom Audiences</div>
          <div className="value">
            {adset.excluded_custom_audience_names || adset.excluded_custom_audiences}
          </div>
        </div>
      )}
      {adset.start_time && (
        <div className="fieldRow">
          <div className="field">Start Time</div>
          <div className="value">{adset.start_time}</div>
        </div>
      )}
      {adset.end_time && (
        <div className="fieldRow">
          <div className="field">End Time</div>
          <div className="value">{adset.end_time}</div>
        </div>
      )}
      {adset.lastuser && variant !== "editing" && (
        <div className="fieldRow">
          <div className="field">Last Modified By</div>
          <div className="value">{adset.lastuser}</div>
        </div>
      )}
      {adset.created && variant !== "editing" && (
        <div className="fieldRow">
          <div className="field">Created On</div>
          <div className="value">{new Date(adset.created).toLocaleString()}</div>
        </div>
      )}
    </div>
  );
};

const AdView = ({
  ad,
  dimensions,
  width,
  variant = ReviewModalVariant.EDITING,
}: {
  ad: AdRow;
  dimensions?: Record<string, any>;
  width?: number;
  variant?: ReviewModalVariant;
}): JSX.Element => {
  return (
    <div className="innerBody" style={width ? { width: `${width}%` } : {}}>
      <div className="headerRow">
        <div className="level ad">Ad</div>
        <div className="name">{ad.ad_name}</div>
      </div>
      <div className="fieldRow">
        <div className="field">Ad Set</div>
        <div className="value">{ad.adset_name}</div>
      </div>
      {variant === "editing" && ad.id === null && dimensions && dimensions[TAB_AD] && (
        <DimensionRows tab={TAB_AD} dimensions={dimensions} />
      )}
      <div className="fieldRow">
        <div className="field">Campaign</div>
        <div className="value">{ad.campaign_name}</div>
      </div>
      <div className="fieldRow">
        <div className="field">Facebook Page</div>
        <div className="value">{ad.page_name}</div>
      </div>
      {/*
       * TODO: Update to account for API changes
       */}
      {/* {ad.instagram_actor_id && (
        <div className="fieldRow">
          <div className="field">Instagram account</div>
          <div className="value">{ad.instagram_actor_id || "Missing"}</div>
        </div>
      )} */}
      <div className="fieldRow">
        <div className="field">Creative Format</div>
        <div className="value">{ad.format}</div>
      </div>
      <div className="fieldRow">
        <div className="field">Asset</div>
        <div className="value">{ad.asset_name}</div>
      </div>
      <div className="fieldRow">
        <div className="field">Link</div>
        <div className="value">{ad.link}</div>
      </div>
      <div className="fieldRow">
        <div className="field">Primary Text</div>
        <div className="value">{ad.message}</div>
      </div>
      <div className="fieldRow">
        <div className="field">{ad.format === "IMAGE" ? "Name" : "Title"}</div>
        <div className="value">{ad.headline}</div>
      </div>
      <div className="fieldRow">
        <div className="field">Description</div>
        <div className="value">{ad.description}</div>
      </div>
      <div className="fieldRow">
        <div className="field">CTA</div>
        <div className="value">{ad.call_to_action}</div>
      </div>
      {ad.lastuser && variant !== "editing" && (
        <div className="fieldRow">
          <div className="field">Last Modified By</div>
          <div className="value">{ad.lastuser}</div>
        </div>
      )}
      {ad.created && variant !== "editing" && (
        <div className="fieldRow">
          <div className="field">Created On</div>
          <div className="value">{new Date(ad.created).toLocaleString()}</div>
        </div>
      )}
    </div>
  );
};

export interface ReviewModalProps {
  variant: ReviewModalVariant;
  accountID: string;
  businessManager: string;
  isInternal?: boolean;
  campaign?: CampaignRow;
  adset?: AdSetRow;
  ad?: AdRow;
  dimensions?: Record<string, any>;
  escapable?: boolean;
  selectedTab: ReviewModalTab;
  setSelectedTab: React.Dispatch<React.SetStateAction<ReviewModalTab>>;
  onHide: () => void;
  publishAsPaused?: () => void;
  disablePublishAsPaused?: boolean;
  saveAsDraft?: () => void;
  edit?: () => void;
  approve?: () => void;
  disapprove?: () => void;
  remove?: () => void;
  loading?: boolean;
}

export const ReviewModal = ({
  variant,
  accountID,
  businessManager,
  isInternal = true,
  campaign,
  adset,
  ad,
  dimensions,
  escapable = true,
  selectedTab = ReviewModalTab.NONE,
  setSelectedTab = () => {},
  onHide = () => {},
  publishAsPaused = () => {},
  disablePublishAsPaused = false,
  saveAsDraft = () => {},
  edit = () => {},
  approve = () => {},
  disapprove = () => {},
  remove = () => {},
  loading = false,
}: ReviewModalProps): JSX.Element => {
  const isDraft = variant === ReviewModalVariant.DRAFT;
  const isPublished = variant === ReviewModalVariant.PUBLISHED;

  const modalTitle = useMemo(() => {
    let title = "";
    if ((isDraft || isPublished) && campaign) {
      title = `Review Campaign${isDraft ? " Draft" : ""}`;
    } else if ((isDraft || isPublished) && adset) {
      title = `Review Ad Set${isDraft ? " Draft" : ""}`;
    } else if ((isDraft || isPublished) && ad) {
      title = `Review Ad${isDraft ? " Draft" : ""}`;
    } else {
      title = "Review Draft";
    }

    return title;
  }, [ad, adset, campaign, isDraft, isPublished]);

  const { idTitle, idValue } = useMemo(() => {
    if (isInternal && campaign && !adset && !ad && isDraft) {
      return { idTitle: `Internal Campaign ID: ${campaign.id}`, idValue: campaign.id };
    } else if (campaign && !adset && !ad && isPublished) {
      return { idTitle: `Meta Campaign ID: ${campaign.id}`, idValue: campaign.id };
    } else if (isInternal && !campaign && adset && !ad && isDraft) {
      return { idTitle: `Internal Ad Set ID: ${adset.id}`, idValue: adset.id };
    } else if (!campaign && adset && !ad && isPublished) {
      return { idTitle: `Meta Ad Set ID: ${adset.id}`, idValue: adset.id };
    } else if (isInternal && !campaign && !adset && ad && isDraft) {
      return { idTitle: `Internal Ad ID: ${ad.id}`, idValue: ad.id };
    } else if (!campaign && !adset && ad && isPublished) {
      return { idTitle: `Meta Ad ID: ${ad.id}`, idValue: ad.id };
    } else {
      return { idTitle: "", idValue: "" };
    }
  }, [ad, adset, campaign, isInternal, isDraft, isPublished]);

  const [iframeIsLoaded, setIframeIsLoaded] = useState(false);
  const [iframeFields, setIframeFields] = useState<{
    src: string;
    width: string;
    height: string;
  }>();

  useEffect(() => {
    if (!iframeFields && ad) {
      (async () => {
        const res = await MetaLambdaFetch("/getAdPreview", {
          method: "POST",
          body: {
            accountID,
            businessManager,
            ad,
          },
        });

        const parsedRes = await awaitJSON(res);
        setIframeFields({
          src: parsedRes.src,
          width: parsedRes.width,
          height: parsedRes.height,
        });
      })();
    }
  }, [iframeFields, setIframeFields, accountID, businessManager, ad]);

  const columnWidth = useMemo(() => 100 / ((campaign ? 1 : 0) + (adset ? 1 : 0) + (ad ? 1 : 0)), [
    campaign,
    adset,
    ad,
  ]);

  return (
    <Modal
      className={`reviewModal ${variant}`}
      show={true}
      onHide={onHide}
      centered
      backdrop={escapable ? true : "static"}
      keyboard={escapable}
    >
      <Modal.Header closeButton className="reviewModalHeader">
        {((isInternal && isDraft) || isPublished) && (
          <div className="titleInfo">
            <div className="modalTitle">{modalTitle}</div>
            <div className="modalID">
              <div className="idDisplay">{idTitle}</div>
              <Button
                icon={<MdContentCopy />}
                variant={ButtonFrameworkVariant.ICON_ONLY}
                type={ButtonType.EMPTY}
                size="sm"
                onClick={() => {
                  navigator.clipboard.writeText(idValue);
                }}
              />
            </div>
          </div>
        )}
        {((!isInternal && isDraft) || variant === ReviewModalVariant.EDITING) && (
          <div className="modalTitle">{modalTitle}</div>
        )}
        {
          // Only show tabs if there is an ad
          ad && (
            <div className="titleButtons">
              <Button
                className={`titleButton${selectedTab === "Details" ? " selected" : " unselected"}`}
                type={ButtonType.EMPTY}
                onClick={() => {
                  setSelectedTab(ReviewModalTab.DETAILS);
                  setIframeIsLoaded(false);
                }}
              >
                Details
              </Button>
              <Button
                className={`titleButton${
                  selectedTab === "Ad Preview" ? " selected" : " unselected"
                }`}
                type={ButtonType.EMPTY}
                onClick={() => {
                  setSelectedTab(ReviewModalTab.AD_PREVIEW);
                }}
              >
                Ad Preview
              </Button>
            </div>
          )
        }
      </Modal.Header>
      <Modal.Body className="reviewModalBody">
        {campaign && selectedTab !== ReviewModalTab.AD_PREVIEW && (
          <CampaignView
            campaign={campaign}
            dimensions={dimensions}
            variant={variant}
            width={columnWidth}
          />
        )}
        {adset && selectedTab !== ReviewModalTab.AD_PREVIEW && (
          <AdSetView adset={adset} dimensions={dimensions} width={columnWidth} variant={variant} />
        )}
        {ad && selectedTab !== ReviewModalTab.AD_PREVIEW && (
          <AdView ad={ad} dimensions={dimensions} width={columnWidth} variant={variant} />
        )}
        {ad && selectedTab === ReviewModalTab.AD_PREVIEW && (
          <div className="adPreview">
            {!iframeIsLoaded && <Spinner />}
            <div
              className="mobilePreview"
              style={{
                opacity: iframeIsLoaded ? 1 : 0,
                height: iframeIsLoaded ? "auto" : 0,
                width: iframeIsLoaded ? "auto" : 0,
                transition: "opacity 0.3s ease-in-out",
              }}
            >
              {iframeFields && (
                <iframe
                  title="test"
                  onLoad={() => setIframeIsLoaded(true)}
                  src={iframeFields?.src}
                  width={iframeFields?.width}
                  height={iframeFields?.height}
                  scrolling="yes"
                  style={{
                    border: "none",
                    display: "block",
                    borderRadius: "20px",
                    visibility: iframeIsLoaded ? "visible" : "hidden",
                  }}
                ></iframe>
              )}
            </div>
          </div>
        )}
      </Modal.Body>
      {isPublished && (
        <Modal.Footer className="reviewModalFooter warning">
          Details may not be correct here if edits have been made in Ads Manager
        </Modal.Footer>
      )}
      {!isInternal && isDraft && (
        <Modal.Footer className="reviewModalFooter">
          <div className="mainActions">
            <Button
              className="disapproveButton"
              type={ButtonType.OUTLINED}
              variant={ButtonFrameworkVariant.TRAILING_ICON}
              icon={<MdClose />}
              onClick={disapprove}
              enableAnimation={false}
              disabled={ad && ad.approval_stage !== "IN_REVIEW"}
            >
              Disapprove
            </Button>
            <Button
              className="approveButton"
              type={ButtonType.OUTLINED}
              variant={ButtonFrameworkVariant.TRAILING_ICON}
              icon={<MdCheck />}
              onClick={approve}
              enableAnimation={false}
              disabled={ad && ad.approval_stage !== "IN_REVIEW"}
            >
              Approve
            </Button>
          </div>
        </Modal.Footer>
      )}
      {((isInternal && isDraft) || variant === ReviewModalVariant.EDITING) && (
        <Modal.Footer className="reviewModalFooter">
          <div className="deleteAction">
            <Button
              variant={ButtonFrameworkVariant.ICON_ONLY}
              type={ButtonType.EMPTY}
              icon={<MdDelete />}
              onClick={remove}
              disabled={loading}
            />
          </div>
          <div className="mainActions">
            <Button
              className="editButton"
              variant={ButtonFrameworkVariant.LEADING_ICON}
              icon={<MdEdit />}
              type={ButtonType.OUTLINED}
              onClick={edit}
              disabled={loading || (isDraft && !ad)}
            >
              Edit
            </Button>
            <Button
              variant={ButtonFrameworkVariant.TRAILING_ICON}
              icon={
                <img src={WHITE_META_LOGO} alt="icon" style={{ width: "22px", height: "22px" }} />
              }
              type={ButtonType.FILLED}
              onClick={publishAsPaused}
              disabled={
                (ad && ad.approval_stage !== "APPROVED") || loading || disablePublishAsPaused
              }
            >
              Publish As Paused
            </Button>
            {variant === ReviewModalVariant.EDITING && (
              <Button type={ButtonType.FILLED} onClick={saveAsDraft} disabled={loading}>
                Save as Draft
              </Button>
            )}
          </div>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default ReviewModal;

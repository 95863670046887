import React, { Dispatch, useState } from "react";
import "./BudgetIntakeToolGoals.scss";
import { RouteComponentProps } from "@reach/router";
import { PendingChangesPanel, Skeleton, TableSkeleton } from "../Components";
import { ModalEditTableForBudgets } from "./Modals/ModalEditTableForBudgets";
import { StateSetter } from "../utils/types";
import { BudgetIntakeRowInternal } from "@blisspointmedia/bpm-types/dist/BudgetIntakeTool";

interface BudgetIntakeToolGoalsProps {
  company: string;
  tableData: BudgetIntakeRowInternal[];
  setTableData: Dispatch<any[]>;
  originalTableData: BudgetIntakeRowInternal[];
  originalTableDataMap: Record<string, any>;
  customSegmentsList: string[];
  budgetIntakeHeadersCustom: any[];
  budgetIntakeSelectorOptionsPull: {};
  showTableData: boolean;
  saving: boolean;
  saveChanges: () => void;
  clearAllChanges: () => void;
  bulkImport: (file: File) => Promise<void>;
  hasPendingChanges: boolean;
  showPendingChanges: boolean;
  setShowPendingChanges: StateSetter<boolean>;
  updatedRows: BudgetIntakeRowInternal[];
  newRows: BudgetIntakeRowInternal[];
  deletedRows: BudgetIntakeRowInternal[];
  setDeletedRows?: Dispatch<any[]>;
  deletedBudget: BudgetIntakeRowInternal[];
  shouldEnableBudgetIntakeTool: boolean | string;
}

const BudgetIntakeToolGoals = ({
  company,
  tableData,
  setTableData,
  originalTableData,
  originalTableDataMap,
  customSegmentsList,
  budgetIntakeHeadersCustom,
  budgetIntakeSelectorOptionsPull,
  showTableData,
  saving,
  saveChanges,
  clearAllChanges,
  bulkImport,
  hasPendingChanges,
  showPendingChanges,
  setShowPendingChanges,
  updatedRows,
  newRows,
  deletedRows,
  setDeletedRows,
  deletedBudget,
  shouldEnableBudgetIntakeTool,
}: BudgetIntakeToolGoalsProps & RouteComponentProps): JSX.Element => {
  const [invalidText, setInvalidText] = useState<string>();

  const checkIsValid = (data: any) => {
    const { id, start_date, end_date, goal_name, goal_value } = data;
    if (
      !start_date ||
      !end_date ||
      !customSegmentsList?.some(segment => data[segment] != null) ||
      !goal_name ||
      !goal_value
    ) {
      setInvalidText("Start Date, End Date, segments, Goal Name and Goal Value cannot be empty");
      return false;
    }
    const startDate = new Date(start_date);
    const endDate = new Date(end_date);
    if (startDate > endDate) {
      setInvalidText("End Date should not be before start date");
      return false;
    }
    if (tableData) {
      for (let oldRow of tableData) {
        if (oldRow.id === id) {
          return true;
        }
        const isSegmentDuplicate = !customSegmentsList?.some(
          segment => oldRow[segment] !== data[segment]
        );

        if (
          oldRow.start_date === start_date &&
          oldRow.end_date === end_date &&
          isSegmentDuplicate &&
          oldRow.goal_name === goal_name
        ) {
          setInvalidText("Duplicate of existing Row");
          return false;
        }
      }
    }
    return true;
  };

  return (
    <div className="budgetIntakeToolContainer">
      {showTableData && tableData && budgetIntakeSelectorOptionsPull ? (
        <ModalEditTableForBudgets
          className="budgetIntakeTool"
          filterBar
          headers={budgetIntakeHeadersCustom}
          tableData={tableData}
          setTableData={setTableData}
          deletedRows={deletedRows}
          setDeletedRows={setDeletedRows}
          selectorOptions={budgetIntakeSelectorOptionsPull}
          enableDelete={true}
          enableAdd={true}
          checkIsValid={checkIsValid}
          invalidText={invalidText}
          showModalOnAdd={true}
          hasPendingChanges={hasPendingChanges}
          setShowPendingChanges={setShowPendingChanges}
          saveChanges={saveChanges}
          isSaving={saving}
          clearAllChanges={clearAllChanges}
          downloadData={tableData}
          company={company}
          customSegmentsList={customSegmentsList || []}
          importFunction={bulkImport}
          shouldEnableBudgetIntakeTool={shouldEnableBudgetIntakeTool}
        ></ModalEditTableForBudgets>
      ) : (
        <Skeleton>
          <TableSkeleton />
        </Skeleton>
      )}
      {showPendingChanges && (
        <PendingChangesPanel
          originalData={originalTableDataMap || {}}
          pendingChanges={{
            editedRows: updatedRows,
            newRows: newRows,
            deletedRows: deletedBudget,
          }}
          showPendingChanges={showPendingChanges}
          setShowPendingChanges={setShowPendingChanges}
          headers={budgetIntakeHeadersCustom}
        />
      )}
    </div>
  );
};

export default BudgetIntakeToolGoals;
